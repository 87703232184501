import React from 'react';
import clsx from 'clsx';
import { Card, Typography, Avatar,makeStyles  } from '@material-ui/core';
import gradients from "../../../../utils/gradients";
import {List} from "react-feather";
import {useSelector} from "react-redux";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";


const useStyles = makeStyles(theme => ({
  root: {
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48
  }
}));

const truncateDecimals = function (number, digits) {
  let multiplier = Math.pow(10, digits),
      adjustedNum = number * multiplier,
      truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};


const FinanceSelect = props => {
  const { className, ...rest } = props;
  const classes = useStyles(props);
  const student = useSelector(state => state.students.fetched_student);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      onClick={()=> {props.handleNavigatePages('finance')}}
    >
      <div>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="inherit"
        >
          Finance
        </Typography>
        <div className={classes.details}>
          <Typography variant="h5" color="inherit" style={{marginRight: '5px'}}>
            Student Balance:
          </Typography>
          <Typography variant="h3" color="inherit">
            ${
            truncateDecimals((parseFloat(student.all_payed)) - (student.agreement_tax), 2)
          }
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <AttachMoneyIcon />
      </Avatar>
    </Card>
  );
};

FinanceSelect.propTypes = {
};

export default FinanceSelect;
