import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {
    Link as RouterLink, Navigate,
    useNavigate
} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Checkbox, CircularProgress, Container, FormHelperText, Link} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../../Actions";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import * as Yup from "yup";
import Page from "../../../../Components/Page";
import ErrorAlert from "../../../../Helpers/error-alert";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(7),
        paddingTop: theme.spacing(0)
    },
    box:{
        marginBottom: theme.spacing(3),

    }

}));


export default function CreateSecretary() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState('')

    const create_status = useSelector(state => state.teachers.secretary.status)
    const dispatch = useDispatch();


    function handleSubmit(values) {
        // e.preventDefault();
        if (values) {
            dispatch(userActions.createSecretary(values));
            setLoading(true)
            // handleEnableTeacherProfile()
        }
    }

    useEffect(()=>{
        if(create_status === 'succeeded'){
            setLoading(false)
            setMessage('Secretary Created Successfully')
        }

        if(create_status === 'failed'){
            setLoading(false)
            setMessage('Failed To Create Secretary')

        }
    },[create_status])

    useEffect(()=>{
        setTimeout(()=>{
            setMessage('')
        }, 6000)
    }, [message])



    return <Page
        className={classes.root}
        // title="Register Instructor"
    >
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
        >
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        username: '',
                        password: '',
                        is_staff: true,
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            first_name: Yup.string().max(255).required('First name is required'),
                            last_name: Yup.string().max(255).required('Last name is required'),
                            password: Yup.string().max(255).required('password is required'),
                        })
                    }
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off"
                        >
                            <Box mb={3} className={classes.box}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Create New Secretary
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Enter secretary's details to create new account
                                </Typography>
                            </Box>
                            <TextField
                                autoFocus
                                error={Boolean(touched.firstName && errors.firstName)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.firstName && errors.firstName}
                                label="First name"
                                margin="normal"
                                required
                                name="first_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.lastName && errors.lastName)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.lastName && errors.lastName}
                                label="Last name"
                                required
                                margin="normal"
                                name="last_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="username"
                                name="username"
                                // autoFocus
                                autoComplete="off"
                                value={values.username}
                                onChange={handleChange}
                            />
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.password && errors.password}
                                label="Password"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.password}
                                variant="outlined"
                            />

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {loading ? <CircularProgress size={24} className={classes.buttonProgress}/> : 'Create Secretary'}
                                </Button>
                                {message? <ErrorAlert open={true} alertMessage={message} severity={'outlinedInfo'}/>  : null}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    </Page>
}
