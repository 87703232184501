import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Typography, makeStyles, Button} from '@material-ui/core';
import {Link as RouterLink, useParams} from "react-router-dom";
import {ArrowLeft} from "react-feather";

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  startDateButton: {
    marginRight: theme.spacing(1)
  },
  endDateButton: {
    marginLeft: theme.spacing(1)
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  button:{
    marginTop: theme.spacing(1),
    borderRadius: '12px'
  }
}));

const Header = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const {id} = useParams();


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h1"
        gutterBottom
        variant="h3"
      >
        Export Student Data
      </Typography>
      <Typography variant="subtitle1">Select the data you want to export, then preview or download the pdf</Typography>
      <Button
          color="primary"
          className={classes.button}
          variant="contained"
          component={RouterLink}
          to={id? `/app/account/${id}/sessions` : `/app/list`}
          startIcon={<ArrowLeft />}
      >
        Back
      </Button>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};
Header.defaultProps = {};
export default Header;
