import React, {useEffect} from 'react';
import {Navigate, useParams, useNavigate} from 'react-router-dom';
import {Tabs, Tab, Divider, makeStyles, colors} from '@material-ui/core';

import {
    Header,
    General,
    Other,
} from './components';
import Page from "../../components/Page";
import {userActions} from "../../Actions";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

const Settings = () => {
    const classes = useStyles();
    const {tab} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const constants = useSelector(state => state.students.settings)
    const editing_settings = useSelector(state => state.students.editing_settings)
    const settings_edited = useSelector(state => state.students.settings_edited)
    const [loading, setLoading] = React.useState(false)
    const [fieldsAreEmpty, setFieldsAreEmpty] = React.useState(false)
    const userType = useSelector(state => state.authentication.userType)

    const [settingsData, setSettingsData] = React.useState(
        {
            session_hour: 18,
            inspection_hour: 6,
            tax_rate: 14.975,
            manual_price: "1699.99",
            auto_price: "1499.99",
            session_price: "79.99",
            extra_hour_price: "79.99",
            rental_truck_price: "179.99",
            email_send_in_price: '00.00',
            session_hour_for_send_email: 1,
            f_package_hour:24,
            f_package_inspection_hour:6,
            f_package_job_hour:4,
            f_package_price:2999.99,
        }
    )

    useEffect(() => {
        dispatch(userActions.getSettings())

    }, [])

    useEffect(() => {
        if (constants) {
            setSettingsData(constants)
        }
    }, [constants])

    useEffect(() => {
        if (settings_edited && !editing_settings) {
            setLoading(false)
        }
    }, [settings_edited, editing_settings])


    const handleTabsChange = (event, value) => {
        navigate(`/app/settings/${value}`, {replace: true})
    };

    const tabs = [
        {value: 'general', label: 'General'},
        {value: 'other', label: 'Other'}
    ];


    function handleChange(e) {
        const {name, value} = e.target;
        if (name === 'tax_rate') {
            setSettingsData(data => ({...data, [name]: parseFloat(value)}));

        } else {
            setSettingsData(data => ({...data, [name]: value}));

        }

        // if(name === 'agreement'){
        //     setUser(user => ({...user, agreement_after_tax: (parseFloat(value) * (1+ 0.14975)).toFixed(2)}))
        // }
    }

    function submitSettings(e) {
        e.preventDefault()
        if (settingsData.session_hour &&
            settingsData.inspection_hour &&
            settingsData.rental_truck_price &&
            settingsData.tax_rate &&
            settingsData.manual_price &&
            settingsData.auto_price &&
            settingsData.extra_hour_price &&
            settingsData.session_price &&
            settingsData.email_send_in_price &&
            settingsData.session_hour_for_send_email&&
            settingsData.f_package_hour&&
            settingsData.f_package_inspection_hour&&
            settingsData.f_package_job_hour&&
            settingsData.f_package_price
        ) {
            setFieldsAreEmpty(false)
            setLoading(true)
            dispatch(userActions.editSettings(settingsData))
        } else {
            setFieldsAreEmpty(true)
        }

    }


    if (!tab) {
        return <Navigate to="/settings/general/"/>;
    }

    if (!tabs.find(t => t.value === tab)) {
        return <Navigate to="/errors/error-404"/>;
    }


    return (

        <Page
            className={classes.root}
            title="Settings"
        >
            {userType === 'admin' &&
            <div>
                <Header/>
                <Tabs
                    className={classes.tabs}
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={tab}
                    variant="scrollable"
                >
                    {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                    ))}
                </Tabs>
                <Divider className={classes.divider}/>
                <div className={classes.content}>
                    {
                        tab === 'general' && <General FieldsAreEmpty={fieldsAreEmpty} loading={loading}
                                                      settingsData={settingsData} handleChange={handleChange}
                                                      submitSettings={submitSettings}
                        />
                    }
                    {/*{tab === 'other' && <Other/>}*/}
                </div>
            </div>
            }


        </Page>
    );
};

Settings.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default Settings;
