import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useNavigate} from 'react-router-dom';
import {makeStyles, colors, Grid, Tabs, Tab,} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import Header from "./components/Header";
import Page from "../../components/Page";
import TeacherSessions from "./components/TeacherSessions";
import TeacherExpenses from "./components/TeacherExpenses";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));


const TeachersView = () => {
    const classes = useStyles();
    const {tab} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));


    const handleTabsChange = (event, value) => {
        navigate(`/teacher/${value}`, {replace: true})
    };

    const tabs = [
        {value: 'sessions', label: 'Upcoming Sessions'},
        {value: 'expenses', label: 'Expenses'}
    ];

    if (!tab) {
        return <Navigate to="/teacher/sessions"/>;
    }

    if (!tabs.find(t => t.value === tab)) {
        return <Navigate to="/teacher/sessions"/>;
    }


    return (
        <Page
            className={classes.root}
            title="Teacher's Panel"
        >

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Header user={user}/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Tabs
                        className={classes.tabs}
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={tab}
                        variant="scrollable"
                    >
                        {tabs.map(tab => (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                    </Tabs>
                </Grid >


                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {
                        tab === 'sessions' && <TeacherSessions/>

                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {
                        tab === 'expenses' && <TeacherExpenses/>

                    }

                </Grid>
            </Grid>


        </Page>
    );
};

TeachersView.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default TeachersView;
