import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    colors, makeStyles, ListItem, List, ListItemIcon, ListItemText, Box, withStyles
} from '@material-ui/core';
import National_logo2 from '../../static/National_Logo2.png'
import National_logo from '../../static/National_Logo.jpg'
import Signature from '../../static/Signature.jpg'

import Seal from '../../static/Seal.png'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const useStyles = makeStyles(theme => ({
  root: {
      position: 'relative',
      height: '949px',
      width: '1161px',
      '&:before':{
          content: '""',
          background: `url(${National_logo2}) no-repeat center center`,
          backgroundSize: '1000px 500px',
          padding: theme.spacing(6),
          margin:theme.spacing(6),
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          // opacity: '0.06',
      }
  },

  content: {
    padding: theme.spacing(4),
      margin:theme.spacing(4),
      border: '1px solid #000',
      borderWidth: '20px',
  },
  marginTop: {
    marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  },
  level: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  school:{
    color: '#ec1a31'
  },
  succeed:{
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
    seal:{
        display:'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    passed:{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight:theme.spacing(4)
    },
  footer:{

  },
  listItem: {
    padding: theme.spacing(1, 0),
    justifyContent: 'space-evenly'
  },
  photo :{
  height: '100px',
  width: '200px'
},
    sealImage:{
        height: '200px',
        width: '210px'
    },

    signature:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    signature_photo:{
        height: '190px',
        width: '110px'
    }
}));

const Details = React.forwardRef((props, ref) => {
  const { className,name, certDate, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      ref={ref}
      id={'cert'}
    >
        <CardContent className={classes.content}
        >
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              align="left"
              component="h5"
              variant="h5"
            >
              Date of Issue: {certDate? certDate.format('DD/MM/yyyy'): moment().format('DD/MM/yyyy')}
            </Typography>

            <Typography
                align="left"
                component="h5"
                variant="h5"
            >
              License Number: -
            </Typography>

          </Grid>
          <Grid item>
            <img
                alt="Brand"
                className={classes.photo}
                src={National_logo}
            />
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          className={classes.marginTop}
          container
          justify="center"
        >
          <Grid item>
            <Typography align="center" component="h1"
                        variant="h1">
                <Box fontFamily={"UnifrakturMaguntia"} fontSize={60}>
                    Certificate of Graduation
                </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.marginTop}
          container
          justify="center"
        >
          <Grid item>
            <Typography variant={"overline"}>
              This certificate is awarded to
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.marginTop,)}
          container
          justify="center"
        >
          <Grid item>
            <Typography
              component="h1"
              gutterBottom
              variant="h1"
            >
                {name? name :'No Name'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
            className={clsx(classes.marginTop,)}
            container
            justify="center"
        >
          <Grid item >
            <div className={classes.level}>
              <Typography
                  gutterBottom
                  variant="overline"
              >
                Has successfully completed The Truck Driving course of {' '}
              </Typography>
              <Typography
                  // component="h1"
                  gutterBottom
                  variant="h3"
              >
                Class 1
              </Typography>
              <Typography
                  // component="h1"
                  gutterBottom
                  variant="overline"
              >
                {' '}at:
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
            className={clsx(classes.marginTop,)}
            container
            justify="center"
        >
          <Grid item>
            <Typography
                component="h1"
                gutterBottom
                variant="h1"
                className={classes.school}
            >
              National Driving School
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.footer}>
          <Grid container justify={"space-between"}>
            <Grid item xs={3} style={{justifySelf: 'flex-end'}}>
                <div className={clsx(classes.marginTop, classes.seal)}>
                    <div>
                        <img
                            alt="Brand"
                            className={classes.sealImage}
                            src={Seal}
                        />
                    </div>


                    {/*<div className={clsx(classes.marginTop, classes.succeed)}>*/}
                    {/*    <Typography*/}
                    {/*        component="h4"*/}
                    {/*        gutterBottom*/}
                    {/*        variant="h6"*/}
                    {/*        color={"textPrimary"}*/}
                    {/*    >*/}
                    {/*        Training to job environment :*/}
                    {/*    </Typography>*/}
                    {/*</div>*/}

                    <div className={clsx( classes.succeed)}>
                        <Typography
                            component="h4"
                            gutterBottom
                            variant="h6"
                            color={"textSecondary"}
                        >
                            {`Succeeded:${' '}`}
                        </Typography>
                        <Typography
                            component="h4"
                            gutterBottom
                            variant="body1"
                        >
                            {`${' '}Class 1 license`}
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={5}>
              <List>
                  <ListItem className={classes.listItem} divider>
                      <ListItemText>
                          <div className={classes.succeed}>
                              <ListItemIcon>
                                  <FiberManualRecordIcon />
                              </ListItemIcon>
                              <Typography variant="subtitle2">Training to job enviroment</Typography>
                          </div>
                      </ListItemText>
                      <ListItemText className={classes.passed}>
                          <Typography variant="h6">Passed</Typography>
                      </ListItemText>
                  </ListItem>
                <ListItem className={classes.listItem} divider>
                  <ListItemText>
                    <div className={classes.succeed}>
                    <ListItemIcon>
                      <FiberManualRecordIcon />
                    </ListItemIcon>
                    <Typography variant="subtitle2">Attitude</Typography>
                    </div>
                  </ListItemText>
                  <ListItemText className={classes.passed}>
                    <Typography variant="h6">Passed</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem} divider>
                    <ListItemText>
                        <div className={classes.succeed}>
                            <ListItemIcon>
                                <FiberManualRecordIcon />
                            </ListItemIcon>
                            <Typography variant="subtitle2">Road handling</Typography>
                        </div>
                    </ListItemText>

                    <ListItemText className={classes.passed}>
                        <Typography variant="h6">Passed</Typography>
                    </ListItemText>
                </ListItem>

                <ListItem className={classes.listItem} divider>
                  <ListItemText>
                    <div className={classes.succeed}>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <Typography variant="subtitle2">Safe decision</Typography>
                    </div>
                  </ListItemText>
                  <ListItemText className={classes.passed}>
                    <Typography variant="h6">Passed</Typography>
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.listItem} divider>
                    <ListItemText>
                        <div className={classes.succeed}>
                            <ListItemIcon>
                                <FiberManualRecordIcon />
                            </ListItemIcon>
                            <Typography variant="subtitle2">Both and unhitch Trailer</Typography>
                        </div>
                    </ListItemText>
                  <ListItemText className={classes.passed}>
                      <Typography variant="h6">Passed</Typography>
                  </ListItemText>
                </ListItem>

                <ListItem className={classes.listItem} divider>
                    <ListItemText>
                        <div className={classes.succeed}>
                            <ListItemIcon>
                                <FiberManualRecordIcon />
                            </ListItemIcon>
                            <Typography variant="subtitle2">Truck maintenance</Typography>
                        </div>
                    </ListItemText>
                    <ListItemText className={classes.passed}>
                        <Typography variant="h6">Passed</Typography>
                    </ListItemText>
                </ListItem>
              </List>
            </Grid>


            <Grid item xs={4} className={classes.signature}>
                    <div className={classes.signature}>
                        <img
                            alt="Signature"
                            className={classes.signature_photo}
                            src={Signature}
                        />
                        <Typography
                            component="h6"
                            gutterBottom
                            variant="h6"
                        >
                            National Driving School Inc.
                        </Typography>
                        <Typography
                            component="h6"
                            gutterBottom
                            variant="h6"
                        >
                            Administration
                        </Typography>
                    </div>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
});

Details.propTypes = {
  className: PropTypes.string,
};

export default Details;
