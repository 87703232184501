import React, {useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography,
    colors,
    makeStyles, TextField, CircularProgress
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Label from "../Notes/components/Label";
import {ArrowLeft, Delete, Edit, Save, Trash} from "react-feather";


const useStyles = makeStyles(theme => ({
    root: { minHeight: '100%'},
    header: {
        paddingBottom: 0
    },
    content: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0
        }
    },
    description: {
        padding: theme.spacing(2, 3, 1, 3)
    },
    tags: {
        padding: theme.spacing(0, 3, 1, 3),
        '& > * + *': {
            marginLeft: theme.spacing(1)
        }
    },
    learnMoreButton: {
        marginLeft: theme.spacing(2)
    },
    likedButton: {
        color: colors.red[600]
    },
    shareButton: {
        marginLeft: theme.spacing(1)
    },
    details: {
        padding: theme.spacing(1, 3)
    },
    text: {
        minWidth: '100%'
    }
}));

const NoteDetails = props => {
    const {
        sendingData,
        disableIsEditing,
        handleSubmitEdit,
        handleChange,
        editNoteData,
        handleEditNoteToggle,
        isEditing,
        noteDetails,
        note_id,
        className,
        isAdding,
        handleSubmitCreate,
        handleDeleteNote,
        ...rest
    } = props;
    const classes = useStyles();
    const {id} = useParams()
    const [liked, setLiked] = useState(false);


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader

                className={classes.header}
                disableTypography
                title={
                    isEditing || isAdding ? (
                        <TextField
                            placeholder={"Note Title"}
                            autoFocus
                            multiline={true}
                            inputProps={{className: classes.text}}
                            value={editNoteData.title}
                            name={"title"}
                            fullWidth
                            onChange={handleChange}
                            // onBlur={event => setIsNamedFocused(false)}
                        />
                    ) : (
                        <Typography
                            color="textPrimary"
                            // component={RouterLink}
                            // to="/projects/1/overview"
                            variant="h4"
                        >
                            {noteDetails[note_id].title}
                        </Typography>
                    )
                }
            />
            <CardContent className={classes.content}>
                <div className={classes.description}>
                    {isEditing || isAdding? (
                        <TextField
                            placeholder={"Note Text (optional)"}
                            // label="Text"
                            multiline
                            rows={4}
                            // variant="outlined"
                            // autoFocus
                            inputProps={{className: classes.text}}
                            value={editNoteData.text}
                            name={"text"}
                            fullWidth
                            onChange={handleChange}
                            // onBlur={event => setIsNamedFocused(false)}
                        />
                    ) : (

                        <Typography style={{maxWidth: '100%', whiteSpace: "unset",
                            wordBreak: "break-all"}}
                            color="textSecondary"
                            variant="h6"
                        >
                            {noteDetails[note_id].text}
                        </Typography>
                    )}

                </div>
                <Divider/>
                <div className={classes.details}>
                    <Grid
                        alignItems="center"
                        container
                        justify="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            {!isAdding? (<div>
                                <Typography variant="subtitle2">
                                    {moment(noteDetails[note_id].updated_at).format('DD MMMM YYYY')}
                                </Typography>
                                <Typography variant="body2">Updated at</Typography>
                            </div>) : null}


                        </Grid>
                        <Grid item>

                            {isEditing || isAdding? (<Tooltip title="Save">
                                <IconButton
                                    className={classes.likeButton}
                                    onClick={isEditing? handleSubmitEdit: isAdding? handleSubmitCreate : null}
                                    size="small"
                                >
                                    {sendingData ? (
                                            <CircularProgress size={24} className={classes.buttonProgress}/>)
                                        : (
                                            <Save/>
                                        )
                                    }

                                </IconButton>
                            </Tooltip>) : (
                                <Tooltip title="Edit Note">
                                    <IconButton
                                        className={classes.likeButton}
                                        onClick={handleEditNoteToggle}
                                        size="small"
                                    >
                                        <Edit/>
                                    </IconButton>
                                </Tooltip>)}


                            {!isEditing && !isAdding ? <Tooltip title="Delete Note">
                                <IconButton
                                    className={classes.shareButton}
                                    size="small"
                                    onClick={()=>handleDeleteNote(note_id)}
                                >
                                    {sendingData ? (
                                            <CircularProgress size={24} className={classes.buttonProgress}/>)
                                        : (
                                            <Trash/>
                                        )
                                    }
                                </IconButton>
                            </Tooltip> :null}
                            {isEditing || isAdding? (<Button
                                    className={classes.learnMoreButton}
                                    // component={RouterLink}
                                    size="small"
                                    // to={`/app/account/${id}/sessions/notes`}
                                    onClick={disableIsEditing}
                                >
                                    Cancel
                                </Button>) :
                                (<Button
                                    className={classes.learnMoreButton}
                                    component={RouterLink}
                                    size="small"
                                    to={`/app/account/${id}/sessions/notes`}
                                    startIcon={<ArrowLeft />}
                                >
                                    Back
                                </Button>)}

                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    );
};

NoteDetails.propTypes = {
    className: PropTypes.string,
    noteDetails: PropTypes.object.isRequired
};

export default NoteDetails;
