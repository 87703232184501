import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    makeStyles,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    FormControlLabel,
    Radio,
    RadioGroup,
    TableContainer,
    Typography,
    Checkbox,
    CardActions,
    CardContent,
    Menu,
    MenuItem, Hidden, Grid, Fab, withStyles, ListItemIcon, ListItemText
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../Actions";
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SessionCardComponent from "../../views/account/Student/Components/SessionCardComponent";
import InspectionCardComponent from "../../views/account/Student/Components/InspectionCardComponent";
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import {
    Book,
    PlusCircle,
    Truck
} from "react-feather";
import {students} from "../../Reducers/students.reducer";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .super-app-theme--cell': {
            backgroundColor: 'rgb(255,255,255)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgb(255,255,255)',
            color: '#1a3e72',
            fontWeight: '600',
            borderRight: `1px solid ${
                theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
            }`,

        },
        '& .super-app.free': {
            backgroundColor: '#67d038',
            color: '#1a3e72',
            fontWeight: '600',

        },
        '& .super-app.busy': {
            backgroundColor: '#d01e41',
            color: '#1a3e72',
            fontWeight: '600',
        },

        '& .super-app.selected': {
            backgroundColor: '#46e9da',
            color: '#1a3e72',
            fontWeight: '600',
        },

    },
    root2: {
        minHeight: '100%',
        borderRadius: '12px'
    },
    actions: {
        justifyContent: 'flex-end'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(8),
            right: theme.spacing(2),
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(8),
            left: theme.spacing(2),
        },
    },
    cardTitle:{
        color: 'rgba(2,1,1,0.74)'
    }

}));


function returnActions(student){
    if(student.f_package){
        return [
            {icon: <Truck/>, name: 'Regular', value: 'regular'},
            {icon: <WorkOutlineRoundedIcon/>, name: 'Job', value: 'job'},
            {icon: <Book/>, name: 'Inspection', value: 'inspection'},
            {icon: <PlusCircle/>, name: 'Extra', value: 'extra'},

        ]
    }else{
        return [
            {icon: <Truck/>, name: 'Regular', value: 'regular'},
            {icon: <Book/>, name: 'Inspection', value: 'inspection'},
            {icon: <PlusCircle/>, name: 'Extra', value: 'extra'},

        ]
    }

}



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


const ShowStudentSessions = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {id} = useParams(); // this is the Student ID
    const navigate = useNavigate();
    const sessions = useSelector(state => state.students.fetched_student_sessions);
    const student = useSelector(state => state.students.fetched_student);
    const inspection_sessions = useSelector(state => state.students.fetched_student_inspection_sessions);
    const [openConfirmSessionDialog, setOpenConfirmSessionDialog] = React.useState(false)
    const [openConfirmDeleteSessionDialog, setOpenConfirmDeleteSessionDialog] = React.useState(false)
    const [openConfirmCancelSessionDialog, setOpenConfirmCancelSessionDialog] = React.useState(false)
    const [deleteSessionId, setDeleteSessionId] = React.useState("")
    const [cancelSessionId, setCancelSessionId] = React.useState("")
    const [deletedSession, setDeletedSession] = React.useState({})
    const [canceledSession, setCanceledSession] = React.useState({})
    const [confirmInspectionDialog, setConfirmInspectionDialog] = React.useState(false)
    const [confirmingInspection, setConfirmingInspection] = React.useState(false)
    const [confirmInspectionId, setConfirmInspectionId]= React.useState()

    const [confirmSessionWithStudentData, setConfirmSessionWithStudentData] = React.useState(
        {
            teacher_id: '',
            student_id: '',
            id: '',
            identification_number: ''
        }
    )

    // const [confirmSessionWithTeacherData, setConfirmSessionWithTeacherData] = React.useState(
    //     {
    //         teacher_id: '',
    //         student_id: '',
    //         id: '',
    //     }
    // )
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        // if (props.allFetchingDone) {
        setAnchorEl(false)

        // }


    };


    const renderRedirect = (type) => {
        return navigate(`/app/account/${id}/add_student_session/${type}`)
    }

    // function handleClassTypeChange(e) {
    //     // if(!addingInspectionClass){
    //     setCreateAddSessionData(createAddSessionData =>
    //         ({...createAddSessionData, type_of_class: e.target.value}));
    //     // }
    //     // if(addingInspectionClass){
    //     if (e.target.value === 'short') {
    //         setCreateAddInspectionSessionData(createAddInspectionSessionData =>
    //             ({...createAddInspectionSessionData, duration: 'S'}))
    //     }
    //     if (e.target.value === 'long') {
    //         setCreateAddInspectionSessionData(createAddInspectionSessionData =>
    //             ({...createAddInspectionSessionData, duration: 'L'}))
    //     }
    //
    //     // }
    //
    // }
    //
    // function handleExtraHourChange(value) { // when creating a new session for student, set it as extra hour if the option is selected
    //     setCreateAddSessionData(createAddSessionData => ({
    //         ...createAddSessionData,
    //         extra_hour: !createAddSessionData.extra_hour
    //     }));
    //
    // }


// ------------------------------------------------------------------------------------------------------- Show Session STuff
    function getDate(s) {
        let b = s.split(/\D+/);
        let toObject = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return toObject.toDateString()
    }

    function getTimeRange(start_time, end_time) {
        let b = start_time.split(/\D+/);
        let start = new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]).getHours().toString()

        let c = end_time.split(/\D+/);
        let end = new Date(c[0], --c[1], c[2], c[3], c[4], c[5], c[6]).getHours().toString()

        return start + " - " + end
    }

//-----------------------------------------------------------------------------------------------------------------------
//     const handleOpenDialog = () => {
//         setAddingInspectionClass(false)
//
//         setCreateAddSessionData(createAddSessionData => ({
//             ...createAddSessionData,
//             extra_hour: false
//         }));
//         props.setOpenSessionTableDialog(true)
//
//     };

    // const handleOpenInspectionDialog = () => {
    //     setAddingInspectionClass(true)
    //     setCreateAddInspectionSessionData(createAddInspectionSessionData =>
    //         ({...createAddInspectionSessionData, student: id, duration: 'L'}))
    //     props.setOpenSessionTableDialog(true)
    //
    // };
    //
    // const handleOpenExtraHourDialog = () => { //same as open dialog but sets extra hour to true
    //     setAddingInspectionClass(false)
    //     setCreateAddSessionData(createAddSessionData => ({
    //         ...createAddSessionData,
    //         extra_hour: true
    //     }));
    //     props.setOpenSessionTableDialog(true)
    //
    // };
    // const handleCloseDialog = () => {
    //     props.setOpenDialog(false);
    // };


    // const handleOpenSessionTableDialog = () => { //TODO: on close, delete store data and fetch again
    //     if (props.allFetchingDone) {
    //         dispatch(userActions.clearFetchedTeacherVacations())
    //         dispatch(userActions.clearFetchedTeacherOvertimes())
    //         dispatch(userActions.clearFetchedTeacherSessions())
    //         dispatch(userActions.clearFetchedTeacherInspectionSessions())
    //         dispatch(userActions.clearTeacherTableData())
    //         props.setOpenSessionTableDialog(false);
    //     }
    //
    //
    // };
    //

    const handleCloseConfirmSessionDialog = () => {
        setOpenConfirmSessionDialog(false)
        // setConfirmSessionWithTeacherData({
        //     teacher_id: '',
        //     student_id: '',
        //     id: '',
        // })
        setConfirmSessionWithStudentData({
            teacher_id: '',
            student_id: '',
            id: '',
            identification_number: ''
        })

    }
    const handleOpenConfirmSessionDialog = (session, student, teacher) => {
        setConfirmSessionWithStudentData(data => ({...data, id: session}))
        setConfirmSessionWithStudentData(data => ({...data, student_id: student}))
        setConfirmSessionWithStudentData(data => ({...data, teacher_id: teacher}))
        // setConfirmSessionWithTeacherData(data => ({...data, id: session}))
        // setConfirmSessionWithTeacherData(data => ({...data, student_id: student}))
        setOpenConfirmSessionDialog(true)
    }


    const handleStudentIdentificationNumberChange = (e) => {
        setConfirmSessionWithStudentData(data => ({...data, identification_number: e.target.value}))
    }

    // const handleTeacherIdChange = (e) => {
    //     setConfirmSessionWithTeacherData(data => ({...data, teacher_id: e.target.value}))
    // }

    const handleConfirmSessionSubmit = () => {
        dispatch(userActions.confirmSessionWithStudentIdentification(confirmSessionWithStudentData))
        dispatch(userActions.getStudentSessions(id))
        dispatch(userActions.getStudent(id));
        setOpenConfirmSessionDialog(false)

    }

    // const handleConfirmSessionWithTeacherSubmit = () => {
    //     dispatch(userActions.confirmSessionWithTeacherId(confirmSessionWithTeacherData))
    //     dispatch(userActions.getStudentSessions(id))
    //     dispatch(userActions.getStudent(id));
    //     setOpenConfirmSessionDialog(false)
    //
    // }


    // const handleAddSessionSubmit = () => {
    //     if (!addingInspectionClass) {
    //         setCreateAddSessionData({...createAddSessionData, type_of_class: sessionTypeOfClass})
    //         if (createAddSessionData.start && createAddSessionData.type_of_class &&
    //             createAddSessionData.teacher && createAddSessionData.student) {
    //             dispatch(userActions.createStudentSession(createAddSessionData))
    //             setCreateAddSessionData(createAddSessionData => ({...createAddSessionData, extra_hour: false}));
    //             handleOpenSessionTableDialog() //closes the dialog after request is done
    //
    //         }
    //     }
    //
    //     if (addingInspectionClass) {
    //         if (createAddInspectionSessionData.start && createAddInspectionSessionData.duration &&
    //             createAddInspectionSessionData.teacher && createAddInspectionSessionData.student) {
    //             dispatch(userActions.createStudentInspectionSession(createAddInspectionSessionData, id))
    //             setCreateAddSessionData(createAddSessionData => ({...createAddSessionData, extra_hour: false}));
    //             handleOpenSessionTableDialog()
    //             // setTimeout(() =>{
    //             //     dispatch(        dispatch(userActions.getStudentInspectionSessions(id))
    //             //     )
    //             // }, 800)
    //
    //         }
    //     }
    //
    //
    // }

    //----------------------------------------------------Delete Session Stuff


    const handleOpenConfirmDeleteSessionDialog = (session_id) => {
        setDeleteSessionId(session_id)
        setOpenConfirmDeleteSessionDialog(true)
        let temp = {...sessions.find(session => session.id === session_id)}
        console.log(temp)
        setDeletedSession(temp)
    }

    const handleSubmitDeleteSession = () => { // runs when clicked yes in delete session confirmation
        dispatch(userActions.deleteSession(deleteSessionId))
        handleCloseConfirmDeleteSessionDialog()
    }

    const handleCloseConfirmDeleteSessionDialog = () => {
        setOpenConfirmDeleteSessionDialog(false)
        setDeletedSession({})
        setDeleteSessionId("")
        setTimeout(function () {
            dispatch(userActions.getStudentSessions(id));
        }, 800); // refresh afrer 800 ms
    }

    const handleCancelDeletingSession = () => { // runs when clicked no in delete session confirmation
        handleCloseConfirmDeleteSessionDialog()
        setDeletedSession({})
        setDeleteSessionId("")
    }

    //---------------------------------------Handle Cancel session stuff------------------------------
    const handleOpenConfirmCancelSessionDialog = (session_id) => {
        setCancelSessionId(session_id)
        setOpenConfirmCancelSessionDialog(true)
        let temp = {...sessions.find(session => session.id === session_id)}
        console.log(temp)
        setCanceledSession(temp)
    }

    const handleSubmitCancelSession = () => { // runs when clicked yes in delete session confirmation
        dispatch(userActions.cancelSession(cancelSessionId))
        handleCloseConfirmCancelSessionDialog()
    }

    const handleCloseConfirmCancelSessionDialog = () => {
        setOpenConfirmCancelSessionDialog(false)
        setCanceledSession({})
        setCancelSessionId("")
        setTimeout(function () {
            dispatch(userActions.getStudentSessions(id));
        }, 800); // refresh afrer 800 ms
    }

    const handleCancelCancellingSession = () => { // runs when clicked no in delete session confirmation
        handleCloseConfirmCancelSessionDialog()
        setCanceledSession({})
        setCancelSessionId("")
    }

    // useEffect(() =>{
    //     if(addingInspectionClass){
    //         setCreateAddInspectionSessionData(createAddInspectionSessionData=>
    //             ({...createAddInspectionSessionData,teacher: props.selectedTeacherId}))
    //         console.log(createAddInspectionSessionData)
    //     }
    // },[props.selectedTeacherId, addingInspectionClass])

    // CONFIRM INSPECTION STUFF--------------------
    function handleToggleCofirmInspection(id){
        if(!confirmInspectionDialog){
            setConfirmInspectionId(id)
            setConfirmInspectionDialog(true)
        }else{
            setConfirmInspectionId(undefined)
            setConfirmInspectionDialog(false)
        }

    }


    return (
        <div>
            <Hidden smDown>
                <Card className={clsx(classes.root2)}>
                    <CardHeader title={
                        <Typography variant={"subtitle1"}>
                            Session History
                        </Typography>
                    } />
                    <Divider/>
                    <CardContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell sortDirection="desc">
                                            <Tooltip enterDelay={300} title="Sort">
                                                <TableSortLabel active direction="desc">
                                                    Date
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>time</TableCell>
                                        <TableCell>Teacher</TableCell>

                                        <TableCell>Status</TableCell>

                                        {/*<TableCell>*/}
                                        {/*    Cancellation*/}
                                        {/*</TableCell>*/}

                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sessions.map((session, index) => (!session.extra_hour && !session.job_session ? (
                                        <TableRow
                                            hover
                                            key={session.start}
                                            style={
                                                session.cancellation
                                                    ? {backgroundColor: '#fafafa'}
                                                    : null
                                            }
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <Typography
                                                    color={
                                                        session.cancellation
                                                            ? 'textSecondary'
                                                            : 'textPrimary'
                                                    }
                                                    variant="body1"
                                                >
                                                    {session ? getDate(session.start) : null}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    color={
                                                        session.cancellation
                                                            ? 'textSecondary'
                                                            : 'textPrimary'
                                                    }
                                                    variant="body1"
                                                >
                                                    {session
                                                        ? getTimeRange(session.start, session.end)
                                                        : null}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography
                                                    color={
                                                        session.cancellation
                                                            ? 'textSecondary'
                                                            : 'textPrimary'
                                                    }
                                                    variant="body1"
                                                >
                                                    {session.teacher}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Button
                                                    startIcon={
                                                        session.status === 'confirmed' ? (
                                                            <DoneOutlineRoundedIcon/>
                                                        ) : null
                                                    }
                                                    disabled={session.cancellation}
                                                    variant="contained"
                                                    style={
                                                        session.status === 'confirmed'
                                                            ? {backgroundColor: '#8DFF76'}
                                                            : session.status === 'no show'
                                                            ? {backgroundColor: '#FF1D4D'}
                                                            : null
                                                    }
                                                    type="success"
                                                    onClick={() => handleOpenConfirmSessionDialog(
                                                        session.id,
                                                        session.student,
                                                        session.teacher_id
                                                    )}
                                                >
                                                    {session.cancellation ? 'Cancelled' : session.status}
                                                </Button>
                                            </TableCell>

                                            {!session.cancellation ? (
                                                <TableCell
                                                    style={{display: 'flex', flexDirection: 'row'}}
                                                    align="right"
                                                >
                                                    <Button
                                                        color="secondary"
                                                        disabled={session.cancellation}
                                                        onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}
                                                        startIcon={
                                                            !session.cancellation ? (
                                                                <CancelOutlinedIcon/>
                                                            ) : null
                                                        }
                                                    >
                                                        {/*{session.cancellation ? '' : ''}*/}
                                                    </Button>
                                                    <br/>

                                                    <IconButton
                                                        onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}
                                                        color="secondary"
                                                        disabled={session.cancellation}
                                                    >
                                                        <DeleteForeverRoundedIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            ) : (
                                                <TableCell/>
                                            )}
                                        </TableRow>
                                    ) : null))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>

                    <CardHeader title={
                        <Typography variant={"subtitle1"}>
                            Extra Sessions
                        </Typography>
                    }/>
                    <Divider/>
                    <Box minWidth={800}>
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell sortDirection="desc">
                                                <Tooltip enterDelay={300} title="Sort">
                                                    <TableSortLabel active direction="desc">
                                                        Date
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                time
                                                </div>
                                            </TableCell>
                                            <TableCell>Teacher</TableCell>

                                            <TableCell>Status</TableCell>

                                            {/*<TableCell>*/}
                                            {/*    Cancellation*/}
                                            {/*</TableCell>*/}

                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessions.map((session, index) => (session.extra_hour && !session.job_session ? (
                                            <TableRow
                                                hover
                                                key={session.start}
                                                style={
                                                    session.cancellation
                                                        ? {backgroundColor: '#fafafa'}
                                                        : null
                                                }
                                            >
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session ? getDate(session.start) : null}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session
                                                            ? getTimeRange(session.start, session.end)
                                                            : null}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session.teacher}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        startIcon={
                                                            session.status === 'confirmed' ? (
                                                                <DoneOutlineRoundedIcon/>
                                                            ) : null
                                                        }
                                                        disabled={session.cancellation}
                                                        variant="contained"
                                                        style={
                                                            session.status === 'confirmed'
                                                                ? {backgroundColor: '#8DFF76'}
                                                                : session.status === 'no show'
                                                                ? {backgroundColor: '#FF1D4D'}
                                                                : null
                                                        }
                                                        type="success"
                                                        onClick={() => handleOpenConfirmSessionDialog(
                                                            session.id,
                                                            session.student,
                                                            session.teacher_id
                                                        )}
                                                    >
                                                        {session.cancellation
                                                            ? 'Cancelled'
                                                            : session.status}
                                                    </Button>
                                                </TableCell>

                                                {/*<TableCell>*/}
                                                {/*    <Button color={"secondary"}*/}
                                                {/*            disabled={session.cancellation}*/}

                                                {/*            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}*/}
                                                {/*            startIcon={<CancelOutlinedIcon/>}*/}
                                                {/*        // style={session.cancellation ? {backgroundColor: '#808B96', color:'#17202A'} :*/}
                                                {/*        //         null*/}
                                                {/*        //             }*/}
                                                {/*    >*/}
                                                {/*        {session.cancellation ? 'Cancelled' : 'Cancel'}*/}

                                                {/*    </Button>*/}

                                                {/*</TableCell>*/}

                                                {!session.cancellation ? (
                                                    <TableCell
                                                        style={{display: 'flex', flexDirection: 'row'}}
                                                        align="right"
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            disabled={session.cancellation}
                                                            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}
                                                            startIcon={
                                                                !session.cancellation ? (
                                                                    <CancelOutlinedIcon/>
                                                                ) : null
                                                            }
                                                        >
                                                            {/*{session.cancellation ? '' : ''}*/}
                                                        </Button>
                                                        <br/>

                                                        <IconButton
                                                            onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}
                                                            color="secondary"
                                                            disabled={session.cancellation}
                                                        >
                                                            <DeleteForeverRoundedIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell/>
                                                )}
                                            </TableRow>
                                        ) : null))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>


                    <CardHeader title={
                        <Typography variant={"subtitle1"}>
                            Job Environment
                        </Typography>
                    }/>
                    <Divider/>
                    <Box minWidth={800}>
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell sortDirection="desc">
                                                <Tooltip enterDelay={300} title="Sort">
                                                    <TableSortLabel active direction="desc">
                                                        Date
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>time</TableCell>
                                            <TableCell>Teacher</TableCell>

                                            <TableCell>Status</TableCell>

                                            {/*<TableCell>*/}
                                            {/*    Cancellation*/}
                                            {/*</TableCell>*/}

                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessions.map((session, index) => (!session.extra_hour && session.job_session ? (
                                            <TableRow
                                                hover
                                                key={session.start}
                                                style={
                                                    session.cancellation
                                                        ? {backgroundColor: '#fafafa'}
                                                        : null
                                                }
                                            >
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session ? getDate(session.start) : null}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session
                                                            ? getTimeRange(session.start, session.end)
                                                            : null}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography
                                                        color={
                                                            session.cancellation
                                                                ? 'textSecondary'
                                                                : 'textPrimary'
                                                        }
                                                        variant="body1"
                                                    >
                                                        {session.teacher}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        startIcon={
                                                            session.status === 'confirmed' ? (
                                                                <DoneOutlineRoundedIcon/>
                                                            ) : null
                                                        }
                                                        disabled={session.cancellation}
                                                        variant="contained"
                                                        style={
                                                            session.status === 'confirmed'
                                                                ? {backgroundColor: '#8DFF76'}
                                                                : session.status === 'no show'
                                                                ? {backgroundColor: '#FF1D4D'}
                                                                : null
                                                        }
                                                        type="success"
                                                        onClick={() => handleOpenConfirmSessionDialog(
                                                            session.id,
                                                            session.student,
                                                            session.teacher_id
                                                        )}
                                                    >
                                                        {session.cancellation
                                                            ? 'Cancelled'
                                                            : session.status}
                                                    </Button>
                                                </TableCell>

                                                {/*<TableCell>*/}
                                                {/*    <Button color={"secondary"}*/}
                                                {/*            disabled={session.cancellation}*/}

                                                {/*            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}*/}
                                                {/*            startIcon={<CancelOutlinedIcon/>}*/}
                                                {/*        // style={session.cancellation ? {backgroundColor: '#808B96', color:'#17202A'} :*/}
                                                {/*        //         null*/}
                                                {/*        //             }*/}
                                                {/*    >*/}
                                                {/*        {session.cancellation ? 'Cancelled' : 'Cancel'}*/}

                                                {/*    </Button>*/}

                                                {/*</TableCell>*/}

                                                {!session.cancellation ? (
                                                    <TableCell
                                                        style={{display: 'flex', flexDirection: 'row'}}
                                                        align="right"
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            disabled={session.cancellation}
                                                            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}
                                                            startIcon={
                                                                !session.cancellation ? (
                                                                    <CancelOutlinedIcon/>
                                                                ) : null
                                                            }
                                                        >
                                                            {/*{session.cancellation ? '' : ''}*/}
                                                        </Button>
                                                        <br/>

                                                        <IconButton
                                                            onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}
                                                            color="secondary"
                                                            disabled={session.cancellation}
                                                        >
                                                            <DeleteForeverRoundedIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell/>
                                                )}
                                            </TableRow>
                                        ) : null))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>




                    <Box>
                        <CardHeader title={
                            <Typography variant={"subtitle1"}>
                                Inspection Sessions
                            </Typography>
                        }/>
                        <Divider/>
                        <CardContent>
                            <TableContainer>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell sortDirection="desc">
                                                <Tooltip
                                                    enterDelay={300}
                                                    title="Sort"
                                                >
                                                    <TableSortLabel
                                                        active
                                                        direction="desc"
                                                    >
                                                        Date
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                time
                                            </TableCell>
                                            <TableCell>
                                                Teacher
                                            </TableCell>

                                            <TableCell>
                                                Status
                                            </TableCell>

                                            {/*<TableCell>*/}
                                            {/*    Cancellation*/}
                                            {/*</TableCell>*/}

                                            <TableCell>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inspection_sessions.map((session, index) => (
                                            <TableRow
                                                hover
                                                key={session.start}
                                                style={session.cancellation ? {backgroundColor: '#fafafa'} : null}
                                            >
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={session.cancellation ? 'textSecondary' : 'textPrimary'}
                                                        variant='body1'
                                                    >
                                                        {session ? getDate(session.inspection.start) : null}

                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color={session.cancellation ? 'textSecondary' : 'textPrimary'}
                                                        variant='body1'
                                                    >
                                                        {session ? getTimeRange(session.inspection.start, session.inspection.end) : null}

                                                    </Typography>
                                                </TableCell>


                                                <TableCell>
                                                    <Typography
                                                        color={session.cancellation ? 'textSecondary' : 'textPrimary'}
                                                        variant='body1'
                                                    >
                                                        {session.inspection.teacher}
                                                    </Typography>
                                                </TableCell>


                                                <TableCell>
                                                    <Button startIcon={session.status === 'confirmed' ?
                                                        <DoneOutlineRoundedIcon/> : null}
                                                            disabled={true} //{session.cancellation}

                                                        // TODO: fix after adding functionality
                                                            variant="contained"
                                                            style={session.status === 'confirmed' ? {backgroundColor: '#8DFF76'} :
                                                                session.status === 'no show' ? {backgroundColor: '#FF1D4D'} :
                                                                    null}
                                                            type="success"
                                                            onClick={() => handleToggleCofirmInspection(session.inspection.id)}
                                                    >
                                                        {session.cancellation ? 'Cancelled' : session.status === 'P' ? 'PENDING' : null}
                                                    </Button>
                                                </TableCell>


                                                {/*<TableCell>*/}
                                                {/*    <Button color={"secondary"}*/}
                                                {/*            disabled={session.cancellation}*/}

                                                {/*            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}*/}
                                                {/*            startIcon={<CancelOutlinedIcon/>}*/}
                                                {/*        // style={session.cancellation ? {backgroundColor: '#808B96', color:'#17202A'} :*/}
                                                {/*        //         null*/}
                                                {/*        //             }*/}
                                                {/*    >*/}
                                                {/*        {session.cancellation ? 'Cancelled' : 'Cancel'}*/}

                                                {/*    </Button>*/}

                                                {/*</TableCell>*/}

                                                {!session.cancellation ?
                                                    <TableCell style={{display: 'flex', flexDirection: 'row',}}
                                                               align="right">

                                                        <Button color={"secondary"}
                                                                disabled={true} //{session.cancellation}

                                                                onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}
                                                                startIcon={!session.cancellation ?
                                                                    <CancelOutlinedIcon/> : null}
                                                            // style={session.cancellation ? {backgroundColor: '#808B96', color:'#17202A'} :
                                                            //         null
                                                            //             }
                                                        >
                                                            {/*{session.cancellation ? '' : ''}*/}

                                                        </Button>
                                                        <br/>

                                                        <IconButton
                                                            onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}
                                                            color="secondary"
                                                            disabled={true}

                                                        >
                                                            <DeleteForeverRoundedIcon/>
                                                        </IconButton>


                                                    </TableCell>

                                                    :
                                                    <TableCell/>
                                                }

                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Box>


                    <CardActions style={{display: 'flex', justifyItems: 'flex-end', justifyContent: 'flex-end'}}>
                        <Button
                            color="primary"
                            endIcon={<ArrowRightIcon/>}
                            size="small"
                            variant="contained"
                            onClick={handleClick}

                            // onClick={handleOpenDialog}
                        >
                            <AddIcon/> Add Session
                        </Button>

                        <StyledMenu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <StyledMenuItem onClick={() => renderRedirect('regular')}>
                                <ListItemIcon>
                                    <Truck/>
                                </ListItemIcon>
                                <ListItemText primary="Regular"/>
                            </StyledMenuItem>

                            <StyledMenuItem onClick={() => renderRedirect('inspection')}>
                                <ListItemIcon>
                                    <Book/>
                                </ListItemIcon>
                                <ListItemText primary="Inspection"/>
                            </StyledMenuItem>
                            {student.f_package?
                                <StyledMenuItem onClick={() => renderRedirect('job')}>
                                <ListItemIcon>
                                <WorkOutlineRoundedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Job Environment"/>
                                </StyledMenuItem>
                                : null }


                            <StyledMenuItem onClick={() => renderRedirect('extra')}>
                                <ListItemIcon>
                                    <PlusCircle/>
                                </ListItemIcon>
                                <ListItemText primary="Extra"/>
                            </StyledMenuItem>
                        </StyledMenu>

                    </CardActions>
                </Card>
            </Hidden>
            <Hidden mdUp>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        {sessions.map((session, index) => (!session.extra_hour ? (
                            <div key={session.id}>
                                <SessionCardComponent
                                    session={session}
                                    date={getDate(session.start)}
                                    time={getTimeRange(session.start, session.end)}
                                    handleOpenConfirmSessionDialog={handleOpenConfirmSessionDialog}
                                    handleOpenConfirmDeleteSessionDialog={handleOpenConfirmDeleteSessionDialog}
                                    handleOpenConfirmCancelSessionDialog={handleOpenConfirmCancelSessionDialog}


                                />
                                <br/>
                            </div>


                        ) : null))}

                        {sessions.map((session, index) => (session.extra_hour ? (
                            <div key={session.id}>
                                <SessionCardComponent
                                    session={session}
                                    date={getDate(session.start)}
                                    time={getTimeRange(session.start, session.end)}
                                    handleOpenConfirmSessionDialog={handleOpenConfirmSessionDialog}
                                    handleOpenConfirmDeleteSessionDialog={handleOpenConfirmDeleteSessionDialog}
                                    handleOpenConfirmCancelSessionDialog={handleOpenConfirmCancelSessionDialog}


                                />
                                <br/>
                            </div>


                        ) : null))}

                        {inspection_sessions.map((session, index) => (session ? (
                            <div key={session.id}>
                                <InspectionCardComponent
                                    status={session.status}
                                    session={session.inspection}
                                    date={getDate(session.inspection.start)}
                                    time={getTimeRange(session.inspection.start, session.inspection.end)}
                                    handleOpenConfirmSessionDialog={handleOpenConfirmSessionDialog}
                                    handleOpenConfirmDeleteSessionDialog={handleOpenConfirmDeleteSessionDialog}
                                    handleOpenConfirmCancelSessionDialog={handleOpenConfirmCancelSessionDialog}


                                />
                                <br/>
                            </div>


                        ) : null))}

                        {/*<Menu*/}
                        {/*    id="simple-menu"*/}
                        {/*    anchorEl={anchorEl}*/}
                        {/*    keepMounted*/}
                        {/*    open={Boolean(anchorEl)}*/}
                        {/*    onClose={handleClose}*/}
                        {/*>*/}
                        {/*    <MenuItem>*/}
                        {/*        <Link to={`add_student_session/extra`}>*/}
                        {/*            Extra Hour*/}
                        {/*        </Link>*/}
                        {/*    </MenuItem>*/}

                        {/*    <MenuItem>*/}
                        {/*        <Link to={`add_student_session/inspection`}>*/}
                        {/*            Inspection Session*/}
                        {/*        </Link>*/}
                        {/*    </MenuItem>*/}

                        {/*    <MenuItem>*/}
                        {/*        <Link to={`add_student_session/regular`}>*/}
                        {/*            Regular Session*/}
                        {/*        </Link>*/}
                        {/*    </MenuItem>*/}
                        {/*</Menu>*/}


                        {/*<Fab*/}
                        {/*    color="primary"*/}
                        {/*    aria-label="add"*/}
                        {/*    className={classes.fab}*/}
                        {/*    onClick={handleClick}*/}

                        {/*    //   style={{*/}
                        {/*    //     margin: 0,*/}
                        {/*    //     top: 'auto',*/}
                        {/*    //     right: 20,*/}
                        {/*    //     bottom: 70,*/}
                        {/*    //     left: 'auto',*/}
                        {/*    //     position: 'fixed',*/}
                        {/*    // }}*/}
                        {/*>*/}
                        {/*    <AddIcon/>*/}
                        {/*</Fab>*/}
                        <SpeedDial
                            ariaLabel="SpeedDial example"
                            className={classes.speedDial}
                            // hidden={hidden}
                            icon={<AddIcon/>}
                            onClose={handleClose}
                            onOpen={handleClick}
                            open={Boolean(anchorEl)}
                            direction={'up'}
                        >
                            {returnActions(student).map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    tooltipOpen={true}
                                    onClick={() => renderRedirect(action.value)}
                                />
                            ))}

                        </SpeedDial>
                    </Grid>


                </Grid>


            </Hidden>


            {/*<Dialog open={openConfirmSessionDialog} onClose={handleCloseConfirmSessionDialog} // confirm inspection dialog*/}
            {/*        aria-labelledby="form-dialog-title">*/}
            {/*    <DialogTitle id="form-dialog-title">Confirm Session</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DialogContentText>*/}
            {/*            Enter student identification number to confirm class*/}
            {/*        </DialogContentText>*/}

            {/*        <div className={classes.search}>*/}
            {/*            <div className={classes.row}>*/}
            {/*                <TextField style={{}} id="student_identification_number"*/}
            {/*                           autoComplete="off"*/}
            {/*                           label="Student Identification" variant="outlined"*/}
            {/*                           color={"secondary"}*/}
            {/*                           onChange={handleStudentIdentificationNumberChange}*/}
            {/*                           value={confirmSessionWithStudentData.identification_number} required/>*/}

            {/*                <IconButton size={"medium"}*/}
            {/*                            onClick={handleConfirmSessionSubmit}*/}
            {/*                >*/}
            {/*                    <DoneOutlineRoundedIcon color={"primary"}/>*/}
            {/*                </IconButton>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button startIcon={<CloseRoundedIcon/>} variant="contained"*/}
            {/*                onClick={handleCloseConfirmSessionDialog} color="primary" type={"next"}>*/}
            {/*            Close*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}


            <Dialog open={openConfirmSessionDialog} onClose={handleCloseConfirmSessionDialog}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm Session</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter student identification number to confirm class
                    </DialogContentText>

                    <div className={classes.search}>
                        <div className={classes.row}>
                            <TextField style={{}} id="student_identification_number"
                                       autoComplete="off"
                                       label="Student Identification" variant="outlined"
                                       color={"secondary"}
                                       onChange={handleStudentIdentificationNumberChange}
                                       value={confirmSessionWithStudentData.identification_number} required/>

                            <IconButton size={"medium"}
                                        onClick={handleConfirmSessionSubmit}
                            >
                                <DoneOutlineRoundedIcon color={"primary"}/>
                            </IconButton>
                        </div>
                    </div>
                    {/*<br/>*/}
                    {/*<div className={classes.search}>*/}
                    {/*    <div className={classes.row}>*/}
                    {/*        <TextField style={{}} id="teacher_id" label="Teacher Id" variant="outlined"*/}
                    {/*                   autoComplete="off"*/}
                    {/*                   color={"secondary"}*/}
                    {/*                   onChange={handleTeacherIdChange}*/}
                    {/*                   value={confirmSessionWithTeacherData.teacher_id} required/>*/}

                    {/*        <IconButton size={"medium"}*/}
                    {/*                    onClick={handleConfirmSessionWithTeacherSubmit}*/}
                    {/*        >*/}
                    {/*            <DoneOutlineRoundedIcon color={"primary"}/>*/}
                    {/*        </IconButton>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </DialogContent>
                <DialogActions>

                    <Button startIcon={<CloseRoundedIcon/>} variant="contained"
                            onClick={handleCloseConfirmSessionDialog} color="primary" type={"next"}>
                        Close
                    </Button>


                </DialogActions>
            </Dialog>


            {/*</Box>*/}


            <Dialog open={openConfirmDeleteSessionDialog} onClose={handleCloseConfirmDeleteSessionDialog}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are you Sure you want to Delete This Session?</DialogTitle>

                <DialogContent>
                    {deletedSession ?

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection="desc">
                                        <Tooltip
                                            enterDelay={300}
                                            title="Sort"
                                        >
                                            <TableSortLabel
                                                active
                                                direction="desc"
                                            >
                                                Date
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        time
                                    </TableCell>
                                    <TableCell>
                                        Teacher
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                <TableRow
                                    hover
                                    key={deletedSession.start}
                                >
                                    <TableCell>
                                        {deletedSession.start ? getDate(deletedSession.start) : null}
                                    </TableCell>
                                    <TableCell>
                                        {deletedSession.start && deletedSession.end ? getTimeRange(deletedSession.start, deletedSession.end) : null}
                                    </TableCell>
                                    <TableCell>
                                        {deletedSession.teacher}
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table> : null}


                </DialogContent>


                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitDeleteSession}
                    >

                        Yes
                    </Button>

                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"
                        // style={session.status === 'confirmed' ? {backgroundColor: '#8DFF76'} :
                        //     session.status ==='no show' ? {backgroundColor: '#FF1D4D'} :
                        //         null}
                            color="primary"
                            onClick={handleCancelDeletingSession}
                    >

                        No
                    </Button>

                </DialogActions>


            </Dialog>


            <Dialog open={openConfirmCancelSessionDialog} onClose={handleCloseConfirmCancelSessionDialog}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are you Sure you want to Cancel This Session?</DialogTitle>

                <DialogContent>
                    {canceledSession ?

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection="desc">
                                        <Tooltip
                                            enterDelay={300}
                                            title="Sort"
                                        >
                                            <TableSortLabel
                                                active
                                                direction="desc"
                                            >
                                                Date
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        time
                                    </TableCell>
                                    <TableCell>
                                        Teacher
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                <TableRow
                                    hover
                                    key={canceledSession.start}
                                >
                                    <TableCell>
                                        {canceledSession.start ? getDate(canceledSession.start) : null}
                                    </TableCell>
                                    <TableCell>
                                        {canceledSession.start && canceledSession.end ? getTimeRange(canceledSession.start, canceledSession.end) : null}
                                    </TableCell>
                                    <TableCell>
                                        {canceledSession.teacher}
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table> : null}


                </DialogContent>


                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitCancelSession}
                    >

                        Yes
                    </Button>

                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleCancelCancellingSession}
                    >

                        No
                    </Button>

                </DialogActions>


            </Dialog>


            {/*<Dialog open={props.openSessionTableDialog} onClose={handleOpenSessionTableDialog}*/}
            {/*        fullWidth*/}
            {/*        maxWidth={"xl"}*/}
            {/*        aria-labelledby="form-dialog-title">*/}
            {/*    <DialogTitle id="form-dialog-title">Sessions Table</DialogTitle>*/}

            {/*    <DialogContent>*/}
            {/*        <RadioGroup aria-label="type_of_package" name="type_of_package"*/}
            {/*                    value={createAddSessionData.type_of_class} onChange={handleClassTypeChange}*/}
            {/*                    style={{display: 'flex', flexDirection: 'row'}}>*/}
            {/*            <FormControlLabel value="short" control={<Radio/>} label="1-Hour Class"/>*/}
            {/*            <FormControlLabel value="long" control={<Radio/>} label="2-Hour Class"/>*/}
            {/*        </RadioGroup>*/}

            {/*        <Box>*/}

            {/*            <RadioGroup aria-label="Select Instructor" name="Select Instructor"*/}
            {/*                        value={props.selectedTeacherId}*/}
            {/*                        onChange={props.handleChangeSelectedTeacherId}*/}
            {/*                        style={{display: 'flex', flexDirection: 'row'}}>*/}
            {/*                {teachers_list ? teachers_list.map(teacher => (*/}
            {/*                    <FormControlLabel key={teacher.id} value={teacher.id} control={<Radio/>}*/}
            {/*                                      label={teacher.first_name + ' ' + teacher.last_name}/>*/}
            {/*                )) : null}*/}


            {/*            </RadioGroup>*/}


            {/*            /!*<FormControl variant="filled" className={classes.formControl}>*!/*/}
            {/*            /!*    <InputLabel id="demo-simple-select-filled-label">Teacher</InputLabel>*!/*/}
            {/*            /!*    <Select*!/*/}
            {/*            /!*        labelId="demo-simple-select-filled-label"*!/*/}
            {/*            /!*        id="demo-simple-select-filled"*!/*/}
            {/*            /!*        value={props.selectedTeacherId}*!/*/}
            {/*            /!*        onChange={props.handleChangeSelectedTeacherId}*!/*/}
            {/*            /!*    >*!/*/}
            {/*            /!*        {teachers_list? teachers_list.map(teacher =>(*!/*/}
            {/*            /!*            <MenuItem value={teacher.id}>{teacher.first_name + ' ' + teacher.last_name}</MenuItem>*!/*/}
            {/*            /!*        )) : null}*!/*/}

            {/*            /!*    </Select>*!/*/}
            {/*            /!*</FormControl>*!/*/}
            {/*        </Box>*/}


            {/*        <TeacherScheduleContainerForSessions*/}
            {/*            teacher_id={props.selectedTeacherId}*/}
            {/*            student_id={id}*/}
            {/*            inputDate={props.selectedDate}*/}
            {/*            sessionTypeOfClass={sessionTypeOfClass}*/}
            {/*            setCreateAddSessionData={setCreateAddSessionData}*/}
            {/*            setCreateAddInspectionSessionData={setCreateAddInspectionSessionData}*/}
            {/*            createAddSessionData={createAddSessionData}*/}
            {/*            class_type={createAddSessionData.type_of_class}*/}
            {/*            handleAllFetchingDoneChange={props.handleAllFetchingDoneChange}*/}


            {/*        />*/}


            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <FormControlLabel*/}
            {/*            control={<Checkbox checked={createAddSessionData.extra_hour}*/}
            {/*                               onChange={handleExtraHourChange} name="checkedA"/>}*/}
            {/*            label="Set session as extra"*/}
            {/*        />*/}


            {/*        <Button color="primary" variant="contained" onClick={handleAddSessionSubmit}*/}
            {/*                startIcon={<DoneOutlineRoundedIcon/>}*/}
            {/*        >*/}
            {/*            Confirm*/}
            {/*        </Button>*/}

            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            {/*{renderRedirect()}*/}

        </div>


    );
};

ShowStudentSessions.propTypes =
    {
        className: PropTypes.string
    }
;

export default ShowStudentSessions;
