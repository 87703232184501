import * as React from "react";
import {
    makeStyles,
} from "@material-ui/core";
import TeacherProfileData from "./components/TeacherProfileData";
import {useEffect} from "react";
import {userActions} from "../../Actions";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import WeekTableContainer from "../../Containers/TableContainer/WeekTableContainer";
import TeacherScheduleContainer from "../../Containers/Teacher/Containers/TeacherScheduleContainer";
import EditScheduleDialog from "./components/EditScheduleDialog";
import {func} from "prop-types";
import moment from "moment";

const useStyles = makeStyles(() => ({
    root: {},
}));

function makeRange(start_time, end_time) {

    const time_range = []
    for (let i = start_time; i <= end_time; i++) {
        time_range.push(i);
    }
    return time_range
}


export default function TeacherProfile() {
    const classes = useStyles()
    let {teacherId, type} = useParams();
    const dispatch = useDispatch();
    const teacher = useSelector(state => state.teachers.fetched_teacher);
    const navigate = useNavigate();

    //Edit teacher Schedule data ---------------------------------------------------------------------------------------
    const [editScheduleData, setEditScheduleData] = React.useState({
        teacherId: '',
        action: '',
        addType: '',
        deleteType: '',
        start: '',
        end: '',
    })
    const [selectedEditScheduleDay, setSelectedEditScheduleDay] = React.useState()
    const [sessions, setSessions] = React.useState() // used for Edit Schedule
    const [inspectionSessions, setInspectionSessions] = React.useState() // used for Edit Schedule
    const [isEditingScheduleDay, setIsEditingScheduleDay] = React.useState(false)
    const [tempOff, setTempOff] = React.useState([])
    const [tempOvertime, setTempOvertime] = React.useState([])
    const editScheduleStatus = useSelector(state => state.weekTable.editing_schedule);

    //-----------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        dispatch(userActions.clearCreatedTeacher())
        if(!teacher){
            dispatch(userActions.getTeacher(teacherId));
        }
    }, []);

    useEffect(() => { // making sure the fetched teacher is the same teacher as the id in the url
        if(teacher && teacherId){
            if(parseInt(teacher.id) !== parseInt(teacherId)){
                dispatch(userActions.getTeacher(teacherId));
            }
        }
    }, [teacher, teacherId]);

    useEffect(() => {
        if (type === 'edit_schedule') {
            dispatch(userActions.getTeacherSchedule(teacherId))
        }
    }, [type])


    // VACATION AND OFF LOGIC ----------------------------------------------------------------------------------------

    function handleEditScheduleToggle(day, sessions, inspection_sessions, currentWeek) { // opens dialog
        setSelectedEditScheduleDay(day)
        setSelectedEditScheduleDay(state => ({...state, currentWeek: currentWeek}))
        setSessions(sessions)
        setInspectionSessions(inspection_sessions)
        setEditScheduleData(data => ({...data, teacherId: teacherId}));
        setIsEditingScheduleDay(true)
    }

    function handleEditScheduleDialogClose() {
        setIsEditingScheduleDay(false)
        setEditScheduleData({
            teacherId: '', action: '', addType: '', deleteType: '', start: '', end: '',
        })
        setTempOff([])
        setTempOvertime([])
    }

    function handleChange(e) {
        const {name, value} = e.target
        if (name === 'action') {
            setEditScheduleData(data => ({...data, addType: '', deleteType: '', start: '', end: ''}))
        }
        setEditScheduleData(data => ({...data, [name]: value, start: '', end: ''}))
        setTempOff([])
        setTempOvertime([])
    }

    function handleClickHour(day, hour) {
        if (!editScheduleData.start && !editScheduleData.end) {
            let time = moment.utc(day).set({hour: hour}).toISOString()
            setEditScheduleData(data => ({...data, start: time}))
            if (editScheduleData.action === 'add') {
                if (editScheduleData.addType === 'overtime') {
                    let array = [hour]
                    setTempOvertime(array)
                }
                if (editScheduleData.addType === 'off') {
                    let array = [hour]
                    setTempOff(array)
                }
            }
        }
        if (editScheduleData.start && !editScheduleData.end) {
            let time = moment.utc(day).set({hour: hour}).toISOString()
            if (time === editScheduleData.start) {// makes having 1 hour overtime or off possible
                setEditScheduleData(data => ({...data, end: moment.utc(day).set({hour: hour + 1}).toISOString()}))
                if (editScheduleData.action === 'add') {
                    if (editScheduleData.addType === 'overtime') {
                        setTempOvertime([hour])
                    }
                    if (editScheduleData.addType === 'off') {
                        setTempOff([hour])
                    }
                }
            } else {
                setEditScheduleData(data => ({...data, end: moment.utc(day).set({hour: hour + 1}).toISOString()}))
                if (editScheduleData.action === 'add') {
                    if (editScheduleData.addType === 'overtime') {
                        setTempOvertime(makeRange(tempOvertime[0], hour))
                    }
                    if (editScheduleData.addType === 'off') {
                        setTempOff(makeRange(tempOff[0], hour))
                    }
                }
            }
        }
        if (editScheduleData.start && editScheduleData.end) {  // if there's already start and end selected
            let time = moment.utc(day).set({hour: hour}).toISOString()
            if (time > editScheduleData.start) {
                setEditScheduleData(data => ({...data, end: moment.utc(day).set({hour: hour + 1}).toISOString()}))
                if (editScheduleData.action === 'add') {
                    if (editScheduleData.addType === 'overtime') {
                        setTempOvertime(makeRange(tempOvertime[0], hour))
                    }
                    if (editScheduleData.addType === 'off') {
                        setTempOff(makeRange(tempOff[0], hour))
                    }
                }
            }
            if (time < editScheduleData.start) {
                setEditScheduleData(data => ({...data, start: time, end: ''}))
                if (editScheduleData.action === 'add') {
                    if (editScheduleData.addType === 'overtime') {
                        setTempOvertime([hour])
                    }
                    if (editScheduleData.addType === 'off') {
                        setTempOff([hour])
                    }
                }
            }
            if (time === editScheduleData.start) { // makes having 1 hour overtime or off possible
                setEditScheduleData(data => ({...data, end: moment.utc(day).set({hour: hour + 1}).toISOString()}))
                if (editScheduleData.action === 'add') {
                    if (editScheduleData.addType === 'overtime') {
                        setTempOvertime([hour])
                    }
                    if (editScheduleData.addType === 'off') {
                        setTempOff([hour])
                    }
                }
            }

        }
    }


    //-------------------------------------------------------------------------------------------------------------------

    function handleToggleTeacherWeekTable(e) {
        e.preventDefault()
        if (type === 'full_schedule') {
            navigate(`/app/teacher/${teacherId}/edit_schedule`,)
        } else {
            if (type === 'edit_schedule') {
                navigate(`/app/teacher/${teacherId}/full_schedule`,)

            }
        }
    }

    function handleSubmitChange(e) { // dispatch requests depending on the seelcted parameters
        e.preventDefault()
        if (editScheduleData.action === 'add') {
            if (editScheduleData.addType === 'off') {
                dispatch(userActions.createTeacherVacation(
                    editScheduleData.teacherId,
                    {
                        start: editScheduleData.start,
                        end: editScheduleData.end
                    },
                    selectedEditScheduleDay.Date,
                    selectedEditScheduleDay.currentWeek,
                    makeRange(moment.utc(editScheduleData.start).hour(), moment.utc(editScheduleData.end).hour() - 1)
                ))
            }
            if (editScheduleData.addType === 'overtime') {
                dispatch(userActions.createTeacherOvertime(editScheduleData.teacherId,
                    {start: editScheduleData.start, end: editScheduleData.end},
                    selectedEditScheduleDay.Date,
                    selectedEditScheduleDay.currentWeek,
                    makeRange(moment.utc(editScheduleData.start).hour(), moment.utc(editScheduleData.end).hour() - 1)
                ))
            }
        }
        if (editScheduleData.action === 'delete') {
            if (editScheduleData.deleteType === 'off') {
                dispatch(userActions.deleteVacation(editScheduleData.teacherId,
                    moment.utc(selectedEditScheduleDay.Date).toISOString(),
                    selectedEditScheduleDay.Date,
                    selectedEditScheduleDay.currentWeek,
                ))
            }
            if (editScheduleData.deleteType === 'overtime') {
                dispatch(userActions.deleteOvertime(editScheduleData.teacherId, moment.utc(selectedEditScheduleDay.Date).toISOString(),
                    selectedEditScheduleDay.Date,
                    selectedEditScheduleDay.currentWeek,
                ))
            }
        }
    }

    useEffect(() => { // closes the Dialog popup after the request is done
        if (editScheduleStatus) {
            if (editScheduleStatus.status === 'succeeded') {
                handleEditScheduleDialogClose()
            }
            if (editScheduleStatus.status === 'failed') {
                //TODO:
            }
        }

    }, [editScheduleStatus])


    return (
        <div>
            {teacher ?
                <TeacherProfileData handleEditTeacherSchedule={handleToggleTeacherWeekTable} type={type}/>
                : null}
            {type === 'full_schedule' ?
                <WeekTableContainer teacher={parseInt(teacherId)} handleEditScheduleToggle={handleEditScheduleToggle}/>
                : null}
            {type === 'edit_schedule' ?
                <TeacherScheduleContainer teacher_id={parseInt(teacherId)} addSchedule={true}/>
                : null}
            {isEditingScheduleDay && sessions && inspectionSessions && selectedEditScheduleDay ?
                <EditScheduleDialog handleEditScheduleDialogClose={handleEditScheduleDialogClose}
                                    open={isEditingScheduleDay}
                                    sessions={sessions}
                                    item={selectedEditScheduleDay}
                                    inspection_sessions={inspectionSessions}
                                    handleClickHour={handleClickHour}
                                    handleChange={handleChange}
                                    editScheduleData={editScheduleData}
                                    tempOff={tempOff}
                                    tempOvertime={tempOvertime}
                                    handleSubmitChange={handleSubmitChange}
                                    loading={editScheduleStatus.status === 'loading'}
                />
                : null}
        </div>
    )
}
