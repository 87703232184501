import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Link,
    Typography,
    colors,makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexWrap: 'wrap'
        },
        '&:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    header: {
        maxWidth: '100%',
        width: 240,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            flexBasis: '100%'
        }
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    stats: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    },
    actions: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    }
}));

const ProjectCard = ({   deposit,
                         openDeleteDepositDialog,
                         className,
                         handleDeleteDepositOpenDialog,
                         selectedDeleteDeposit,
                         handleDeleteDepositCloseDialog,
                         handleDeleteDepositConfirm,
                         ...rest}) => {
    const classes = useStyles();
    const statusColors = {
        'In progress': colors.orange[600],
        Canceled: colors.grey[600],
        Completed: colors.green[600]
    };


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <div className={classes.stats}>
                    <Typography variant="h6">
                        {'C$'}
                        {deposit.deposit}
                    </Typography>
                    <Typography variant="body2">Deposit Amount</Typography>
                </div>
                <div className={classes.stats}>
                    <Typography variant="h6">{deposit.type}</Typography>
                    <Typography variant="body2">Payment Type</Typography>
                </div>
                {/*<div className={classes.stats}>*/}
                {/*    <Typography variant="h6">*/}
                {/*        {moment(deposit.start_date).format('DD MMMM YYYY')}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body2">Project started</Typography>*/}
                {/*</div>*/}
                <div className={classes.stats}>
                    <Typography variant="h6">
                        {moment(deposit.create_date).format('DD MMMM YYYY')}
                    </Typography>
                    <Typography variant="body2">Payment Date</Typography>
                </div>
                <div className={classes.stats}>
                    <Typography
                        // style={{ color: statusColors[deposit.status] }}
                        variant="h6"
                    >
                        {deposit.detail? deposit.detail : '-'}
                    </Typography>
                    <Typography variant="body2">Details</Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        style={deposit.shown? {color: '#dc004e'}: null}
                        size="small"
                        variant="outlined"
                        disabled={!deposit.shown}
                        onClick={() => handleDeleteDepositOpenDialog(deposit.id)}

                    >
                        {deposit.shown? 'Delete' : 'Deleted'}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

ProjectCard.propTypes = {
    className: PropTypes.string,
    deposit: PropTypes.object.isRequired
};

export default ProjectCard;
