import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  startDateButton: {
    marginRight: theme.spacing(1)
  },
  endDateButton: {
    marginLeft: theme.spacing(1)
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = props => {
  const { className,user, ...rest } = props;

  const classes = useStyles();


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h1"
        gutterBottom
        variant="h3"
      >
        Good Day, {`${user.first_name} ${user.last_name}`}
      </Typography>
      <Typography variant="subtitle1">Here's your panel</Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
