import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    makeStyles, Button, TableCell
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    statsItem: {
        alignItems: 'center',
        display: 'flex'
    },
    statsIcon: {
        marginRight: theme.spacing(1)
    }
}));

const SessionCardComponent = ({
                                  handleOpenConfirmDeleteSessionDialog,
                                  handleOpenConfirmCancelSessionDialog,
                                  className,
                                  handleOpenConfirmSessionDialog,
                                  time,
                                  date,
                                  session,
                                  ...rest
                              }) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Typography
                    align="center"
                    color="textSecondary"
                    gutterBottom
                    variant="body1"
                >
                    {session.extra_hour ? '*Extra Hour*' : session.job_session? '*Job Session*' : null}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="body1"
                >
                    {date}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    Time:
                    {` ${time}`}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    Teacher:
                    {` ${session.teacher}`}
                </Typography>
                {/*<Typography*/}
                {/*  align="center"*/}
                {/*  color="textPrimary"*/}
                {/*  variant="body1"*/}
                {/*>*/}
                {/*  Time:*/}
                {/*  {` ${time}`}*/}
                {/*</Typography>*/}
            </CardContent>
            <Box flexGrow={1}/>
            <Divider/>
            <Box p={2}>
                <Grid
                    container
                    justify="space-between"
                    spacing={2}
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <Button
                            startIcon={
                                session.status === 'confirmed' ? (
                                    <DoneOutlineRoundedIcon/>
                                ) : null
                            }
                            disabled={session.cancellation}
                            variant="contained"
                            style={
                                session.status === 'confirmed'
                                    ? {
                                        backgroundColor: '#8DFF76', zIndex: 0
                                    }
                                    : session.status === 'no show'
                                    ? {backgroundColor: '#FF1D4D', zIndex: 0}
                                    : null
                            }
                            type="success"
                            onClick={() => handleOpenConfirmSessionDialog(
                                session.id,
                                session.student,
                                session.teacher_id
                            )}
                        >
                            {session.cancellation ? 'Cancelled' : session.status === 'confirmed' ? '' : session.status}
                        </Button>
                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <Button
                            color="secondary"
                            disabled={session.cancellation}
                            onClick={() => handleOpenConfirmCancelSessionDialog(session.id)}
                            startIcon={
                                !session.cancellation ? (
                                    <CancelOutlinedIcon/>
                                ) : null
                            }
                        >
                            {/*{session.cancellation ? '' : ''}*/}
                        </Button>
                        <br/>

                        <IconButton
                            onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}
                            color="secondary"
                            disabled={session.cancellation}
                        >
                            <DeleteForeverRoundedIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

SessionCardComponent.propTypes = {
    className: PropTypes.string,
    session: PropTypes.object.isRequired
};

export default SessionCardComponent;
