import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {
    makeStyles,
    colors,
    Grid,
    CardHeader,
    Button,
    Typography,
    CardContent,
    List,
    ListItem,
    CardActions,
    Card,
    Divider,
    TextField,
    Tooltip,
    IconButton,
    GridList,
    GridListTile,
    withWidth,
    isWidthUp,
    Dialog, InputAdornment, CircularProgress, DialogTitle,
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {Check, Edit, Edit2, FileText, ThumbsUp, Trash, User, X} from "react-feather";
import {Label} from "../../../accounting/components";
import moment from "moment";
import {userActions} from "../../../../Actions";
import StudentNotes from "../../../StudentNotes/StudentNotes";
import {Note} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width:'auto'

        // backgroundColor: theme.palette.background.paper,
    },

    gridList: {
        flexWrap: 'nowrap',
        width:'auto',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // props => props.width
        '&::-webkit-scrollbar': { width: 0, height: 0  }

    },
    object: {
        fontWeight: theme.typography.fontWeightMedium
    },

    createCardActions: {
        justifyContent: 'space-around'
    },


    listItem: {
        padding: theme.spacing(2, 0),
        justifyContent: 'space-between'
    },
    listItemDetails: { // Details
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        paddingBottom: 0
    },
    deleteButton: {
        color: '#e30e2f'
    },

    SessionConfirmedButton: {
        color: colors.green[600]
    },
    createSubmitButton: {
        color: colors.green[600]
    },
    selectedListItem: {
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.white
    },

    normalListItem: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selectedListItemText: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightMedium
    },
    selectedListItemDetails: {
        color: theme.palette.white,
        // fontWeight: theme.typography.fontWeightMedium
    },
    addObjectText: {
        minWidth: '100%'
    },
    field: {
        marginTop: theme.spacing(3)
    },
    cancelButton: {
        marginLeft: 'auto'
    },
    confirmButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    viewNotesCard:{
        backgroundColor:theme.palette.background.default,
        borderRadius: '12px'
    }
}));

// const sessions = [
//     {
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },
//     {
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },
//     {
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },
//     {
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },{
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },{
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },{
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },{
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },{
//         start: '2021-05-27T15:00:00Z',
//         end: '2021-05-27T16:00:00Z',
//         teacher: '2',
//         student: '71',
//         type_of_class: 'short',
//         id: '275',
//         status: 'pending',
//         confirmed_by_teacher: false,
//         confirmed_by_student: false,
//         teacher_id: '2',
//         cancellation: false,
//         extra_hour: true
//     },
//
// ]


const TeacherSessions = (props) => {
    const classes = useStyles(props);
    const {status, sessionId, action} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sessions = useSelector(state => state.teachersView.upcomingSessions.sessions)
    const sessions_status = useSelector(state => state.teachersView.upcomingSessions.status)
    const confirming_session_status = useSelector(state => state.teachersView.upcomingSessions.confirming_session_status)
    const [confirming, setConfirming] = React.useState(false)
    const [addingNote, setAddingNote] = React.useState(false)
    const [addingNoteData, setAddingNoteData] = React.useState({title: '', text: ''})
    const [creatingNoteLoading, setCreatingNoteLoading]=React.useState(false)
    const noteStatus = useSelector(state => state.teachersView.notes.status)
    const [viewNotes, setViewNotes] = React.useState(false)
    const [viewNotesUserId, setViewNotesUserId]=React.useState()
    const studentNotes = useSelector(state => state.students.student_notes_list.results)

    const [confirmSessionWithStudentData, setConfirmSessionWithStudentData] = React.useState(
        {
            teacher_id: '',
            student_id: '',
            id: '',
            identification_number: ''
        }
    )


    function handleConfirmSessionSubmit(e) {
        e.preventDefault()
        if (confirmSessionWithStudentData.identification_number &&
            confirmSessionWithStudentData.student_id &&
            confirmSessionWithStudentData.teacher_id &&
            confirmSessionWithStudentData.id) {
            dispatch(userActions.confirmSessionWithStudentIdentification(confirmSessionWithStudentData, 'teacher'))
        }
    }

    function handleChange(e) {
        const {name, value} = e.target;
        setConfirmSessionWithStudentData(data => ({...data, [name]: value}));
    }

    function handleToggleConfirmSession(sessionId, teacherId, studentId) {
        if (!confirming) {
            setConfirming(true)
            setConfirmSessionWithStudentData(data => ({
                ...data,
                teacher_id: teacherId,
                id: sessionId,
                student_id: studentId
            }));
        }
        if (confirming) {
            setConfirming(false)
            setConfirmSessionWithStudentData({
                teacher_id: '',
                student_id: '',
                id: '',
                identification_number: ''
            })
        }

    }

    function handleToggleAddNote(studentId){
        if(addingNote){
            setAddingNoteData({title: '', text: ''})
            setCreatingNoteLoading(false)
        }else{
            setAddingNoteData(data=>({
                ...data,
                student:studentId
            }))
        }
        setAddingNote(state => !state)
    }

    function handleNoteDataChange(e){
        const {
            name,
            value
        } = e.target;
        setAddingNoteData(data => ({
            ...data,
            [name]: value
        }));
    }

    function handleSubmitCreate() {
        if (!creatingNoteLoading) {
            if (addingNoteData.title && addingNoteData.student) {
                dispatch(userActions.createNote(addingNoteData.student, addingNoteData))
                setCreatingNoteLoading(true)
            }
        }
    }

    function handleOpenViewNotesDialog(id){
        setViewNotesUserId(id)
        setViewNotes(true)
    }

    function handleCloseViewNotesDialog(){
        setViewNotes(false)
        setViewNotesUserId(undefined)
    }

    useEffect(() => { // know if session confirm request is done
        if (confirming && confirming_session_status === 'succeeded') {
            dispatch(userActions.resetTeachersViewConfirmSessionStatus())
            setConfirming(false)
            setConfirmSessionWithStudentData({
                teacher_id: '',
                student_id: '',
                id: '',
                identification_number: ''
            })
            navigate('/teacher',)
        } else {
            if (confirming && confirming_session_status === 'failed') {
                setConfirming(false)
                setConfirmSessionWithStudentData({
                    teacher_id: '',
                    student_id: '',
                    id: '',
                    identification_number: ''
                })
                navigate('/teacher',)
            }
        }
    }, [confirming_session_status])


    useEffect(() => {
        if (sessions_status === 'idle' && sessions.length === 0) {
            dispatch(userActions.getUpComingTeacherSessions())
        }
    }, [sessions_status, sessions])

    const getGridListCols = () => {
        if (isWidthUp('xl', props.width)) {
            return 4.5;
        }

        if (isWidthUp('lg', props.width)) {
            return 3.5;
        }

        if (isWidthUp('md', props.width)) {
            return 2.5;
        }

        return 1.16;
    }

    useEffect(()=>{
        if(addingNote && creatingNoteLoading && noteStatus === 'failed'){
            handleToggleAddNote()
        }
        if(addingNote && creatingNoteLoading && noteStatus === 'succeeded'){
            handleToggleAddNote()
        }
    },[noteStatus, creatingNoteLoading])

    useEffect(()=>{
        if(viewNotesUserId !== undefined && viewNotesUserId > 0){
            dispatch(userActions.getStudentNotesList(viewNotesUserId))
        }


    },[viewNotesUserId])


    return (

                <div
                    // className={classes.content}
                >
                    {/*<Grid container spacing={10}  direction={"row"}>*/}
                    <GridList
                        className={classes.gridList}
                              spacing={10} cols={getGridListCols()} cellHeight={'auto'}>
                        {sessions_status === 'succeeded' && sessions.length > 0? sessions.map((session, index) => (
                            // <Grid item md={3} lg={3} xs={12} key={session.id}>
                            <GridListTile key={session.id} >
                                <Card
                                    // className={clsx(classes.root, className)}

                                >
                                    <CardHeader
                                        className={classes.header}
                                        disableTypography
                                        action={
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={()=>handleToggleAddNote(session.student.id)}

                                            >
                                                <Edit2/>
                                                Add Note
                                            </Button>
                                        }
                                        // subheader={
                                        //     <Typography

                                        //         variant="h5"
                                        //     >
                                        //         {expenses[0].title}
                                        //     </Typography>
                                        // }
                                        title={
                                            <Typography
                                                display="block"
                                                variant="h5"

                                            >
                                                {moment.utc(session.start).format('HH') + ' - ' + moment.utc(session.end).format('HH')}
                                            </Typography>
                                        }
                                    />
                                    <CardContent>
                                        {/*{selectedExpense && currentExpenseIndex !== null && currentObjectIndex !== null && !deletingItem ?*/}
                                        <List>
                                            <ListItem
                                                className={classes.listItem}
                                                disableGutters
                                                divider
                                            >
                                                <Typography variant="subtitle2">Student Name</Typography>
                                                <Typography variant="h6">
                                                    {session.student.first_name+ ' ' + session.student.last_name}
                                                </Typography>
                                            </ListItem>
                                            <ListItem
                                                className={classes.listItem}
                                                disableGutters
                                                divider
                                            >
                                                <Typography variant="subtitle2">
                                                    Date
                                                </Typography>
                                                <Typography variant="h6">
                                                    {moment().format('DD/MM/YYYY')}
                                                </Typography>
                                            </ListItem>

                                            <ListItem
                                                className={classes.listItem}
                                                disableGutters
                                                // divider
                                            >
                                                <Typography variant="subtitle2">Status</Typography>
                                                <Label // TODO: change import
                                                    color={
                                                        session.status === 'confirmed' ? colors.green[600] :
                                                            session.status === 'pending' ? colors.orange[600]
                                                                : session.status === 'canceled' ? colors.red[600] : null
                                                    }>
                                                    {session.status}
                                                </Label>
                                            </ListItem>

                                        </List>


                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                {parseInt(sessionId) === session.id && action === 'confirm' ?
                                                    <div>
                                                        <form onSubmit={handleConfirmSessionSubmit}>
                                                            <TextField placeholder={"Student Identification"}
                                                                       autoFocus
                                                                       type={"number"}
                                                                       inputProps={{className: classes.addObjectText}}
                                                                       value={confirmSessionWithStudentData.identification_number}
                                                                       name={"identification_number"}
                                                                       fullWidth
                                                                       onChange={handleChange}
                                                            />
                                                        </form>


                                                        <Tooltip title="Confirm Session">
                                                            <IconButton onClick={handleConfirmSessionSubmit}
                                                                        className={classes.createSubmitButton}>
                                                                <Check/>
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Cancel">
                                                            <IconButton
                                                                component={RouterLink}
                                                                to={`/teacher`}
                                                                onClick={handleToggleConfirmSession}
                                                                className={classes.deleteButton}>
                                                                <X/>
                                                            </IconButton>
                                                        </Tooltip>

                                                    </div>

                                                    :


                                                    <Button
                                                        className={session.status === 'confirmed' ? classes.SessionConfirmedButton : null}
                                                        size="small"
                                                        color={'primary'}
                                                        startIcon={session.status === 'confirmed' ? <Check/> : <ThumbsUp />}
                                                        disabled={sessionId && sessionId !== session.id}
                                                        component={RouterLink}
                                                        to={`/teacher/sessions/${session.id}/confirm`}
                                                        onClick={() => handleToggleConfirmSession(session.id, session.teacher, session.student.id)}
                                                    >
                                                        {
                                                            session.status === 'confirmed' ? 'Session Confirmed' :
                                                                session.status === 'pending' ? 'Click to Confirm'
                                                                    : session.status === 'canceled' ? 'Session Canceled' : null
                                                        }
                                                    </Button>
                                                }
                                                <Divider />

                                            </Grid >

                                            <Grid item xs={12} lg={12} md={12}>
                                                <Button
                                                    // className={session.status === 'confirmed' ? classes.SessionConfirmedButton : null}
                                                    size="small"
                                                    color={'primary'}
                                                    startIcon={<Note />}
                                                    // disabled={true}
                                                    // component={RouterLink}
                                                    // to={`/teacher/notes/view/${session.student.id}`}
                                                    onClick={() => handleOpenViewNotesDialog(session.student.id)}
                                                >
                                                    View Student Notes
                                                </Button>
                                            </Grid>
                                        </Grid>



                                    </CardActions>
                                </Card>
                            </GridListTile>


                            // </Grid>

                        ))
                            :

                            <Typography variant={"h6"}>
                                There are no sessions for you in the next 24 hours yet!
                            </Typography>
                        }
                    </GridList>
                    <Dialog open={addingNote} onClose={handleToggleAddNote} maxWidth={'md'}>
                        <Card
                            // className={clsx(classes.root, className)}
                            // ref={ref}
                        >
                            <form>
                                <CardContent>
                                    <Typography
                                        align="center"
                                        gutterBottom
                                        variant="h3"
                                    >
                                        Add a note for student
                                    </Typography>
                                    <TextField
                                        className={classes.field}
                                        autoComplete="off"
                                        id="deposit"
                                        label="Note Title"
                                        variant="outlined"
                                        name="title"
                                        value={addingNoteData.title}
                                        onChange={handleNoteDataChange}
                                        // startAdornment={<InputAdornment position="start">C$</InputAdornment>}
                                        fullWidth
                                        required
                                        // type="number"

                                    />

                                    <TextField
                                        autoComplete="off"
                                        className={classes.field}
                                        id="detail"
                                        name="text"
                                        value={addingNoteData.text}
                                        onChange={handleNoteDataChange}
                                        variant="outlined"
                                        placeholder={"Payment Details(optional)"}
                                        multiline
                                        rows={3}
                                        rowsMax={5}
                                        fullWidth

                                    />


                                </CardContent>
                                <Divider/>
                                <CardActions>
                                    <Button
                                        className={classes.cancelButton}
                                        variant="contained"
                                        onClick={handleToggleAddNote}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        className={classes.confirmButton}
                                        onClick={handleSubmitCreate}
                                        variant="contained"
                                    >
                                        {creatingNoteLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Submit'}
                                    </Button>


                                </CardActions>
                            </form>
                        </Card>
                    </Dialog>


                    <Dialog open={viewNotes} onClose={handleCloseViewNotesDialog} maxWidth={"md"} >
                        <DialogTitle className={classes.viewNotesCard}>
                            Student's Notes
                        </DialogTitle>
                        <Card className={classes.viewNotesCard}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {
                                        studentNotes? studentNotes.map((item, index) =>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Card style={{borderRadius: '12px'}}>
                                                    <CardHeader title={item.title} />
                                                    <Divider />

                                                    <CardContent>
                                                        <Typography variant={"body1"}>
                                                            {item.text}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>

                                            </Grid>



                                        ) : null



                                    }
                                </Grid>

                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Button variant={"outlined"} color={"primary"} onClick={handleCloseViewNotesDialog}>
                                    Close
                                </Button>
                            </CardActions>
                        </Card>

                    </Dialog>


                </div>
        //     </Card>
        // </div>

    );
}

TeacherSessions.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default withWidth()(TeacherSessions);
