/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
    colors,
    makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import Label from "./components/Label";
import {Link as RouterLink, useParams} from "react-router-dom";


const getLabel = todo => {
    if (todo.updated_at) {
        return (
            <Label color={colors.green[600]}>
                {moment(todo.updated_at).format('DD MMMM YYYY')}
            </Label>
        );
    }
};

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    addIcon: {
        marginRight: theme.spacing(1)
    },
    done: {
        textDecoration: 'line-through',
        color: theme.palette.text.secondary
    }
}));

const Notes = ({handleAddNoteToggle,notes_list, className, ...rest }) => {
    const {id} = useParams()
    const classes = useStyles();
    useEffect(() => {
        let mounted = true;
        return () => {
            mounted = false;
        };
    }, []);
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                action={
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleAddNoteToggle}
                    >
                        <AddIcon className={classes.addIcon} />
                        Add
                    </Button>
                }
                title="Title"
            />
            <Divider />
            <CardContent className={classes.content}>
                <List>
                    {notes_list.length > 0 ? notes_list.map((note, i) => (
                        <ListItem
                            divider={i < note.length - 1}
                            key={note.id}
                        >
                            <ListItemText>
                                <Typography
                                    className={clsx({
                                        [classes.done]: note.done
                                    })}
                                    variant="h6"

                                >
                                    {note.title}
                                </Typography>
                            </ListItemText>
                            {getLabel(note)}
                            <Tooltip title="View Details">
                                <IconButton component={RouterLink} to={`/app/account/${id}/sessions/notes/${note.id}`} size={"medium"}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItem>
                    )) : (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography variant={"subtitle2"} color={"textSecondary"} align={"center"}>
                                No Notes Yet!
                            </Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"} align={"center"}>
                                Click {
                                <Button
                                color="primary"
                                size="small"
                                onClick={handleAddNoteToggle}
                            >
                                <AddIcon className={classes.addIcon} />
                                <Typography variant={"subtitle2"} color={"textSecondary"}>
                                    Add
                                </Typography>
                            </Button>
                            } to create
                            </Typography>
                            {/*<Button*/}
                            {/*    color="primary"*/}
                            {/*    size="small"*/}
                            {/*    onClick={handleAddNoteToggle}*/}
                            {/*>*/}
                            {/*    <AddIcon className={classes.addIcon} />*/}
                            {/*    Add*/}
                            {/*</Button>*/}
                        </div>

                    )}
                </List>
            </CardContent>
        </Card>
    );
};

Notes.propTypes = {
    className: PropTypes.string
};

export default Notes;
