import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from 'react-redux';

import {Link as RouterLink, Navigate, Redirect, useParams} from "react-router-dom";
import {userActions} from "../../Actions";
import {CircularProgress} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ChangePassword() {
    const classes = useStyles();
    const {code, id} = useParams();

    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const {username, password} = inputs;
    const loggedIn = useSelector(state => state.authentication.loggedIn);
    const changeStatus = useSelector(state => state.authentication.changePassword.status);
    const dispatch = useDispatch();
    const [resetData, setResetData] = React.useState({password: '', confirmPassword: '', code: '', id: ''});

    useEffect(() => {
        if (code && id) {
            setResetData({
                ...resetData,
                code: code,
                id: id
            })
        }
    }, [code, id])

    function handleChange(e) {
        const {name, value} = e.target;
        setResetData(resetData => ({...resetData, [name]: value}));
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (resetData.id && resetData.code && resetData.password) {
            if (resetData.password === resetData.confirmPassword) {
                dispatch(userActions.changePassword(resetData.code, resetData.id, resetData.password));

            }


        }
    }


    // const renderRedirect = () => {
    //     // if (studentRedirect) {
    //     return <Navigate to={{pathname: '/student/create'}}/>
    //     // }
    // }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                {changeStatus === 'idle' &&
                <Typography component="h1" variant="h5">
                    Set your new password
                </Typography>
                }

                {changeStatus === 'succeeded' &&
                <Typography component="h1" variant="h5" color={'#128f01'}>
                    Your password has been successfully reset! Go back to login page
                </Typography>
                }


                {changeStatus === 'failed' &&
                <Typography component="h1" variant="h5" color={'#ff0000'}>
                    Your link is expired. please go back and try again
                </Typography>
                }


                {(changeStatus === 'idle' || changeStatus === 'loading') ?
                    <form className={classes.form} noValidate name="form" autoComplete="off" onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            type={"password"}
                            fullWidth
                            id="password"
                            label="New Password"
                            name="password"
                            autoFocus
                            value={resetData.password}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            type={"password"}
                            margin="normal"
                            required
                            fullWidth
                            id="confirm_password"
                            label="Confirm New Password"
                            name="confirmPassword"
                            autoFocus
                            value={resetData.confirmPassword}
                            onChange={handleChange}
                        />
                        {
                            resetData.password !== resetData.confirmPassword &&
                            <Typography>
                                *Make sure both fields match
                            </Typography>

                        }

                        <Button
                            color="primary" type={"submit"}
                            fullWidth
                            className={classes.submit}
                            disabled={(resetData.password !== resetData.confirmPassword) ||changeStatus === 'loading' }
                            onClick={handleSubmit}
                            variant={changeStatus !== 'loading'? "contained": "outlined"}

                        >
                            {changeStatus === 'loading' ? <CircularProgress size={24} className={classes.buttonProgress}/> : 'Submit'}
                        </Button>

                    </form> : null}
                <Grid container>
                    <Grid item xs>
                        {(changeStatus === 'succeeded' || changeStatus === 'failed') &&
                        <Button
                            color="primary" type={"submit"}
                            fullWidth
                            component={RouterLink}
                            to={'/login'}
                            className={classes.submit}
                            // disabled={resetData.password !== resetData.confirmPassword}
                            // onClick={handleSubmit}
                            variant={changeStatus !== 'loading'? "contained": "outlined"}
                        >
                            Go to login
                        </Button>
                        }

                        {changeStatus === 'idle' &&
                        <Link href="/login" variant="body2">
                            Back to login
                        </Link>
                        }


                    </Grid>

                </Grid>
            </div>

            {/*{loggedIn? renderRedirect(): null}*/}
        </Container>
    );
}
