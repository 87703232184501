import * as React from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent, CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogTitle, IconButton,
    makeStyles, Menu, MenuItem,
    Typography
} from "@material-ui/core";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
// import TeacherScheduleContainer from "./Containers/TeacherScheduleContainer";
import Button from "@material-ui/core/Button";
// import AddTeacherWeeklySchedule from "./Components/AddTeacherSchedule";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import NavigateNextSharpIcon from "@material-ui/icons/NavigateNextSharp";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import {userActions} from "../../../../Actions";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
const useStyles = makeStyles(() => ({
    root: {},
}));

export default function TeacherProfileData({handleEditTeacherSchedule, type}) {
    let {teacherId} = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [addSchedule, setAddSchedule] = React.useState(false)
    const teacher = useSelector(state => state.teachers.fetched_teacher);
    const deleteTeacherStatus = useSelector(state => state.teachers.deleteTeacher);

    let teacher_schedule = useSelector(state => state.teachers.fetched_teacher_schedule);
    const [openEditTeacherDialog, setOpenEditTeacherDialog] = React.useState(false);
    const [openDeleteTeacherDialog, setOpenDeleteTeacherDialog] = React.useState(false);
    const [loadingDeleteTeacher,setLoadingDeleteTeacher] = React.useState(false)

    const [editTeacherData, setEditTeacherData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        password: '',
        phone_number: '',
        formatted_phone_number: '',
    });


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleAnchorClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorClose = () => {
        setAnchorEl(null);
    };



    const handleScheduleCreate =() =>{
        setAddSchedule(true)
    }

    const handleExitScheduleCreate = () => {
        setAddSchedule(false)
    }





    //
    // const handleScheduleReset =() =>{
    //     // setScheduleStatus(true)
    //     teacher_schedule.map(schedule =>(
    //         dispatch(userActions.resetTeacherSchedule(schedule.day_of_week, id))
    //     ))
    //     dispatch(userActions.resetLocalTeacherSchedule())
    //     // setIsReset(true)
    //     // setRedirect()
    // }
    //
    //
    //
    //

    //-------------------------------------------------Show Teacher Schedule Stuff-------------------------------------

    let ShowScheduleRows = []
    let date = new Date()
    let today = date.getDate()
    let week = []

    for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
        date.setDate(today + i)
        week[i] = new Date(date)
    }

    const weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    for (let i = 0; i < 7; i++) {
        ShowScheduleRows.push({
            id: i + 1,
            Day: weekday[week[i].getDay()],
            Date: months[week[i].getMonth()] + ' ' + week[i].getDate() + ',' + ' ' + week[i].getFullYear(),
            time: []
        });
    }

    //----------------------------------------------------------------------------------------------

    let schedule = teacher_schedule.length > 0  ?
        <div />
        // <TeacherScheduleContainer teacher_id = {id} />

        : null

    if(addSchedule) {
        schedule = <div />

        // <AddTeacherWeeklySchedule id={id}     />
    }

    let editCreateButton



    //---------Edit Teacher Stuff------------------------------------------------------------------------
    const handleEditTeacher = () =>{
        setOpenEditTeacherDialog(true)

        // we set the values we want to edit to the previous value that we got from server
        setEditTeacherData(user => ({...user, first_name: teacher.first_name}));
        setEditTeacherData(user => ({...user, last_name: teacher.last_name}));
        setEditTeacherData(user => ({...user, email: teacher.email}));
        setEditTeacherData(user => ({...user, phone_number: teacher.phone_number}));
        setEditTeacherData(user => ({...user, formatted_phone_number: teacher.phone_number}));

    }

    function deleteTeacherDialogToggle(){
        setOpenDeleteTeacherDialog(true)
    }

    function handleDeleteTeacherConfirm(){
        if(openDeleteTeacherDialog && teacherId){
            setLoadingDeleteTeacher(true)
            dispatch(userActions.deleteTeacher(teacherId))
        }
    }

    useEffect(()=>{
        if(openDeleteTeacherDialog && deleteTeacherStatus === 'succeeded'){
            setOpenDeleteTeacherDialog(false)
            navigate(`/app/teachers/list`, {replace: true})
        }
        if(openDeleteTeacherDialog && deleteTeacherStatus === 'failed'){
            setOpenDeleteTeacherDialog(false)
            navigate(`/app/teachers/list`, {replace: true})
        }
    }, [deleteTeacherStatus])





    function handleChange(e) {
        const {name, value} = e.target;
        setEditTeacherData(user => ({...user, [name]: value}));
    }


    function handlePhoneNumberChange(value){
        if (value){
            setEditTeacherData(user => ({...user, formatted_phone_number: value}))
            setEditTeacherData(user => ({...user, phone_number:
                    value.replace(" ", "").replace("-", "").replace
                    ("(", "").replace(")", "").replace(" ", "")
            }))
        }
    }


    const handleEditSubmit = (e) =>{

        e.preventDefault();
        if (editTeacherData.first_name && editTeacherData.last_name && editTeacherData.phone_number && editTeacherData.email) {

            dispatch(userActions.editTeacher(editTeacherData, teacherId));
            handleEditTeacherDialogClose()
        }

    }






    const handleEditTeacherDialogClose = () =>{
        setTimeout(function() {dispatch(userActions.getTeacher(teacherId)); }, 800); // refresh afrer 800 ms
        setOpenEditTeacherDialog(false)

    }





    return (
        <div>
            <Card>
                <CardContent style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box
                        alignItems="left"
                        display="flex"
                        flexDirection="column"
                    >

                        <div style={{display: 'flex', flexDirection:'row' , alignItems: 'center'}}>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h3"
                            >
                                {teacher.first_name + ' ' + teacher.last_name}
                            </Typography>


                        </div>

                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            ID : {teacher.id}
                        </Typography>
                        <Typography
                            className={classes.dateText}
                            color="textPrimary"
                            variant="h5"
                        >
                            Phone number : {teacher.phone_number}
                        </Typography>
                        <Typography
                            className={classes.dateText}
                            color="textPrimary"
                            variant="h5"
                        >
                            Email : {teacher.email}
                        </Typography>
                    </Box>


                    <div style={{display: 'flex', flexDirection: 'column'}}>

                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleAnchorClick}
                        >
                            <MoreVertIcon/>
                        </IconButton>

                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleAnchorClose}
                            PaperProps={{
                                style: {
                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >

                            <MenuItem key={0} onClick={handleEditTeacher}>
                                <EditIcon/>
                                Edit Teacher
                            </MenuItem>

                            <MenuItem key={1} onClick={deleteTeacherDialogToggle}>
                                <DeleteForeverRoundedIcon/>
                                Delete Teacher
                            </MenuItem>
                            {/*<br/>*/}
                            {/*<MenuItem key={1} onClick={handleDeleteStudent} style={{backgroundColor: ''}}*/}

                            {/*>*/}
                            {/*    <DeleteForeverRoundedIcon/>*/}
                            {/*    Delete Student*/}
                            {/*</MenuItem>*/}


                        </Menu>



                    </div>








                </CardContent>
                <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>

                    {/*{!addSchedule ? <Button variant="contained"*/}
                    {/*                        color="primary" onClick={handleScheduleCreate }*/}
                    {/*                        startIcon={teacher_schedule.length>0 ? <EditIcon /> : <AddIcon />}*/}

                    {/*>*/}
                    {/*    {teacher_schedule.length > 0 ? editCreateButton=" Edit Schedule" : editCreateButton= "Create Schedule"}*/}
                    {/*</Button> : null }*/}

                    {/*{addSchedule?*/}
                    {/*    <Button                                 variant="contained"*/}
                    {/*                                            color="primary"*/}
                    {/*                                            startIcon={<NavigateBeforeIcon/>} onClick={handleExitScheduleCreate }>*/}
                    {/*        Go Back*/}
                    {/*    </Button>*/}

                    {/*    : null}*/}

                    <Button variant="contained"
                            color="primary" onClick={handleEditTeacherSchedule}>
                        {type === 'full_schedule'? 'Edit Schedule' : type==='edit_schedule'? 'Back': 'error'}
                    </Button>

                </CardActions>
            </Card>

            <Dialog open={openEditTeacherDialog} onClose={handleEditTeacherDialogClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <DialogTitle id="form-dialog-title">Edit Teacher Info</DialogTitle>
                <DialogContent>



                    <form className={classes.form} name="form" onSubmit={handleEditSubmit}>


                        <TextField
                            required
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            variant="outlined"
                            onChange={handleChange}
                            value={editTeacherData.first_name}
                            className={classes.style}
                        />


                        <br />
                        <Divider/>
                        <br />


                        <TextField
                            required
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            // autoComplete="Last Name"
                            variant="outlined"
                            onChange={handleChange}
                            value={editTeacherData.last_name}
                        />


                        <br />
                        <Divider/>
                        <br />


                        <TextField
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            value={editTeacherData.email}

                        />


                        <br />
                        <Divider/>
                        <br />

                        <TextField
                            required
                            id="username"
                            label="Username"
                            variant="outlined"
                            name="username"
                            onChange={handleChange}
                            value={editTeacherData.username}

                        />


                        <br />
                        <Divider/>
                        <br />


                        <TextField
                            required
                            id="password"
                            label="Password"
                            variant="outlined"
                            // type={"password"}
                            name="password"
                            onChange={handleChange}
                            value={editTeacherData.password}

                        />


                        <br />
                        <Divider/>
                        <br />


                        <MuiPhoneNumber
                            disableAreaCodes
                            disableDropdown
                            requierd
                            id="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            name="phone_number"
                            data-cy="phone_number"
                            defaultCountry={"ca"}
                            value={editTeacherData.formatted_phone_number}
                            onChange={handlePhoneNumberChange}
                        />


                        <br />
                        <Divider/>
                        <br />



                    </form>


                </DialogContent>
                <DialogActions>

                    <Button startIcon={<NavigateNextSharpIcon />} variant="contained"
                            size={"large"} color={"primary"}
                            onClick={handleEditSubmit}
                            className={classes.button}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDeleteTeacherDialog} onClose={() => setOpenDeleteTeacherDialog(false)}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are you Sure you want to delete this Teacher?</DialogTitle>
                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant={loadingDeleteTeacher? 'outlined': "contained"}
                            color="secondary"
                            onClick={handleDeleteTeacherConfirm}
                            disabled={loadingDeleteTeacher}
                    >
                        {loadingDeleteTeacher ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Yes'}

                    </Button>
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenDeleteTeacherDialog(false)}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            {/*<TeacherScheduleContainer teacher_id = {id} addSchedule={addSchedule}/>*/}
        </div>
    )
}
