import React, {useState, useEffect, useRef} from 'react';
import { Divider,makeStyles } from '@material-ui/core';
import { Header, Details } from './components';
import Page from "../../Components/Page";
import moment from "moment";
import html2canvas from "html2canvas";
import { useNavigate, useParams} from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import * as pdfMake from "pdfmake";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {userActions} from "../../Actions";
import {useDispatch} from "react-redux";
import jsPDF from "jspdf";
// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
// import jsPDF from 'jspdf';


const useStyles = makeStyles(theme => ({

  root: {

    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

const Certificate = () => {
  const classes = useStyles();
  const {first, last, id} = useParams();
  const [certDate, setCertDate] = React.useState('')
  const [calendarDate, setCalendarDate] = React.useState('')
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false)

  const [calendar, setCalendar] = React.useState(true)
  // const {last} = useParams();
  // const {id} = useParams();


  const componentRef = useRef();
  const handlePrintCertificate = useReactToPrint({
    content: () => componentRef.current,
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function downloadPDF(){
    const input = document.getElementById('cert');
    html2canvas(input, {backgroundColor: null})
        .then((canvas) => {
          const data = canvas.toDataURL('image/png');
          const docDefinition = {
            content: [{
              image: data,
              width: 700,
            }],
            pageOrientation: 'landscape',
            pageSize: 'A4',
            pageMargins: [75,1,1,150]
          };
          pdfMake.createPdf(docDefinition).download(`${id}_${first+ ' '+ last}`)
        })
    ;
  }


    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }

  function UploadAndEmailPDF(){
      setLoading(true)
    const input = document.getElementById('cert');
    html2canvas(input, {backgroundColor: null})
        .then((canvas) => {

          const data = canvas.toDataURL('image/png');
          const docDefinition = {
            content: [{
              image: data,
              width: 700,
            }],
            pageOrientation: 'landscape',
            pageSize: 'A4',
            pageMargins: [75,1,1,150]
          };

          const pdf = new pdfMake.createPdf(docDefinition)
            // pdf.getDataUrl((dataUrl) => {
            //     const pdf = dataURLtoFile(dataUrl, 'mhd.pdf')
            //     console.log(pdf)
            //     const file = new FormData()
            //         file.append('file', pdf)
            //         dispatch(userActions.sendCertificateEmail(id, file))
            //         console.log(file)
            // });

            pdf.getBlob((blob) => {
                const pdf = new File([blob], `${id}_${first+ ' '+ last}.pdf`,{ type: "application/pdf" })
                console.log(pdf)
                const file = new FormData()
                    file.append('file', pdf)
                    dispatch(userActions.sendCertificateEmail(id, file))
                    console.log(file)
            });
        })
    ;
    setTimeout(()=>{
        setLoading(false)
    }, 10000)
  }



  const handleCalendarChange = date => {
    setCalendarDate(moment(date))
    // setCertDate(moment(date))
  };

  const handleCalendarAccept = date => {
    setCalendar(false)
  };

  const handleCalendarClose = date => {
    console.log(moment(calendarDate))
    setCalendar(false)
  };

  return (
    <Page
      className={classes.root}
      title="Invoice Details"
    >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                open={calendar}
                // maxDate={moment()}
                clearable
                value={calendarDate}
                // placeholder="01/03/2021"
                onChange={handleCalendarChange}
                onAccept={handleCalendarAccept}
                onClose={handleCalendarClose}
                format="dd/MM/yyyy"
                style={{ display: 'none' }} // Temporal fix to hide the input element
            />
          </MuiPickersUtilsProvider>
      <Header handlePrintCertificate={handlePrintCertificate} loading={loading} downloadPDF={downloadPDF} id={id} UploadAndEmailPDF={UploadAndEmailPDF}/>
      <Divider className={classes.divider} />
      <Details  ref={componentRef} certDate={calendarDate} name={capitalizeFirstLetter(first) + ` ` + capitalizeFirstLetter(last)}/>
    </Page>
  );
};

export default Certificate;
