import * as React from "react";
import {
 Button, Card, CardActions, CardContent, CircularProgress, colors,
 Divider,
 TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";

const useStyles = makeStyles(theme => ({
    root: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        // outline: 'none',
        // boxShadow: theme.shadows[20],
        // width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        // maxWidth: '100%'
    },
    field: {
        marginTop: theme.spacing(3)
    },
    cancelButton: {
        marginLeft: 'auto'
    },
    confirmButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));


const truncateDecimals = function (number, digits) {
    let multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};


export default function AddRentComponent({
                                             createRent, handleCreateRentChange,openCalandar,
                                             creatingRent,handleCreateRentSubmit,
                                             handleCalanderAccept,handleCreateRentHourChange,
                                             handleToggleCalandar,calendarDate,
                                             handleCreateRentDetailChange,
                                             handleCloseAddRentDialog,
                                             className,
                                             handleCreateRentDateChange, handleCreateRentPriceChange
                                         }) {

    const classes = useStyles();


    return (

        <Card
            className={clsx(classes.root, className)}
            // ref={ref}
        >
            <form>
                <CardContent>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h3"
                    >
                        Add Rental
                    </Typography>
                    <TextField
                        className={classes.field}

                        type="number"
                        autoComplete="off"
                        id="price_tax"
                        label="Rent Time (Hours)"
                        variant="outlined"
                        name="price_tax"
                        value={createRent.hour}
                        onChange={handleCreateRentHourChange}
                        // startAdornment={<InputAdornment position="start">C$</InputAdornment>}
                        fullWidth
                        required
                    />
                    <TextField
                        className={classes.field}

                        type="number"
                        autoComplete="off"
                        id="price"
                        label="Rent Price (Pre-Tax)"
                        variant="outlined"
                        name="price"
                        value={createRent.price}
                        onChange={handleCreateRentChange}
                        // startAdornment={<InputAdornment position="start">C$</InputAdornment>}
                        fullWidth
                        required
                    />

                    <Typography variant="body1" color="textPrimary" component="p"
                                className={classes.field}

                    >
                        After
                        Tax: {createRent.price ? truncateDecimals((parseFloat(createRent.price)) * (1 + 0.14975), 2) : '00.00'}
                    </Typography>

                    <Button onClick={handleToggleCalandar}             className={classes.field}
                    >
                        <CalendarTodayIcon className={classes.calendarTodayIcon} />
                        {moment(createRent.date).format('DD MMM YYYY | hh:mm:ss')}
                    </Button>

                    <TextField
                        className={classes.field}

                        autoComplete="off"
                        placeholder={"Details (Optional)"}
                        // label="Rent Price (Pre-Tax)"
                        id="detail"
                        name="detail"
                        value={createRent.detail}
                        onChange={handleCreateRentDetailChange}
                        variant="outlined"
                        multiline
                        rows={2}
                        rowsMax={4}
                        fullWidth
                    />
                </CardContent>
                {/*<Divider />*/}
                {/*<CardActions>*/}
                {/*    <Button*/}
                {/*        className={classes.cancelButton}*/}
                {/*        // onClick={onCancel}*/}
                {/*        variant="contained"*/}
                {/*        onClick={handleCloseAddRentDialog}*/}
                {/*    >*/}
                {/*        Cancel*/}
                {/*    </Button>*/}

                {/*        <Button*/}
                {/*            className={classes.confirmButton}*/}
                {/*            onClick={handleCreateRentSubmit}*/}
                {/*            variant="contained"*/}
                {/*        >*/}
                {/*            {creatingRent ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Add'}*/}
                {/*        </Button>*/}



                {/*</CardActions>*/}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        open={openCalandar}
                        // maxDate={moment()}
                        // clearable
                        value={calendarDate}
                        // placeholder="01/03/2021"
                        onChange={date => handleCreateRentChange(date)}
                        onAccept={handleCalanderAccept}
                        onClose={handleToggleCalandar}
                        format="dd/MM/yyyy | hh:mm:ss"
                        style={{ display: 'none' }} // Temporal fix to hide the input element
                    />
                </MuiPickersUtilsProvider>
            </form>
        </Card>

    )


}
