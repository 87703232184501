import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {

  Settings as SettingsIcon,
  User as UserIcon,

  Book as BookIcon,
} from 'react-feather';
import NavItem from './NavItem';
import {useSelector} from "react-redux";
import {setIn} from "formik";



const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const userType = useSelector(state => state.authentication.userType)
  const [items, setitems] = React.useState([
    {
      href: '/app/create_student',
      icon: BookIcon,
      title: 'Create Student'
    },
    {
      href: '/app/teachers_schedule/show',
      icon: UserIcon,
      title: 'Instructors List'
    },
    {
      href: '/app/list',
      icon: UserIcon,
      title: 'Students list'
    },
    {
      href: '/app/accounting/payments',
      icon: BookIcon,
      title: 'Accounting'
    },
    // {
    //   href: '/app/settings/general',
    //   icon: SettingsIcon,
    //   title: 'Settings'
    // },

  ])


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(()=>{
    if(userType === 'admin'){
      setitems(items =>[...items, {
        href: '/app/settings/general',
        icon: SettingsIcon,
        title: 'Settings'
      }])
      // items.push({
      //   href: '/app/settings/general',
      //   icon: SettingsIcon,
      //   title: 'Settings'
      // })
    }
  }, [userType])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          // src={user.avatar}
          to="/app/teacher/1/full_schedule"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          Max Mostafalou
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Admin
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
