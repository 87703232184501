import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {

    Divider,
    makeStyles,
    colors,
    Grid,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Typography,
    DialogActions,
    Dialog,
    FormControl,
    CardHeader,
    Tooltip,
    CardActions,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton, ListItemSecondaryAction, Button, TextField, Input, InputAdornment
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import {ArrowRight, Check, Edit, Folder, Trash, X} from "react-feather";
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {Label} from "../../../accounting/components";
import {userActions} from "../../../../Actions";
import ExpensesList from "./components/ExpensesList";
import ExpenseDetails from "./components/ExpenseDetails";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        // marginTop: theme.spacing(3)
    },
    object: {
        fontWeight: theme.typography.fontWeightMedium
    },

    createCardActions: {
        justifyContent: 'space-around'
    },


    listItem: {
        padding: theme.spacing(2, 0),
        justifyContent: 'space-between'
    },
    listItemDetails: { // Details
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        paddingBottom: 0
    },
    deleteButton: {
        color: '#E30425'
    },
    createSubmitButton: {
        color: colors.green[600]
    },
    selectedListItem: {
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.white
    },

    normalListItem: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selectedListItemText: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightMedium
    },
    selectedListItemDetails: {
        color: theme.palette.white,
        // fontWeight: theme.typography.fontWeightMedium
    },
    addObjectText: {
        minWidth: '100%'
    }
}));

const TeacherExpenses = () => {
    const {selectedObject} = useParams();
    const {selectedExpense} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const fetched_objects = useSelector(state => state.teachersView.temp.objects)
    const objects = useSelector(state => state.teachersView.expenses.objects)
    const objects_fetch_status = useSelector(state => state.teachersView.expenses.status)
    const post_status = useSelector(state => state.teachersView.expenses.post_status)
    const [addingExpense, setAddingExpense] = React.useState(false)
    const [addingObjectRequest, setAddingObjectRequest] = React.useState(false)
    const [expenseData, setExpenseData] = React.useState({title: '', amount: '', detail: ''})

    function handleChange(e) {
        const {
            name,
            value
        } = e.target;
        setExpenseData(data => ({
            ...data,
            [name]: value
        }));
    }

    function handleAddExpenseToggle() {
        if (addingExpense === true) {
            setExpenseData({title: '', amount: '', detail: ''})
        }
        setAddingExpense(state => !state)
    }

    function handleAddExpenseSubmit() {
        if (expenseData.title && expenseData.amount) {
            dispatch(userActions.AddObjectExpenseTeacher(expenseData, selectedObject))
            setAddingObjectRequest(true) // enable loading
        }
    }

    useEffect(() => {
        if (post_status === 'succeeded' && addingObjectRequest) {
            setAddingObjectRequest(false)
            setAddingExpense(false)
            setExpenseData({title: '', amount: '', detail: ''})
        }
    }, [post_status, addingObjectRequest])


    // function returnExpenses (object){
    //     let expenses = {}
    //     if(object.expense){
    //         object.expense.map((expense, index) =>
    //             expenses[expense.id] = expense
    //         )
    //         // expenses[object.expense.id] = object.expense
    //         return expenses
    //     }
    // }
    //
    // function processExpenses(objects){
    //     let tempObjects = {}
    //     let temp = {}
    //     objects.map((object, index) =>
    //         tempObjects[object.id] = {id: object.id, name: object.name, expenses:returnExpenses(object)}
    //     )
    //     console.dir(tempObjects)
    // }

    function processExpenses(objects) {
        let tempObjects = {}
        objects.map((object, index) =>
            tempObjects[object.id] = new function () {
                this.id = object.id
                this.name = object.name
                this.expenses = new function () {
                    if (object.expense) {
                        object.expense.map((expense, index) =>
                            this[expense.id] = expense
                        )
                        return this
                    }
                }()
            }()
        )
        dispatch(userActions.setExpenseData(tempObjects))
    }

    useEffect(() => {
        if (fetched_objects.length < 1) {
            dispatch(userActions.getAccountingObjectsTeacher())
            console.log(fetched_objects)

        }
    }, [fetched_objects])

    useEffect(() => {
        if (fetched_objects.length > 0 && objects_fetch_status !== 'succeeded') {
            processExpenses(fetched_objects)
        }
    }, [fetched_objects])

    useEffect(() => {
        if (objects) {
            if (objects_fetch_status === 'succeeded' && selectedObject && !Object.keys(objects).includes(selectedObject)) {
                if (Object.keys(objects).length > 0) {
                    navigate(`/teacher/expenses${objects[Object.keys(objects)[0]].id}/`,)

                }

            }
        }
    }, [selectedObject, selectedExpense, objects])

    return (
        <div>
            {objects_fetch_status === 'succeeded' ?
                <Grid container spacing={1}>
                    <Grid item md={3} xs={12}>
                        <Card
                        >
                            <CardHeader
                                title={(<div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography variant={"h5"} style={{marginRight: 10}}>
                                            Objects:
                                        </Typography>
                                    </div>
                                </div>)}
                            >
                            </CardHeader>
                            <Divider/>
                            <CardContent className={classes.content}>
                                {objects_fetch_status === 'succeeded' && objects ?

                                    <List>


                                        {Object.keys(objects).map((key, index) => (
                                            <ListItem
                                                className={parseInt(key) === parseInt(selectedObject) ? classes.selectedListItem : classes.normalListItem}
                                                divider={index < Object.keys(objects).length - 1}
                                                key={key}
                                                component={RouterLink}
                                                to={`/teacher/expenses/${key}/`}
                                            >
                                                <ListItemIcon> <Folder/> </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="body1"
                                                                className={parseInt(key) === parseInt(selectedObject) ? classes.selectedListItemText : null}>
                                                        {objects[key].name}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>


                                        ))}
                                    </List>

                                    : null}

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        {Object.keys(objects).includes(selectedObject) && objects_fetch_status === 'succeeded' ?
                            <ExpensesList handleAddExpenseToggle={handleAddExpenseToggle}
                                          objects={objects}
                                          selectedObject={selectedObject}
                                          objects_fetch_status={objects_fetch_status}
                                          selectedExpense={selectedExpense}
                            />
                            : null}
                    </Grid>

                    <Grid item md={5} xs={12}>
                        {objects[selectedObject] || addingExpense ?
                            objects[selectedObject].expenses[selectedExpense] || addingExpense ?
                                <ExpenseDetails
                                    handleAddExpenseToggle={handleAddExpenseToggle}
                                    objects={objects}
                                    selectedObject={selectedObject}
                                    selectedExpense={selectedExpense}
                                    addingExpense={addingExpense}
                                    expenseData={expenseData}
                                    handleChange={handleChange}
                                    handleAddExpenseSubmit={handleAddExpenseSubmit}
                                />
                                : null
                            : null}
                    </Grid>

                </Grid> : null //TODO: Add loding Screen
            }

        </div>


    );
};

TeacherExpenses.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default TeacherExpenses;
