import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Link,
    Typography,
    colors,makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2)
    },
    content: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexWrap: 'wrap'
        },
        '&:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    header: {
        maxWidth: '100%',
        width: 240,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            flexBasis: '100%'
        }
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    stats: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    },
    actions: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%'
        }
    }
}));

const RentalTruckCardComponent = ({   rent,
                                      openDeleteRentDialog,
                                      className,
                                      handleDeleteRentOpenDialog,
                                      selectedDeleteRent,
                                      handleDeleteRentCloseDialog,
                                      handleDeleteRentConfirm,
                         ...rest}) => {
    const classes = useStyles();
    const statusColors = {
        'In progress': colors.orange[600],
        Canceled: colors.grey[600],
        Completed: colors.green[600]
    };


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <div className={classes.stats}>
                    <Typography variant="h6">{rent.hour}</Typography>
                    <Typography variant="body2">Hours</Typography>
                </div>

                <div className={classes.stats}>
                    <Typography variant="h6">
                        {'C$'}
                        {rent.price_tax}
                    </Typography>
                    <Typography variant="body2">Price(after-tax)</Typography>
                </div>

                {/*<div className={classes.stats}>*/}
                {/*    <Typography variant="h6">*/}
                {/*        {moment(deposit.start_date).format('DD MMMM YYYY')}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body2">Project started</Typography>*/}
                {/*</div>*/}
                <div className={classes.stats}>
                    <Typography variant="h6">
                        {moment(rent.created_at).format('DD MMMM YYYY')}
                    </Typography>
                    <Typography variant="body2">Date</Typography>
                </div>
                <div className={classes.stats}>
                    <Typography
                        // style={{ color: statusColors[deposit.status] }}
                        variant="h6"
                    >
                        {rent.detail? rent.detail : '-'}
                    </Typography>
                    <Typography variant="body2">Details</Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        style={{color: '#dc004e'}}
                        size="small"
                        variant="outlined"
                        onClick={() => handleDeleteRentOpenDialog(rent.id)}

                    >
                        Delete
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

RentalTruckCardComponent.propTypes = {
    className: PropTypes.string,
    rent: PropTypes.object.isRequired
};

export default RentalTruckCardComponent;
