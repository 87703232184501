import {authHeader} from "../Helpers";
import {history} from '../Helpers';

export const userService = {
    login,
    recoverPassword,
    changePassword,
    logout,
    createTeacher,
    createSecretary,
    editTeacher,
    getTeacher,
    getTeacherSchedule,
    createTeacherSchedule,
    deleteSession,
    cancelSession,

    resetTeacherSchedule,
    createStudent,
    editStudent,
    editHour,
    getStudent,
    deleteStudent,
    deleteTeacher,
    getAllStudents,
    getStudentSessions,
    getStudentInspectionSessions,
    getStudentDeposits,
    getAllDeposits,
    getStudentRents,
    deleteRent,
    deleteStudentDeposits,
    getTeachersList,
    createStudentSession,
    createStudentInspectionSession,
    getTeacherVacations,
    getTeacherOvertimes,
    createTeacherOvertimes,
    createTeacherVacation,
    getTeacherSessions,
    getTeacherSessionsForAWeek,
    getTeacherInspectionSessionsForAWeek,
    getTableDataForAWeek,

    confirmSessionWithStudentIdentification,
    confirmSessionWithTeacherId,
    deleteTeacherSchedule,
    getTeacherOvertimesForAWeek,
    getTeacherVacationsForAWeek,
    createStudentDeposit,
    createStudentRent,
    getSettings,
    editSettings,
    getStudentNotesList,
    getStudentNoteDetails,
    editNote,
    createNote,
    deleteNote,
    getAccountingObjects,
    getObjectExpenses,
    AddAccountingObject,
    AddObjectExpense,
    deleteExpense,
    deleteObject,
    editExpense,
    getUpComingTeacherSessions,
    AddObjectExpenseTeacher,
    getAccountingObjectsTeacher,
    sendCertificateEmail,
    deleteOvertime,
    deleteVacation,

}



function changePassword(code, username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },

        body: JSON.stringify({password: password})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/resetpassword/${code}/${username}/`, requestOptions).then(handleResponse)
}


function recoverPassword(username) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },

        body: JSON.stringify({user_or_email: username})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/forgotpassword/`, requestOptions).then(handleResponse)
}


function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch('https://api-m.nationaltruckdrivingschool.ca/api/v1/login/', requestOptions)
        .then(handleResponse)
        .then(user => {
            // history.push("/")
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const data = user.data.user
            data.token = user.data.token
            localStorage.setItem('user', JSON.stringify(data));
            return user;
        });
}


function deleteTeacherSchedule(week_day, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${id}/schedule/${week_day}/`, requestOptions).then(handleResponse)
}



function deleteObject(object_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/${object_id}/`, requestOptions).then(handleResponse)
}

function deleteVacation(teacher_id, time) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/vacation/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse)
}

function deleteOvertime(teacher_id, time) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/overtime/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse)
}


function deleteExpense(object_id, expense_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/${object_id}/expenses/${expense_id}/`, requestOptions).then(handleResponse)
}

function deleteNote(student_id, note_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/note/${note_id}/`, requestOptions).then(handleResponse)
}



function createSecretary(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(user)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/register/`, requestOptions).then(handleResponse)
}



function createTeacher(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(user)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/create/`, requestOptions).then(handleResponse)
}

function editTeacher(user, teacher_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(user)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${teacher_id}/`, requestOptions).then(handleResponse)
}

function editSettings(settings) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(settings)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/administration/update/`, requestOptions).then(handleResponse)
}

function editNote(student_id, note_id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/note/${note_id}/`, requestOptions).then(handleResponse)
}




function editExpense(data, object_id, expense_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/${object_id}/expenses/${expense_id}/`, requestOptions).then(handleResponse)
}



function sendCertificateEmail(id, file) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), },

        body: file
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${id}/certificate/`, requestOptions).then(handleResponse)
}

function AddObjectExpenseTeacher(data, object_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/objects/${object_id}/expenses/`, requestOptions).then(handleResponse)
}

function AddObjectExpense(data, object_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/${object_id}/expenses/`, requestOptions).then(handleResponse)
}



function AddAccountingObject(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/`, requestOptions).then(handleResponse)
}

function createNote(student_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/note/`, requestOptions).then(handleResponse)
}

function createStudent(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(user)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/create/`, requestOptions).then(handleResponse)
}

function editStudent(user, student_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(user)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/`, requestOptions).then(handleResponse)
}

function editHour( student_id, hour ) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(hour)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/addhour/`, requestOptions).then(handleResponse)
}
function confirmSessionWithStudentIdentification(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/confirmation/student/`, requestOptions).then(handleResponse)
}

function confirmSessionWithTeacherId(data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/confirmation/teacher/`, requestOptions).then(handleResponse)
}

function createTeacherSchedule(schedule, id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(schedule)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${id}/schedule/`, requestOptions).then(handleResponse)
}


function createStudentDeposit(student_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/payment/`, requestOptions).then(handleResponse)
}

function createStudentRent(student_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/student/${student_id}/rentaltruck/`, requestOptions).then(handleResponse)
}

function deleteSession(session_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/deletesession/${session_id}/`, requestOptions).then(handleResponse)
}

function cancelSession(session_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/deletesession/${session_id}/`, requestOptions).then(handleResponse)
}

function createTeacherVacation(teacherId, vacation) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(vacation)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/vacation/teacher/${teacherId}/`, requestOptions).then(handleResponse)
}

function createTeacherOvertimes(teacherId, overtime) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(overtime)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/overtime/teacher/${teacherId}/`, requestOptions).then(handleResponse)
}

function createStudentSession(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch('https://api-m.nationaltruckdrivingschool.ca/api/v1/setsession/', requestOptions).then(handleResponse)
}

function createStudentInspectionSession(data, student_id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify(data)
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/student/${student_id}/inspection/`, requestOptions).then(handleResponse)
}


function resetTeacherSchedule(day, teacher_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },

        body: JSON.stringify({})
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${teacher_id}/schedule/${day}`, requestOptions).then(handleResponse)
}


function getTeacher(teacher_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${teacher_id}/`, requestOptions).then(handleResponse);
}

function getTeachersList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/`, requestOptions).then(handleResponse);
}

//
//
// function getDaySchedule(dayIso) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/schedule/${dayIso}/`, requestOptions).then(handleResponse);
// }
//
// function getDaySessions(dayIso) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/session/${dayIso}/`, requestOptions).then(handleResponse);
// }
//


function getStudent(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/`, requestOptions).then(handleResponse);
}

function deleteStudent(student_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/`, requestOptions).then(handleResponse);
}

function deleteTeacher(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${id}/`, requestOptions).then(handleResponse);
}


function deleteRent(student_id, rent_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/student/${student_id}/rentaltruck/${rent_id}/`, requestOptions).then(handleResponse);
}


function getAllStudents() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/`, requestOptions).then(handleResponse);
}

function getTeacherSchedule(teacher_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${teacher_id}/schedule/`, requestOptions).then(handleResponse);
}

function getTeacherVacations(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/vacation/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse);
}

function getTeacherOvertimes(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/overtime/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse);
}

function getTeacherOvertimesForAWeek(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/overtimetest/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse);
}

function getSettings() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/administration/`, requestOptions).then(handleResponse);
}

function getTeacherVacationsForAWeek(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/vacationtest/teacher/${teacher_id}/${time}/`, requestOptions).then(handleResponse);
}

function getTeacherSessions(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/${teacher_id}/sessions/${time}/`, requestOptions).then(handleResponse);
}

function getTeacherSessionsForAWeek(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teachertest/${teacher_id}/sessions/${time}/`, requestOptions).then(handleResponse);
}

function getTeacherInspectionSessionsForAWeek(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/teacher/${teacher_id}/inspection/weekly/${time}/`, requestOptions).then(handleResponse);
}

function getTableDataForAWeek(teacher_id, time) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teachertestt/${teacher_id}/sessions/${time}/`, requestOptions).then(handleResponse);
}


function getStudentSessions(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/sessions/`, requestOptions).then(handleResponse);
}

function getStudentInspectionSessions(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/student/${student_id}/inspection/`, requestOptions).then(handleResponse);
}

function getStudentDeposits(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/payment/`, requestOptions).then(handleResponse);
}

function getAllDeposits(start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/deposits/${start}/${end}/`, requestOptions).then(handleResponse);
}

function getStudentRents(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/student/${student_id}/rentaltruck/`, requestOptions).then(handleResponse)
}

function getStudentNotesList(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/note/`, requestOptions).then(handleResponse)
}

    function getAccountingObjectsTeacher() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/objects/`, requestOptions).then(handleResponse)
    }

function getObjectExpenses(object_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/${object_id}/expenses/`, requestOptions).then(handleResponse)
}



function getUpComingTeacherSessions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/teacher/recentSessions/`, requestOptions).then(handleResponse)
}

function getAccountingObjects() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v2/expenses/objects/`, requestOptions).then(handleResponse)
}

function getStudentNoteDetails(student_id, note_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/note/${note_id}/`, requestOptions).then(handleResponse)
}


function deleteStudentDeposits(student_id, deposit_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`https://api-m.nationaltruckdrivingschool.ca/api/v1/student/${student_id}/payment/${deposit_id}/`, requestOptions).then(handleResponse);
}



export function logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('user');



    // localStorage.removeItem('user_data');

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                history.push('/login')
                window.location.reload(true);
            }
            let error = data
            if(data.error){
                if(data.error.description){
                    error = data.error.description
                }else{
                    if(data.error.error){
                        error = data.error.error
                    }else{
                        error= data.error
                    }
                }
                }
            // const error = data.error ? data.error.description : data
            // console.log(data.error.code)
            return Promise.reject(error);
        }
        // console.dir(data)
        return data;
    });
}
