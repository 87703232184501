import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, Typography, Avatar,makeStyles } from '@material-ui/core';
import gradients from "../../../../utils/gradients";
import {UserPlus} from "react-feather";
const useStyles = makeStyles(theme => ({
  root: {
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundImage: gradients.green,
    // color: theme.palette.primary.main,
    height: 48,
    width: 48
  }
}));

const CreateTeacherSelect = props => {
  const { className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      onClick={()=> {props.handleNavigatePages('create')}}
    >
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline"
        >
          Create User
        </Typography>
        <div className={classes.details}>
          <Typography
            color="inherit"
            variant="h3"
          >
            Create User
          </Typography>
        </div>
      </div>
      <Avatar
        className={classes.avatar}
        color="inherit"
      >
        <UserPlus />
      </Avatar>
    </Card>
  );
};


CreateTeacherSelect.propTypes = {
  className: PropTypes.string
};

export default CreateTeacherSelect;
