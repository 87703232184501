import {userConstants} from '../Constants';
import produce from 'immer';

const initialState = {
    temp:{
        objects:[]
    },
    upcomingSessions: {
        sessions: [],
        status: 'idle',
        confirming_session_status:'idle',
        error: null
    },
    expenses: {
        objects: {},
        status: 'idle',
        post_status: 'idle',
        error: null
    },
    notes: {
        status: 'idle'
    }

}

export function teachersView(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_TEACHERS_UPCOMING_SESSIONS_REQUEST:
            return {
                ...state,
                upcomingSessions: {
                    ...state.upcomingSessions,
                    status: 'loading'
                }
            }

        case userConstants.GET_TEACHERS_UPCOMING_SESSIONS_SUCCESS:
            return {
                ...state,
                upcomingSessions: {
                    ...state.upcomingSessions,
                    status: 'succeeded',
                    sessions: action.sessions.results
                }
            }

        case userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_REQUEST:
            return {
                ...state,
                upcomingSessions: {
                    ...state.upcomingSessions,
                    confirming_session_status : 'loading',
                }
            }

        case userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_SUCCESS:
            const index = state.upcomingSessions.sessions.findIndex(item => item.id === action.session.data[0].id)
            return produce(state, draft => {
                draft.upcomingSessions.confirming_session_status = 'succeeded';
                draft.upcomingSessions.sessions[index] = action.session.data[0]
            });

        case userConstants.RESET_CONFIRM_SESSION_STATUS:
            return {
                ...state,
                upcomingSessions: {
                    ...state.upcomingSessions,
                    confirming_session_status : 'idle',
                }
            }

        case userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_FAILURE:
            return {
                ...state,
                upcomingSessions: {
                    ...state.upcomingSessions,
                    confirming_session_status : 'failed',
                }
            }

        case userConstants.SET_EXPENSE_DATA:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    objects: action.expenses,
                    status: 'succeeded'
                }

            }

        case userConstants.CREATE_OBJECT_EXPENSE_TEACHER_REQUEST:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    post_status: 'loading'
                }
            }

        case userConstants.CREATE_OBJECT_EXPENSE_TEACHER_SUCCESS:
            return produce(state, draft => {
                draft.expenses.objects[action.expense._object].expenses[action.expense.id] = action.expense
                draft.expenses.post_status = 'succeeded';
            });

        case userConstants.CREATE_OBJECT_EXPENSE_TEACHER_FAILURE:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    post_status: 'failed'
                }
            }

        case userConstants.GET_ACCOUNTING_OBJECTS_TEACHER_REQUEST:
            return {
                ...state,
                expenses: {
                    ...state.expenses,
                    status: 'loading'
                }
            }

        case userConstants.GET_ACCOUNTING_OBJECTS_TEACHER_SUCCESS:
            return {
                ...state,
                temp:{
                    ...state.temp,
                    objects: action.objects.results
                }
            }

        case userConstants.CREATE_NOTE_SUCCESS:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    status: 'succeeded'
                }
            }

        case userConstants.CREATE_NOTE_REQUEST:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    status: 'loading'
                }
            }

        case userConstants.CREATE_NOTE_FAILURE:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    status: 'failed'
                }
            }

        default:
            return state
    }
}
