import * as React from "react";
import {
    Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";



export default function ShowRentsListComponent({ rents ,handleDeleteRentConfirm,
                                                     handleDeleteRentOpenDialog,
                                                     openDeleteRentDialog,
                                                     selectedDeleteRent,
                                                     handleDeleteRentCloseDialog}) {
    // const classes = useStyles();
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false)
    const [selectedRentDetailId, setSelectedRentDetailId] = React.useState('')
    function handleOpenDetailsDialog(deposit_id) {
        setSelectedRentDetailId(deposit_id)
        setOpenDetailsDialog(true)
    }

    function handleCloseDetailsDialog() {
        setSelectedRentDetailId('')
        setOpenDetailsDialog(false)
    }

    function getDate(s) {
        let b = s.split(/\D+/);
        let toObject = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return toObject.toDateString()
    }

    return (
        <div>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    #
                                </TableCell>
                                <TableCell sortDirection="desc">
                                    <Tooltip
                                        enterDelay={300}
                                        title="Sort"
                                    >
                                        <TableSortLabel
                                            active
                                            direction="desc"
                                        >
                                            Hours
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    Price(After-Tax)
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    Amount(After-Tax)*/}
                                {/*</TableCell>*/}

                                <TableCell>
                                    Date
                                </TableCell>

                                <TableCell>
                                    Details
                                </TableCell>

                                {/*<TableCell>*/}
                                {/*    Cancel*/}
                                {/*</TableCell>*/}

                                <TableCell>
                                    Delete
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rents.map((rent, index) => (
                                // rent.shown === true ?
                                    <TableRow
                                        hover
                                        key={rent.id}
                                    >
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {rent? rent.hour : null}
                                        </TableCell>


                                        <TableCell>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant="body1" color="textSecondary" component="p">
                                                    C$
                                                </Typography>
                                                <Typography variant="body1" color="textPrimary">
                                                    {rent.price_tax}

                                                </Typography>
                                            </div>
                                        </TableCell>

                                        <TableCell>
                                            {rent.created_at ? getDate(rent.created_at) : null}
                                        </TableCell>

                                        <TableCell>
                                            {rent.detail ?
                                                <Button startIcon={<ListIcon/>}
                                                        variant="contained"

                                                        color="primary"
                                                        onClick={() => handleOpenDetailsDialog(rent.id)}
                                                >

                                                    Details
                                                </Button>
                                                :
                                                <Button startIcon={<ListIcon/>}
                                                        variant="contained"
                                                        disabled
                                                        color="primary"
                                                        onClick={() => handleOpenDetailsDialog(rent.id)}
                                                >

                                                    Details
                                                </Button>
                                            }

                                        </TableCell>


                                        <TableCell>
                                            <Button name="Delete"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => handleDeleteRentOpenDialog(rent.id)}
                                            >
                                                <DeleteForeverRoundedIcon/>
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog} style={{
                display:'grid'
            }}>
                <DialogTitle id="alert-dialog-title">{"Deposit Details Text"}</DialogTitle>

                <DialogContent>
                    <Card >
                        <CardContent>
                            <Typography>
                                {selectedRentDetailId ? rents.find(item => item.id === selectedRentDetailId).detail : null}
                            </Typography>

                        </CardContent>
                    </Card>
                </DialogContent>

                <DialogActions>
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"

                            color="primary"
                            onClick={handleCloseDetailsDialog}
                    >

                        Back
                    </Button>
                </DialogActions>
            </Dialog>



        </div>
    )



}
