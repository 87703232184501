import * as React from "react";
import clsx from 'clsx';
import Button from "@material-ui/core/Button";
import ListIcon from '@material-ui/icons/List';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions, DialogContent, DialogTitle,
    Divider, makeStyles, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles((theme) => ({

    root2: {
        minHeight: '100%'
    },
    actions: {
        justifyContent: 'flex-end'
    }

}));


export default function ShowDepositListComponent({
                                                     handleDeleteDepositConfirm,
                                                     deposits, disableDelete,
                                                     handleDeleteDepositOpenDialog,
                                                     openDeleteDepositDialog,
                                                     selectedDeleteDeposit,
                                                     handleDeleteDepositCloseDialog
                                                 }) {
    const classes = useStyles();
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false)
    const [selectedDepositDetailId, setSelectedDepositDetailId] = React.useState('')


    function handleOpenDetailsDialog(deposit_id) {
        setSelectedDepositDetailId(deposit_id)
        setOpenDetailsDialog(true)
    }

    function handleCloseDetailsDialog() {
        setSelectedDepositDetailId('')
        setOpenDetailsDialog(false)
    }

    function getDate(s) {
        let b = s.split(/\D+/);
        let toObject = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return toObject.toDateString()
    }

    function calculateSum(deposits){
        let sum = 0
        for (var i = 0; i < deposits.length; i++) {
            sum += parseFloat(deposits[i].deposit)

        }

        return sum

    }


    return (
        <div>
            <Box>
                {deposits && disableDelete ?
                    <div>
                        <Typography>
                            Sum:
                        </Typography>
                        <Typography>
                            CA$ {calculateSum(deposits)}
                        </Typography>
                    </div>



                    : null}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    #
                                </TableCell>
                                <TableCell sortDirection="desc">
                                    <Tooltip
                                        enterDelay={300}
                                        title="Sort"
                                    >
                                        <TableSortLabel
                                            active
                                            direction="desc"
                                        >
                                            Date
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    Amount(Pre-Tax)
                                </TableCell>
                                {/*<TableCell>*/}
                                {/*    Amount(After-Tax)*/}
                                {/*</TableCell>*/}

                                <TableCell>
                                    Payment Type
                                </TableCell>

                                <TableCell>
                                    Details
                                </TableCell>

                                {/*<TableCell>*/}
                                {/*    Cancel*/}
                                {/*</TableCell>*/}

                                <TableCell>
                                    Delete
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deposits.map((deposit, index) => (
                                deposit.shown === true ?
                                    <TableRow
                                        hover
                                        key={deposit.id}
                                    >
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {deposit ? getDate(deposit.create_date) : null}
                                        </TableCell>


                                        <TableCell>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant="body1" color="textSecondary" component="p">
                                                    C$
                                                </Typography>
                                                <Typography variant="body1" color="textPrimary">
                                                    {deposit.deposit}

                                                </Typography>
                                            </div>
                                        </TableCell>


                                        {/*<TableCell>*/}
                                        {/*    <div style={{*/}
                                        {/*        display: 'flex',*/}
                                        {/*        flexDirection: 'row',*/}
                                        {/*        alignItems: 'center'*/}
                                        {/*    }}>*/}
                                        {/*        <Typography variant="body1" color="textSecondary" component="p">*/}
                                        {/*            C$*/}
                                        {/*        </Typography>*/}
                                        {/*        <Typography variant="body1" color="textPrimary">*/}
                                        {/*            {deposit.deposit_tax}*/}
                                        {/*        </Typography>*/}
                                        {/*    </div>*/}
                                        {/*</TableCell>*/}


                                        <TableCell>
                                            {deposit.type}
                                        </TableCell>

                                        <TableCell>
                                            {deposit.detail.length > 0 ?
                                                <Button startIcon={<ListIcon/>}
                                                        variant="contained"

                                                        color="primary"
                                                        onClick={() => handleOpenDetailsDialog(deposit.id)}
                                                >

                                                    Details
                                                </Button>
                                                :
                                                <Button startIcon={<ListIcon/>}
                                                        variant="contained"
                                                        disabled
                                                        color="primary"
                                                        onClick={() => handleOpenDetailsDialog(deposit.id)}
                                                >

                                                    Details
                                                </Button>
                                            }

                                        </TableCell>


                                        {/*<TableCell>*/}
                                        {/*    <Button name="Cancel"*/}
                                        {/*            variant="outlined"*/}
                                        {/*        // style={session.status === 'confirmed' ? {backgroundColor: '#8DFF76'} :*/}
                                        {/*        //     session.status ==='no show' ? {backgroundColor: '#FF1D4D'} :*/}
                                        {/*        //         null}*/}
                                        {/*            color="secondary"*/}
                                        {/*        // onClick={() => handleOpenConfirmDeleteSessionDialog(session.id)}*/}
                                        {/*    >*/}
                                        {/*        <ClearRoundedIcon/>*/}

                                        {/*    </Button>*/}

                                        {/*</TableCell>*/}

                                        <TableCell>
                                            <Button name="Delete"
                                                    variant="outlined"
                                                    color="secondary"
                                                    disabled={disableDelete}
                                                    onClick={() => handleDeleteDepositOpenDialog(deposit.id)}
                                            >
                                                <DeleteForeverRoundedIcon/>
                                            </Button>

                                        </TableCell>
                                    </TableRow> : null
                            ))}


                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog} style={{
                display: 'grid'
            }}>
                <DialogTitle id="alert-dialog-title">{"Deposit Details Text"}</DialogTitle>

                <DialogContent>
                    <Card style={{minWidth: 300}}>
                        <CardContent>
                            <Typography>
                                {selectedDepositDetailId ? deposits.find(item => item.id === selectedDepositDetailId).detail : null}
                            </Typography>

                        </CardContent>
                    </Card>
                </DialogContent>

                <DialogActions>
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"

                            color="primary"
                            onClick={handleCloseDetailsDialog}
                    >

                        Back
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDeleteDepositDialog} onClose={handleDeleteDepositCloseDialog} style={{
                display: 'grid'
            }}>
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this payment?"}</DialogTitle>

                <DialogContent>

                    {selectedDeleteDeposit ?
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>

                                            <TableCell sortDirection="desc">
                                                <Tooltip
                                                    enterDelay={300}
                                                    title="Sort"
                                                >
                                                    <TableSortLabel
                                                        active
                                                        direction="desc"
                                                    >
                                                        Date
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                Amount(Pre-Tax)
                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    Amount(After-Tax)*/}
                                            {/*</TableCell>*/}

                                            <TableCell>
                                                Payment Type
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            hover
                                            key={1}
                                        >

                                            <TableCell>
                                                {selectedDeleteDeposit ? getDate(selectedDeleteDeposit.create_date) : null}
                                            </TableCell>


                                            <TableCell>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <Typography variant="body1" color="textSecondary" component="p">
                                                        C$
                                                    </Typography>
                                                    <Typography variant="body1" color="textPrimary">
                                                        {selectedDeleteDeposit ? selectedDeleteDeposit.deposit : null}

                                                    </Typography>
                                                </div>
                                            </TableCell>


                                            {/*<TableCell>*/}
                                            {/*    <div style={{*/}
                                            {/*        display: 'flex',*/}
                                            {/*        flexDirection: 'row',*/}
                                            {/*        alignItems: 'center'*/}
                                            {/*    }}>*/}
                                            {/*        <Typography variant="body1" color="textSecondary" component="p">*/}
                                            {/*            C$*/}
                                            {/*        </Typography>*/}
                                            {/*        <Typography variant="body1" color="textPrimary">*/}
                                            {/*            {selectedDeleteDeposit ? selectedDeleteDeposit.deposit_tax : null}*/}
                                            {/*        </Typography>*/}
                                            {/*    </div>*/}
                                            {/*</TableCell>*/}


                                            <TableCell>
                                                {selectedDeleteDeposit ? selectedDeleteDeposit.type : null}
                                            </TableCell>


                                        </TableRow>


                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Box>

                        : null}

                </DialogContent>

                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"

                            color="secondary"
                            onClick={handleDeleteDepositConfirm}
                    >

                        Yes
                    </Button>

                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"

                            color="primary"
                            onClick={handleDeleteDepositCloseDialog}
                    >

                        No
                    </Button>
                </DialogActions>


            </Dialog>
        </div>


    )


}
