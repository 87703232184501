import * as React from "react";
import ShowRentsListComponent from "../Components/ShowRentsListComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Card, CardActions,
    CardContent,
    CardHeader, CircularProgress, colors,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab,
    Hidden,
    LinearProgress, makeStyles,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import ShowDepositListComponent from "../Components/ShowDepositsListComponent";
import ShowDeletedDepositListComponent from "../Components/ShowDeletedDepositsListComponent";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import AddIcon from "@material-ui/icons/Add";
import AddDepositComponent from "../Components/AddDepositComponent";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import {userActions} from "../../../Actions";
import {useEffect, useState} from "react";
import AddRentComponent from "../Components/AddRentComponent";
import RentalTruckCardComponent from "../../../views/account/Student/Components/RentalTruckCardComponent";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import moment from "moment";
import {useParams} from "react-router-dom";




const useStyles = makeStyles((theme) => ({

    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
    },
    cancelButton: {
        marginLeft: 'auto'
    },
    confirmButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }

}));



export default function RentalTruckContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {id} = useParams();
    const [openAddRentDialog, setOpenAddRentDialog] = React.useState(false)
    const created_student_rent_done = useSelector(state => state.students.created_student_rent_done)
    const creating_student_rent = useSelector(state => state.students.creating_student_rent)
    const fetched_student_rents = useSelector(state => state.students.fetched_student_rents)
    const [openDeleteRentDialog, setOpenDeleteRentDialog] = React.useState(false)
    const [selectedDeleteRent, setSelectedDeleteRent] = React.useState(null)
    const deleting_student_rent = useSelector(state => state.students.deleting_student_rent)
    const deleted_student_rent_done = useSelector(state => state.students.deleted_student_rent_done)
    const [openShowDeletedRentsDialog, setOpenShowDeletedRentsDialog] = React.useState(false)
    const [creatingRent, setCreatingRent] = React.useState(false)
    const [openCalandar, setOpenCalandar] = React.useState(false)
    const [calendarDate, setCalendarDate] = useState(moment());

    const constants = useSelector(state => state.students.settings)
    {
        /*

    the GET request for deposits is made in StudentProfile alongside the get request for sessions

        */
    }



    const truncateDecimals = function (number, digits) {
        let multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

        return truncatedNum / multiplier;
    };



    const [createRent, setCreateRent] = React.useState({
        hour: '1',
        price: '',
        price_tax: '',
        date_normal: '',
        date: moment().toISOString(),
        // detail: '',
        // student : student_id
    })

    useEffect(()=>{
        if(createRent){
            setCreateRent({
                hour: '1',
                price: constants.rental_truck_price,
                price_tax: truncateDecimals((parseFloat(1))
                    *parseFloat(constants.rental_truck_price) * (1 + (constants.tax_rate/100)), 2),
                date_normal: '',
                date: moment().toISOString(),
                // detail: '',
                // student : student_id
            })
        }

    },[constants, openAddRentDialog])

    function handleCreateRent() {
        setOpenAddRentDialog(true)
    }

    function handleCloseAddRentDialog() {
        setOpenAddRentDialog(false)
        setCreateRent({
            hour: '1',
            price: constants.rental_truck_price,
            price_tax: truncateDecimals((parseFloat(1))
                *parseFloat(constants.rental_truck_price) * (1 + (constants.tax_rate/100)), 2),
            date_normal: '',
            date: moment().toISOString(),
            // detail: '',
            // student : student_id
        })

    }



    function handleToggleCalandar(){
        if(openCalandar){
            setOpenCalandar(false)
            setCalendarDate(moment())
        }
        else{
            setOpenCalandar(true)

        }
    }

    function handleCreateRentDetailChange(e){
        const {name, value} = e.target;
        setCreateRent(createRent => ({...createRent, detail: value}))

    }

    function handleCreateRentChange(date) {
        setCalendarDate(date)
    }

    const handleCalanderAccept = date =>{
        setCreateRent(createRent => ({...createRent, date: moment(date).toISOString()}))
        setOpenCalandar(false)
    }

    function handleCreateRentHourChange(e) {
        const {name, value} = e.target;

        setCreateRent(createRent => ({...createRent, hour: value}));
        setCreateRent(createRent => ({...createRent, price: value * parseFloat(constants.rental_truck_price)}));
        setCreateRent(createRent => ({...createRent, price_tax: truncateDecimals((parseFloat(value))
                *parseFloat(constants.rental_truck_price) * (1+ constants.tax_rate/10), 2)}));

    }

    function handleCreateRentDateChange(e){
        const {name, value} = e.target;

        setCreateRent(createRent => ({...createRent, date: value}));

    }

    function handleCreateRentSubmit() {
        if (createRent.hour && createRent.price_tax  && createRent.date &&!creatingRent) {
            setCreatingRent(true)
            dispatch(userActions.createStudentRent(id, createRent))

        }
    }

    function handleDeleteRentOpenDialog(rent_id){
        // setSelectedDeleteDepositId(deposit_id)
        setSelectedDeleteRent(fetched_student_rents.find(item=> item.id === rent_id))
        setOpenDeleteRentDialog(true)
    }

    function handleDeleteRentConfirm(){
        // send request
        dispatch(userActions.deleteRent(id, selectedDeleteRent.id))
        setOpenDeleteRentDialog(false)
    }


    function handleDeleteRentCloseDialog(){
        // send request
        setSelectedDeleteRent(null)
        // setSelectedDeleteDepositId('')
        setOpenDeleteRentDialog(false)

        // refresh data
    }


    function handleCloseDeletedRents (){
        setOpenShowDeletedRentsDialog(false)
    }

    useEffect(() => { // reload the deposits after a deposit has been successfully created.
        if (created_student_rent_done && !creating_student_rent && createRent.hour && createRent.price_tax) {
            {/*

                Checking if anything is in createDeposit . if there is, we'll reload because that means a new deposit
                has been created. if there isn't anything in createDeposit, that means there wasn't any request
                for creating a deposit to begin with and so we don't need to refresh

            */
            }
            // console.log("Refreshing")
            dispatch(userActions.getStudentRents(id))
            dispatch(userActions.getStudent(id))
            setCreateRent({
                hour: '',
                price:'',
                price_tax: '',
                date_normal: '',
                date: '',

                // detail: '',
            })
            setCreatingRent(false)
            setOpenAddRentDialog(false)

        }
    }, [created_student_rent_done, creating_student_rent])



    useEffect(()=>{ // runs in order to refresh payments if a payment is deleted

        if(deleted_student_rent_done && !deleting_student_rent && selectedDeleteRent){
            dispatch(userActions.getStudentRents(id))
            dispatch(userActions.getStudent(id))
            setSelectedDeleteRent(null)
        }

    },[deleting_student_rent,deleted_student_rent_done])




    return (
        <div>
            <Hidden smDown>
                <Card>
                    <CardContent>

                        <div style={{display: 'flex', justifyContent:'space-between'}}>

                            <CardHeader title="Rents History"/>

                        </div>




                        <ShowRentsListComponent rents={fetched_student_rents}
                                                openDeleteRentDialog={openDeleteRentDialog}
                                                handleDeleteRentOpenDialog={handleDeleteRentOpenDialog}
                                                selectedDeleteRent={selectedDeleteRent}
                                                handleDeleteRentCloseDialog={handleDeleteRentCloseDialog}
                                                handleDeleteRentConfirm={handleDeleteRentConfirm}
                        />




                    </CardContent>


                    <Box display="flex"
                         justifyContent="flex-end"
                         p={2}>
                        <Button onClick={handleCreateRent}
                                startIcon={<AddIcon/>}
                                variant="contained"
                                color={"primary"}
                                style={{justifySelf: 'flex-end'}}>
                            Add Rent
                        </Button>
                    </Box>
                </Card>
            </Hidden>

            <Hidden mdUp>
                {fetched_student_rents.map(rent => {
                    return <RentalTruckCardComponent rent={rent}
                                                     key={rent.id}
                                                     openDeleteRentDialog={openDeleteRentDialog}
                                                     handleDeleteRentOpenDialog={handleDeleteRentOpenDialog}
                                                     selectedDeleteRent={selectedDeleteRent}
                                                     handleDeleteRentCloseDialog={handleDeleteRentCloseDialog}
                                                     handleDeleteRentConfirm={handleDeleteRentConfirm}/>
                })}

            </Hidden>

            <Hidden mdUp>

            <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={handleCreateRent}

                //   style={{
                //     margin: 0,
                //     top: 'auto',
                //     right: 20,
                //     bottom: 70,
                //     left: 'auto',
                //     position: 'fixed',
                // }}
            >
                <AddIcon/>
            </Fab>
            </Hidden>

            <Dialog open={openDeleteRentDialog} onClose={handleDeleteRentCloseDialog} style={{
                display:'grid'
            }}>
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this payment?"}</DialogTitle>

                <DialogContent>

                    {selectedDeleteRent?
                        <Box >
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>

                                            <TableCell sortDirection="desc">
                                                <Tooltip
                                                    enterDelay={300}
                                                    title="Sort"
                                                >
                                                    <TableSortLabel
                                                        active
                                                        direction="desc"
                                                    >
                                                        Hours
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                Price(After-Tax)
                                            </TableCell>
                                            <TableCell>
                                                Date
                                            </TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            hover
                                            key={1}
                                        >

                                            <TableCell>
                                                {selectedDeleteRent? selectedDeleteRent.hour : null}
                                            </TableCell>




                                            <TableCell>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    <Typography variant="body1" color="textSecondary" component="p">
                                                        C$
                                                    </Typography>
                                                    <Typography variant="body1" color="textPrimary">
                                                        {selectedDeleteRent? selectedDeleteRent.price_tax: null}

                                                    </Typography>
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                {selectedDeleteRent?  moment(selectedDeleteRent.created_at).format('DD MMMM YYYY') : null}
                                            </TableCell>


                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        : null}

                </DialogContent>

                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"

                            color="secondary"
                            onClick={handleDeleteRentConfirm}
                    >

                        Yes
                    </Button>

                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"

                            color="primary"
                            onClick={handleDeleteRentCloseDialog}
                    >

                        No
                    </Button>
                </DialogActions>


            </Dialog>

            <Dialog open={openShowDeletedRentsDialog} onClose={handleCloseDeletedRents}
                    fullWidth
                    maxWidth={"md"}
            >
                <DialogContent>
                    <ShowDeletedDepositListComponent rents={fetched_student_rents}
                                                     handleDeleteRentOpenDialog={handleDeleteRentOpenDialog}
                    />
                </DialogContent>

                <DialogActions >
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleCloseDeletedRents}
                    >

                        Back
                    </Button>
                </DialogActions>

            </Dialog>


            <Dialog open={openAddRentDialog} onClose={handleCloseAddRentDialog} fullWidth
                    maxWidth={"sm"}
            >


                <AddRentComponent
                    handleCalanderAccept={handleCalanderAccept}
                    handleToggleCalandar={handleToggleCalandar}
                    openCalandar={openCalandar}
                    handleCreateRentChange={handleCreateRentChange}
                    calendarDate={calendarDate}
                    handleCreateRentHourChange={handleCreateRentHourChange}
                    createRent={createRent}
                    handleCreateRentDateChange={handleCreateRentDateChange}
                    handleCreateRentSubmit={handleCreateRentSubmit}
                    creatingRent={creatingRent}
                    handleCreateRentDetailChange={handleCreateRentDetailChange}
                    handleCloseAddRentDialog={handleCloseAddRentDialog}

                />


                <DialogActions>
                    {/*<Button startIcon={<CheckSharpIcon/>} variant="contained"*/}
                    {/*        onClick={handleCreateRentSubmit} color="primary" type={"submit"}*/}
                    {/*>*/}
                    {/*    {creatingRent ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Submit'}*/}

                    {/*</Button>*/}
                    <Button
                        className={classes.cancelButton}
                        // onClick={onCancel}
                        variant="contained"
                        onClick={handleCloseAddRentDialog}
                    >
                        Cancel
                    </Button>

                    <Button
                        className={classes.confirmButton}
                        onClick={handleCreateRentSubmit}
                        variant="contained"
                    >
                        {creatingRent ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
