import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../Actions";
import ShowTeacherWeeklyScheduleForSessions from "../Student/Components/ShowTeacherWeeklyScheduleForSessions";
import {Navigate, useParams} from "react-router-dom";
import {
    Button,
    Checkbox,
    CircularProgress, FormControl,
    FormControlLabel,
    Grid, InputAdornment, InputLabel,
    makeStyles, MenuItem,
    Radio,
    RadioGroup, Select, Typography
} from "@material-ui/core";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import Page from "../../Components/Page";
import TextField from "@material-ui/core/TextField";
//-------------------------------------------------Show Teacher Schedule Stuff-------------------------------------

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    tabAppBar: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    RightButton: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    formControl:{
        // margin: theme.spacing(1),
        maxHeight: 200,
    },
    formControlLabel:{
        marginRight: theme.spacing(2)
    }

}));


let week = []

function createWeek(inputDate) {
    let date = new Date(inputDate)
    for (let i = 0; i < 7; i++) {
        week[i] = new Date(date)
        date.setDate(date.getDate() + 1) // TODO: change to UTC maybe?
    }
}


const weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const Simpleweekday = new Array(7);
Simpleweekday[0] = "Sun";
Simpleweekday[1] = "Mon";
Simpleweekday[2] = "Tue";
Simpleweekday[3] = "Wed";
Simpleweekday[4] = "Thu";
Simpleweekday[5] = "Fri";
Simpleweekday[6] = "Sat";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function makeRange(start_time, end_time) {

    const time_range = []
    start_time = Number(start_time.split(':')[0])
    end_time = Number(end_time.split(':')[0])
    for (let i = start_time; i < end_time; i++) {
        time_range.push(i);
    }
    return time_range
}


export default function WeekTableContainer({teacher, handleEditScheduleToggle,}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let {id, teacherId} = useParams();
    let {type} = useParams();
    const all_students = useSelector(state => state.students.fetched_all_students)
    const currentWeek = useSelector(state => state.weekTable.currentWeek)
    const currentTeacher = useSelector(state => state.weekTable.currentTeacher)
    const weekTableData = useSelector(state => state.weekTable.weekTableData)
    const fetching_weekTableData = useSelector(state => state.weekTable.fetching_weekTableData)
    const fetched_weekTableData_done = useSelector(state => state.weekTable.fetched_weekTableData_done)
    const fetched_weekTableData = useSelector(state => state.weekTable.fetched_weekTableData)
    const unProcessed_weeks = useSelector(state => state.weekTable.unProcessed_weeks)
    const [allFetchingDone, setAllFetchingDone] = React.useState(false)
    const teachers_list = useSelector(state => state.teachers.fetched_teachers_list);
    const [selectedTeacherId, setSelectedTeacherId] = React.useState(teacher ? teacher : 1)
    const [addingInspectionClass, setAddingInspectionClass] = React.useState(false)
    const createStudentSessionDone = useSelector(state => state.weekTable.student_session_creation_done);
    const createStudentInspectionSessionDone = useSelector(state => state.weekTable.student_inspection_session_creation_done);
    const [fetching, setFetching] = React.useState(false)
    const [isReadOnly, setIsReadOnly] = React.useState(false)
    const [loading, setLoading] = React.useState(false)


    const [createAddSessionData, setCreateAddSessionData] = React.useState(
        {
            start: "", teacher: '', student: id, type_of_class: "long", extra_hour: false,
        }
    );

    const [createAddInspectionSessionData, setCreateAddInspectionSessionData] = React.useState(
        {
            start: "", teacher: '', student: id, duration: "L"
        }
    );

    const handleChangeSelectedTeacherId = (e) => {

        setSelectedTeacherId(parseInt(e.target.value))
    }

    const handleAddSessionSubmit = () => {
        if (!addingInspectionClass) {
            setCreateAddSessionData({...createAddSessionData, type_of_class: 'Long'})
            if (createAddSessionData.start && createAddSessionData.type_of_class &&
                createAddSessionData.teacher && createAddSessionData.student) {
                setLoading(true)
                dispatch(userActions.createStudentSession(createAddSessionData))
                setCreateAddSessionData(createAddSessionData => ({...createAddSessionData, extra_hour: false}));
                // handleOpenSessionTableDialog() //closes the dialog after request is done
//TODO: redirect to student page
            }
        }

        if (addingInspectionClass) {
            if (createAddInspectionSessionData.start && createAddInspectionSessionData.duration &&
                createAddInspectionSessionData.teacher && createAddInspectionSessionData.student) {
                setLoading(true)
                dispatch(userActions.createStudentInspectionSession(createAddInspectionSessionData, id))
                setCreateAddSessionData(createAddSessionData => ({...createAddSessionData, extra_hour: false}));
                // handleOpenSessionTableDialog()
            }
        }
    }

    function handleClassTypeChange(e) {
        // if(!addingInspectionClass){
        setCreateAddSessionData(createAddSessionData =>
            ({...createAddSessionData, type_of_class: e.target.value}));
        if (e.target.value === 'short') {
            setCreateAddInspectionSessionData(createAddInspectionSessionData =>
                ({...createAddInspectionSessionData, duration: 'S'}))
        }
        if (e.target.value === 'long') {
            setCreateAddInspectionSessionData(createAddInspectionSessionData =>
                ({...createAddInspectionSessionData, duration: 'L'}))
        }

    }


    function handleExtraHourChange() { // when creating a new session for student, set it as extra hour if the option is selected
        setCreateAddSessionData(createAddSessionData => ({
            ...createAddSessionData,
            extra_hour: !createAddSessionData.extra_hour
        }));

    }

    function setAsInspection(type) {
        if (type === 'inspection') {
            setAddingInspectionClass(true)
        }
        if (type === 'job') {
            setCreateAddSessionData(createAddSessionData => ({
                ...createAddSessionData,
                job_session: true
            }));
        }
        if (type === 'extra') {
            setCreateAddSessionData(createAddSessionData => ({
                ...createAddSessionData,
                extra_hour: true
            }));
        }
    }

    function makeInitialSkeleton() {
        week[0].setUTCHours(1, 0, 1)
        const currentWeek = `${week[0].toUTCString()}`
        let tempWeek = {[currentWeek]: []}
        for (let i = 0; i < 7; i++) { // make the current week
            week[i].setUTCHours(1, 0, 1)
            tempWeek[currentWeek].push({
                id: i + 1,
                Day: weekday[week[i].getUTCDay()],
                Date: week[i].toUTCString(),
                simpleDate: Simpleweekday[week[i].getUTCDay()] + ', ' + months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear(),
                time: [],
                vacations: [],
                overtimes: [],
                sessions: [],
                inspection_sessions: [],
                selectedSessions: []

            })
        }
        const current_week_last_day = new Date(Math.max.apply(null, week)) //getting ready to create next week
        const current_week_first_day = new Date(Math.min.apply(null, week)); // get the min date from array


        for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
            current_week_first_day.setDate(current_week_first_day.getDate() + -1)
            week[6 - i] = new Date(current_week_first_day)
        }
        week[0].setUTCHours(1, 0, 1)
        const prevWeek = `${week[0].toUTCString()}`
        tempWeek[prevWeek] = []
        for (let i = 0; i < 7; i++) { // make the current week
            week[i].setUTCHours(1, 0, 1)
            tempWeek[prevWeek].push({
                id: i + 1,
                Day: weekday[week[i].getUTCDay()],
                Date: week[i].toUTCString(),
                simpleDate: Simpleweekday[week[i].getUTCDay()] + ', ' + months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear(),
                time: [],
                vacations: [],
                overtimes: [],
                sessions: [],
                inspection_sessions: [],
                selectedSessions: []

            })
        }
        for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
            current_week_last_day.setDate(current_week_last_day.getDate() + 1)
            week[i] = new Date(current_week_last_day)
        }

        week[0].setUTCHours(1, 0, 1)

        const nextWeek = `${week[0].toUTCString()}`
        tempWeek[nextWeek] = []
        for (let i = 0; i < 7; i++) { // make the current week
            week[i].setUTCHours(1, 0, 1)
            tempWeek[nextWeek].push({
                id: i + 1,
                Day: weekday[week[i].getUTCDay()],
                Date: week[i].toUTCString(),
                simpleDate: Simpleweekday[week[i].getUTCDay()] + ', ' + months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear(),
                time: [],
                vacations: [],
                overtimes: [],
                sessions: [],
                inspection_sessions: [],
                selectedSessions: []

            })
        }
        dispatch(userActions.initializeWeekTableData(tempWeek, currentWeek, selectedTeacherId))
        Object.keys(tempWeek).map(week => dispatch(userActions.addUnProcessedWeek(week)))
    }

    function fetchNeededData() {
        // finding out which weeks we need to make a request for
        const tableDataWeeks = Object.keys(weekTableData)
        const fetched_tableDataWeeks = fetched_weekTableData ? Object.keys(fetched_weekTableData) : []
        const results = tableDataWeeks.filter(e => !fetched_tableDataWeeks.find(a => e === a)); // difference between two arrays

        //make requests for the weeks that need to fetch data
        results.map(result =>
            dispatch(userActions.getTableDataForAWeek(selectedTeacherId, new Date(result).toISOString(), result))
        )
        setFetching(false)
    }

    function proccessFetchedData() { // check for inconsistancy between data. check if data is fetched
        unProcessed_weeks.map((unprocessedWeek) => {
            const tableData = weekTableData[unprocessedWeek]
            const fetchedTableData = fetched_weekTableData[unprocessedWeek]
            //---------------------------------------------------------------------------- process teacher schedule-----
            fetchedTableData.schedule.map((single_schedule) => {
                dispatch(userActions.setTableScheduleTime(
                    unprocessedWeek,
                    tableData.findIndex(row => row.Day === single_schedule.day_of_week),
                    makeRange(single_schedule.start_time, single_schedule.end_time)
                ))
            });
            //---------------------------------------------------------------------------------------------------------
            //------------------------------------------------------------------------------------Making temp Arrays----
            let sessionArray = []
            let inspectionArray = []
            let overtimeArray = []
            let vacationArray = []
            //-------------------------------------------------------------------------------------Process Sessions----
            fetchedTableData.sessions.map((session) => {
                let tempTimes = []
                const sessionStart = new Date(session.start)
                sessionStart.setUTCHours(1, 0, 1)
                if (tableData.findIndex(item => item.Date === sessionStart.toUTCString()) >= 0) {
                    const index = tableData.findIndex(item => item.Date === sessionStart.toUTCString())
                    let start = new Date(session.start).getUTCHours()
                    let end = new Date(session.end).getUTCHours()
                    for (let k = start; k < end; k++) {
                        if (!tempTimes.includes(k)) { //condition is so we don't get reoccoring times TODO: might not be needed
                            tempTimes.push(k)
                        }
                    }
                    if (!sessionArray[sessionStart.toUTCString()]) {
                        sessionArray[sessionStart.toUTCString()] = {sessions: null, index: null, week: null}
                        sessionArray[sessionStart.toUTCString()].sessions = tempTimes
                        sessionArray[sessionStart.toUTCString()].index = index
                    } else {
                        sessionArray[sessionStart.toUTCString()].sessions.map(item =>
                            tempTimes.push(item)
                        )
                        sessionArray[sessionStart.toUTCString()] = {sessions: null, index: null}
                        sessionArray[sessionStart.toUTCString()].sessions = tempTimes
                        sessionArray[sessionStart.toUTCString()].index = index
                    }
                }
                Object.keys(sessionArray).map(key =>
                    dispatch(userActions.setSessionInWeekTable(unprocessedWeek,
                        sessionArray[key].index, sessionArray[key].sessions
                    ))
                )
            })
            //----------------------------------------------------------------------------------------------------------
            //-----------------------------------------------------------------------------------Process Inspections----
            fetchedTableData.inspection.map((inspection) => {
                let tempTimes = []
                const sessionStart = new Date(inspection.start)
                sessionStart.setUTCHours(1, 0, 1)
                if (tableData.findIndex(item => item.Date === sessionStart.toUTCString()) >= 0) {
                    const index = tableData.findIndex(item => item.Date === sessionStart.toUTCString())
                    let start = new Date(inspection.start).getUTCHours()
                    let end = new Date(inspection.end).getUTCHours()
                    for (let k = start; k < end; k++) {
                        if (!tempTimes.includes(k)) { //condition is so we don't get reoccoring times TODO: might not be needed
                            tempTimes.push(k)
                        }
                    }
                    if (!inspectionArray[sessionStart.toUTCString()]) {
                        inspectionArray[sessionStart.toUTCString()] = {inspection: null, index: null, week: null}
                        inspectionArray[sessionStart.toUTCString()].inspection = tempTimes
                        inspectionArray[sessionStart.toUTCString()].index = index
                    } else {
                        inspectionArray[sessionStart.toUTCString()].inspection.map(item =>
                            tempTimes.push(item)
                        )
                        inspectionArray[sessionStart.toUTCString()] = {inspection: null, index: null}
                        inspectionArray[sessionStart.toUTCString()].inspection = tempTimes
                        inspectionArray[sessionStart.toUTCString()].index = index
                    }
                }
                Object.keys(inspectionArray).map(key =>
                    dispatch(userActions.setInspectionInWeekTable(unprocessedWeek,
                        inspectionArray[key].index, inspectionArray[key].inspection
                    ))
                )
            })
            //----------------------------------------------------------------------------------------------------------
            //-----------------------------------------------------------------------------------Process Overtimes------
            fetchedTableData.overtime.map((overtime) => {
                let tempTimes = []
                const sessionStart = new Date(overtime.start)
                sessionStart.setUTCHours(1, 0, 1)
                if (tableData.findIndex(item => item.Date === sessionStart.toUTCString()) >= 0) {
                    const index = tableData.findIndex(item => item.Date === sessionStart.toUTCString())
                    let start = new Date(overtime.start).getUTCHours()
                    let end = new Date(overtime.end).getUTCHours()
                    for (let k = start; k < end; k++) {
                        if (!tempTimes.includes(k)) { //condition is so we don't get reoccoring times TODO: might not be needed
                            tempTimes.push(k)
                        }
                    }
                    if (!overtimeArray[sessionStart.toUTCString()]) {
                        overtimeArray[sessionStart.toUTCString()] = {overtime: null, index: null, week: null}
                        overtimeArray[sessionStart.toUTCString()].overtime = tempTimes
                        overtimeArray[sessionStart.toUTCString()].index = index
                    } else {
                        overtimeArray[sessionStart.toUTCString()].overtime.map(item =>
                            tempTimes.push(item)
                        )
                        overtimeArray[sessionStart.toUTCString()] = {overtime: null, index: null}
                        overtimeArray[sessionStart.toUTCString()].overtime = tempTimes
                        overtimeArray[sessionStart.toUTCString()].index = index
                    }
                }
                Object.keys(overtimeArray).map(key =>
                    dispatch(userActions.setOvertimeInWeekTable(unprocessedWeek,
                        overtimeArray[key].index, overtimeArray[key].overtime
                    ))
                )
            })
            //----------------------------------------------------------------------------------------------------------
            //-----------------------------------------------------------------------------------Process Vacations------
            fetchedTableData.vacation.map((vacation) => {
                let tempTimes = []
                const sessionStart = new Date(vacation.start)
                sessionStart.setUTCHours(1, 0, 1)
                if (tableData.findIndex(item => item.Date === sessionStart.toUTCString()) >= 0) {
                    const index = tableData.findIndex(item => item.Date === sessionStart.toUTCString())
                    let start = new Date(vacation.start).getUTCHours()
                    let end = new Date(vacation.end).getUTCHours()
                    for (let k = start; k < end; k++) {
                        if (!tempTimes.includes(k)) { //condition is so we don't get reoccoring times TODO: might not be needed
                            tempTimes.push(k)
                        }
                    }
                    if (!vacationArray[sessionStart.toUTCString()]) {
                        vacationArray[sessionStart.toUTCString()] = {vacation: null, index: null, week: null}
                        vacationArray[sessionStart.toUTCString()].vacation = tempTimes
                        vacationArray[sessionStart.toUTCString()].index = index
                    } else {
                        vacationArray[sessionStart.toUTCString()].vacation.map(item =>
                            tempTimes.push(item)
                        )
                        vacationArray[sessionStart.toUTCString()] = {vacation: null, index: null}
                        vacationArray[sessionStart.toUTCString()].vacation = tempTimes
                        vacationArray[sessionStart.toUTCString()].index = index
                    }
                }
                Object.keys(vacationArray).map(key =>
                    dispatch(userActions.setVacationInWeekTable(unprocessedWeek,
                        vacationArray[key].index, vacationArray[key].vacation
                    ))
                )
            })
            //----------------------------------------------------------------------------------------------------------
            dispatch(userActions.removeUnProcessedWeek(unprocessedWeek))
        })
    }


    function addNewWeek(inputWeek, type) {
        const week = []
        const first_day = new Date(inputWeek)
        first_day.setUTCHours(1, 0, 1)
        if (type === 'next') {
            first_day.setUTCDate(first_day.getUTCDate() + 6)
            first_day.setUTCHours(1, 0, 1)
            for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
                first_day.setUTCDate(first_day.getUTCDate() + 1)
                first_day.setUTCHours(1, 0, 1)

                week[i] = new Date(first_day.setUTCHours(1, 0, 1))
            }
        } else {
            if (type === 'prev') {
                for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
                    first_day.setUTCDate(first_day.getUTCDate() - 1)
                    first_day.setUTCHours(1, 0, 1)

                    week[6 - i] = new Date(first_day.setUTCHours(1, 0, 1))
                }
            }
        }

        const newWeek = `${week[0].toUTCString()}`
        const tempWeek = []

        for (let i = 0; i < 7; i++) { // make the current week
            tempWeek.push({
                id: i + 1,
                Day: weekday[week[i].getUTCDay()],
                Date: week[i].toUTCString(),
                simpleDate: Simpleweekday[week[i].getUTCDay()] + ', ' + months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear(),
                time: [],
                vacations: [],
                overtimes: [],
                sessions: [],
                inspection_sessions: [],
                selectedSessions: []

            })
        }
        dispatch(userActions.addWeek(newWeek, tempWeek))
        dispatch(userActions.addUnProcessedWeek(newWeek))
    }

    useEffect(() => {
        if (allFetchingDone) {
            if (currentTeacher) {
                if (currentTeacher !== selectedTeacherId) {
                    let today = new Date()
                    today.setUTCHours(1, 0, 1) // Added 1 hour because of daylight saving.
                    createWeek(today)
                    setAllFetchingDone(false)
                    dispatch(userActions.clearWeekTableData())
                    makeInitialSkeleton()
                }
            }
        }
    }, [allFetchingDone])

    useEffect(() => {
        let today = new Date()
        today.setUTCHours(1, 0, 1) // Added 1 hour because of daylight saving.
        createWeek(today)
    }, [])

    useEffect(() => {
        if (type !== 'full_schedule') {
            dispatch(userActions.getTeachersList());
        }
        setAsInspection(type)
        if (type === 'show' || type === 'full_schedule') {
            setIsReadOnly(true)
        }
        if (weekTableData.length === 0) {
            makeInitialSkeleton()
        }
        // only the first time. after that, TODO: check if there's already weeks and do this if there is
        // dispatch(userActions.getAllStudents())

    }, [])

    // useEffect(() => {
    //     // if(type === 'full_schedule' && teacherId){
    //     //     setSelectedTeacherId(parseInt(teacherId))
    //     // }
    // }, [teacherId, type, selectedTeacherId])


    useEffect(() => { // fetch data

        if (
            // weekTableData.length !== 0 && Object.keys(fetched_weekTableData).length !== Object.keys(weekTableData).length
            unProcessed_weeks.length > 0 && !fetching
        ) {
            setFetching(true)
            fetchNeededData()
        }
    }, [
        // weekTableData, fetched_weekTableData
        unProcessed_weeks
        //TODO: add selected teacher too
    ])

    useEffect(() => {
        if (Object.keys(fetched_weekTableData).length === Object.keys(weekTableData).length) {
            //TODO: check if fetching is truly done
            proccessFetchedData()
        }
    }, [fetched_weekTableData])

    useEffect(() => {
        if (Object.keys(fetched_weekTableData).length === Object.keys(weekTableData).length &&
            fetched_weekTableData_done && !fetching_weekTableData) {
            setAllFetchingDone(true)
        }
    }, [fetched_weekTableData, fetched_weekTableData_done, fetching_weekTableData, weekTableData])

    useEffect(() => { // runs when teacher is changed
        if (allFetchingDone) {
            let today = new Date()
            today.setUTCHours(1, 0, 1) // Added 1 hour because of daylight saving.
            createWeek(today)
            setAllFetchingDone(false)
            dispatch(userActions.clearWeekTableData())
            makeInitialSkeleton()
        }


    }, [selectedTeacherId])


    if (createStudentSessionDone || createStudentInspectionSessionDone) {
        return <Navigate to={`/app/account/${id}/sessions`}/>;

    }


    function handleGoNextWeek() {
        if (unProcessed_weeks.length === 0) {
            const nextWeek = new Date(currentWeek)
            const weeks = Object.keys(weekTableData)
            nextWeek.setUTCDate(nextWeek.getUTCDate() + 7)
            nextWeek.setUTCHours(1, 0, 1)
            const nextNextWeek = new Date(nextWeek)
            nextNextWeek.setUTCDate(nextNextWeek.getUTCDate() + 7)
            nextNextWeek.setUTCHours(1, 0, 1)
            if (weeks.includes(nextNextWeek.toUTCString()) === false) {
                addNewWeek(nextWeek.toUTCString(), 'next')
            }
            dispatch(userActions.setCurrentWeek(nextWeek.toUTCString()))
        }
    }

    function handleGoPrevWeek() {
        if (unProcessed_weeks.length === 0) {
            const weeks = Object.keys(weekTableData)
            const prevWeek = new Date(currentWeek)
            prevWeek.setUTCDate(prevWeek.getUTCDate() + -7)
            prevWeek.setUTCHours(1, 0, 1)
            const prevPrevWeek = new Date(prevWeek)
            prevPrevWeek.setUTCDate(prevPrevWeek.getUTCDate() + -7)
            prevPrevWeek.setUTCHours(1, 0, 1)
            if (weeks.includes(prevPrevWeek.toUTCString()) === false) {
                addNewWeek(prevWeek.toUTCString(), 'prev')
            }
            dispatch(userActions.setCurrentWeek(prevWeek.toUTCString()))
        }
    }


    const handleAddSessionClick = (time, item) => { // the state for createAddSessionData is in the parent component
        if (!item.vacations.includes(time)) { // Don't select hours that are in vacation
            if (item.time.includes(time) || item.overtimes.includes(time)) { // only select hour if it's either standard schedule or it's in overtime
                const date = new Date(item.Date)
                date.setUTCHours(time, 0, 0)
                let start_time_iso = date.toISOString()
                setCreateAddSessionData({...createAddSessionData, start: start_time_iso, teacher: selectedTeacherId})
                setCreateAddInspectionSessionData(data => ({
                    ...data,
                    start: start_time_iso,
                    teacher: selectedTeacherId
                }))
                // setCreateAddSessionArray([...createAddSessionArray, time])


                // ---------------------------------------------------------------------- Color thingy when selecting sessions
                weekTableData[currentWeek].map((item, index) => {
                    if (item.selectedSessions) {
                        dispatch(userActions.clearSelectedSession(currentWeek, index))
                    }
                })

                if (createAddSessionData.type_of_class === 'short') {

                    dispatch(userActions.setSelectedSession(currentWeek, weekTableData[currentWeek].findIndex(data => data.Day === item.Day), [time]))
                }
                if (createAddSessionData.type_of_class === 'long') {
                    dispatch(userActions.setSelectedSession(currentWeek, weekTableData[currentWeek].findIndex(data => data.Day === item.Day), [time, time + 1]))

                }

            }
        }
    }


    return (
        <Page
            className={classes.root}
            title={isReadOnly ? 'Instructors List' : 'Add Session'}
        >

            <Grid container spacing={2}>

                {!isReadOnly ? <Grid item xs={12} className={classes.button}>
                    <div>
                        <FormControlLabel
                            labelPlacement={"start"}
                            control={
                                <Select
                                    label="Duration"
                                    variant={"outlined"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="Select Instructor"
                                    value={createAddSessionData.type_of_class}
                                    onChange={handleClassTypeChange}
                                >

                                        <MenuItem value={"short"}

                                        >
                                            1-Hour Class</MenuItem>

                                    <MenuItem value={"long"}

                                    >
                                        2-Hour Class</MenuItem>
                                </Select>
                            }
                            label={
                                <Typography className={classes.formControlLabel}>
                                    Duration
                                </Typography>
                            }
                        />


                        {/*<RadioGroup aria-label="type_of_package" name="type_of_package"*/}
                        {/*            value={createAddSessionData.type_of_class} onChange={handleClassTypeChange}*/}
                        {/*            style={{display: 'flex', flexDirection: 'row'}}>*/}
                        {/*    <FormControlLabel value="short" control={<Radio/>} label="1-Hour Class"/>*/}
                        {/*    <FormControlLabel value="long" control={<Radio/>} label="2-Hour Class"/>*/}
                        {/*</RadioGroup>*/}
                    </div>
                </Grid> : null}

                {
                    type !== 'full_schedule' ?
                        <Grid item xs={12} className={classes.button}>
                            <div>


                                <FormControlLabel
                                    labelPlacement={"start"}
                                    control={
                                        <Select
                                            label="Teacher"
                                            variant={"outlined"}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="Select Instructor"
                                            value={selectedTeacherId}
                                            onChange={handleChangeSelectedTeacherId}
                                        >
                                            {teachers_list ? teachers_list.map(teacher => (

                                                <MenuItem value={teacher.id}
                                                          disabled={!allFetchingDone || unProcessed_weeks.length !== 0}>
                                                    {teacher.first_name + ' ' + teacher.last_name}</MenuItem>
                                            ) ): null}
                                        </Select>
                                    }
                                    label={
                                        <Typography className={classes.formControlLabel}>
                                            Teacher
                                        </Typography>
                                    }
                                />

                                {/*<RadioGroup aria-label="Select Instructor" name="Select Instructor"*/}
                                {/*            value={selectedTeacherId}*/}
                                {/*            onChange={handleChangeSelectedTeacherId}*/}
                                {/*            style={{display: 'flex', flexDirection: 'row'}}>*/}
                                {/*    {teachers_list ? teachers_list.map(teacher => (*/}
                                {/*        <FormControlLabel value={teacher.id} control={<Radio/>}*/}
                                {/*                          disabled={!allFetchingDone || unProcessed_weeks.length !== 0}*/}
                                {/*                          label={teacher.first_name + ' ' + teacher.last_name}/>*/}
                                {/*    )) : null}*/}


                                {/*</RadioGroup>*/}
                            </div>
                        </Grid>
                        : null
                }


                <Grid item xs={12} md={12} xl={12}>
                    <div>
                        {allFetchingDone ?
                            <ShowTeacherWeeklyScheduleForSessions // TODO: use the students object in session. show student details on hover
                                isReadOnly={isReadOnly}
                                handleGoNextWeek={handleGoNextWeek}
                                handleGoPreviousWeek={handleGoPrevWeek}
                                rows={weekTableData[currentWeek]}
                                allFetchingDone={allFetchingDone}
                                unProcessed_weeks={unProcessed_weeks}
                                handleAddSessionClick={handleAddSessionClick}
                                sessions={fetched_weekTableData[currentWeek].sessions}
                                inspection_sessions={fetched_weekTableData[currentWeek].inspection}
                                all_students={all_students}
                                //NEW STUFF
                                handleEditScheduleToggle={handleEditScheduleToggle}
                                currentWeek={currentWeek}
                            /> : null}
                    </div>
                </Grid>

                {!isReadOnly ? <Grid item xs={12} className={classes.RightButton}>
                    <div style={{display: "flex", justifyContent: 'flex-end'}}>
                        <FormControlLabel
                            control={<Checkbox checked={createAddSessionData.extra_hour}
                                               onChange={handleExtraHourChange} name="checkedA"/>}
                            label="Set session as extra"
                        />


                        <Button color="primary" variant={!loading? "contained": "outlined"} onClick={!loading ? handleAddSessionSubmit : null}
                                startIcon={!loading? <DoneOutlineRoundedIcon/> : null}

                        >
                            {loading ? <CircularProgress size={24} className={classes.buttonProgress}/> : 'Confirm'}
                        </Button>
                    </div>

                </Grid> : null}
            </Grid>

        </Page>

    )
}
