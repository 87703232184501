import React from 'react';
import clsx from 'clsx';
import { Card, Typography, Avatar,makeStyles  } from '@material-ui/core';
import gradients from "../../../../utils/gradients";
import {List} from "react-feather";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.indigo,
    height: 48,
    width: 48
  }
}));

const RentalSelect = props => {
  const { className, ...rest } = props;
  const classes = useStyles(props);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      onClick={()=> {props.handleNavigatePages('rental_truck')}}
    >
      <div>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="inherit"
        >
          Rental Truck
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3" color="inherit">
            Rental Truck
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <LocalShippingOutlinedIcon />
      </Avatar>
    </Card>
  );
};

RentalSelect.propTypes = {
};

export default RentalSelect;
