import React from 'react';
import clsx from 'clsx';
import {Card, Typography, Avatar, makeStyles, LinearProgress, Box, Grid} from '@material-ui/core';
import gradients from "../../../../utils/gradients";
import {List} from "react-feather";
import {useSelector} from "react-redux";


const useStyles = makeStyles(theme => ({
  root: {
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px'
  },
  details: {
    display: 'flex',
    alignItems: 'row',
    alignContent: 'center'
    // flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.blue,
    height: 48,
    width: 48,
    // flexGrow: 1
  },
  progress: {
    margin: theme.spacing(0, 1),
    flexGrow: 1,


  },
  bars:{
    marginRight: theme.spacing(10),
    flexGrow: 2,
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null
  },
  barsColor:{
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null
  }
}));

const SessionsSelect = props => {
  const { className,handleNavigatePages, ...rest } = props;
  const classes = useStyles(props);
  const student = useSelector(state => state.students.fetched_student);




  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      onClick={()=> {handleNavigatePages('sessions')}}
    >
      {/*<Grid container spacing={1} justify={"space-between"}>*/}
        {/*<Grid item xs={9}>*/}
          <div className={classes.bars}>
            <Typography
                component="h3"
                gutterBottom
                variant="overline"
                color="inherit"
            >
              Sessions
            </Typography>
            {/*<div className={classes.details}>*/}
            {/*<Typography variant="h6" color="inherit">*/}
            {/*  Sessions*/}
            {/*</Typography>*/}
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flexWrap="wrap"
            >
              <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.barsColor}
              >
                Hours:
              </Typography>
              <LinearProgress
                  className={classes.progress}
                  value={(student.permanent_hour - student.hour) / (student.permanent_hour - 6) * 100}
                  color="secondary"
                  variant="determinate"
              />
              <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.barsColor}
              >
                {`${Math.round(
                    student.permanent_hour - student.hour,
                )}/${student.permanent_hour - 6}`}
              </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flexWrap="wrap"
            >
              <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.barsColor}

              >
                Inspection:
              </Typography>
              <LinearProgress
                  className={classes.progress}
                  value={student.inspection_hour !== 0 ? student.completed_inspection_hour
                      / (student.inspection_hour) * 100 : 0}
                  color="secondary"
                  variant="determinate"
              />
              <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.barsColor}

              >

              {`${Math.round(
                  student.completed_inspection_hour,
              )}/${student.inspection_hour}`}

              </Typography>

            </Box>
            {
              student.f_package ?
              <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
              >
                <Typography
                    variant="h6"
                    color="textPrimary"
                    className={classes.barsColor}

                >
                  Job
                </Typography>
                <LinearProgress
                    className={classes.progress}
                    value={student.job_env_intro !== 0 ? student.completed_job_env_intro
                        / (student.job_env_intro) * 100 : 0}
                    color="secondary"
                    variant="determinate"
                />
                <Typography
                    variant="h6"
                    color="textPrimary"
                    className={classes.barsColor}

                >
                {`${Math.round(
                    student.completed_job_env_intro,
                )}/${student.job_env_intro}`}
                </Typography>
              </Box> : null
            }

            {/*</div>*/}
          </div>

        {/*</Grid>*/}





        {/*<Grid item xs={3}>*/}
          <Avatar className={classes.avatar}>
            <List />
          </Avatar>
        {/*</Grid>*/}
      {/*</Grid>*/}

    </Card>
  );
};

SessionsSelect.propTypes = {
};

export default SessionsSelect;
