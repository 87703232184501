import { alertActions } from './';
import { userService } from '../Services';
import { userConstants } from '../Constants';
import { history } from '../Helpers';


export const userActions = {
    login,
    recoverPassword,
    logout,
    createTeacher,
    createSecretary,
    editTeacher,
    getTeacher,
    getTeacherSchedule,
    createTeacherSchedule,
    resetTeacherSchedule,
    resetLocalTeacherSchedule,
    createStudent,
    editStudent,
    getStudent,
    deleteStudent,
    deleteTeacher,
    getAllStudents,
    getStudentSessions,
    getStudentInspectionSessions,
    getStudentDeposits,
    getAllDeposits,
    getStudentRents,

    DeleteStudentDeposits,
    deleteSession,
    cancelSession,
    createStudentRent,
    getTeachersList,
    createStudentSession,
    createStudentInspectionSession,
    clearfetchedStudent,
    clearFetchedTeacher,
    clearCreatedTeacher,
    getTeacherVacation,
    getTeacherOvertime,
    getTeacherOvertimesForAWeek,

    clearFetchedTeacherVacations,
    clearFetchedTeacherOvertimes,
    clearAllFetchedData,
    clearFetchedTeacherSessions,
    clearFetchedTeacherInspectionSessions,
    clearFetchedTeacherSchedules,
    createTeacherVacation,
    createTeacherOvertime,
    initializeTeacherTableData,

    setTeacherScheduleTime,
    deleteTeacherScheduleDay,
    deleteScheduleAfterSuccessfulResponse,
    clearTeacherTableData,
    clearCreatedTeacherOvertimeDone,
    clearCreatedTeacherVacationDone,
    teacherScheduleGoNextWeek,
    teacherScheduleGoPreviousWeek,
    setTeacherVacationsInData,
    setTeacherOvertimesInData,
    setTeacherSessionsInData,
    setTeacherInspectionSessionsInData,
    getTeacherSessions,
    deleteRent,
    confirmSessionWithStudentIdentification,
    confirmSessionWithTeacherId,
    setSelectedTempTime,
    setSelectedSession,
    clearSelectedTempTime,
    clearTeacherScheduleInData,
    clearSelectedSession,
    editHour,
    getTeacherSessionsForAWeek,
    getTeacherInspectionSessionsForAWeek,
    processTeacherSessionsForAWeek,
    processTeacherInspectionSessionsForAWeek,
    processTeacherOvertimesForAWeek,
    processTeacherVacationsForAWeek,
    createStudentDeposit,
    getTeacherVacationsForAWeek,
    clearStudentSessionCreationDone,
    resetCreatedSessionDone,
    getTableDataForAWeek,
    initializeWeekTableData,
    addUnProcessedWeek,
    removeUnProcessedWeek,
    setTableScheduleTime,
    setSessionInWeekTable,
    setInspectionInWeekTable,
    setVacationInWeekTable,
    setOvertimeInWeekTable,
    setCurrentWeek,
    addWeek,
    clearWeekTableData,
    resetEditStudent,
    getSettings,
    editSettings,
    resetDepositCreation,
    getStudentNotesList,
    getStudentNoteDetails,
    editNote,
    resetEditNote,
    createNote,
    resetCreateNote,
    deleteNote,
    resetDeleteNote,
    getAccountingObjects,
    getObjectExpenses,
    AddAccountingObject,
    AddObjectExpense,
    deleteExpense,
    deleteObject,
    editExpense,
    getUpComingTeacherSessions,
    // editObject,
    setExpenseData,
    AddObjectExpenseTeacher,
    getAccountingObjectsTeacher,
    resetTeachersViewConfirmSessionStatus,
    sendCertificateEmail,
    deleteOvertime,
    deleteVacation,
    setUserType,
    changePassword,

};




function changePassword(code, username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.changePassword(code, username, password)
            .then(
                user => {
                    dispatch(success(username));
                    // history.push('/student/create');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(username) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, username } }
    function success(username) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, username } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function recoverPassword(username) {
    return dispatch => {
        dispatch(request({ username }));

        userService.recoverPassword(username)
            .then(
                user => {
                    dispatch(success(username));
                    // history.push('/student/create');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(username) { return { type: userConstants.RECOVER_PASSWORD_REQUEST, username } }
    function success(username) { return { type: userConstants.RECOVER_PASSWORD_SUCCESS, username } }
    function failure(error) { return { type: userConstants.RECOVER_PASSWORD_FAILURE, error } }
}

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/student/create');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function initializeTeacherTableData(data){
    return dispatch =>{
        dispatch(request(data))
    }

    function request(data) { return { type: userConstants.INITIALIZE_TEACHER_TABLE_DATA, data } }
}

function initializeWeekTableData(data, dateString, teacher){
    return dispatch =>{
        dispatch(request(data))
    }

    function request(data) { return { type: userConstants.INITIALIZE_WEEK_TABLE_DATA, data, dateString, teacher } }
}

function addUnProcessedWeek(week){
    return dispatch =>{
        dispatch(request(week))
    }

    function request(week) { return { type: userConstants.ADD_TO_UNPROCESSED_WEEKS, week } }
}

function removeUnProcessedWeek(week){
    return dispatch =>{
        dispatch(request(week))
    }

    function request(week) { return { type: userConstants.REMOVE_FROM_UNPROCESSED_WEEKS, week } }
}

function setCurrentWeek(week){
    return dispatch =>{
        dispatch(request(week))
    }

    function request(week) { return { type: userConstants.SET_CURRENT_WEEK, week } }
}


function setExpenseData(expenses){
    return dispatch =>{
        dispatch(request(expenses))
    }

    function request(expenses) { return { type: userConstants.SET_EXPENSE_DATA, expenses } }
}

function addWeek(weekDate, week){
    return dispatch =>{
        dispatch(request(weekDate, week))
    }

    function request(weekDate, week) { return { type: userConstants.ADD_WEEK, weekDate, week } }
}


function resetEditStudent(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.RESET_EDIT_STUDENT, } }
}
function setTeacherScheduleTime(index, time){
    return dispatch =>{
        dispatch(request(index, time))
    }

    function request(index, time) { return { type: userConstants.SET_TEACHER_SCHEDULE_TIME, index, time } }
}

function setTableScheduleTime(week, index, time){
    return dispatch =>{
        dispatch(request(week,index, time))
    }

    function request(week,index, time) { return { type: userConstants.SET_TABLE_SCHEDULE_TIME, week,index, time } }
}

function clearTeacherTableData(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.CLEAR_TEACHER_TABLE_DATA, } }
}

function clearWeekTableData(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.CLEAR_WEEK_TABLE_DATA, } }
}

function resetDepositCreation(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.CLEAR_DEPOSIT_CREATION, } }
}



function teacherScheduleGoNextWeek(Day, Date, index){
    return dispatch =>{
        dispatch(request(Day, Date, index))
    }

    function request(Day , Date, index) { return { type: userConstants.TEACHER_SCHEDULE_GO_NEXT_WEEK,Day, Date , index} }
}

function teacherScheduleGoPreviousWeek(Day, Date, index){
    return dispatch =>{
        dispatch(request(Day, Date, index))
    }

    function request(Day , Date, index) { return { type: userConstants.TEACHER_SCHEDULE_GO_PREVIOUS_WEEK,Day, Date , index} }
}

function setTeacherVacationsInData(index, vacation){
    return dispatch =>{
        dispatch(request(index, vacation))
    }

    function request(index, vacation) { return { type: userConstants.SET_TEACHER_VACATIONS_IN_DATA,index, vacation } }
}

function setTeacherOvertimesInData(index, overtime){
    return dispatch =>{
        dispatch(request(index, overtime))
    }

    function request(index, overtime) { return { type: userConstants.SET_TEACHER_OVERTIMES_IN_DATA,index, overtime } }
}

function setTeacherSessionsInData(index, session){
    return dispatch =>{
        dispatch(request(index, session))
    }

    function request(index, session) { return { type: userConstants.SET_TEACHER_SESSIONS_IN_DATA,index, session } }
}

function setSessionInWeekTable(week, index, session){
    return dispatch =>{
        dispatch(request(week,index, session))
    }

    function request(week,index, session) { return { type: userConstants.SET_SESSION_IN_WEEK_TABLE,week,index, session } }
}


function setUserType(userType){
    return dispatch =>{
        dispatch(request(userType))
    }

    function request(userType) { return { type: userConstants.SET_USER_TYPE,userType } }
}



function setInspectionInWeekTable(week, index, inspection){
    return dispatch =>{
        dispatch(request(week,index, inspection))
    }

    function request(week,index, inspection) { return { type: userConstants.SET_INSPECTION_IN_WEEK_TABLE,week,index, inspection } }
}

function setVacationInWeekTable(week, index, vacation){
    return dispatch =>{
        dispatch(request(week,index, vacation))
    }

    function request(week,index, vacation) { return { type: userConstants.SET_VACATION_IN_WEEK_TABLE,week,index, vacation } }
}

function setOvertimeInWeekTable(week, index, overtime){
    return dispatch =>{
        dispatch(request(week,index, overtime))
    }

    function request(week,index, overtime) { return { type: userConstants.SET_OVERTIME_IN_WEEK_TABLE,week,index, overtime } }
}

function resetEditNote(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.RESET_EDIT_NOTE_DONE, } }
}

function resetCreateNote(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.RESET_CREATE_NOTE_DONE, } }
}

function resetDeleteNote(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.RESET_DELETE_NOTE_DONE, } }
}

function setTeacherInspectionSessionsInData(index, session){
    return dispatch =>{
        dispatch(request(index, session))
    }

    function request(index, session) { return { type: userConstants.SET_TEACHER_INSPECTION_SESSIONS_IN_DATA,index, session } }
}

function setSelectedTempTime(index, time){
    console.log(time)// setting the selected time when creating schedule in store
    return dispatch =>{
        dispatch(request(index, time))
    }

    function request(index, time) { return { type: userConstants.SET_SELECTED_TEMP_TIME_IN_DATA,index, time } }
}

function resetCreatedSessionDone(){
    return dispatch =>{
        dispatch(request())
    }

    function request(index, time) { return { type: userConstants.RESET_CREATED_SESSION_DONE, } }
}

function setSelectedSession(week, index, time){
    return dispatch =>{
        dispatch(request(week,index, time))
    }

    function request(week,index, time) { return { type: userConstants.SET_SELECTED_SESSION_IN_DATA,week,index, time } }
}

function clearSelectedSession(week,index){
    return dispatch =>{
        dispatch(request(week,index))
    }

    function request(week,index, time) { return { type: userConstants.CLEAR_SELECTED_SESSION_IN_DATA,week,index} }
}

function clearSelectedTempTime(index){
    return dispatch =>{
        dispatch(request(index))
    }

    function request(index) { return { type: userConstants.CLEAR_SELECTED_TEMP_TIME_IN_DATA,index} }
}

function clearTeacherScheduleInData(index){
    return dispatch =>{
        dispatch(request(index))
    }

    function request(index) { return { type: userConstants.CLEAR_TEACHER_SCHEDULE_IN_DATA,index} }
}

function clearStudentSessionCreationDone(index){ // TODO: BAD... Fix the whole refreshin proccess after session is created
    return dispatch =>{
        dispatch(request(index))
    }

    function request(index) { return { type: userConstants.CLEAR_STUDENT_SESSION_CREATION_DONE,index} }
}

function createTeacher(user) {
    return dispatch => {
        dispatch(request(user));

        userService.createTeacher(user)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Create Teacher successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_TEACHER_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_TEACHER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_TEACHER_FAILURE, error } }
}


function createSecretary(user) {
    return dispatch => {
        dispatch(request(user));

        userService.createSecretary(user)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Create Secretary successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_SECRETARY_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_SECRETARY_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_SECRETARY_FAILURE, error } }
}


function editTeacher(user, teacher_id) {
    return dispatch => {
        dispatch(request(user));

        userService.editTeacher(user, teacher_id)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Edit Teacher successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.EDIT_TEACHER_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_TEACHER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_TEACHER_FAILURE, error } }
}


function deleteSession(session_id) {
    return dispatch => {
        dispatch(request(session_id));

        userService.deleteSession(session_id)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Delete Session Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(session_id) { return { type: userConstants.DELETE_STUDENT_SESSION_REQUEST, session_id} } // no reducer for these three
    function success(session_id) { return { type: userConstants.DELETE_STUDENT_SESSION_SUCCESS,session_id } }
    function failure(error) { return { type: userConstants.DELETE_STUDENT_SESSION_FAILURE, error } }
}

function deleteNote(student_id ,note_id) {
    return dispatch => {
        dispatch(request(note_id));

        userService.deleteNote(student_id,note_id)
            .then(
                note => {
                    dispatch(success(note_id));
                    dispatch(alertActions.success('Delete Note Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(session_id) { return { type: userConstants.DELETE_NOTE_REQUEST, session_id} } // no reducer for these three
    function success(note_id) { return { type: userConstants.DELETE_NOTE_SUCCESS,note_id}}
    function failure(error) { return { type: userConstants.DELETE_NOTE_FAILURE, error } }
}


function deleteObject(object_id, object_index) {
    return dispatch => {
        dispatch(request());

        userService.deleteObject(object_id)
            .then(
                data => {
                    dispatch(success(data, object_index));

                    dispatch(alertActions.success('Delete Object Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_OBJECT_REQUEST,} }
    function success(data,object_index) { return { type: userConstants.DELETE_OBJECT_SUCCESS,data ,object_index} }
    function failure(error) { return { type: userConstants.DELETE_OBJECT_FAILURE, error }}
}



function deleteVacation(teacher_id, time, Date, currentWeek, ) {
    return dispatch => {
        dispatch(request());

        userService.deleteVacation(teacher_id, time)
            .then(
                data => {
                    dispatch(success( Date, currentWeek,));

                    dispatch(alertActions.success('Delete Off Time Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_VACATION_REQUEST,} }
    function success( Date, currentWeek,) { return { type: userConstants.DELETE_VACATION_SUCCESS, Date, currentWeek,} }
    function failure(error) { return { type: userConstants.DELETE_VACATION_FAILURE, error }}
}

function deleteOvertime(teacher_id, time ,Date, currentWeek,) {
    return dispatch => {
        dispatch(request());

        userService.deleteOvertime(teacher_id, time)
            .then(
                data => {
                    dispatch(success( Date, currentWeek,));

                    dispatch(alertActions.success('Delete Off Time Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_OVERTIME_REQUEST,} }
    function success( Date, currentWeek,) { return { type: userConstants.DELETE_OVERTIME_SUCCESS, Date, currentWeek,} }
    function failure(error) { return { type: userConstants.DELETE_OVERTIME_FAILURE, error }}
}


function deleteExpense(object_id, object_index, expense_id, expense_index) {
    return dispatch => {
        dispatch(request());

        userService.deleteExpense(object_id,expense_id)
            .then(
                data => {
                    dispatch(success(data, object_index, expense_index));

                    dispatch(alertActions.success('Delete Expense Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_EXPENSE_REQUEST,} }
    function success(data,object_index,expense_index) { return { type: userConstants.DELETE_EXPENSE_SUCCESS,data ,object_index,expense_index} }
    function failure(error) { return { type: userConstants.DELETE_EXPENSE_FAILURE, error }}
}




function deleteRent(student_id, rent_id) {
    return dispatch => {
        dispatch(request(rent_id));

        userService.deleteRent(student_id, rent_id)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Delete Rent Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(rent_id) { return { type: userConstants.DELETE_STUDENT_RENT_REQUEST, rent_id} } // no reducer for these three
    function success(rent_id) { return { type: userConstants.DELETE_STUDENT_RENT_SUCCESS,rent_id } }
    function failure(error) { return { type: userConstants.DELETE_STUDENT_RENT_FAILURE, error } }
}


function cancelSession(session_id) {
    return dispatch => {
        dispatch(request(session_id));

        userService.cancelSession(session_id)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success('Cancel Session Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(session_id) { return { type: userConstants.CANCEL_STUDENT_SESSION_REQUEST, session_id} }
    function success(session_id) { return { type: userConstants.CANCEL_STUDENT_SESSION_SUCCESS,session_id } }
    function failure(error) { return { type: userConstants.CANCEL_STUDENT_SESSION_FAILURE, error } }
}



function createStudent(user) { ///////////////////////// TODO: JUST WANNA HIGHLIGHT THIS> TESTING ERROR HANDLING ON THIS FUNC
    return dispatch => {
        dispatch(request(user));

        userService.createStudent(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('Create Student successful'));
                },
                error => {
                    console.log(error)
                    dispatch(failure(typeof error === 'string' ? error.toString() : null));
                    dispatch(alertActions.error(typeof error === 'string' ? error.toString() : null));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_STUDENT_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_STUDENT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_FAILURE, error } }
}

function editHour(student_id, hour) {
    return dispatch => {
        dispatch(request(student_id));

        userService.editHour(student_id, hour)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('Edit hour successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.EDIT_STUDENT_HOUR_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_STUDENT_HOUR_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_STUDENT_HOUR_FAILURE, error } }
}

function editStudent(user, student_id) {
    return dispatch => {
        dispatch(request(user));

        userService.editStudent(user, student_id)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('Edit Student successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.EDIT_STUDENT_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_STUDENT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_STUDENT_FAILURE, error } }
}


function editSettings(settings) {
    return dispatch => {
        dispatch(request(settings));

        userService.editSettings(settings)
            .then(
                settings => {
                    dispatch(success(settings));
                    dispatch(alertActions.success('Edit Settings successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(settings) { return { type: userConstants.EDIT_SETTINGS_REQUEST, settings } }
    function success(settings) { return { type: userConstants.EDIT_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: userConstants.EDIT_SETTINGS_FAILURE, error } }
}

function createStudentSession(data) {
    return dispatch => {
        dispatch(request(data));

        userService.createStudentSession(data)
            .then(
                session => {
                    dispatch(success(session));
                    dispatch(alertActions.success('Create Session successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: userConstants.CREATE_STUDENT_SESSION_REQUEST, data } }
    function success(session) { return { type: userConstants.CREATE_STUDENT_SESSION_SUCCESS, session } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_SESSION_FAILURE, error } }
}

function createStudentInspectionSession(data, student_id) {
    return dispatch => {
        dispatch(request(data));

        userService.createStudentInspectionSession(data, student_id)
            .then(
                session => {
                    dispatch(success(session));
                    dispatch(alertActions.success('Create Inspection Session successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: userConstants.CREATE_STUDENT_INSPECTION_SESSION_REQUEST, data } }
    function success(session) { return { type: userConstants.CREATE_STUDENT_INSPECTION_SESSION_SUCCESS, session } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_INSPECTION_SESSION_FAILURE, error } }
}


function createStudentDeposit(student_id, data) {
    return dispatch => {
        dispatch(request(student_id,data));

        userService.createStudentDeposit(student_id,data)
            .then(
                deposit => {
                    dispatch(success(deposit));
                    dispatch(alertActions.success('Create Deposit successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: userConstants.CREATE_STUDENT_DEPOSIT_REQUEST, data } }
    function success(deposit) { return { type: userConstants.CREATE_STUDENT_DEPOSIT_SUCCESS, deposit } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_DEPOSIT_FAILURE, error } }
}

function createStudentRent(student_id, data) {
    return dispatch => {
        dispatch(request(student_id,data));

        userService.createStudentRent(student_id,data)
            .then(
                deposit => {
                    dispatch(success(deposit));
                    dispatch(alertActions.success('Create Rent successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: userConstants.CREATE_STUDENT_RENT_REQUEST, data } }
    function success(rent) { return { type: userConstants.CREATE_STUDENT_RENT_SUCCESS, rent } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_RENT_FAILURE, error } }
}




function confirmSessionWithStudentIdentification(data, user) { // depending on the user who is confirming(either admin or teacher)
    // , different action types will be dispatched. only difference is where the data is being store
    return dispatch => {
        dispatch(request(data));

        userService.confirmSessionWithStudentIdentification(data)
            .then(
                session => {
                    dispatch(success(session));
                    dispatch(alertActions.success('Confirm Session With Student Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: user === 'teacher' ?
            userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_REQUEST
            :userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_REQUEST, data } }
    function success(session) { return { type:user === 'teacher' ?
            userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_SUCCESS
            : userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_SUCCESS, session } }
    function failure(error) { return { type:user === 'teacher' ?
            userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_FAILURE
            : userConstants.CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_FAILURE, error } }
}

function resetTeachersViewConfirmSessionStatus(){
    return dispatch =>{
        dispatch(request())
    }

    function request() { return { type: userConstants.RESET_CONFIRM_SESSION_STATUS,} }
}

function confirmSessionWithTeacherId(data) {
    return dispatch => {
        dispatch(request(data));

        userService.confirmSessionWithTeacherId(data)
            .then(
                session => {
                    dispatch(success(session));
                    dispatch(alertActions.success('Confirm Session With Teacher id Successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(data) { return { type: userConstants.CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_REQUEST, data } }
    function success(session) { return { type: userConstants.CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_SUCCESS, session } }
    function failure(error) { return { type: userConstants.CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_FAILURE, error } }
}


function createTeacherSchedule(schedule, id) {
    return dispatch => {
        dispatch(request(schedule, id));

        userService.createTeacherSchedule(schedule, id)
            .then(
                schedule => {
                    dispatch(success(schedule));
                    dispatch(alertActions.success('Create Teacher Schedule successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(schedule) { return { type: userConstants.CREATE_TEACHER_SCHEDULE_REQUEST, schedule } }
    function success(schedule) { return { type: userConstants.CREATE_TEACHER_SCHEDULE_SUCCESS, schedule } }
    function failure(error) { return { type: userConstants.CREATE_TEACHER_SCHEDULE_FAILURE, error } }
}

function deleteTeacherScheduleDay(week_day, id) {
    return dispatch => {
        dispatch(request(week_day, id));

        userService.deleteTeacherSchedule(week_day, id)
            .then(
                schedule => {
                    dispatch(success(schedule, week_day));
                    dispatch(alertActions.success('Delete Teacher Schedule successful'));
                },
                error => { // TODO: handle error correctly
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(week_day, id) { return { type: userConstants.DELETE_TEACHER_SCHEDULE_REQUEST, week_day, id } }
    function success(schedule, week_day) { return { type: userConstants.DELETE_TEACHER_SCHEDULE_SUCCESS, schedule, week_day } }
    function failure(error) { return { type: userConstants.DELETE_TEACHER_SCHEDULE_FAILURE, error } }
}

function createTeacherVacation(teacherId, vacation, Date, currentWeek, range) {
    return dispatch => {
        dispatch(request(teacherId, vacation));

        userService.createTeacherVacation(teacherId, vacation)
            .then(
                schedule => {
                    dispatch(success(vacation,Date, currentWeek,range));
                    dispatch(alertActions.success('Create Teacher Vacation successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(vacation) { return { type: userConstants.CREATE_TEACHER_VACATION_REQUEST, vacation } }
    function success(vacation,Date, currentWeek, range) { return { type: userConstants.CREATE_TEACHER_VACATION_SUCCESS, vacation,Date, currentWeek,range } }
    function failure(error) { return { type: userConstants.CREATE_TEACHER_VACATION_FAILURE, error } }
}

function createTeacherOvertime(teacherId, overtime, Date, currentWeek, range) {
    return dispatch => {
        dispatch(request(teacherId, overtime));

        userService.createTeacherOvertimes(teacherId, overtime)
            .then(
                schedule => {
                    dispatch(success(overtime, Date, currentWeek, range));
                    dispatch(alertActions.success('Create Teacher Overtime successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(overtime) { return { type: userConstants.CREATE_TEACHER_OVERTIME_REQUEST, overtime } }
    function success(overtime, Date, currentWeek, range) { return { type: userConstants.CREATE_TEACHER_OVERTIME_SUCCESS, overtime, Date, currentWeek, range } }
    function failure(error) { return { type: userConstants.CREATE_TEACHER_OVERTIME_FAILURE, error } }
}

function resetTeacherSchedule(day, teacher_id) {
    return dispatch => {
        dispatch(request(day, teacher_id));

        userService.resetTeacherSchedule(day, teacher_id)
            .then(
                schedule => {
                    dispatch(success());
                    dispatch(alertActions.success('Reset Teacher Schedule successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(schedule) { return { type: userConstants.RESET_TEACHER_SCHEDULE_REQUEST, schedule } }
    function success(schedule) { return { type: userConstants.RESET_TEACHER_SCHEDULE_SUCCESS, schedule } }
    function failure(error) { return { type: userConstants.RESET_TEACHER_SCHEDULE_FAILURE, error } }
}

function resetLocalTeacherSchedule() {
    return { type: userConstants.RESET_LOCAL_TEACHER_SCHEDULE };
}

function getTeacher(teacher_id) {
    return dispatch => {
        dispatch(request());

        userService.getTeacher(teacher_id)
            .then(
                teacher => dispatch(success(teacher)),
                error => dispatch(alertActions.error(error.toString()))
                // {

                    // dispatch(failure(error.toString()))
                    // dispatch(alertActions.error(error.toString()));

                // }
            );
    };

    function request() { return { type: userConstants.GET_TEACHER_REQUEST } }
    function success(teacher) { return { type: userConstants.GET_TEACHER_SUCCESS, teacher } }
    // function failure(error) { return { type: userConstants.GET_TEACHER_FAILURE, error } } //TODO: use this
}

function getTeacherVacation(teacher_id, time, dateString) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getTeacherVacations(teacher_id, time)
            .then(
                vacation =>

                    dispatch(success({date: dateString, vacations:vacation.data})),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_VACATION_REQUEST } }
    function success(vacations) { return { type: userConstants.GET_TEACHER_VACATION_SUCCESS, vacations } }
    function failure(error) { return { type: userConstants.GET_TEACHER_VACATION_FAILURE, error } }
}

function getTeacherOvertime(teacher_id, time, dateString) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getTeacherOvertimes(teacher_id, time)
            .then(
                overtimes =>

                    dispatch(success({date: dateString, overtimes:overtimes.data})),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_OVERTIME_REQUEST } }
    function success(overtimes) { return { type: userConstants.GET_TEACHER_OVERTIME_SUCCESS, overtimes } }
    function failure(error) { return { type: userConstants.GET_TEACHER_OVERTIME_FAILURE, error } }
}



function getTeacherOvertimesForAWeek(teacher_id, time) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getTeacherOvertimesForAWeek(teacher_id, time)
            .then(
                overtimes =>

                    dispatch(success(overtimes.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_REQUEST } }
    function success(overtimes) { return { type: userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_SUCCESS, overtimes } }
    function failure(error) { return { type: userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_FAILURE, error } }
}



function getStudentNotesList(student_id) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getStudentNotesList(student_id)
            .then(
                notes =>

                    dispatch(success(notes)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_STUDENT_NOTES_LIST_REQUEST } }
    function success(notes) { return { type: userConstants.GET_STUDENT_NOTES_LIST_SUCCESS, notes } }
    function failure(error) { return { type: userConstants.GET_STUDENT_NOTES_LIST_FAILURE, error } }
}



function getObjectExpenses(object_id, object_index) {
    return dispatch => {
        dispatch(request());

        userService.getObjectExpenses(object_id)
            .then(
                expenses =>

                    dispatch(success(expenses, object_index)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_OBJECT_EXPENSES_REQUEST } }
    function success(expenses, object_index) { return { type: userConstants.GET_OBJECT_EXPENSES_SUCCESS, expenses, object_index } }
    function failure(error) { return { type: userConstants.GET_OBJECT_EXPENSES_FAILURE, error } }
}


function getAccountingObjectsTeacher() {
    return dispatch => {
        dispatch(request());

        userService.getAccountingObjectsTeacher()
            .then(
                objects =>

                    dispatch(success(objects)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_ACCOUNTING_OBJECTS_TEACHER_REQUEST } }
    function success(objects) { return { type: userConstants.GET_ACCOUNTING_OBJECTS_TEACHER_SUCCESS, objects } }
    function failure(error) { return { type: userConstants.GET_ACCOUNTING_OBJECTS_TEACHER_FAILURE, error } }
}

function getAccountingObjects() {
    return dispatch => {
        dispatch(request());

        userService.getAccountingObjects()
            .then(
                objects =>

                    dispatch(success(objects)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_ACCOUNTING_OBJECTS_REQUEST } }
    function success(objects) { return { type: userConstants.GET_ACCOUNTING_OBJECTS_SUCCESS, objects } }
    function failure(error) { return { type: userConstants.GET_ACCOUNTING_OBJECTS_FAILURE, error } }
}

function getUpComingTeacherSessions() {
    return dispatch => {
        dispatch(request());

        userService.getUpComingTeacherSessions()
            .then(
                sessions =>

                    dispatch(success(sessions)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHERS_UPCOMING_SESSIONS_REQUEST } }
    function success(sessions) { return { type: userConstants.GET_TEACHERS_UPCOMING_SESSIONS_SUCCESS, sessions } }
    function failure(error) { return { type: userConstants.GET_TEACHERS_UPCOMING_SESSIONS_FAILURE, error } }
}



function getStudentNoteDetails(student_id, note_id) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getStudentNoteDetails(student_id,note_id)
            .then(
                note =>

                    dispatch(success(note)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_STUDENT_NOTE_DETAILS_REQUEST } }
    function success(note) { return { type: userConstants.GET_STUDENT_NOTE_DETAILS_SUCCESS, note } }
    function failure(error) { return { type: userConstants.GET_STUDENT_NOTE_DETAILS_FAILURE, error } }
}

function editNote(student_id, note_id, data) {
    return dispatch => {
        dispatch(request());

        userService.editNote(student_id,note_id, data)
            .then(
                note =>

                    dispatch(success(note)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.EDIT_NOTE_REQUEST } }
    function success(note) { return { type: userConstants.EDIT_NOTE_SUCCESS, note } }
    function failure(error) { return { type: userConstants.EDIT_NOTE_FAILURE, error } }
}






function editExpense(data, object_id, object_index, expense_id, expense_index) { // we need the object id for url. and we need when object is created and we want to add it to store
    return dispatch => {
        dispatch(request());

        userService.editExpense(data,object_id,expense_id)
            .then(
                expense =>

                    dispatch(success(expense, object_index,expense_index)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.EDIT_EXPENSE_REQUEST } }
    function success(expense, object_index,expense_index) { return { type: userConstants.EDIT_EXPENSE_SUCCESS, expense, object_index,expense_index } }
    function failure(error) { return { type: userConstants.EDIT_EXPENSE_FAILURE, error } }
}


function AddObjectExpenseTeacher(data, object_id) { // we need the object id for url. and we need when object is created and we want to add it to store
    return dispatch => {
        dispatch(request());

        userService.AddObjectExpenseTeacher(data,object_id)
            .then(
                expense =>

                    dispatch(success(expense)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.CREATE_OBJECT_EXPENSE_TEACHER_REQUEST } }
    function success(expense, object_id) { return { type: userConstants.CREATE_OBJECT_EXPENSE_TEACHER_SUCCESS, expense, object_id } }
    function failure(error) { return { type: userConstants.CREATE_OBJECT_EXPENSE_TEACHER_FAILURE, error } }
}





function sendCertificateEmail(id, file) {
    return dispatch => {
        dispatch(request());

        userService.sendCertificateEmail(id,file)
            .then(
                data =>

                    dispatch(success(id, file, data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.SEND_EMAIL_CERTIFICATE_REQUEST } }
    function success(id, file, data) { return { type: userConstants.SEND_EMAIL_CERTIFICATE_SUCCESS, id, file, data } }
    function failure(error) { return { type: userConstants.SEND_EMAIL_CERTIFICATE_FAILURE, error } }
}

function AddObjectExpense(data, object_id, object_index) { // we need the object id for url. and we need when object is created and we want to add it to store
    return dispatch => {
        dispatch(request());

        userService.AddObjectExpense(data,object_id)
            .then(
                expense =>

                    dispatch(success(expense)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.CREATE_OBJECT_EXPENSE_REQUEST } }
    function success(expense) { return { type: userConstants.CREATE_OBJECT_EXPENSE_SUCCESS, expense, object_index } }
    function failure(error) { return { type: userConstants.CREATE_OBJECT_EXPENSE_FAILURE, error } }
}
function AddAccountingObject(data) {
    return dispatch => {
        dispatch(request());

        userService.AddAccountingObject(data)
            .then(
                object =>

                    dispatch(success(object)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.CREATE_ACCOUNTING_OBJECT_REQUEST } }
    function success(object) { return { type: userConstants.CREATE_ACCOUNTING_OBJECT_SUCCESS, object } }
    function failure(error) { return { type: userConstants.CREATE_ACCOUNTING_OBJECT_FAILURE, error } }
}


function createNote(student_id, data) {
    return dispatch => {
        dispatch(request());

        userService.createNote(student_id, data)
            .then(
                note =>

                    dispatch(success(note,student_id)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.CREATE_NOTE_REQUEST } }
    function success(note) { return { type: userConstants.CREATE_NOTE_SUCCESS, note } }
    function failure(error) { return { type: userConstants.CREATE_NOTE_FAILURE, error } }
}





function getTeacherSessions(teacher_id, time, dateString) { // TODO: set the correct time format
    return dispatch => {
        dispatch(request());

        userService.getTeacherSessions(teacher_id, time)
            .then(
                sessions=>

                    dispatch(success({date: dateString, sessions:sessions.data})),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_SESSIONS_REQUEST } }
    function success(sessions) { return { type: userConstants.GET_TEACHER_SESSIONS_SUCCESS, sessions } }
    function failure(error) { return { type: userConstants.GET_TEACHER_SESSIONS_FAILURE, error } }
}

function getTeacherSessionsForAWeek(teacher_id, time) {
    return dispatch => {
        dispatch(request());

        userService.getTeacherSessionsForAWeek(teacher_id, time)
            .then(
                sessions=>

                    dispatch(success(sessions.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_REQUEST } }
    function success(sessions) { return { type: userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_SUCCESS, sessions } }
    function failure(error) { return { type: userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_FAILURE, error } }
}

function getTeacherInspectionSessionsForAWeek(teacher_id, time) {
    return dispatch => {
        dispatch(request());

        userService.getTeacherInspectionSessionsForAWeek(teacher_id, time)
            .then(
                sessions=>

                    dispatch(success(sessions.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_REQUEST } }
    function success(sessions) { return { type: userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_SUCCESS, sessions } }
    function failure(error) { return { type: userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_FAILURE, error } }
}



function getTeacherVacationsForAWeek(teacher_id, time) {
    return dispatch => {
        dispatch(request());

        userService.getTeacherVacationsForAWeek(teacher_id, time)
            .then(
                vacations=>

                    dispatch(success(vacations.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }

            );
    };

    function request() { return { type: userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_REQUEST } }
    function success(vacations) { return { type: userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_SUCCESS, vacations } }
    function failure(error) { return { type: userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_FAILURE, error } }
}



function processTeacherSessionsForAWeek(sessions) {
    return dispatch => {
        dispatch(request(sessions));

    };

    function request(sessions) { return { type: userConstants.PROCESS_TEACHER_SESSIONS_FOR_A_WEEK  ,sessions}}

}

function processTeacherInspectionSessionsForAWeek(sessions) {
    return dispatch => {
        dispatch(request(sessions));

    };

    function request(sessions) { return { type: userConstants.PROCESS_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK  ,sessions}}

}

function processTeacherOvertimesForAWeek(overtimes) {
    return dispatch => {
        dispatch(request(overtimes));

    };

    function request(overtimes) { return { type: userConstants.PROCESS_TEACHER_OVERTIMES_FOR_A_WEEK  ,overtimes}}

}

function processTeacherVacationsForAWeek(vacations) {
    return dispatch => {
        dispatch(request(vacations));

    };

    function request(vacations) { return { type: userConstants.PROCESS_TEACHER_VACATIONS_FOR_A_WEEK  ,vacations}}

}


function clearfetchedStudent() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_STUDENT } }

}

function clearFetchedTeacher() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER } }

}

function clearCreatedTeacher() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_CREATED_TEACHER } }

}


function clearFetchedTeacherVacations() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER_VACATIONS } }

}

function deleteScheduleAfterSuccessfulResponse(deletedScheduleIndex) {
    return dispatch => {
        dispatch(request(deletedScheduleIndex));

    };

    function request(deletedScheduleIndex) { return { type: userConstants.DELETE_SCHEDULE_AFTER_SUCCESSFUL_RESPONSE, deletedScheduleIndex } }

}

function clearCreatedTeacherOvertimeDone() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_CREATED_TEACHER_OVERTIME_DONE } }

}

function clearCreatedTeacherVacationDone() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_CREATED_TEACHER_VACATION_DONE } }

}

function clearFetchedTeacherOvertimes() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER_OVERTIMES } }

}

function clearAllFetchedData() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_ALL_FETCHED_DATA } }

}


function clearFetchedTeacherSessions() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER_SESSIONS } }

}

function clearFetchedTeacherInspectionSessions() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER_INSPECTION_SESSIONS } }

}

function clearFetchedTeacherSchedules() {
    return dispatch => {
        dispatch(request());

    };

    function request() { return { type: userConstants.CLEAR_FETCHED_TEACHER_SCHEDULES } }

}

function getStudent(student_id) {
    return dispatch => {
        dispatch(request());

        userService.getStudent(student_id)
            .then(
                student => dispatch(success(student, student_id)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_STUDENT_REQUEST } }
    function success(student, student_id) { return { type: userConstants.GET_STUDENT_SUCCESS, student , student_id} }
    function failure(error) { return { type: userConstants.GET_STUDENT_FAILURE, error } }
}

function deleteStudent(student_id) {
    return dispatch => {
        dispatch(request());

        userService.deleteStudent(student_id)
            .then(
                student => dispatch(success(student, student_id)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_STUDENT_REQUEST } }
    function success(student, student_id) { return { type: userConstants.DELETE_STUDENT_SUCCESS, student , student_id} }
    function failure(error) { return { type: userConstants.DELETE_STUDENT_FAILURE, error } }
}

function deleteTeacher(id) {
    return dispatch => {
        dispatch(request());

        userService.deleteTeacher(id)
            .then(
                student => dispatch(success(student, id)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_TEACHER_REQUEST } }
    function success(id) { return { type: userConstants.DELETE_TEACHER_SUCCESS, id} }
    function failure(error) { return { type: userConstants.DELETE_TEACHER_FAILURE, error } }
}


function getAllStudents() {
    return dispatch => {
        dispatch(request());

        userService.getAllStudents()
            .then(
                students => dispatch(success(students)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_ALL_STUDENTS_REQUEST } }
    function success(students) { return { type: userConstants.GET_ALL_STUDENTS_SUCCESS, students } }
    function failure(error) { return { type: userConstants.GET_ALL_STUDENTS_FAILURE, error } }
}

function getStudentSessions(student_id) {
    return dispatch => {
        dispatch(request());

        userService.getStudentSessions(student_id)
            .then(
                student_sessions => dispatch(success(student_sessions)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_STUDENT_SESSIONS_REQUEST } }
    function success(student_sessions) { return { type: userConstants.GET_STUDENT_SESSIONS_SUCCESS, student_sessions } }
    function failure(error) { return { type: userConstants.GET_STUDENT_SESSIONS_FAILURE, error } }
}

function getTableDataForAWeek(teacher_id, time, utcString) {
    return dispatch => {
        dispatch(request());

        userService.getTableDataForAWeek(teacher_id, time)
            .then(
                tableData => dispatch(success(tableData, utcString)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_TABLE_DATA_REQUEST, time } }
    function success(tableData, utcString) { return { type: userConstants.GET_TABLE_DATA_SUCCESS, tableData, utcString } }
    function failure(error) { return { type: userConstants.GET_TABLE_DATA_FAILURE, error } }
}

function getSettings() {
    return dispatch => {
        dispatch(request());

        userService.getSettings()
            .then(
                settings => dispatch(success(settings)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_SETTINGS_REQUEST} }
    function success(settings) { return { type: userConstants.GET_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: userConstants.GET_SETTINGS_FAILURE, error } }
}

function getStudentInspectionSessions(student_id) {
    return dispatch => {
        dispatch(request());

        userService.getStudentInspectionSessions(student_id)
            .then(
                student_sessions => dispatch(success(student_sessions)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_STUDENT_INSPECTION_SESSIONS_REQUEST } }
    function success(student_sessions) { return { type: userConstants.GET_STUDENT_INSPECTION_SESSIONS_SUCCESS, student_sessions } }
    function failure(error) { return { type: userConstants.GET_STUDENT_INSPECTION_SESSIONS_FAILURE, error } }
}

function getStudentDeposits(student_id) {
    return dispatch => {
        dispatch(request());

        userService.getStudentDeposits(student_id)
            .then(
                deposits => dispatch(success(deposits)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_STUDENT_DEPOSITS_REQUEST } }
    function success(deposits) { return { type: userConstants.GET_STUDENT_DEPOSITS_SUCCESS, deposits } }
    function failure(error) { return { type: userConstants.GET_STUDENT_DEPOSITS_FAILURE, error } }
}

function getAllDeposits(start, end) {
    return dispatch => {
        dispatch(request());

        userService.getAllDeposits(start, end)
            .then(
                deposits => dispatch(success(deposits)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_ALL_DEPOSITS_REQUEST } }
    function success(deposits) { return { type: userConstants.GET_ALL_DEPOSITS_SUCCESS, deposits } }
    function failure(error) { return { type: userConstants.GET_ALL_DEPOSITS_FAILURE, error } }
}

function getStudentRents(student_id) {
    return dispatch => {
        dispatch(request());

        userService.getStudentRents(student_id)
            .then(
                deposits => dispatch(success(deposits)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_STUDENT_RENTS_REQUEST } }
    function success(rents) { return { type: userConstants.GET_STUDENT_RENTS_SUCCESS, rents } }
    function failure(error) { return { type: userConstants.GET_STUDENT_RENTS_FAILURE, error } }
}


function DeleteStudentDeposits(student_id, deposit_id) {
    return dispatch => {
        dispatch(request());

        userService.deleteStudentDeposits(student_id, deposit_id)
            .then(
                deposits => dispatch(success(deposits)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DELETE_STUDENT_DEPOSITS_REQUEST } }
    function success(deposits) { return { type: userConstants.DELETE_STUDENT_DEPOSITS_SUCCESS, deposits } }
    function failure(error) { return { type: userConstants.DELETE_STUDENT_DEPOSITS_FAILURE, error } }
}



// function getDaySchedule(dateIso) {
//     return dispatch => {
//         dispatch(request());
//
//         userService.getDaySchedule(dateIso)
//             .then(
//                 Day_schedules => dispatch(success(Day_schedules)),
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };
//
//     function request() { return { type: userConstants.GET_DAY_SCHEDULE_REQUEST } }
//     function success(day_schedules) { return { type: userConstants.GET_DAY_SCHEDULE_SUCCESS, day_schedules } }
//     function failure(error) { return { type: userConstants.GET_DAY_SCHEDULE_FAILURE, error } }
// }

function getTeachersList() {
    return dispatch => {
        dispatch(request());

        userService.getTeachersList()
            .then(
                teachers_list => dispatch(success(teachers_list)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_TEACHERS_LIST_REQUEST } }
    function success(teachers_list) { return { type: userConstants.GET_TEACHERS_LIST_SUCCESS, teachers_list } }
    function failure(error) { return { type: userConstants.GET_TEACHERS_LIST_FAILURE, error } }
}
//
// function getDaySessions(dayIso) {
//     return dispatch => {
//         dispatch(request());
//
//         userService.getDaySessions(dayIso)
//             .then(
//                 day_sessions => dispatch(success(day_sessions)),
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };
//
//     function request() { return { type: userConstants.GET_DAY_SESSIONS_REQUEST } }
//     function success(day_sessions) { return { type: userConstants.GET_DAY_SESSIONS_SUCCESS, day_sessions } }
//     function failure(error) { return { type: userConstants.GET_DAY_SESSIONS_FAILURE, error } }
// }



function getTeacherSchedule(teacher_id) {
    return dispatch => {
        dispatch(request());

        userService.getTeacherSchedule(teacher_id)
            .then(
                schedule => dispatch(success(schedule)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GET_TEACHER_SCHEDULE_REQUEST } }
    function success(schedule) { return { type: userConstants.GET_TEACHER_SCHEDULE_SUCCESS, schedule} }
    function failure(error) { return { type: userConstants.GET_TEACHER_SCHEDULE_FAILURE, error } }
}


function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
