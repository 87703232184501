import {userConstants} from '../Constants';
// import update from 'react-addons-update';
import produce from 'immer';
import {act} from "@testing-library/react";

const initialState = {
    currentWeek: 0,
    weekTableData: [],
    fetched_weekTableData:{},
    unProcessed_weeks: [],
    editing_schedule: {
        status: 'idle',
        action: '',
    },
    fetching_weekTableData : false,
    fetched_weekTableData_done: false,
    creating_student_session: false,
    student_session_creation_done: false,
    creating_student_inspection_session:false,
    student_inspection_session_creation_done: false,

}

export function weekTable(state = initialState, action) {
    switch (action.type) {

        case userConstants.INITIALIZE_WEEK_TABLE_DATA:
            return {
                ...state,
                weekTableData: action.data,
                currentWeek: action.dateString,
                currentTeacher: action.teacher,
            }

        case userConstants.GET_TABLE_DATA_REQUEST:
            return {
                ...state,
                fetching_weekTableData: true,
                fetched_weekTableData_done: false,
            }

        case userConstants.GET_TABLE_DATA_SUCCESS:
            return produce(state, draft => {
                draft.fetched_weekTableData[action.utcString] = action.tableData.data[0];
                draft.fetching_weekTableData = false;
                draft.fetched_weekTableData_done = true;
            });

        case userConstants.ADD_TO_UNPROCESSED_WEEKS:
            return produce(state, draft => {
                draft.unProcessed_weeks.push(action.week)
            });

        case userConstants.REMOVE_FROM_UNPROCESSED_WEEKS:
            return produce(state, draft => {
            draft.unProcessed_weeks = draft.unProcessed_weeks.filter(item => item !== action.week)
        });

        case userConstants.SET_TABLE_SCHEDULE_TIME:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].time = action.time;

            });

        case userConstants.SET_SESSION_IN_WEEK_TABLE:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].sessions = action.session;

            });

        case userConstants.SET_INSPECTION_IN_WEEK_TABLE:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].inspection_sessions = action.inspection;

            });

        case userConstants.SET_VACATION_IN_WEEK_TABLE:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].vacations = action.vacation;

            });


        case userConstants.SET_OVERTIME_IN_WEEK_TABLE:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].overtimes = action.overtime;

            });

        case userConstants.SET_CURRENT_WEEK:
            return {
                ...state,
                currentWeek: action.week
            }

        case userConstants.ADD_WEEK:
            return produce(state, draft => {

                draft.weekTableData[action.weekDate] = action.week;
                // draft.fetched_weekTableData = false;

            });

        case userConstants.SET_SELECTED_SESSION_IN_DATA:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].selectedSessions = action.time
            })

        case userConstants.CLEAR_SELECTED_SESSION_IN_DATA:
            return produce(state, draft => {
                draft.weekTableData[action.week][action.index].selectedSessions = []
            })

        case userConstants.CREATE_STUDENT_SESSION_REQUEST:
            return {
                ...state,
                creating_student_session: true,
                student_session_creation_done: false,
            }

        case userConstants.CREATE_STUDENT_SESSION_SUCCESS:
            return {
                ...state,
                // created_student_session: action.session.data,
                creating_student_session: false,
                student_session_creation_done: true,
                // created_session_id: action.session.data.id
            };
        case userConstants.CREATE_STUDENT_SESSION_FAILURE:
            return {...state};

        case userConstants.CREATE_STUDENT_INSPECTION_SESSION_REQUEST:
            return {
                ...state,
                creating_student_inspection_session: true,
                student_inspection_session_creation_done: false,
            }

        case userConstants.CREATE_STUDENT_INSPECTION_SESSION_SUCCESS:
            return {
                ...state,
                // created_student_session: action.session.data,
                creating_student_inspection_session: false,
                student_inspection_session_creation_done: true,
                // created_session_id: action.session.data.id
            };
        case userConstants.CREATE_STUDENT_INSPECTION_SESSION_FAILURE:
            return {...state};

        case userConstants.CLEAR_WEEK_TABLE_DATA:
            return initialState
            // return {
            //     currentWeek: 0,
            //     weekTableData: [],
            //     fetched_weekTableData:{},
            //     unProcessed_weeks: [],
            //     fetching_weekTableData : false,
            //     fetched_weekTableData_done: false,
            //     creating_student_session: false,
            //     student_session_creation_done: false,
            //     creating_student_inspection_session:false,
            //     student_inspection_session_creation_done: false,
            // }



        case userConstants.CREATE_TEACHER_VACATION_REQUEST:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'loading',
                    action: 'add_off'
                }
            }
        case userConstants.CREATE_TEACHER_VACATION_SUCCESS:
            return produce(state, draft => {
                draft.editing_schedule.status = 'succeeded';
                draft.editing_schedule.action = 'add_off';
                const index = draft.weekTableData[action.currentWeek].findIndex(item => item.Date === action.Date)
                action.range.map(hour=>draft.weekTableData[action.currentWeek][index].vacations.push(hour))
            })

        case userConstants.CREATE_TEACHER_VACATION_FAILURE:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'failed',
                    action: 'add_off'
                }
            }

        case userConstants.CREATE_TEACHER_OVERTIME_REQUEST:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'loading',
                    action: 'add_overtime'
                }
            }
        case userConstants.CREATE_TEACHER_OVERTIME_SUCCESS:
            return produce(state, draft => {
                draft.editing_schedule.status = 'succeeded';
                draft.editing_schedule.action = 'add_overtime';
                const index = draft.weekTableData[action.currentWeek].findIndex(item => item.Date === action.Date)
                action.range.map(hour=>draft.weekTableData[action.currentWeek][index].overtimes.push(hour))
            })

        case userConstants.CREATE_TEACHER_OVERTIME_FAILURE:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'failed',
                    action: 'add_overtime'
                }
            }

        case userConstants.DELETE_VACATION_REQUEST:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'loading',
                    action: 'delete_vacation'
                }
            }

        case userConstants.DELETE_VACATION_SUCCESS:
            return produce(state, draft => {
                draft.editing_schedule.status = 'succeeded';
                draft.editing_schedule.action = 'delete_vacation';
                const index = draft.weekTableData[action.currentWeek].findIndex(item => item.Date === action.Date)
                draft.weekTableData[action.currentWeek][index].vacations = []
            })

        case userConstants.DELETE_VACATION_FAILURE:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'failed',
                    action: 'delete_vacation'
                }
            }

        case userConstants.DELETE_OVERTIME_REQUEST:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'loading',
                    action: 'delete_overtime'
                }
            }

        case userConstants.DELETE_OVERTIME_SUCCESS:
            return produce(state, draft => {
                draft.editing_schedule.status = 'succeeded';
                draft.editing_schedule.action = 'delete_overtime';
                const index = draft.weekTableData[action.currentWeek].findIndex(item => item.Date === action.Date)
                draft.weekTableData[action.currentWeek][index].overtimes = []
            })

        case userConstants.DELETE_OVERTIME_FAILURE:
            return {
                ...state,
                editing_schedule: {
                    ...state.editing_schedule,
                    status: 'failed',
                    action: 'delete_overtime'
                }
            }




        default:
            return state
    }
}
