import * as React from "react";
import AddDepositComponent from "../Components/AddDepositComponent";
import Button from "@material-ui/core/Button";
import {
    Box,
    Card,
    CardContent,
    CardHeader, Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Hidden,  makeStyles, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip,
    Typography,
} from "@material-ui/core";
import {userActions} from "../../../Actions";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import ShowDepositListComponent from "../Components/ShowDepositsListComponent";
import AddIcon from "@material-ui/icons/Add";
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import ShowDeletedDepositListComponent from "../Components/ShowDeletedDepositsListComponent";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import DepositCardComponent from "../../../views/account/Student/Components/DepositCardComponent";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import {useParams} from "react-router-dom";



const useStyles = makeStyles((theme) => ({

    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(2),
    },

}));



export default function FinanceContainer() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const classes = useStyles();
    const [openAddDepositDialog, setOpenAddDepositDialog] = React.useState(false)
    const created_student_deposit_done = useSelector(state => state.students.created_student_deposit_done)
    const creating_student_deposit = useSelector(state => state.students.creating_student_deposit)
    const fetched_student_deposits = useSelector(state => state.students.fetched_student_deposits)
    const student = useSelector(state => state.students.fetched_student)
    const [openDeleteDepositDialog, setOpenDeleteDepositDialog] = React.useState(false)
    // const [selectedDeleteDepositId, setSelectedDeleteDepositId] = React.useState('')
    const [selectedDeleteDeposit, setSelectedDeleteDeposit] = React.useState(null)
    const deleting_student_deposit = useSelector(state => state.students.deleting_student_deposit)
    const deleted_student_deposit_done = useSelector(state => state.students.deleted_student_deposit_done)
    const [openShowDeletedDepositsDialog, setOpenShowDeletedDepositsDialog] = React.useState(false)
    const [showDeletedDeposits, setShowDeletedDeposits] = React.useState(true)
    const [creatingDeposit, setCreatingDeposit] = React.useState(false)

    {
        /*

    the GET request for deposits is made in StudentProfile alongside the get request for sessions

        */
    }

    const truncateDecimals = function (number, digits) {
        let multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

        return truncatedNum / multiplier;
    };


    // const BorderLinearProgress = withStyles((theme) => ({
    //     root: {
    //         height: 10,
    //         borderRadius: 5,
    //     },
    //     colorPrimary: {
    //         backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    //     },
    //     bar: {
    //         borderRadius: 5,
    //         backgroundColor: '#1a90ff',
    //     },
    // }))(LinearProgress);


    const [createDeposit, setCreateDeposit] = React.useState({
        deposit: '',
        type: '',
        // detail: ' ',
    })

    function handleCreateDeposit() {
        setOpenAddDepositDialog(true)
    }

    function handleCloseAddDepositDialog() {
        setOpenAddDepositDialog(false)
        setCreateDeposit({
            deposit: '',
            type: '',
        })

    }

    function handleCreateDepositChange(e) {
        const {name, value} = e.target;
        setCreateDeposit(createDeposit => ({...createDeposit, [name]: value}));
    }

    function handleCreateDepositSubmit() {
        if (createDeposit.deposit && createDeposit.type && !creatingDeposit) {
            setCreatingDeposit(true)
            dispatch(userActions.createStudentDeposit(id, createDeposit))

        }
    }

    function handleDeleteDepositOpenDialog(deposit_id){
        // setSelectedDeleteDepositId(deposit_id)
        setSelectedDeleteDeposit(fetched_student_deposits.find(item=> item.id === deposit_id))
        setOpenDeleteDepositDialog(true)
    }

    function handleDeleteDepositConfirm(){
        // send request
        dispatch(userActions.DeleteStudentDeposits(id, selectedDeleteDeposit.id))
        setOpenDeleteDepositDialog(false)
    }


    function handleDeleteDepositCloseDialog(){
        // send request
        setSelectedDeleteDeposit(null)
        // setSelectedDeleteDepositId('')
        setOpenDeleteDepositDialog(false)

        // refresh data
    }


    function handleShowDeletedDeposits(){
        setOpenShowDeletedDepositsDialog(true)
    }

    function handleCloseDeletedDeposits (){
        setOpenShowDeletedDepositsDialog(false)
    }

    function handleChangeSwitch(){
        setShowDeletedDeposits(!showDeletedDeposits)
    }

    useEffect(() => { // reload the deposits after a deposit has been successfully created.
        if (created_student_deposit_done && !creating_student_deposit && createDeposit.deposit.length > 0 && createDeposit.type.length > 0) {
            {/*

                Checking if anything is in createDeposit . if there is, we'll reload because that means a new deposit
                has been created. if there isn't anything in createDeposit, that means there wasn't any request
                for creating a deposit to begin with and so we don't need to refresh

            */
            }
            dispatch(userActions.resetDepositCreation())
            setCreatingDeposit(false)
            dispatch(userActions.getStudentDeposits(id))
            dispatch(userActions.getStudent(id))
            setCreateDeposit({
                deposit: '',
                type: '',
            })
            setOpenAddDepositDialog(false)

        }
    }, [created_student_deposit_done, creating_student_deposit])



    useEffect(()=>{ // runs in order to refresh payments if a payment is deleted

        if(deleted_student_deposit_done && !deleting_student_deposit && selectedDeleteDeposit){
            dispatch(userActions.getStudentDeposits(id))
            dispatch(userActions.getStudent(id))
            setSelectedDeleteDeposit(null)
        }

    },[deleting_student_deposit,deleted_student_deposit_done])

    function getDate(s) {
        let b = s.split(/\D+/);
        let toObject = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        return toObject.toDateString()
    }
    return (
        <div>
                <Card>
                    <CardContent>
                        <div style={{
                            display: 'flex',
                            flexDirection:'row',
                            flexWrap: 'wrap',
                            alignItems: 'center'

                        }}>
                            <div style={{flexGrow: 1}}>
                                <CardHeader title="Student Balance :"/>
                                <CardContent>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>

                                        <div style={{flexGrow: 3, display: 'flex', flexDirection:'row', alignItems: 'center'}}>
                                            {/*<Typography*/}
                                            {/*    // className={classes.dateText}*/}
                                            {/*    color="textPrimary"*/}
                                            {/*    variant="h6"*/}
                                            {/*>*/}
                                            {/*    Student Balance :*/}
                                            {/*</Typography>*/}
                                            <div>
                                                <Typography variant="h4" color="textSecondary" style={{paddingRight:"5px"}}>
                                                    C$
                                                </Typography>
                                            </div>

                                            <Box minWidth={35}>

                                                <Typography variant="h4" color="textPrimary">
                                                    {
                                                        truncateDecimals((parseFloat(student.all_payed)) - (student.agreement_tax), 2)
                                                    }
                                                </Typography>
                                            </Box>

                                        </div>


                                        {/*<Typography variant="h6" color="textPrimary">*/}
                                        {/*    {'Pre-Tax:  '}*/}
                                        {/*</Typography>*/}
                                        {/*/!*<br />*!/*/}
                                        {/*{student.all_payed ?<Typography variant="h6" color="textSecondary">*/}
                                        {/*    C$*/}
                                        {/*</Typography>:null}*/}
                                        {/*/!*<br/>*!/*/}
                                        {/*<Typography variant="h6" color="textPrimary">*/}
                                        {/*    {student.all_payed ? student.all_payed : 'not set'}*/}
                                        {/*</Typography>*/}

                                    </div>
                                </CardContent>
                            </div>




                            <div style={{flexGrow: 1}}>
                                <CardHeader title="Total course fee"/>
                                <CardContent>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="h6" color="textPrimary" style={{paddingRight:"5px"}}>
                                            Pre-Tax:
                                        </Typography>
                                        {student.agreement ?<Typography variant="h6" color="textSecondary" style={{paddingRight:"5px"}}>
                                            C$
                                        </Typography>:null}
                                        <Typography variant="h6" color="textPrimary">
                                            {student.agreement ? student.agreement : 'not set'}
                                        </Typography>

                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="h6" color="textPrimary">
                                            After-Tax:
                                        </Typography>
                                        {student.agreement_tax ?<Typography variant="h6" color="textSecondary">
                                            C$
                                        </Typography>:null}
                                        <Typography variant="h6" color="textPrimary">
                                            {student.agreement_tax ? student.agreement_tax : 'not set'}
                                        </Typography>
                                    </div>

                                </CardContent>
                            </div>
                        </div>


                        <br/>
                        <Hidden smDown>
                            <div style={{display: 'flex', justifyContent:'space-between'}}>

                                <CardHeader title="Payment Record History"/>

                                <Button name="Deleted Deposits"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleShowDeletedDeposits}
                                >
                                    <HistoryRoundedIcon/>
                                </Button>
                            </div>
                        </Hidden>

                        <Hidden mdUp>
                            <FormControlLabel
                                // className={classes.active}
                                control={<Checkbox checked={!showDeletedDeposits} onChange={handleChangeSwitch} name="showActive"/>}
                                label="Show Only Deleted Deposits"
                            />
                        </Hidden>







                        <Hidden smDown>

                        <ShowDepositListComponent deposits={fetched_student_deposits}
                                                  openDeleteDepositDialog={openDeleteDepositDialog}
                                                  handleDeleteDepositOpenDialog={handleDeleteDepositOpenDialog}
                                                  selectedDeleteDeposit={selectedDeleteDeposit}
                                                  handleDeleteDepositCloseDialog={handleDeleteDepositCloseDialog}
                                                  handleDeleteDepositConfirm={handleDeleteDepositConfirm}
                        />
                        </Hidden>









                    </CardContent>

                    <Hidden smDown>

                    <Box display="flex"
                         justifyContent="flex-end"
                         p={2}>
                        <Button onClick={handleCreateDeposit}
                                startIcon={<AddIcon/>}
                                variant="contained"
                                color={"primary"}
                                style={{justifySelf: 'flex-end'}}>
                            Add Deposit
                        </Button>
                    </Box>

                    </Hidden>
                </Card>


            <Hidden mdUp>
                <br/>

                {fetched_student_deposits.map(deposit => {
                        if(deposit.shown === showDeletedDeposits ){
                            return <DepositCardComponent deposit={deposit}
                                                  openDeleteDepositDialog={openDeleteDepositDialog}
                                                  handleDeleteDepositOpenDialog={handleDeleteDepositOpenDialog}
                                                  selectedDeleteDeposit={selectedDeleteDeposit}
                                                  handleDeleteDepositCloseDialog={handleDeleteDepositCloseDialog}
                                                  handleDeleteDepositConfirm={handleDeleteDepositConfirm}
                                                  key={deposit.id} />
                }

                }


                )}
            </Hidden>

            <Hidden mdUp>

            <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={handleCreateDeposit}

                //   style={{
                //     margin: 0,
                //     top: 'auto',
                //     right: 20,
                //     bottom: 70,
                //     left: 'auto',
                //     position: 'fixed',
                // }}
            >
                <AddIcon/>
            </Fab>

            </Hidden>
            <Grid item xs={12}>
                <Dialog open={openDeleteDepositDialog} onClose={handleDeleteDepositCloseDialog}>
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this payment?"}</DialogTitle>

                    <DialogContent>

                        {selectedDeleteDeposit ?
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>

                                                <TableCell sortDirection="desc">
                                                    <Tooltip
                                                        enterDelay={300}
                                                        title="Sort"
                                                    >
                                                        <TableSortLabel
                                                            active
                                                            direction="desc"
                                                        >
                                                            Date
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    Amount(Pre-Tax)
                                                </TableCell>
                                                {/*<TableCell>*/}
                                                {/*    Amount(After-Tax)*/}
                                                {/*</TableCell>*/}

                                                <TableCell>
                                                    Payment Type
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                hover
                                                key={1}
                                            >

                                                <TableCell>
                                                    {selectedDeleteDeposit ? getDate(selectedDeleteDeposit.create_date) : null}
                                                </TableCell>


                                                <TableCell>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Typography variant="body1" color="textSecondary" component="p">
                                                            C$
                                                        </Typography>
                                                        <Typography variant="body1" color="textPrimary">
                                                            {selectedDeleteDeposit ? selectedDeleteDeposit.deposit : null}

                                                        </Typography>
                                                    </div>
                                                </TableCell>


                                                {/*<TableCell>*/}
                                                {/*    <div style={{*/}
                                                {/*        display: 'flex',*/}
                                                {/*        flexDirection: 'row',*/}
                                                {/*        alignItems: 'center'*/}
                                                {/*    }}>*/}
                                                {/*        <Typography variant="body1" color="textSecondary" component="p">*/}
                                                {/*            C$*/}
                                                {/*        </Typography>*/}
                                                {/*        <Typography variant="body1" color="textPrimary">*/}
                                                {/*            {selectedDeleteDeposit ? selectedDeleteDeposit.deposit_tax : null}*/}
                                                {/*        </Typography>*/}
                                                {/*    </div>*/}
                                                {/*</TableCell>*/}


                                                <TableCell>
                                                    {selectedDeleteDeposit ? selectedDeleteDeposit.type : null}
                                                </TableCell>


                                            </TableRow>


                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Box>

                            : null}

                    </DialogContent>

                    <DialogActions>
                        <Button startIcon={<DeleteForeverRoundedIcon/>}
                                variant="contained"

                                color="secondary"
                                onClick={handleDeleteDepositConfirm}
                        >

                            Yes
                        </Button>

                        <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                                variant="contained"

                                color="primary"
                                onClick={handleDeleteDepositCloseDialog}
                        >

                            No
                        </Button>
                    </DialogActions>


                </Dialog>
            </Grid>


            <Dialog open={openShowDeletedDepositsDialog} onClose={handleCloseDeletedDeposits}
                    fullWidth
                    maxWidth={"md"}
            >
                <DialogContent>
                    <ShowDeletedDepositListComponent deposits={fetched_student_deposits}
                                                     handleDeleteDepositOpenDialog={handleDeleteDepositOpenDialog}
                    />
                </DialogContent>

                <DialogActions >
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleCloseDeletedDeposits}
                    >

                        Back
                    </Button>
                </DialogActions>

            </Dialog>


            <Dialog open={openAddDepositDialog} onClose={handleCloseAddDepositDialog} fullWidth
                    maxWidth={"sm"}
            >


                <AddDepositComponent
                    handleCreateDepositChange={handleCreateDepositChange}
                    handleCloseAddDepositDialog={handleCloseAddDepositDialog}
                    createDeposit={createDeposit}
                    creatingDeposit={creatingDeposit}
                    handleCreateDepositSubmit={handleCreateDepositSubmit}

                />


                {/*<DialogActions>*/}
                {/*    <Button startIcon={<CheckSharpIcon/>} variant="contained"*/}
                {/*            onClick={handleCreateDepositSubmit} color="primary" type={"submit"}*/}
                {/*    >*/}
                {/*        {creatingDeposit ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Submit'}*/}
                {/*    </Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </div>
    )


}
