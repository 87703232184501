import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import {teachers} from './teachers.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {students} from "./students.reducer";
import {weekTable} from "./weekTable.reducer";
import {accounting} from "./accounting.reducers";
import {teachersView} from "./teachersView.reducers";

const rootReducer = combineReducers({
  authentication,
  teachers,
  students,
  weekTable,
  teachersView,
  users,
  accounting,
  alert
});

export default rootReducer;
