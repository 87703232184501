import * as React from "react";
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid,
    makeStyles, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from "@material-ui/core";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '30px',
    },
    table: {
        borderRight: '1px solid'
    },
    free: {
        backgroundColor: '#58D68D',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
        "&:hover": {
            backgroundColor: '#48add6',
        },

    },
    ellipsis: {
        textAlign: 'center',
        fontSize: 'small',
        // maxWidth: 10, // percentage also works
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    vacation: {
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,

    },
    overtime: {
        backgroundColor: '#F0B27A',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    noSchedule:
        {
            borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
    noScheduleDate:
        {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            position: 'sticky',
            background: '#FFF',
            left: 1,
            zIndex: 1,
        },

    vacationButton: {
        backgroundColor: '#616A6B',
        // color : '#53a9cb'
    },
    overtimeButton: {
        backgroundColor: '#F0B27A'
    },
    freeButton: {
        backgroundColor: '#58D68D',

    },
    addVacationButton: {
        color: '#384040',
    },
    addOvertimeButton: {
        color: '#F0B27A',
    },

    selectedSession: {
        backgroundColor: '#3494c1',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    tableRoot: {
        height: '100%',
        overflowX: 'auto',
        marginRight: 'auto',
        // marginLeft: 'auto',
        // marginTop: '50px',
        // padding: '10px',
        // margin: '10px',


    },
    tableLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    dateCell: {
        // position: '-webkit-sticky',
        position: 'sticky',
        background: '#FFF',
        left: 0,
        zIndex: 1,
    },
    button: {
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    RightButton: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    }


}));

const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]


export default function EditScheduleDialog({
                                               handleEditScheduleDialogClose,
                                               open,
                                               item,
                                               sessions,
                                               inspection_sessions,
                                               handleClickHour,
                                               handleChange,
                                               editScheduleData,
                                               tempOff, tempOvertime,
                                               handleSubmitChange,
                                                loading,
                                           }) {
    const classes = useStyles()
    // let {teacherId, type} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    function returnDisabled(data){ // returns disabled value of submit button given the parameters
        if(data.action){
            if(data.action === 'add'){
                return !(editScheduleData.addType && editScheduleData.start &&
                    editScheduleData.end && editScheduleData.teacherId)
            }

            if(data.action === 'delete'){
                return !(data.deleteType && data.teacherId)
            }

        }else{
            return true
        }

    }

    function returnStudentName(sessions, date, time, isInspection) {
        if (isInspection) { // Temproary
            return '*Inspection*'
        } else {
            const newDate = new Date(date).toUTCString()
            const timeTest = newDate.split(' ')
            const newNewDate = timeTest[0].split(',')[0] + ' ' + timeTest[2] + ' ' + timeTest[1] + ' ' + timeTest[3]
            let sessionIndex
            sessionIndex = sessions.findIndex(
                session => new Date(session.start).toDateString() === newNewDate
                    && time >= new Date(session.start).getUTCHours() && time < new Date(session.end).getUTCHours()
            )
            return sessionIndex >= 0 ?
                sessions[sessionIndex].student.first_name + ' ' + sessions[sessionIndex].student.last_name
                : null
        }
    }

    function setCellColor( item, hour,tempOff, tempOvertime,) {
        return item.selectedSessions.includes(hour) ? classes.selectedSession :
            item.vacations.includes(hour) ? classes.vacation :
                item.overtimes.includes(hour) ? classes.overtime :
                    item.overtimes.includes(hour) ? classes.overtime :
                        tempOff.includes(hour) ? classes.vacation :
                            tempOvertime.includes(hour) ? classes.overtime :
                                item.time.includes(hour) && !item.vacations.includes(hour) ? classes.free :
                                    classes.noSchedule
    }


    return (
        <Dialog open={open} onClose={handleEditScheduleDialogClose} maxWidth={"lg"} fullWidth>
            <DialogTitle>
                {!editScheduleData.addType ? 'Select edit type' : `Add ${editScheduleData.addType}`}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <RadioGroup aria-label="Action" name="action"
                                    value={editScheduleData.action} onChange={handleChange}
                                    style={{display: 'flex', flexDirection: 'row'}}>
                            <FormControlLabel value="add" control={<Radio/>} label="Add Overtime/Off Time"/>
                            <FormControlLabel value="delete" control={<Radio/>} label="Delete Overtime/Off Time"/>
                        </RadioGroup>
                    </Grid>
                    {editScheduleData.action === 'add' ?
                        <Grid item xs={12} md={12} lg={12}>
                            <RadioGroup aria-label="Add Type" name="addType"
                                        value={editScheduleData.addType} onChange={handleChange}
                                        style={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel value="off" control={<Radio/>} label="Time Off"/>
                                <FormControlLabel value="overtime" control={<Radio/>} label="Overtime"/>
                            </RadioGroup>
                        </Grid>
                        : null}

                    {editScheduleData.action === 'delete' ?
                        <Grid item xs={12} md={12} lg={12}>
                            <RadioGroup aria-label="Delete Type" name="deleteType"
                                        value={editScheduleData.deleteType} onChange={handleChange}
                                        style={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel value="off" control={<Radio/>} label="Delete off time"/>
                                <FormControlLabel value="overtime" control={<Radio/>} label="Delete Overtime"/>
                            </RadioGroup>
                        </Grid>
                        : null}

                    {editScheduleData.action === 'add' && editScheduleData.addType ?
                        <Grid item xs={4} md={4} lg={4}>
                            {!editScheduleData.start && !editScheduleData.end ? <Alert severity="info">
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Typography variant={"body2"} style={{marginRight: '4px'}}>Select</Typography>
                                        <Typography variant={"h6"} style={{marginRight: '4px'}}>START</Typography>
                                        <Typography variant={"body2"}>time</Typography>
                                    </div>
                                </Alert> :
                                editScheduleData.start && !editScheduleData.end ?
                                    <Alert severity="info">
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <Typography variant={"body2"}
                                                        style={{marginRight: '4px'}}>Select</Typography>
                                            <Typography variant={"h6"} style={{marginRight: '4px'}}>END</Typography>
                                            <Typography variant={"body2"} style={{marginRight: '4px'}}>time</Typography>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <Typography style={{marginRight: '4px'}}>
                                                Start Time:
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                {` ${moment.utc(editScheduleData.start).format('HH')}`}
                                            </Typography>
                                        </div>
                                    </Alert> :
                                    <Alert severity="success">
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <Typography style={{marginRight: '4px'}}>
                                                Selected Time:
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                {` ${moment.utc(editScheduleData.start).format('HH')} - ${moment.utc(editScheduleData.end).format('HH')}`}
                                            </Typography>
                                        </div>

                                    </Alert>}
                        </Grid> : null
                    }
                    <Grid item>
                        {
                            editScheduleData.action === 'add' && editScheduleData.addType ?
                                <TableContainer className={classes.tableRoot}>

                                    <Table style={{whiteSpace: 'normal'}}>
                                        <TableHead>
                                            <TableRow>
                                                {/*<Hidden xsDown>*/}
                                                {/*    <TableCell className={classes.tableCell}>Day</TableCell>*/}
                                                {/*</Hidden>*/}
                                                <TableCell className={classes.noScheduleDate}>Date</TableCell>
                                                <TableCell className={classes.noSchedule}>07-08</TableCell>
                                                <TableCell className={classes.noSchedule}>08-09</TableCell>
                                                <TableCell className={classes.noSchedule}>09-10</TableCell>
                                                <TableCell className={classes.noSchedule}>10-11</TableCell>
                                                <TableCell className={classes.noSchedule}>11-12</TableCell>
                                                <TableCell className={classes.noSchedule}>12-13</TableCell>
                                                <TableCell className={classes.noSchedule}>13-14</TableCell>
                                                <TableCell className={classes.noSchedule}>14-15</TableCell>
                                                <TableCell className={classes.noSchedule}>15-16</TableCell>
                                                <TableCell className={classes.noSchedule}>16-17</TableCell>
                                                <TableCell className={classes.noSchedule}>17-18</TableCell>
                                                <TableCell className={classes.noSchedule}>18-19</TableCell>
                                                <TableCell className={classes.noSchedule}>19-20</TableCell>
                                                <TableCell className={classes.noSchedule}>20-21</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow
                                                hover
                                                key={1}
                                            >


                                                {/*<Hidden xsDown>*/}
                                                {/*    <TableCell key={index}*/}
                                                {/*               className={classes.tableCell}> {item.Day} </TableCell>*/}

                                                {/*</Hidden>*/}
                                                <TableCell className={classes.dateCell} key={0}
                                                           style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                                                >
                                                    {item.simpleDate}
                                                </TableCell>

                                                {hours.map((hour, index) =>
                                                    <TableCell key={index}
                                                        // onMouseEnter={item.sessions.includes(hour) ? handleSessionCellHover : null}
                                                               onClick={() => handleClickHour(item.Date, hour)}
                                                               className={setCellColor(item, hour, tempOff, tempOvertime)}
                                                    >

                                                        <Typography className={classes.ellipsis}>
                                                            {item.inspection_sessions.includes(hour) &&
                                                            returnStudentName(
                                                                inspection_sessions,
                                                                item.Date, hour,
                                                                true
                                                            )}
                                                            {item.sessions.includes(hour) ?
                                                                returnStudentName(sessions,
                                                                    item.Date,
                                                                    hour
                                                                ) : null}
                                                        </Typography>
                                                    </TableCell>
                                                )}


                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : null
                        }
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary"
                        // variant="contained"
                    variant={!loading? "contained": "outlined"}
                    onClick={!loading ? handleSubmitChange : null}
                    startIcon={!loading? <DoneOutlineRoundedIcon/>: null}
                        disabled={returnDisabled(editScheduleData)}
                >
                    {!loading? 'Submit' + ' ' + editScheduleData.addType : <CircularProgress size={24} className={classes.buttonProgress}/>}
                </Button>
            </DialogActions>


        </Dialog>
    )
}
