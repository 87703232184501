import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '30px',
    },
    table: {
        borderRight: '1px solid'
    },
    free: {
        backgroundColor: '#58D68D',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    vacation: {
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)'

    },
    overtime: {
        backgroundColor: '#F0B27A',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    noSchedule: {
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    vacationButton: {
        backgroundColor: '#616A6B',
    },
    overtimeButton: {
        backgroundColor: '#F0B27A'
    },
    freeButton: {
        backgroundColor: '#58D68D',

    },
    addScheduleButton: {
        backgroundColor: '#58D68D',
    },
    addOvertimeButton: {
        color: '#F0B27A',
    }

}));


export default function AddTeacherWeeklySchedule({
                                                     rows, handleGoNextWeek, handleGoPreviousWeek, onAddScheduleSubmit,
                                                     allFetchingDone, onDeleteSchedule,onAddScheduleCellClick
                                                 }) {
    const classes = useStyles();

    useEffect(() => {

    }, [rows]);


    return (
        <div
            className={classes.root}>

            <PerfectScrollbar>

                <Box minWidth={800}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            {/*<Button*/}
                            {/*    disabled={!allFetchingDone}*/}
                            {/*    onClick={handleGoPreviousWeek}*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    startIcon={<NavigateBeforeIcon/>}*/}
                            {/*>*/}
                            {/*    Previous Week*/}
                            {/*</Button>*/}
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                className={classes.freeButton}
                                startIcon={<AlarmOnIcon/>}
                            >
                                Free
                            </Button>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    className={classes.vacationButton}*/}
                            {/*    startIcon={<TimerOffIcon/>}*/}
                            {/*>*/}
                            {/*    Vacation*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    className={classes.overtimeButton}*/}
                            {/*    startIcon={<AlarmAddIcon/>}*/}
                            {/*>*/}
                            {/*    Overtime*/}
                            {/*</Button>*/}
                        </div>
                        <div>
                            {/*<Button*/}
                            {/*    disabled={!allFetchingDone}*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    endIcon={<NavigateNextIcon/>}*/}
                            {/*    onClick={handleGoNextWeek}*/}
                            {/*>*/}
                            {/*    Next Week*/}
                            {/*</Button>*/}
                        </div>

                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>Day</TableCell>
                                {/*<TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>Date</TableCell>*/}
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>07-08</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>08-09</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>09-10</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>10-11</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>11-12</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>12-13</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>13-14</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>14-15</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>15-16</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>16-17</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>17-18</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>18-19</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>19-20</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>20-21</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows ? rows.map((item, index) => (
                                <TableRow
                                    hover
                                    key={index}
                                >
                                    {console.log(item)}

                                    <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                                        {item.Day}
                                    </TableCell>



                                    <TableCell onClick={() => onAddScheduleCellClick(7,item)}
                                        className={
                                            item.time.includes(7) || item.selectedTempTime.includes(7)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(8,item)}
                                        className={
                                            item.time.includes(8)|| item.selectedTempTime.includes(8) ? classes.free :
                                                classes.noSchedule}>
                                    </TableCell>

                                    <TableCell  onClick={() => onAddScheduleCellClick(9,item)}
                                        className={
                                            item.time.includes(9)|| item.selectedTempTime.includes(9)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(10,item)}
                                        className={
                                            item.time.includes(10) || item.selectedTempTime.includes(10)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(11,item)}
                                        className={
                                            item.time.includes(11)|| item.selectedTempTime.includes(11)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(12,item)}
                                        className={
                                            item.time.includes(12)|| item.selectedTempTime.includes(12) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(13,item)}
                                        className={
                                            item.time.includes(13)|| item.selectedTempTime.includes(13)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(14,item)}
                                        className={
                                            item.time.includes(14)|| item.selectedTempTime.includes(14) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(15,item)}
                                        className={
                                            item.time.includes(15)|| item.selectedTempTime.includes(15)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(16,item)}
                                        className={
                                            item.time.includes(16)|| item.selectedTempTime.includes(16) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(17,item)}
                                        className={
                                            item.time.includes(17)|| item.selectedTempTime.includes(17)? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(18,item)}
                                        className={
                                            item.time.includes(18)|| item.selectedTempTime.includes(18) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(19,item)}
                                        className={
                                            item.time.includes(19)|| item.selectedTempTime.includes(19) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => onAddScheduleCellClick(20,item)}
                                        className={
                                            item.time.includes(20)|| item.selectedTempTime.includes(20) ? classes.free : classes.noSchedule}>
                                    </TableCell>
                                    {/*{console.dir(item)}*/}

                                    <TableCell>
                                        {item.time.length === 0 ? <Button
                                                // disabled={!allFetchingDone}
                                                onClick={() => onAddScheduleSubmit(item)}
                                                startIcon={<CheckIcon/>}
                                                size="small"
                                                className={classes.addScheduleButton}
                                                variant="outlined">
                                                Submit
                                            </Button> :

                                            <Button
                                                onClick={() => onDeleteSchedule(item)}
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<DeleteIcon/>}
                                            >
                                                Delete
                                            </Button>
                                        }
                                    </TableCell>

                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </div>
    );
}