import {userConstants} from '../Constants';
// import update from 'react-addons-update';
import produce from 'immer';

const initialState = {
    objects: [],
    fetching_objects: false,
    fetched_objects_done: false,
    fetching_expenses: false,
    creating_object: false,
    creating_expense: false,
    deleting_expense: false,
    deleting_object:false,
    editing_expense: false,

}

export function accounting(state = initialState, action) {
    switch (action.type) {

        case userConstants.GET_ACCOUNTING_OBJECTS_REQUEST:
            return {
                ...state,
                fetching_objects: true,
                fetched_objects_done:false,
            }

        case userConstants.GET_ACCOUNTING_OBJECTS_SUCCESS:
            return {
                ...state,
                fetching_objects: false,
                fetched_objects_done: true,
                objects: action.objects.results
            }

        case userConstants.GET_ACCOUNTING_OBJECTS_FAILURE:
            return {
                ...state,
                fetching_objects: false
            }

        case userConstants.GET_OBJECT_EXPENSES_REQUEST:
            return {
                ...state,
                fetching_expenses: true
            }

        case userConstants.GET_OBJECT_EXPENSES_SUCCESS:
            return produce(state, draft => {
                draft.objects[action.object_index].expenses = action.expenses.results;
                delete draft.objects[action.object_index].expense // TODO: REMOVE AFTER THE API IS FIXED
                draft.fetching_expenses = true;
            });

        case userConstants.GET_OBJECT_EXPENSES_FAILURE:
            return {
                ...state,
                fetching_expenses: false
            }

        case userConstants.CREATE_ACCOUNTING_OBJECT_REQUEST:
            return {
                ...state,
                creating_object: true
            }

        case userConstants.CREATE_ACCOUNTING_OBJECT_SUCCESS:
            return produce(state, draft => {
                draft.objects.push(action.object)
                draft.creating_object= false
            });

        case userConstants.CREATE_OBJECT_EXPENSE_REQUEST:
            return {
                ...state,
                creating_expense: true,
            }

        case userConstants.CREATE_OBJECT_EXPENSE_SUCCESS:
            return produce(state, draft => {
                draft.objects[action.object_index].expenses.push(action.expense)
                draft.creating_expense = false;
            });

        case userConstants.CREATE_OBJECT_EXPENSE_FAILURE:
            return {
                ...state,
                creating_expense: false
            }

        case userConstants.DELETE_EXPENSE_REQUEST:
            return {
                ...state,
                deleting_expense: true
            }

        case userConstants.DELETE_EXPENSE_SUCCESS:
            return produce(state, draft => {
                draft.objects[action.object_index].expenses.splice(action.expense_index, 1)
                draft.deleting_expense = false;
            });

        case userConstants.DELETE_EXPENSE_FAILURE:
            return {
                ...state,
                deleting_expense: false
            }

        case userConstants.DELETE_OBJECT_REQUEST:
            return {
                ...state,
                deleting_object: true
            }

        case userConstants.DELETE_OBJECT_SUCCESS:
            return produce(state, draft => {
                draft.objects.splice(action.object_index, 1)
                draft.deleting_object = false;
            });

        case userConstants.DELETE_OBJECT_FAILURE:
            return {
                ...state,
                deleting_object: false
            }

        case userConstants.EDIT_EXPENSE_REQUEST:
            return {
                ...state,
                editing_expense: true,
            }

        case userConstants.EDIT_EXPENSE_SUCCESS:
            return produce(state, draft => {
                draft.objects[action.object_index].expenses[action.expense_index] = action.expense
                draft.editing_expense = false;
            });

        case userConstants.EDIT_EXPENSE_FAILURE:
            return {
                ...state,
                editing_expense: false
            }




        default:
            return state
    }
}
