import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    makeStyles,
    colors,
    Grid, Divider,
} from '@material-ui/core';
import Page from "../../components/Page";
import {CreateTeacher, Header} from "./componenets";
import CreateTeacherSelect from "./componenets/CreateTeacherSelect";
import TeachersListSelect from "./componenets/TeachersListSelect";
import TeachersList from "./componenets/TeachersList";
import CreateSecretary from "./componenets/CreateSecretary/CreateSecretary";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    },
    select: {
        marginTop: theme.spacing(2)
    }
}));


const TeacherManagementView = () => {
    const classes = useStyles();
    const {action} = useParams();
    const navigate = useNavigate();

    function handleNavigatePages(page) {
        navigate(`/app/teachers/${page}`,)

    }

    return (
        <Page
            className={classes.root}
            title={'Instructors Management'}
        >
            <Header/>
            <Divider/>
            <Grid container spacing={3} className={classes.select}>

                <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CreateTeacherSelect handleNavigatePages={handleNavigatePages} isselected={action === 'create'}/>
                </Grid>

                <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <TeachersListSelect handleNavigatePages={handleNavigatePages} isselected={action === 'list'}/>
                </Grid>


                <Grid item xs={12} md={12} lg={12}>
                    {action === 'list' && <TeachersList />}
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    {action === 'create' && <CreateTeacher/>}
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    {action === 'create' && <CreateSecretary/>}
                </Grid>

            </Grid>
        </Page>
    );
};

TeacherManagementView.propTypes = {
};

export default TeacherManagementView;
