export const userConstants = {
    CREATE_TEACHER_REQUEST: 'CREATE_TEACHER_REQUEST',
    CREATE_TEACHER_SUCCESS: 'CREATE_TEACHER_SUCCESS',
    CREATE_TEACHER_FAILURE: 'CREATE_TEACHER_FAILURE',

    CREATE_SECRETARY_REQUEST: 'CREATE_SECRETARY_REQUEST',
    CREATE_SECRETARY_SUCCESS: 'CREATE_SECRETARY_SUCCESS',
    CREATE_SECRETARY_FAILURE: 'CREATE_SECRETARY_FAILURE',


    RESET_EDIT_NOTE_DONE: 'RESET_EDIT_NOTE_DONE',

    EDIT_TEACHER_REQUEST: 'EDIT_TEACHER_REQUEST',
    EDIT_TEACHER_SUCCESS: 'EDIT_TEACHER_SUCCESS',
    EDIT_TEACHER_FAILURE: 'EDIT_TEACHER_FAILURE',

    EDIT_NOTE_REQUEST: 'EDIT_NOTE_REQUEST',
    EDIT_NOTE_SUCCESS: 'EDIT_NOTE_SUCCESS',
    EDIT_NOTE_FAILURE: 'EDIT_NOTE_FAILURE',

    CREATE_NOTE_REQUEST: 'CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'CREATE_NOTE_FAILURE',

    CREATE_ACCOUNTING_OBJECT_REQUEST: 'CREATE_ACCOUNTING_OBJECT_REQUEST',
    CREATE_ACCOUNTING_OBJECT_SUCCESS: 'CREATE_ACCOUNTING_OBJECT_SUCCESS',
    CREATE_ACCOUNTING_OBJECT_FAILURE: 'CREATE_ACCOUNTING_OBJECT_FAILURE',

    CREATE_OBJECT_EXPENSE_TEACHER_REQUEST: 'CREATE_OBJECT_EXPENSE_TEACHER_REQUEST',
    CREATE_OBJECT_EXPENSE_TEACHER_SUCCESS: 'CREATE_OBJECT_EXPENSE_TEACHER_SUCCESS',
    CREATE_OBJECT_EXPENSE_TEACHER_FAILURE: 'CREATE_OBJECT_EXPENSE_TEACHER_FAILURE',

    SEND_EMAIL_CERTIFICATE_REQUEST: 'SEND_EMAIL_CERTIFICATE_REQUEST',
    SEND_EMAIL_CERTIFICATE_SUCCESS: 'SEND_EMAIL_CERTIFICATE_SUCCESS',
    SEND_EMAIL_CERTIFICATE_FAILURE: 'SEND_EMAIL_CERTIFICATE_FAILURE',


    CREATE_OBJECT_EXPENSE_REQUEST: 'CREATE_OBJECT_EXPENSE_REQUEST',
    CREATE_OBJECT_EXPENSE_SUCCESS: 'CREATE_OBJECT_EXPENSE_SUCCESS',
    CREATE_OBJECT_EXPENSE_FAILURE: 'CREATE_OBJECT_EXPENSE_FAILURE',

    EDIT_EXPENSE_REQUEST: 'EDIT_EXPENSE_REQUEST',
    EDIT_EXPENSE_SUCCESS: 'EDIT_EXPENSE_SUCCESS',
    EDIT_EXPENSE_FAILURE: 'EDIT_EXPENSE_FAILURE',


    RESET_CREATE_NOTE_DONE: 'RESET_CREATE_NOTE_DONE',
    RESET_EDIT_STUDENT: 'RESET_EDIT_STUDENT',
    RESET_DELETE_NOTE_DONE : 'RESET_DELETE_NOTE_DONE',
    CLEAR_STUDENT_SESSION_CREATION_DONE :'CLEAR_STUDENT_SESSION_CREATION_DONE',
    CLEAR_DEPOSIT_CREATION :'CLEAR_DEPOSIT_CREATION',
    INITIALIZE_TEACHER_TABLE_DATA : 'INITIALIZE_TEACHER_TABLE_DATA',
    SET_TEACHER_SCHEDULE_TIME : 'SET_TEACHER_SCHEDULE_TIME',

    SET_TABLE_SCHEDULE_TIME : 'SET_TABLE_SCHEDULE_TIME',

    CLEAR_WEEK_TABLE_DATA : 'CLEAR_WEEK_TABLE_DATA',


    CLEAR_TEACHER_TABLE_DATA : 'CLEAR_TEACHER_TABLE_DATA',
    TEACHER_SCHEDULE_GO_NEXT_WEEK : 'TEACHER_SCHEDULE_GO_NEXT_WEEK',
    TEACHER_SCHEDULE_GO_PREVIOUS_WEEK : 'TEACHER_SCHEDULE_GO_PREVIOUS_WEEK',


    SET_TEACHER_VACATIONS_IN_DATA: 'SET_TEACHER_VACATIONS_IN_DATA',
    SET_TEACHER_OVERTIMES_IN_DATA: 'SET_TEACHER_OVERTIMES_IN_DATA',
    SET_TEACHER_SESSIONS_IN_DATA: 'SET_TEACHER_SESSIONS_IN_DATA',
    SET_TEACHER_INSPECTION_SESSIONS_IN_DATA: 'SET_TEACHER_INSPECTION_SESSIONS_IN_DATA',

    SET_USER_TYPE: 'SET_USER_TYPE',

    SET_SESSION_IN_WEEK_TABLE: 'SET_SESSION_IN_WEEK_TABLE',
    SET_INSPECTION_IN_WEEK_TABLE: 'SET_INSPECTION_IN_WEEK_TABLE',
    SET_VACATION_IN_WEEK_TABLE: 'SET_VACATION_IN_WEEK_TABLE',
    SET_OVERTIME_IN_WEEK_TABLE: 'SET_OVERTIME_IN_WEEK_TABLE',


    CLEAR_SELECTED_TEMP_TIME_IN_DATA: 'CLEAR_SELECTED_TEMP_TIME_IN_DATA',
    SET_SELECTED_TEMP_TIME_IN_DATA: 'SET_SELECTED_TEMP_TIME_IN_DATA',
    SET_SELECTED_SESSION_IN_DATA: 'SET_SELECTED_SESSION_IN_DATA',
    CLEAR_SELECTED_SESSION_IN_DATA: 'CLEAR_SELECTED_SESSION_IN_DATA',
    RESET_CONFIRM_SESSION_STATUS: 'RESET_CONFIRM_SESSION_STATUS',


    CLEAR_TEACHER_SCHEDULE_IN_DATA: 'CLEAR_TEACHER_SCHEDULE_IN_DATA',


    RESET_CREATED_SESSION_DONE: 'RESET_CREATED_SESSION_DONE',


    DELETE_TEACHER_REQUEST: 'DELETE_TEACHER_REQUEST',
    DELETE_TEACHER_SUCCESS: 'DELETE_TEACHER_SUCCESS',
    DELETE_TEACHER_FAILURE: 'DELETE_TEACHER_FAILURE',


    DELETE_STUDENT_REQUEST: 'DELETE_STUDENT_REQUEST',
    DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_FAILURE: 'DELETE_STUDENT_FAILURE',


    CREATE_STUDENT_REQUEST: 'CREATE_STUDENT_REQUEST',
    CREATE_STUDENT_SUCCESS: 'CREATE_STUDENT_SUCCESS',
    CREATE_STUDENT_FAILURE: 'CREATE_STUDENT_FAILURE',

    EDIT_STUDENT_REQUEST: 'EDIT_STUDENT_REQUEST',
    EDIT_STUDENT_SUCCESS: 'EDIT_STUDENT_SUCCESS',
    EDIT_STUDENT_FAILURE: 'EDIT_STUDENT_FAILURE',

    EDIT_SETTINGS_REQUEST: 'EDIT_SETTINGS_REQUEST',
    EDIT_SETTINGS_SUCCESS: 'EDIT_SETTINGS_SUCCESS',
    EDIT_SETTINGS_FAILURE: 'EDIT_SETTINGS_FAILURE',

    EDIT_STUDENT_HOUR_REQUEST: 'EDIT_STUDENT_HOUR_REQUEST',
    EDIT_STUDENT_HOUR_SUCCESS: 'EDIT_STUDENT_HOUR_SUCCESS',
    EDIT_STUDENT_HOUR_FAILURE: 'EDIT_STUDENT_HOUR_FAILURE',

    CREATE_STUDENT_SESSION_REQUEST: 'CREATE_STUDENT_SESSION_REQUEST',
    CREATE_STUDENT_SESSION_SUCCESS: 'CREATE_STUDENT_SESSION_SUCCESS',
    CREATE_STUDENT_SESSION_FAILURE: 'CREATE_STUDENT_SESSION_FAILURE',

    CREATE_STUDENT_INSPECTION_SESSION_REQUEST: 'CREATE_STUDENT_INSPECTION_SESSION_REQUEST',
    CREATE_STUDENT_INSPECTION_SESSION_SUCCESS: 'CREATE_STUDENT_INSPECTION_SESSION_SUCCESS',
    CREATE_STUDENT_INSPECTION_SESSION_FAILURE: 'CREATE_STUDENT_INSPECTION_SESSION_FAILURE',


    CREATE_STUDENT_DEPOSIT_REQUEST: 'CREATE_STUDENT_DEPOSIT_REQUEST',
    CREATE_STUDENT_DEPOSIT_SUCCESS: 'CREATE_STUDENT_DEPOSIT_SUCCESS',
    CREATE_STUDENT_DEPOSIT_FAILURE: 'CREATE_STUDENT_DEPOSIT_FAILURE',

    CREATE_STUDENT_RENT_REQUEST: 'CREATE_STUDENT_RENT_REQUEST',
    CREATE_STUDENT_RENT_SUCCESS: 'CREATE_STUDENT_RENT_SUCCESS',
    CREATE_STUDENT_RENT_FAILURE: 'CREATE_STUDENT_RENT_FAILURE',


    DELETE_STUDENT_RENT_REQUEST: 'DELETE_STUDENT_RENT_REQUEST',
    DELETE_STUDENT_RENT_SUCCESS: 'DELETE_STUDENT_RENT_SUCCESS',
    DELETE_STUDENT_RENT_FAILURE: 'DELETE_STUDENT_RENT_FAILURE',

    DELETE_EXPENSE_REQUEST: 'DELETE_EXPENSE_REQUEST',
    DELETE_EXPENSE_SUCCESS: 'DELETE_EXPENSE_SUCCESS',
    DELETE_EXPENSE_FAILURE: 'DELETE_EXPENSE_FAILURE',


    DELETE_OBJECT_REQUEST: 'DELETE_OBJECT_REQUEST',
    DELETE_OBJECT_SUCCESS: 'DELETE_OBJECT_SUCCESS',
    DELETE_OBJECT_FAILURE: 'DELETE_OBJECT_FAILURE',


    DELETE_VACATION_REQUEST: 'DELETE_VACATION_REQUEST',
    DELETE_VACATION_SUCCESS: 'DELETE_VACATION_SUCCESS',
    DELETE_VACATION_FAILURE: 'DELETE_VACATION_FAILURE',



    DELETE_OVERTIME_REQUEST: 'DELETE_OVERTIME_REQUEST',
    DELETE_OVERTIME_SUCCESS: 'DELETE_OVERTIME_SUCCESS',
    DELETE_OVERTIME_FAILURE: 'DELETE_OVERTIME_FAILURE',


    DELETE_STUDENT_SESSION_REQUEST: 'DELETE_STUDENT_SESSION_REQUEST',
    DELETE_STUDENT_SESSION_SUCCESS: 'DELETE_STUDENT_SESSION_SUCCESS',
    DELETE_STUDENT_SESSION_FAILURE: 'DELETE_STUDENT_SESSION_FAILURE',

    DELETE_NOTE_REQUEST: 'DELETE_NOTE_REQUEST',
    DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILURE: 'DELETE_NOTE_FAILURE',

    CANCEL_STUDENT_SESSION_REQUEST: 'CANCEL_STUDENT_SESSION_REQUEST',
    CANCEL_STUDENT_SESSION_SUCCESS: 'CANCEL_STUDENT_SESSION_SUCCESS',
    CANCEL_STUDENT_SESSION_FAILURE: 'CANCEL_STUDENT_SESSION_FAILURE',


    CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_REQUEST: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_REQUEST',
    CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_SUCCESS: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_SUCCESS',
    CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_FAILURE: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_IDENTIFICATION_FAILURE',

    CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_REQUEST: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_REQUEST',
    CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_SUCCESS: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_SUCCESS',
    CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_FAILURE: 'CONFIRM_STUDENT_SESSION_WITH_STUDENT_ID_BY_TEACHER_FAILURE',

    CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_REQUEST: 'CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_REQUEST',
    CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_SUCCESS: 'CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_SUCCESS',
    CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_FAILURE: 'CONFIRM_STUDENT_SESSION_WITH_TEACHER_ID_FAILURE',

    CREATE_TEACHER_SCHEDULE_REQUEST: 'CREATE_TEACHER_SCHEDULE_REQUEST',
    CREATE_TEACHER_SCHEDULE_SUCCESS: 'CREATE_TEACHER_SCHEDULE_SUCCESS',
    CREATE_TEACHER_SCHEDULE_FAILURE: 'CREATE_TEACHER_SCHEDULE_FAILURE',

    DELETE_TEACHER_SCHEDULE_REQUEST: 'DELETE_TEACHER_SCHEDULE_REQUEST',
    DELETE_TEACHER_SCHEDULE_SUCCESS: 'DELETE_TEACHER_SCHEDULE_SUCCESS',
    DELETE_TEACHER_SCHEDULE_FAILURE: 'DELETE_TEACHER_SCHEDULE_FAILURE',
    DELETE_SCHEDULE_AFTER_SUCCESSFUL_RESPONSE : 'DELETE_SCHEDULE_AFTER_SUCCESSFUL_RESPONSE',

    CREATE_TEACHER_VACATION_REQUEST: 'CREATE_TEACHER_VACATION_REQUEST',
    CREATE_TEACHER_VACATION_SUCCESS: 'CREATE_TEACHER_VACATION_SUCCESS',
    CREATE_TEACHER_VACATION_FAILURE: 'CREATE_TEACHER_VACATION_FAILURE',

    CREATE_TEACHER_OVERTIME_REQUEST: 'CREATE_TEACHER_OVERTIME_REQUEST',
    CREATE_TEACHER_OVERTIME_SUCCESS: 'CREATE_TEACHER_OVERTIME_SUCCESS',
    CREATE_TEACHER_OVERTIME_FAILURE: 'CREATE_TEACHER_OVERTIME_FAILURE',


    RESET_TEACHER_SCHEDULE_REQUEST: 'RESET_TEACHER_SCHEDULE_REQUEST',
    RESET_TEACHER_SCHEDULE_SUCCESS: 'RESET_TEACHER_SCHEDULE_SUCCESS',
    RESET_TEACHER_SCHEDULE_FAILURE: 'RESET_TEACHER_SCHEDULE_FAILURE',

    RESET_LOCAL_TEACHER_SCHEDULE: 'RESET_LOCAL_TEACHER_SCHEDULE',

    RECOVER_PASSWORD_REQUEST: 'RECOVER_PASSWORD_REQUEST',
    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_FAILURE: 'RECOVER_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',


    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_ACCOUNTING_OBJECTS_TEACHER_REQUEST: 'GET_ACCOUNTING_OBJECTS_TEACHER_REQUEST',
    GET_ACCOUNTING_OBJECTS_TEACHER_SUCCESS: 'GET_ACCOUNTING_OBJECTS_TEACHER_SUCCESS',
    GET_ACCOUNTING_OBJECTS_TEACHER_FAILURE: 'GET_ACCOUNTING_OBJECTS_TEACHER_FAILURE',


    GET_ACCOUNTING_OBJECTS_REQUEST: 'GET_ACCOUNTING_OBJECTS_REQUEST',
    GET_ACCOUNTING_OBJECTS_SUCCESS: 'GET_ACCOUNTING_OBJECTS_SUCCESS',
    GET_ACCOUNTING_OBJECTS_FAILURE: 'GET_ACCOUNTING_OBJECTS_FAILURE',

    GET_OBJECT_EXPENSES_REQUEST: 'GET_OBJECT_EXPENSES_REQUEST',
    GET_OBJECT_EXPENSES_SUCCESS: 'GET_OBJECT_EXPENSES_SUCCESS',
    GET_OBJECT_EXPENSES_FAILURE: 'GET_OBJECT_EXPENSES_FAILURE',

    GET_TEACHER_REQUEST: 'GET_TEACHER_REQUEST',
    GET_TEACHER_SUCCESS: 'GET_TEACHER_SUCCESS',
    GET_TEACHER_FAILURE: 'GET_TEACHER_FAILURE',

    GET_TEACHER_VACATION_REQUEST: 'GET_TEACHER_VACATION_REQUEST',
    GET_TEACHER_VACATION_SUCCESS: 'GET_TEACHER_VACATION_SUCCESS',
    GET_TEACHER_VACATION_FAILURE: 'GET_TEACHER_VACATION_FAILURE',

    GET_TEACHER_OVERTIME_REQUEST: 'GET_TEACHER_OVERTIME_REQUEST',
    GET_TEACHER_OVERTIME_SUCCESS: 'GET_TEACHER_OVERTIME_SUCCESS',
    GET_TEACHER_OVERTIME_FAILURE: 'GET_TEACHER_OVERTIME_FAILURE',

    GET_TEACHER_OVERTIME_FOR_A_WEEK_REQUEST: 'GET_TEACHER_OVERTIME_FOR_A_WEEK_REQUEST',
    GET_TEACHER_OVERTIME_FOR_A_WEEK_SUCCESS: 'GET_TEACHER_OVERTIME_FOR_A_WEEK_SUCCESS',
    GET_TEACHER_OVERTIME_FOR_A_WEEK_FAILURE: 'GET_TEACHER_OVERTIME_FOR_A_WEEK_FAILURE',


    GET_TEACHER_SESSIONS_REQUEST: 'GET_TEACHER_SESSIONS_REQUEST',
    GET_TEACHER_SESSIONS_SUCCESS: 'GET_TEACHER_SESSIONS_SUCCESS',
    GET_TEACHER_SESSIONS_FAILURE: 'GET_TEACHER_SESSIONS_FAILURE',

    GET_TEACHER_SESSIONS_FOR_A_WEEK_REQUEST: 'GET_TEACHER_SESSIONS_FOR_A_WEEK_REQUEST',
    GET_TEACHER_SESSIONS_FOR_A_WEEK_SUCCESS: 'GET_TEACHER_SESSIONS_FOR_A_WEEK_SUCCESS',
    GET_TEACHER_SESSIONS_FOR_A_WEEK_FAILURE: 'GET_TEACHER_SESSIONS_FOR_A_WEEK_FAILURE',

    GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_REQUEST: 'GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_REQUEST',
    GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_SUCCESS: 'GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_SUCCESS',
    GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_FAILURE: 'GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_FAILURE',



    GET_TEACHER_VACATIONS_FOR_A_WEEK_REQUEST: 'GET_TEACHER_VACATIONS_FOR_A_WEEK_REQUEST',
    GET_TEACHER_VACATIONS_FOR_A_WEEK_SUCCESS: 'GET_TEACHER_VACATIONS_FOR_A_WEEK_SUCCESS',
    GET_TEACHER_VACATIONS_FOR_A_WEEK_FAILURE: 'GET_TEACHER_VACATIONS_FOR_A_WEEK_FAILURE',



    PROCESS_TEACHER_SESSIONS_FOR_A_WEEK: 'PROCESS_TEACHER_SESSIONS_FOR_A_WEEK',

    PROCESS_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK: 'PROCESS_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK',


    PROCESS_TEACHER_OVERTIMES_FOR_A_WEEK: 'PROCESS_TEACHER_OVERTIMES_FOR_A_WEEK',

    PROCESS_TEACHER_VACATIONS_FOR_A_WEEK: 'PROCESS_TEACHER_VACATIONS_FOR_A_WEEK',



    CLEAR_FETCHED_STUDENT: 'CLEAR_FETCHED_STUDENT',
    CLEAR_FETCHED_TEACHER: 'CLEAR_FETCHED_TEACHER',

    CLEAR_CREATED_TEACHER: 'CLEAR_CREATED_TEACHER',
    CLEAR_CREATED_STUDENT: 'CLEAR_CREATED_STUDENT',


    CLEAR_ALL_FETCHED_DATA : 'CLEAR_ALL_FETCHED_DATA',
    CLEAR_FETCHED_TEACHER_VACATIONS: 'CLEAR_FETCHED_TEACHER_VACATIONS',
    CLEAR_FETCHED_TEACHER_OVERTIMES: 'CLEAR_FETCHED_TEACHER_OVERTIMES',
    CLEAR_FETCHED_TEACHER_SESSIONS: 'CLEAR_FETCHED_TEACHER_SESSIONS',
    CLEAR_FETCHED_TEACHER_INSPECTION_SESSIONS: 'CLEAR_FETCHED_TEACHER_INSPECTION_SESSIONS',
    CLEAR_CREATED_TEACHER_OVERTIME_DONE: 'CLEAR_CREATED_TEACHER_OVERTIME_DONE',
    CLEAR_CREATED_TEACHER_VACATION_DONE: 'CLEAR_CREATED_TEACHER_VACATION_DONE',
    CLEAR_FETCHED_TEACHER_SCHEDULES: 'CLEAR_FETCHED_TEACHER_SCHEDULES',



    GET_ALL_STUDENTS_REQUEST: 'GET_ALL_STUDENTS_REQUEST',
    GET_ALL_STUDENTS_SUCCESS: 'GET_ALL_STUDENTS_SUCCESS',
    GET_ALL_STUDENTS_FAILURE: 'GET_ALL_STUDENTS_FAILURE',



    GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
    GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
    GET_STUDENT_FAILURE: 'GET_STUDENT_FAILURE',

    GET_STUDENT_SESSIONS_REQUEST: 'GET_STUDENT_SESSIONS_REQUEST',
    GET_STUDENT_SESSIONS_SUCCESS: 'GET_STUDENT_SESSIONS_SUCCESS',
    GET_STUDENT_SESSIONS_FAILURE: 'GET_STUDENT_SESSIONS_FAILURE',

    GET_TABLE_DATA_REQUEST: 'GET_TABLE_DATA_REQUEST',
    GET_TABLE_DATA_SUCCESS: 'GET_TABLE_DATA_SUCCESS',
    GET_TABLE_DATA_FAILURE: 'GET_TABLE_DATA_FAILURE',

    GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
    GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',



    GET_STUDENT_INSPECTION_SESSIONS_REQUEST: 'GET_STUDENT_INSPECTION_SESSIONS_REQUEST',
    GET_STUDENT_INSPECTION_SESSIONS_SUCCESS: 'GET_STUDENT_INSPECTION_SESSIONS_SUCCESS',
    GET_STUDENT_INSPECTION_SESSIONS_FAILURE: 'GET_STUDENT_INSPECTION_SESSIONS_FAILURE',

    GET_STUDENT_DEPOSITS_REQUEST: 'GET_STUDENT_DEPOSITS_REQUEST',
    GET_STUDENT_DEPOSITS_SUCCESS: 'GET_STUDENT_DEPOSITS_SUCCESS',
    GET_STUDENT_DEPOSITS_FAILURE: 'GET_STUDENT_DEPOSITS_FAILURE',


    GET_ALL_DEPOSITS_REQUEST: 'GET_ALL_DEPOSITS_REQUEST',
    GET_ALL_DEPOSITS_SUCCESS: 'GET_ALL_DEPOSITS_SUCCESS',
    GET_ALL_DEPOSITS_FAILURE: 'GET_ALL_DEPOSITS_FAILURE',



    GET_STUDENT_RENTS_REQUEST: 'GET_STUDENT_RENTS_REQUEST',
    GET_STUDENT_RENTS_SUCCESS: 'GET_STUDENT_RENTS_SUCCESS',
    GET_STUDENT_RENTS_FAILURE: 'GET_STUDENT_RENTS_FAILURE',

    GET_STUDENT_NOTES_LIST_REQUEST: 'GET_STUDENT_NOTES_LIST_REQUEST',
    GET_STUDENT_NOTES_LIST_SUCCESS: 'GET_STUDENT_NOTES_LIST_SUCCESS',
    GET_STUDENT_NOTES_LIST_FAILURE: 'GET_STUDENT_NOTES_LIST_FAILURE',

    GET_STUDENT_NOTE_DETAILS_REQUEST: 'GET_STUDENT_NOTE_DETAILS_REQUEST',
    GET_STUDENT_NOTE_DETAILS_SUCCESS: 'GET_STUDENT_NOTE_DETAILS_SUCCESS',
    GET_STUDENT_NOTE_DETAILS_FAILURE: 'GET_STUDENT_NOTE_DETAILS_FAILURE',


    DELETE_STUDENT_DEPOSITS_REQUEST: 'DELETE_STUDENT_DEPOSITS_REQUEST',
    DELETE_STUDENT_DEPOSITS_SUCCESS: 'DELETE_STUDENT_DEPOSITS_SUCCESS',
    DELETE_STUDENT_DEPOSITS_FAILURE: 'DELETE_STUDENT_DEPOSITS_FAILURE',


    GET_DAY_SCHEDULE_REQUEST: 'GET_DAY_SCHEDULE_REQUEST',
    GET_DAY_SCHEDULE_SUCCESS: 'GET_DAY_SCHEDULE_SUCCESS',
    GET_DAY_SCHEDULE_FAILURE: 'GET_DAY_SCHEDULE_FAILURE',

    GET_TEACHERS_LIST_REQUEST: 'GET_TEACHERS_LIST_REQUEST',
    GET_TEACHERS_LIST_SUCCESS: 'GET_TEACHERS_LIST_SUCCESS',
    GET_TEACHERS_LIST_FAILURE: 'GET_TEACHERS_LIST_FAILURE',

    GET_TEACHERS_UPCOMING_SESSIONS_REQUEST: 'GET_TEACHERS_UPCOMING_SESSIONS_REQUEST',
    GET_TEACHERS_UPCOMING_SESSIONS_SUCCESS: 'GET_TEACHERS_UPCOMING_SESSIONS_SUCCESS',
    GET_TEACHERS_UPCOMING_SESSIONS_FAILURE: 'GET_TEACHERS_UPCOMING_SESSIONS_FAILURE',


    GET_DAY_SESSIONS_REQUEST: 'GET_DAY_SESSIONS_REQUEST',
    GET_DAY_SESSIONS_SUCCESS: 'GET_DAY_SESSIONS_SUCCESS',
    GET_DAY_SESSIONS_FAILURE: 'GET_DAY_SESSIONS_FAILURE',


    GET_TEACHER_SCHEDULE_REQUEST: 'GET_TEACHER_SCHEDULE_REQUEST',
    GET_TEACHER_SCHEDULE_SUCCESS: 'GET_TEACHER_SCHEDULE_SUCCESS',
    GET_TEACHER_SCHEDULE_FAILURE: 'GET_TEACHER_SCHEDULE_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',


    SET_CURRENT_WEEK: 'SET_CURRENT_WEEK',
    SET_EXPENSE_DATA: 'SET_EXPENSE_DATA',
    ADD_WEEK: 'ADD_WEEK',
    INITIALIZE_WEEK_TABLE_DATA : 'INITIALIZE_WEEK_TABLE_DATA',

    ADD_TO_UNPROCESSED_WEEKS: 'ADD_TO_UNPROCESSED_WEEKS',
    REMOVE_FROM_UNPROCESSED_WEEKS: 'REMOVE_FROM_UNPROCESSED_WEEKS',

};
