import * as React from "react";
import ShowTeacherWeeklySchedule from "../Components/ShowTeacherWeeklySchedule";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../Actions";
import AddVacationView from "../Components/AddVacationView";
import AddOvertimeView from "../Components/AddOvertimeView";
import AddTeacherWeeklySchedule from "../Components/AddTeacherSchedule";
import ShowTeacherWeeklyScheduleForSessions from "../../Student/Components/ShowTeacherWeeklyScheduleForSessions";

//-------------------------------------------------Show Teacher Schedule Stuff-------------------------------------

let date = new Date()
let week = []
date.setUTCHours(0,0,1)
for (let i = 0; i < 7; i++) {
    week[i] = new Date(date)
    date.setDate(date.getDate() + 1) // TODO: change to UTC maybe?
    console.log(date)
}

const weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function makeRange(start_time, end_time) {

    const time_range = []
    start_time = Number(start_time.split(':')[0])
    end_time = Number(end_time.split(':')[0])
    for (let i = start_time; i < end_time; i++) {
        time_range.push(i);
    }
    return time_range
}


export default function TeacherScheduleContainer({teacher_id, addSchedule}) {
    const dispatch = useDispatch();
    const [showScheduleRows, setShowScheduleRows] = React.useState([])
    let teacher_schedule = useSelector(state => state.teachers.fetched_teacher_schedule);
    let teacher_vacations = useSelector(state => state.teachers.fetched_teacher_vacations);
    let fetched_teacher_vacations_done = useSelector(state => state.teachers.fetched_teacher_vacations_done)
    let fetching_teacher_vacations = useSelector(state => state.teachers.fetching_teacher_vacations)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openOvertimeDialog, setOpenOvertimeDialog] = React.useState(false);
    const [vacationType, setVacationType] = React.useState("byDay");
    const [selectedDay, setSelectedDay] = React.useState({});
    const [selectedOvertimeDay, setSelectedOvertimeDay] = React.useState({});
    const [createVacationData, setCreateVacationData] = React.useState({start: "", end: ""});
    const [createOvertimeData, setCreateOvertimeData] = React.useState({start: "", end: ""});
    const [selectedVacationArray, setSelectedVacationArray] = React.useState([])
    const [selectedOvertimeArray, setSelectedOvertimeArray] = React.useState([])
    const [selectedScheduleArray, setSelectedScheduleArray] = React.useState([])
    let created_teacher_vacation = useSelector(state => state.teachers.created_teacher_vacation)
    const teacherTableData = useSelector(state => state.teachers.teacherTableData)
    let fetched_teacher_overtimes_done = useSelector(state => state.teachers.fetched_teacher_overtimes_done)
    let fetching_teacher_overtimes = useSelector(state => state.teachers.fetching_teacher_overtimes)
    const teacher_overtimes = useSelector(state => state.teachers.fetched_teacher_overtimes)
    const [allFetchingDone, setAllFetchingDone] = React.useState(false)
    const created_teacher_overtime = useSelector(state => state.teachers.created_teacher_overtime)
    const created_teacher_schedule = useSelector(state => state.teachers.created_teacher_schedule)
    const creating_teacher_schedule = useSelector(state => state.teachers.creating_teacher_schedule)
    const deleting_teacher_schedule = useSelector(state => state.teachers.deleting_teacher_schedule)
    const deleted_teacher_schedule = useSelector(state => state.teachers.deleted_teacher_schedule)
    const deleted_teacher_schedule_day = useSelector(state => state.teachers.deleted_teacher_schedule_day)
    const [teacherScheduleProccessDone, setTeacherScheduleProccessDone] = React.useState(false);
    const [teacherOvertimeProccessDone, setTeacherOvertimeProccessDone] = React.useState(false);
    const [teacherVacationProccessDone, setTeacherVacationProccessDone] = React.useState(false);
    const [teacherSessionProccessDone, setTeachersessionProccessDone] = React.useState(false);
    const fetched_teacher_sessions_done = useSelector(state => state.teachers.fetched_teacher_sessions_done)
    const teacher_sessions = useSelector(state => state.teachers.fetched_teacher_sessions);
    const fetching_teacher_sessions = useSelector(state => state.teachers.fetching_teacher_sessions)
    const all_students = useSelector(state => state.students.fetched_all_students)
    const fetched_teacher_sessions_for_a_week = useSelector(state => state.teachers.fetched_teacher_sessions_for_a_week)
    const fetched_teacher_overtimes_for_a_week = useSelector(state => state.teachers.fetched_teacher_overtimes_for_a_week)
    const fetched_teacher_vacations_for_a_week = useSelector(state => state.teachers.fetched_teacher_vacations_for_a_week)



    const [selectedSchedule, setSelectedSchedule] = React.useState({
        Saturday: {},
        Sunday: {},
        Monday: {},
        Tuesday: {},
        Wednesday: {},
        Thursday: {},
        Friday: {},
    });


    function goNextWeek() {
        for (let i = 0; i < 7; i++) {
            const Day = weekday[week[i].getUTCDay()]
            const Date = months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear()
            dispatch(userActions.teacherScheduleGoNextWeek(Day, Date, i))
        }
    }

    function goPreviousWeek() {
        for (let i = 0; i < 7; i++) {
            const Day = weekday[week[i].getUTCDay()]
            const Date = months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear()
            dispatch(userActions.teacherScheduleGoPreviousWeek(Day, Date, i))
        }
    }


    function makeInitialSkeleton() {
        let data = []

        for (let i = 0; i < 7; i++) {
            console.log(i)
            data.push({
                id: i + 1,
                Day: weekday[week[i].getUTCDay()],
                Date: months[week[i].getUTCMonth()] + ' ' + week[i].getUTCDate() + ',' + ' ' + week[i].getUTCFullYear(),
                time: [],
                selectedTempTime: [],
                vacations: [],
                overtimes: [],
                sessions: []

            })
        }
        dispatch(userActions.initializeTeacherTableData(data))
        console.log(data)
        console.log("ONLY ONCE")
    }

    function setTeacherSchedule() {
        let temp = [...teacherTableData]


        teacher_schedule.map(single_schedule => (
            dispatch(userActions.setTeacherScheduleTime(
                temp.findIndex(row => row.Day === single_schedule.day_of_week),
                makeRange(single_schedule.start_time, single_schedule.end_time)
            ))

        ));
    }

    function getTeacherVacations() {
        dispatch(userActions.getTeacherVacationsForAWeek(teacher_id, week[0].toISOString())) // Get teacher vacations and put them on redux store

    }

    function proccessTeacherVacationsForAWeek () {
        const temp = [...fetched_teacher_vacations_for_a_week]
        let days = []

        week.map((day, index) => {
            days.push({
                date: months[day.getUTCMonth()] + ' ' + day.getUTCDate() + ',' + ' ' + day.getUTCFullYear(),
                vacations: []
            })
        })

        for (let i = 0; i< temp.length; i++){
            const vacation_date = new Date(temp[i].start)
            console.log(months[vacation_date.getUTCMonth()] + ' ' + vacation_date.getUTCDate() + ',' + ' ' +
                vacation_date.getUTCFullYear())
            const index = days.findIndex(day => day.date === months[vacation_date.getUTCMonth()] + ' ' + vacation_date.getUTCDate() + ',' + ' ' +
                vacation_date.getUTCFullYear())
            if(index >= 0){
                days[index].vacations.push(temp[i])
            }
        }
        dispatch(userActions.processTeacherVacationsForAWeek(days))
    }



    function processTeacherVacations() {// get vacations from store and merge them into schedules
        setTeacherVacationProccessDone(false)

        if (fetched_teacher_vacations_done && teacher_vacations.length === 7 //&& !showScheduleRows.vacations
        ) {
            console.log('PAPAPAPAPAPAPAPAPAPAPP')
            let newArray = [] // make a new array to copy vacation items from store to it
            for (let i = 0; i < teacher_vacations.length; i++) { // gotta make  a new object so you don't mutate store

                newArray.push({
                    date: teacher_vacations[i].date,
                    vacations: teacher_vacations[i].vacations
                })
            }
            console.log(newArray)

            if (newArray.length === teacher_vacations.length) {
                for (let i = 0; i < newArray.length; i++) {
                    let vacate_length = newArray[i].vacations.length
                    console.log(newArray[i])
                    console.log(vacate_length)
                    newArray[i].newVacations = []

                    for (let j = 0; j < vacate_length; j++) { // Loop through each array inside teacher_vacations. we don't know the number
                        if (newArray[i].vacations[j].start && newArray[i].vacations[j].end) { // only do this if there is actually some vacation items
                            let start = new Date(newArray[i].vacations[j].start).getUTCHours()
                            let end = new Date(newArray[i].vacations[j].end).getUTCHours()
                            for (let k = start; k < end; k++) {
                                if (!newArray[i].newVacations.includes(k)) {
                                    newArray[i].newVacations.push(k)
                                }

                            }
                        }
                    }
                }
                let tempDataClone = [...teacherTableData]

                newArray.map((item, index) => {
                    dispatch(userActions.setTeacherVacationsInData(tempDataClone.findIndex(row => row.Date === item.date), item.newVacations))
                })
            }
            console.log(newArray)


        }
        setTeacherVacationProccessDone(true)

    }

    function getTeacherOvertimes() {
        dispatch(userActions.getTeacherOvertimesForAWeek(teacher_id, week[0].toISOString()))  // Get teacher vacations and put them on redux store

    }

    function proccessTeacherOvertimesForAWeek () {
        const temp = [...fetched_teacher_overtimes_for_a_week]
        let days = []

        week.map((day, index) => {
            days.push({
                date: months[day.getUTCMonth()] + ' ' + day.getUTCDate() + ',' + ' ' + day.getUTCFullYear(),
                overtimes: []
            })
        })

        for (let i = 0; i< temp.length; i++){
            const overtime_date = new Date(temp[i].start)
            console.log(months[overtime_date.getUTCMonth()] + ' ' + overtime_date.getUTCDate() + ',' + ' ' +
                overtime_date.getUTCFullYear())
            const index = days.findIndex(day => day.date === months[overtime_date.getUTCMonth()] + ' ' + overtime_date.getUTCDate() + ',' + ' ' +
                overtime_date.getUTCFullYear())
            if(index >= 0){
                days[index].overtimes.push(temp[i])
            }
        }
        dispatch(userActions.processTeacherOvertimesForAWeek(days))
    }

    function processTeacherOvertimes() {// get vacations from store and merge them into schedules
        setTeacherOvertimeProccessDone(false)

        if (fetched_teacher_overtimes_done && teacher_overtimes.length === 7 //&& !showScheduleRows.vacations
        ) {
            console.log('PAPAPAPAPAPAPAPAPAPAPP')
            let newArray = [] // make a new array to copy vacation items from store to it
            for (let i = 0; i < teacher_overtimes.length; i++) { // gotta make  a new object so you don't mutate store

                newArray.push({
                    date: teacher_overtimes[i].date,
                    overtimes: teacher_overtimes[i].overtimes
                })
            }
            console.log(newArray)

            if (newArray.length === teacher_overtimes.length) {
                for (let i = 0; i < newArray.length; i++) {
                    let overt_length = newArray[i].overtimes.length
                    console.log(newArray[i])
                    console.log(overt_length)
                    newArray[i].newOvertimes = []

                    for (let j = 0; j < overt_length; j++) { // Loop through each array inside teacher_vacations. we don't know the number
                        if (newArray[i].overtimes[j].start && newArray[i].overtimes[j].end) { // only do this if there is actually some vacation items
                            let start = new Date(newArray[i].overtimes[j].start).getUTCHours()
                            let end = new Date(newArray[i].overtimes[j].end).getUTCHours()
                            for (let k = start; k < end; k++) {
                                if (!newArray[i].newOvertimes.includes(k)) {
                                    newArray[i].newOvertimes.push(k)
                                }
                            }
                        }
                    }
                }
                let tempDataClone = [...teacherTableData]

                newArray.map((item, index) => {
                    dispatch(userActions.setTeacherOvertimesInData(tempDataClone.findIndex(row => row.Date === item.date), item.newOvertimes))
                })
            }
            console.log(newArray)
        }
        setTeacherOvertimeProccessDone(false)

    }

    function getTeacherSessions() {
        dispatch(userActions.getTeacherSessionsForAWeek(teacher_id,week[0].toISOString() ))

    }


    function proccessTeacherSessionsForAWeek () {
        const temp = [...fetched_teacher_sessions_for_a_week]
        let days = []

        week.map((day, index) => {
            days.push({
                date: months[day.getUTCMonth()] + ' ' + day.getUTCDate() + ',' + ' ' + day.getUTCFullYear(),
                sessions: []
            })
        })

        for (let i = 0; i< temp.length; i++){
            const session_date = new Date(temp[i].start)
            console.log(months[session_date.getUTCMonth()] + ' ' + session_date.getUTCDate() + ',' + ' ' +
                session_date.getUTCFullYear())
            const index = days.findIndex(day => day.date === months[session_date.getUTCMonth()] + ' ' + session_date.getUTCDate() + ',' + ' ' +
                session_date.getUTCFullYear())
            if(index >= 0){
                days[index].sessions.push(temp[i])
            }
        }
        dispatch(userActions.processTeacherSessionsForAWeek(days))
    }


    function proccessTeacherSessions() {
        setTeachersessionProccessDone(false)

        console.log("YAYAYYAYAYAYYAYAYYYAYAYAYYAAYAYYAYAYAYAYYAYAYAYYAYAYYAYAYAYAYAY")
        if (fetched_teacher_sessions_done && teacher_sessions.length === 7
        ) {
            console.log('Proccessing teacher sessions')
            let newArray = [] // make a new array to copy vacation items from store to it
            for (let i = 0; i < teacher_sessions.length; i++) { // gotta make  a new object so you don't mutate store

                newArray.push({
                    date: teacher_sessions[i].date,
                    sessions: teacher_sessions[i].sessions
                })
            }
            console.log(newArray)

            if (newArray.length === teacher_sessions.length) {
                for (let i = 0; i < newArray.length; i++) {
                    let sesh_length = newArray[i].sessions.length
                    console.log(newArray[i])
                    console.log(sesh_length)
                    newArray[i].newSessions = []

                    for (let j = 0; j < sesh_length; j++) { // Loop through each array inside teacher_vacations. we don't know the number
                        if (newArray[i].sessions[j].start && newArray[i].sessions[j].end) { // only do this if there is actually some vacation items

                            // let test  = new Date(newArray[i].overtimes[j].start).toUTCString()
                            // let test2 = new Date(Date.UTC(test))
                            // console.log(test2)
                            //
                            let start = new Date(newArray[i].sessions[j].start).getUTCHours()
                            let end = new Date(newArray[i].sessions[j].end).getUTCHours()
                            // let finalVacationTimeArray = []
                            for (let k = start; k < end; k++) {
                                // finalVacationTimeArray.push(k);
                                if (!newArray[i].newSessions.includes(k)) {
                                    newArray[i].newSessions.push(k)
                                }
                            }
                        }
                    }
                }
                let tempDataClone = [...teacherTableData]

                newArray.map((item, index) => {
                    // console.log(tempDataClone.findIndex(row => row.Date === item.date))
                    dispatch(userActions.setTeacherSessionsInData(tempDataClone.findIndex(row => row.Date === item.date), item.newSessions))
                })
            }
            console.log(newArray)
        }
        setTeachersessionProccessDone(true)

    }

    function updateSchedulesAfterDeletion() {
        const rows = [...teacherTableData]
        const deletedScheduleIndex = rows.findIndex(row => row.Day === deleted_teacher_schedule_day)
        dispatch(userActions.deleteScheduleAfterSuccessfulResponse(deletedScheduleIndex))
        dispatch(userActions.getTeacherSchedule(teacher_id))

    }

    useEffect(() => {
        makeInitialSkeleton()

    }, []) //TODO: consider adding the dependencies

    useEffect(() => { // This makes the teacher schedules be re-fetched AFTER create schedule is successful
        if (created_teacher_schedule && !creating_teacher_schedule) {
            dispatch(userActions.getTeacherSchedule(teacher_id))

        }

    }, [created_teacher_schedule, creating_teacher_schedule])

    useEffect(() => { // This makes the teacher schedules be re-fetched AFTER DELETE schedule is successful
        if (deleted_teacher_schedule && !deleting_teacher_schedule) {
            updateSchedulesAfterDeletion()

        }

    }, [deleted_teacher_schedule, deleting_teacher_schedule])

    useEffect(() => {
        if (teacherTableData.length !== 0 && teacher_schedule) {
            setTeacherSchedule()
        }

    }, [teacherTableData.length, teacher_schedule])


    // useEffect(() => {
    //     if (teacherTableData.length !== 0 && !fetched_teacher_vacations_done && !fetching_teacher_vacations) {
    //         getTeacherVacations()
    //     }
    //     if (teacherTableData.length !== 0 && !fetched_teacher_overtimes_done && !fetching_teacher_overtimes) {
    //         getTeacherOvertimes()
    //     }
    //
    //     if (teacherTableData.length !== 0 && !fetched_teacher_sessions_done && !fetching_teacher_sessions) {
    //         getTeacherSessions()
    //     }
    //
    //
    //     if (teacher_vacations.length === 7 && teacher_sessions.length === 7 && teacher_overtimes.length === 7
    //     ) {
    //         setAllFetchingDone(true)
    //     }
    //
    // })


    useEffect(() =>{ // ran when other teacher is selected
        // dispatch(userActions.clearTeacherTableData())
        dispatch(userActions.clearFetchedTeacherVacations())
        dispatch(userActions.clearFetchedTeacherOvertimes())
        dispatch(userActions.clearFetchedTeacherSessions())
        teacherTableData.map((item, index) => {
            dispatch(userActions.clearTeacherScheduleInData(index))
        })
    }, [teacher_id])
    useEffect(() => {
        makeInitialSkeleton()
        // dispatch(userActions.getAllStudents())
    }, []) //TODO: consider adding the dependencies


    useEffect(() => {
        processTeacherVacations()
    }, [teacher_vacations])


    useEffect(() => {
        processTeacherOvertimes()
    }, [teacher_overtimes])


    useEffect(() => {
        proccessTeacherSessions()
    }, [teacher_sessions])



    useEffect(() => {
        proccessTeacherSessionsForAWeek()
    }, [fetched_teacher_sessions_for_a_week])


    useEffect(() => {
        proccessTeacherOvertimesForAWeek()
    }, [fetched_teacher_overtimes_for_a_week])

    useEffect(() => {
        proccessTeacherVacationsForAWeek()
    }, [fetched_teacher_vacations_for_a_week])




    useEffect(() => { // This is for closing and refreshing the table AFTER an overtime has been created successfully
        if (created_teacher_overtime === true && openOvertimeDialog === true) {
            setOpenOvertimeDialog(false)
            dispatch(userActions.clearCreatedTeacherOvertimeDone())
            handleCloseOvertimeDialog()
        }
    }, [created_teacher_overtime, openOvertimeDialog])

    useEffect(() => { // This is for closing and refreshing the table AFTER a vacation has been created successfully
        if (created_teacher_vacation === true && openDialog === true) {
            setOpenDialog(false)
            dispatch(userActions.clearCreatedTeacherVacationDone())
            handleCloseDialog()
        }
    }, [created_teacher_vacation, openDialog])

    const handleGoNextWeek = () => {
        if (allFetchingDone) {

            dispatch(userActions.clearFetchedTeacherSessions())
            dispatch(userActions.clearFetchedTeacherOvertimes())
            dispatch(userActions.clearFetchedTeacherVacations())
            setAllFetchingDone(false)


            const last_day = new Date(Math.max.apply(null, week)) // select the last element in array
            for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
                last_day.setDate(last_day.getDate() + 1)
                week[i] = new Date(last_day)
            }
            goNextWeek()
            getTeacherVacations()
            getTeacherOvertimes()
            getTeacherSessions()

        }
    }

        const handleGoPreviousWeek = () => {
            if(allFetchingDone) {

                setAllFetchingDone(false)
                dispatch(userActions.clearFetchedTeacherVacations())
                dispatch(userActions.clearFetchedTeacherOvertimes())
                dispatch(userActions.clearFetchedTeacherSessions())

                const first_day = new Date(Math.min.apply(null, week)); // get the min date from array
                for (let i = 0; i < 7; i++) { // make a Date object for the next week starting from today
                    first_day.setDate(first_day.getDate() + -1)
                    week[6 - i] = new Date(first_day) // 6-i because the week would flip if it was week[i]
                }
                console.log(week)
                goPreviousWeek()
                getTeacherVacations()
                getTeacherOvertimes()
                getTeacherSessions()

            }


    }

// ----------------------------------------------------------------ADD VACATION STUFF---------------------------------
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        dispatch(userActions.clearFetchedTeacherVacations())
        setCreateVacationData({start: "", end: ""})
        setSelectedVacationArray([])
    };


    const handleAddVacation = (item) => {
        setSelectedDay(item)
        handleOpenDialog()
    }

    const handleVacationTypeChange = (e) => {
        const {value} = e.target;
        setVacationType(value)
        console.dir(selectedDay)
    }

    const handleVacationClick = (hour) => {
        // ---------------------------------------------------Calculate Start time and convert to ISO String

        if (!createVacationData.start) {
            const initialDate = selectedDay.Date
            const monthString = initialDate.split(" ")[0]
            const month = months.indexOf(monthString)
            const day = parseInt(initialDate.split(" ")[1].split(",")[0])
            const year = parseInt(initialDate.split(" ")[2])
            let start_time = new Date(Date.UTC(year, month, day,
                hour, 0, 0))
            let start_time_iso = start_time.toISOString()
            setCreateVacationData({...createVacationData, start: start_time_iso})
            setSelectedVacationArray([...selectedVacationArray, hour])

        }
        if (createVacationData.start && !createVacationData.end) {
            const initialDate = selectedDay.Date
            const monthString = initialDate.split(" ")[0]
            const month = months.indexOf(monthString)
            const day = parseInt(initialDate.split(" ")[1].split(",")[0])
            const year = parseInt(initialDate.split(" ")[2])
            let end_time = new Date(Date.UTC(year, month, day,
                hour + 1, 0, 0))

            let end_time_iso = end_time.toISOString()
            setCreateVacationData({...createVacationData, end: end_time_iso})
            let temp_array = []
            for (let i = selectedVacationArray[0]; i <= hour; i++) {
                temp_array.push(i)
            }
            setSelectedVacationArray(temp_array)

        }
    }
//------------------------------------------------------Calculate End time and convert to ISO String
    const handleSetVacationSubmit = () => {
        if (vacationType === "byDay") {
            const initialDate = selectedDay.Date
            const monthString = initialDate.split(" ")[0]
            const month = months.indexOf(monthString)
            const day = parseInt(initialDate.split(" ")[1].split(",")[0])
            const year = parseInt(initialDate.split(" ")[2])
            const start_time = new Date(Date.UTC(year, month, day,
                7, 0, 0))
            const end_time = new Date(Date.UTC(year, month, day,
                21, 0, 0))
            const end_time_iso = end_time.toISOString()
            let start_time_iso = start_time.toISOString()
            // setCreateVacationData({...createVacationData, start: start_time_iso})
            // setCreateVacationData({...createVacationData, end: end_time_iso})
            console.log(vacationType)
            // if (createVacationData.start && createVacationData.end) {
            console.dir(createVacationData)
            dispatch(userActions.createTeacherVacation(teacher_id, {start: start_time_iso, end: end_time_iso}))
            // }
        }
        if (vacationType === "byHour") {
            if (createVacationData.start && createVacationData.end) {
                console.dir(createVacationData)
                dispatch(userActions.createTeacherVacation(teacher_id, createVacationData))
                setCreateVacationData({start: "", end: ""})
                setSelectedVacationArray([])
            }
        }
    }


//--------------------------------------------------------------------Set Overtime stuff--------------------------

    const handleOpenOvertimeDialog = () => {
        setOpenOvertimeDialog(true);
    };
    const handleCloseOvertimeDialog = () => {
        setOpenOvertimeDialog(false);
        dispatch(userActions.clearFetchedTeacherOvertimes())
        setSelectedOvertimeArray([])
        setCreateOvertimeData({start: "", end: ""})

    };


    const handleAddOvertime = (item) => {
        setSelectedOvertimeDay(item)
        handleOpenOvertimeDialog()
    }


    const handleSetOvertimeClick = (hour) => {
        if (!createOvertimeData.start) {
            const initialDate = selectedOvertimeDay.Date
            const monthString = initialDate.split(" ")[0]
            const month = months.indexOf(monthString)
            const day = parseInt(initialDate.split(" ")[1].split(",")[0])
            const year = parseInt(initialDate.split(" ")[2])
            let start_time = new Date(Date.UTC(year, month, day,
                hour, 0, 0))
            let start_time_iso = start_time.toISOString()
            setCreateOvertimeData({...createOvertimeData, start: start_time_iso})
            setSelectedOvertimeArray([...selectedOvertimeArray, hour])

        }
        if (createOvertimeData.start && !createOvertimeData.end) {
            const initialDate = selectedOvertimeDay.Date
            const monthString = initialDate.split(" ")[0]
            const month = months.indexOf(monthString)
            const day = parseInt(initialDate.split(" ")[1].split(",")[0])
            const year = parseInt(initialDate.split(" ")[2])
            let end_time = new Date(Date.UTC(year, month, day,
                hour + 1, 0, 0))

            let end_time_iso = end_time.toISOString()
            setCreateOvertimeData({...createOvertimeData, end: end_time_iso})
            let temp_array = []
            for (let i = selectedOvertimeArray[0]; i <= hour; i++) {
                temp_array.push(i)
            }
            setSelectedOvertimeArray(temp_array)

        }
    }

    const handleSetOvertimeSubmit = () => {
        if (createOvertimeData.start && createOvertimeData.end) {
            console.dir(createOvertimeData)
            dispatch(userActions.createTeacherOvertime(teacher_id, createOvertimeData))
            setCreateOvertimeData({start: "", end: ""})
            setSelectedOvertimeArray([])
        }
    }


//--------------------------------------------------------------Add Vacation Stuff-------------------------------------


    function calculateRange(data, time) {
        let array = []
        for (let i = data; i <= time; i++) { //TODO: need to seperate by week day
            array.push(i)//TODO: need to seperate by week day
        }
        return array
    }

    const onAddScheduleCellClick = (time, item) => { // TODO: FIX : 1 hour schhedule is not possible -- it is. just click on it again
        if (item.time.length > 0) {
            return
        }
        console.log(item)
        const week_day = item.Day
        const day = {...selectedSchedule[week_day]}
        console.log(day)
        if (selectedSchedule[week_day].start && !selectedSchedule[week_day].end) {
            day.end = time + 1
            setSelectedSchedule(state => ({...state, [week_day]: day}))
            let temp_array = []//TODO: need to seperate by week day
            for (let i = selectedScheduleArray[0]; i <= time; i++) { //TODO: need to seperate by week day
                temp_array.push(i)//TODO: need to seperate by week day
            }
            setSelectedScheduleArray(temp_array)//TODO: need to seperate by week day
            const tempIndex = teacherTableData.findIndex(item => (item.Day === week_day))
            dispatch(userActions.setSelectedTempTime(teacherTableData.findIndex(item => (item.Day === week_day)), calculateRange(
                teacherTableData[tempIndex].selectedTempTime[0], time
            ))) // set time array in store so component is colored


        } else {
            if (!selectedSchedule[week_day].start && !selectedSchedule[week_day].end) {
                day.start = time
                setSelectedSchedule(state => ({...state, [week_day]: day}))
                setSelectedScheduleArray([...selectedScheduleArray, time])//TODO: need to seperate by week day
                console.log(selectedScheduleArray)
                dispatch(userActions.setSelectedTempTime(teacherTableData.findIndex(item => (item.Day === week_day)), [time])) // set time array in store
                console.log()


            }
        }
    }

    const onAddScheduleSubmit = (item) => {
        const week_day = item.Day
        if (selectedSchedule[week_day].start && selectedSchedule[week_day].end) {
            const requestSchedule = {
                day_of_week: week_day,
                start_time: selectedSchedule[week_day].start,
                end_time: selectedSchedule[week_day].end
            }
            console.dir(requestSchedule)
            console.dir(teacher_id)
            setSelectedScheduleArray([])
            dispatch(userActions.clearSelectedTempTime(teacherTableData.findIndex(item => (item.Day === week_day))))
            dispatch(userActions.createTeacherSchedule(requestSchedule, teacher_id));
        }
    }

    const onDeleteSchedule = (item) => { //TODO: fix updating the schedule after successful deletion
        const week_day = item.Day
        if (selectedSchedule[week_day]) { // resetting the select schedule data after deleting in order for next add scheduels to show
            console.log(week_day)
            setSelectedSchedule(state => ({...state, [week_day]: {}}))
        }
        dispatch(userActions.deleteTeacherScheduleDay(week_day, teacher_id))
        dispatch(userActions.clearSelectedTempTime(teacherTableData.findIndex(item => (item.Day === week_day))))


    }


    return (
        <div>
            {/*{teacherTableData.length !== 0 && !addSchedule ?*/}
            {/*    <ShowTeacherWeeklySchedule handleGoNextWeek={handleGoNextWeek}*/}
            {/*                               handleGoPreviousWeek={handleGoPreviousWeek}*/}
            {/*                               rows={teacherTableData}*/}
            {/*                               teacher_schedule={teacher_schedule}*/}
            {/*                               handleAddVacation={handleAddVacation}*/}
            {/*                               handleAddOvertime={handleAddOvertime}*/}
            {/*                               allFetchingDone={allFetchingDone}*/}
            {/*                               sessions = {teacher_sessions}*/}
            {/*                               all_students={all_students}*/}
            {/*    /> : null}*/}
            {/*<AddVacationView openDialog={openDialog}*/}
            {/*                 handleCloseDialog={handleCloseDialog}*/}
            {/*                 vacationType={vacationType}*/}
            {/*                 handleVacationTypeChange={handleVacationTypeChange}*/}
            {/*                 handleSetVacationSubmit={handleSetVacationSubmit}*/}
            {/*                 item={selectedDay}*/}
            {/*                 handleVacationClick={handleVacationClick}*/}
            {/*                 selectedVacationArray={selectedVacationArray}*/}

            {/*/>*/}
            {/*<AddOvertimeView openOvertimeDialog={openOvertimeDialog}*/}
            {/*                 handleCloseOvertimeDialog={handleCloseOvertimeDialog}*/}
            {/*                 handleSetOvertimeSubmit={handleSetOvertimeSubmit}*/}
            {/*                 item={selectedOvertimeDay}*/}
            {/*                 handleSetOvertimeClick={handleSetOvertimeClick}*/}
            {/*                 selectedOvertimeArray={selectedOvertimeArray}*/}

            {/*/>*/}

            <AddTeacherWeeklySchedule selectedScheduleArray={selectedScheduleArray}
                                                     id={teacher_id}
                                                     rows={teacherTableData}
                                                     onAddScheduleCellClick={onAddScheduleCellClick}
                                                     onAddScheduleSubmit={onAddScheduleSubmit}
                                                     onDeleteSchedule={onDeleteSchedule}


            />

        </div>


    )
}
