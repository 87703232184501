import {userConstants} from '../Constants';
import produce from "immer";

const initialState = {
    created_student_id: null,
    student_creation_done: false,
    fetched_student: {},
    fetched_student_sessions: [],
    fetched_student_inspection_sessions:[],
    fetched_student_deposits: [],
    fetched_student_rents: [],
    student_session_creation_done: false,
    settings: {},
    fetching_student_notes_list: false,
    student_notes_list_fetch_done: false,
    student_notes_list: [],
    student_note_details: {},
    fetched_student_note_details : false,
    editing_note: false,
    edit_note_done: false,
    create_note_done:false,
    creating_note: false,
    note_deleted: false,
    deleting_note: false,

}

export function students(state = initialState, action) {
    switch (action.type) {

        case userConstants.GET_ALL_STUDENTS_REQUEST:
            return {
                ...state,
                getting_all_students: true,
                getting_all_students_done: false
            }

        case userConstants.GET_ALL_STUDENTS_SUCCESS:
            return {
                ...state,
                getting_all_students: false,
                getting_all_students_done: true,
                fetched_all_students: action.students.data
            }


        case userConstants.CREATE_STUDENT_REQUEST:
            return {...state, creating_student: true};
        case userConstants.CREATE_STUDENT_SUCCESS:
            return {
                ...state,
                created_student: action.user.data,
                student_creation_done: true,
                created_student_id: action.user.data.id
            };
        case userConstants.CREATE_STUDENT_FAILURE:
            return {...state, creating_student: false};

        case userConstants.GET_STUDENT_REQUEST:
            return {
                ...state,
                getting_student: true,
                fetched_student_done: false

            }
        case userConstants.GET_STUDENT_SUCCESS:
            return {
                ...state,
                fetched_student: action.student.data,
                getting_student: false,
                fetched_student_done: true
            }
        case userConstants.GET_STUDENT_FAILURE:
            return {...state};

        case userConstants.GET_STUDENT_SESSIONS_REQUEST:
            return {
                ...state,
                getting_student_sessions: true,
                fetched_student_sessions_done: false

            }
        case userConstants.GET_STUDENT_SESSIONS_SUCCESS:
            return {
                ...state,
                fetched_student_sessions:action.student_sessions.data,
                getting_student_sessions: false,
                fetched_student_sessions_done: true

            }
        case userConstants.GET_STUDENT_SESSIONS_FAILURE:
            return {...state};

        case userConstants.GET_DAY_SCHEDULE_REQUEST:
            return {
                ...state,
                getting_day_schedules: true
            }
        case userConstants.GET_DAY_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetched_day_schedules:action.day_schedules.data,
                getting_day_schedules: false
            }
        case userConstants.GET_DAY_SCHEDULE_FAILURE:
            return {...state};

        case userConstants.GET_DAY_SESSIONS_REQUEST:
            return {
                ...state,
                getting_day_sessions: true
            }
        case userConstants.GET_DAY_SESSIONS_SUCCESS:
            return {
                ...state,
                fetched_day_sessions:action.day_sessions.data,
                getting_day_sessions: false
            }
        case userConstants.GET_DAY_SESSIONS_FAILURE:
            return {...state};

        // case userConstants.CREATE_STUDENT_SESSION_REQUEST:
        //     return {
        //         ...state,
        //         creating_student_session: true,
        //         student_session_creation_done: false,
        //     }
        //
        // case userConstants.CREATE_STUDENT_SESSION_SUCCESS:
        //     return {
        //         ...state,
        //         created_student_session: action.session.data,
        //         student_session_creation_done: true,
        //         created_session_id: action.session.data.id
        //     };
        // case userConstants.CREATE_STUDENT_SESSION_FAILURE:
        //     return {...state};

        case userConstants.CLEAR_FETCHED_STUDENT:
            return {
                ...state,
                fetched_student: {}
            }

        case userConstants.DELETE_STUDENT_REQUEST:
            return {
                ...state,
                deleting_student: true,
                deleted_student_done: false,
            }

        case userConstants.DELETE_STUDENT_SUCCESS:
            return {
                ...state,
                deleting_student: false,
                deleted_student_done: true,
                deleted_student_id : action.student_id
            }

        case userConstants.CREATE_STUDENT_DEPOSIT_REQUEST:
            return {
                ...state,
                creating_student_deposit: true,
                created_student_deposit_done: false
            }

        case userConstants.CREATE_STUDENT_DEPOSIT_SUCCESS:
            return {
            ...state,
            creating_student_deposit: false,
            created_student_deposit_done: true
        }

        case userConstants.CREATE_STUDENT_DEPOSIT_FAILURE:
            return {
                ...state
            }

        case userConstants.GET_STUDENT_DEPOSITS_REQUEST:
            return {
                ...state,
                getting_student_deposits: true,
                fetched_student_deposits_done: false

            }

        case userConstants.GET_STUDENT_DEPOSITS_SUCCESS:
            return {
                ...state,
                getting_student_deposits: false,
                fetched_student_deposits_done: true,
                fetched_student_deposits: action.deposits.data
            }

        case userConstants.DELETE_STUDENT_DEPOSITS_REQUEST:
            return {
                ...state,
                deleting_student_deposit: true,
                deleted_student_deposit_done: false,

            }

        case userConstants.DELETE_STUDENT_DEPOSITS_SUCCESS:
            return {
                ...state,
                deleting_student_deposit: false,
                deleted_student_deposit_done: true,

            }







        case userConstants.CREATE_STUDENT_RENT_REQUEST:
            return {
                ...state,
                creating_student_rent: true,
                created_student_rent_done: false
            }

        case userConstants.CREATE_STUDENT_RENT_SUCCESS:
            return {
                ...state,
                creating_student_rent: false,
                created_student_rent_done: true
            }

        case userConstants.CREATE_STUDENT_RENT_FAILURE:
            return {
                ...state
            }

        case userConstants.GET_STUDENT_RENTS_REQUEST:
            return {
                ...state,
                getting_student_rents: true,
                fetched_student_rents_done: false

            }

        case userConstants.GET_STUDENT_RENTS_SUCCESS:
            return {
                ...state,
                getting_student_rents: false,
                fetched_student_rents_done: true,
                fetched_student_rents: action.rents.data
            }

        case userConstants.DELETE_STUDENT_RENT_REQUEST:
            return {
                ...state,
                deleting_student_rent: true,
                deleted_student_rent_done: false,

            }

        case userConstants.DELETE_STUDENT_RENT_SUCCESS:
            return {
                ...state,
                deleting_student_rent: false,
                deleted_student_rent_done: true,

            }



        // case userConstants.CREATE_STUDENT_INSPECTION_SESSION_REQUEST:
        //     return {
        //         ...state,
        //         creating_student_inspection_session: true
        //     }
        //
        // case userConstants.CREATE_STUDENT_INSPECTION_SESSION_SUCCESS:
        //     return {
        //         ...state,
        //         created_student_inspection_session: action.session.data,
        //         student_inspection_session_creation_done: true,
        //         student_session_creation_done: true,
        //         created_inspection_session_id: action.session.data.id
        //     };
        // case userConstants.CREATE_STUDENT_INSPECTION_SESSION_FAILURE:
        //     return {...state};


        case userConstants.GET_STUDENT_INSPECTION_SESSIONS_REQUEST:
            return {
                ...state,
                getting_student_inspection_sessions: true,
                fetched_student_inspection_sessions_done: false

            }
        case userConstants.GET_STUDENT_INSPECTION_SESSIONS_SUCCESS:
            return {
                ...state,
                fetched_student_inspection_sessions:action.student_sessions.data,
                getting_student_inspection_sessions: false,
                fetched_student_inspection_sessions_done: true

            }
        case userConstants.GET_STUDENT_INSPECTION_SESSIONS_FAILURE:
            return {...state};

        case userConstants.CLEAR_STUDENT_SESSION_CREATION_DONE: // setting the "done" to false after the session has been created and the page has been refreshed.
            //did this to fix calls to get student session on every render AFTER a session was successfully created
            return {
                ...state,
                student_session_creation_done: false,
                student_inspection_session_creation_done: false
            }

        case userConstants.EDIT_STUDENT_REQUEST:
            return {
                ...state,
                editing_student: true,
                student_edit_done: false
            }

        case userConstants.EDIT_STUDENT_SUCCESS:
            return {
                ...state,
                editing_student: false,
                student_edit_done: true,
                fetched_student: action.user.data
            }

        case userConstants.RESET_EDIT_STUDENT:
            return {
                ...state,
                editing_student: false,
                student_edit_done: false
            }

        case userConstants.GET_SETTINGS_REQUEST:
            return {
                ...state,
                fetching_settings: true,
            }

        case userConstants.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                fetching_settings: false,
                settings: action.settings.results[0]
            }

        case userConstants.GET_SETTINGS_FAILURE:
            return {
                ...state,
                fetching_settings: false
            }

        case userConstants.EDIT_SETTINGS_REQUEST:
            return {
                ...state,
                editing_settings: true,
                settings_edited:false,
            }
        case userConstants.EDIT_SETTINGS_SUCCESS:
            return {
                ...state,
                editing_settings: false,
                settings_edited:true,
                settings: action.settings
            }
        case userConstants.EDIT_SETTINGS_FAILURE:
            return {
                ...state,
                editing_settings: false,
                settings_edited:false,
            }


        case userConstants.GET_STUDENT_NOTES_LIST_REQUEST:
            return {
                ...state,
                fetching_student_notes_list: true,
                student_notes_list_fetch_done: false,
            }

        case userConstants.GET_STUDENT_NOTES_LIST_SUCCESS:
            return {
                ...state,
                fetching_student_notes_list: false,
                student_notes_list_fetch_done: true,
                student_notes_list: action.notes
            }

        case userConstants.GET_STUDENT_NOTES_LIST_FAILURE:
            return {
                ...state,
            }

        case userConstants.GET_STUDENT_NOTE_DETAILS_REQUEST:
            return {
                ...state,
                fetched_student_note_details: false
            }

        case userConstants.GET_STUDENT_NOTE_DETAILS_SUCCESS:
            return produce(state, draft => {
                draft.student_note_details[action.note.id] = action.note;
                draft.fetched_student_note_details = true;
            });

        case userConstants.EDIT_NOTE_REQUEST:
            return {
                ...state,
                editing_note: true,
                edit_note_done: false,
            }

        case userConstants.EDIT_NOTE_SUCCESS:
            return produce(state, draft => {
                draft.student_note_details[action.note.id] = action.note;
                draft.edit_note_done = true;
                draft.editing_note = false;
            });

        case userConstants.RESET_EDIT_NOTE_DONE:
            return {
                ...state,
                editing_note: false,
                edit_note_done: false
            }

        case userConstants.CREATE_NOTE_REQUEST:
            return {
                ...state,
                creating_note: false,
                create_note_done: false
            }

        case userConstants.CREATE_NOTE_SUCCESS:
            return produce(state, draft => {
                draft.student_note_details[action.note.id] = action.note;
                draft.create_note_done = true;
                draft.creating_note = false;
            });

        case userConstants.RESET_CREATE_NOTE_DONE:
            return {
                ...state,
                creating_note: false,
                create_note_done: false
            }

        case userConstants.DELETE_NOTE_REQUEST:
            return{
                ...state,
                deleting_note: true,
                note_deleted: false,
            }

        case userConstants.DELETE_NOTE_SUCCESS:
            return produce(state, draft => {
                delete draft.student_note_details[action.note_id]
                draft.note_deleted = true;
                draft.deleting_note = false;
            });

        case userConstants.DELETE_NOTE_FAILURE:
            return {
                ...state,
                deleting_note: false,
                note_deleted: false,
            }

        case userConstants.RESET_DELETE_NOTE_DONE:
            return {
                ...state,
                deleting_note: false,
                note_deleted: false,
            }




        default:
            return state

    }

}
