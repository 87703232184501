import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';

import {Link as RouterLink, Navigate, Redirect} from "react-router-dom";
import {userActions} from "../../Actions";
import {CircularProgress} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function RecoverPassword() {
    const classes = useStyles();
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const recoverStatus = useSelector(state => state.authentication.recoverPassword.status);

    // const [studentRedirect, setStudentRedirect] = React.useState(false);
    const dispatch = useDispatch();

    // reset login status
    // useEffect(() => {
    //     dispatch(userActions.logout());
    // }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (username) {
            dispatch(userActions.recoverPassword(username));

        }
    }


    // const renderRedirect = () => {
    //     // if (studentRedirect) {
    //     return <Navigate to={{pathname: '/student/create'}}/>
    //     // }
    // }



    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {recoverStatus === 'idle' ?'Enter your username or email to recover password' :
                        recoverStatus === 'succeeded'?
                        'A link for resetting your password has been sent to your email': 'user does not exist'}
                </Typography>
                {recoverStatus !== 'succeeded' ?

                    <form className={classes.form} noValidate name="form" >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username or Email"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={recoverStatus==='loading'}

                        >
                            {recoverStatus === 'loading' ? <CircularProgress size={24} className={classes.buttonProgress}/> : 'Recover'}                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link component={RouterLink}
                                      to="/login"
                                      variant="body2">
                                    Back to login
                                </Link>
                            </Grid>

                        </Grid>
                    </form>
                    : null }


            </div>

            {/*{loggedIn? renderRedirect(): null}*/}
        </Container>
    );
}
