import * as React from "react";
import {
    Box, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, colors,
    Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Divider,
    FormControl, FormControlLabel, FormLabel,
    InputAdornment,
    InputLabel,
    OutlinedInput, Radio, RadioGroup, TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import moment from "moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({

    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    main: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //     display: 'flex',
        //     flexWrap: 'wrap',
        //     flexDirection: 'column'
    },

    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
        // outline: 'none',
        // boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        // overflowY: 'auto',
        maxWidth: '100%'
    },
    field: {
        marginTop: theme.spacing(3)
    },
    cancelButton: {
        marginLeft: 'auto'
    },
    confirmButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}))


const truncateDecimals = function (number, digits) {
    let multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};


export default function AddDepositComponent({handleCloseAddDepositDialog,handleCreateDepositSubmit,createDeposit, handleCreateDepositChange, creatingDeposit}) {

    const classes = useStyles();

    const payment_types = [
        {value: 'paypal', label: 'Paypal'},
        {value: 'credit_card', label: 'Credit Card'},
        {value: 'debit_card', label: 'Debit Card'},
        {value: 'cheque', label: 'Cheque'},
        {value: 'cash', label: 'Cash'},
        {value: 'e_transfer', label: 'E-transfer'},

    ]

    return (


        <Card
            // className={clsx(classes.root, className)}
            // ref={ref}
        >
            <form>
                <CardContent>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h3"
                    >
                        Add a payment Record
                    </Typography>
                    <TextField
                        className={classes.field}
                        autoComplete="off"
                        id="deposit"
                        label="Deposit Amount"
                        variant="outlined"
                        name="deposit"
                        value={createDeposit.deposit}
                        onChange={handleCreateDepositChange}
                        startAdornment={<InputAdornment position="start">C$</InputAdornment>}
                        fullWidth
                        required
                        type="number"

                    />


                    <TextField
                        className={classes.field}
                        fullWidth
                        label="Type of Payment"
                        name="type"
                        required
                        select
                        SelectProps={{native: true}}
                        value={createDeposit.type}
                        onChange={handleCreateDepositChange}
                        variant="outlined"
                    >
                        {payment_types.map((option) => (
                            <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </TextField>

                    <TextField
                        autoComplete="off"
                        className={classes.field}
                        id="detail"
                        name="detail"
                        value={createDeposit.detail}
                        onChange={handleCreateDepositChange}
                        variant="outlined"
                        placeholder={"Payment Details(optional)"}
                        multiline
                        rows={3}
                        rowsMax={5}
                        fullWidth

                    />


                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        className={classes.cancelButton}
                        // onClick={onCancel}
                        variant="contained"
                        onClick={handleCloseAddDepositDialog}
                    >
                        Cancel
                    </Button>

                    <Button
                        className={classes.confirmButton}
                        onClick={handleCreateDepositSubmit}
                        variant="contained"
                    >
                        {creatingDeposit ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Submit'}
                    </Button>


                </CardActions>
            </form>
        </Card>

    )


}
