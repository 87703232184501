import React, {useEffect,} from 'react';
import { useParams,} from 'react-router-dom';
import {
    makeStyles,
    colors,
    Button,
    CircularProgress,
    Box,
    Dialog,
    Card,
    CardActions, CardHeader, Divider,
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import Page from "../../components/Page";
import {Header} from "./Components";
import {userActions} from "../../Actions";
import FieldSelect from "./Components/FieldSelect";
import {ArrowLeftIcon} from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import {PDFViewer} from "@react-pdf/renderer";
import InvoicePdf from "./Components/InvoicePdf";
import { PDFDownloadLink, } from '@react-pdf/renderer';

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        height: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    card:{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '12px',
            // margin: '0 auto',
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));


const ExportStudentData = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {id} = useParams();

    const [openPdfPreview, setOpenPdfPreview] = React.useState(false)
    const [fetchingDataDone, setFetchingDataDone] = React.useState(false)
    const [processDataDone, setProcessDataDone] = React.useState(false)
    const [student, setStudent] = React.useState({})
    const [exportFilter, setExportFilter] = React.useState(['info','sessions','payments','rents'])
    const [renderPreiviewToggle, setRenderPreiviewToggle] = React.useState(false)

    const fetched_student = useSelector(state => state.students.fetched_student);
    const fetched_student_sessions = useSelector(state => state.students.fetched_student_sessions);
    const fetched_student_inspection_sessions = useSelector(state => state.students.fetched_student_inspection_sessions);
    const fetched_student_deposits = useSelector(state => state.students.fetched_student_deposits)
    const fetched_student_rents = useSelector(state => state.students.fetched_student_rents)
    const fetched_teachers_list = useSelector(state => state.teachers.fetched_teachers_list);


    const fetched_student_done = useSelector(state => state.students.fetched_student_done)
    const fetched_student_sessions_done = useSelector(state => state.students.fetched_student_sessions_done)
    const fetched_student_inspection_sessions_done = useSelector(state => state.students.fetched_student_inspection_sessions_done)
    const fetched_student_deposits_done = useSelector(state => state.students.fetched_student_deposits_done)
    const fetched_student_rents_done = useSelector(state => state.students.fetched_student_rents_done)
    const fetched_teachers_list_done = useSelector(state => state.teachers.fetched_teachers_list_done)


    function handleToggleDialog(e) {
        e.preventDefault()
        setOpenPdfPreview(state => !state)
        setRenderPreiviewToggle(state => !state)
    }

    function handleFilerChange(filter){
        if(exportFilter.includes(filter)){
            setExportFilter(exportFilter.filter(item => item !== filter))
        }else{
            setExportFilter(state => [...state, filter])
        }
    }

    useEffect(() => {
        dispatch(userActions.getStudent(id))
        dispatch(userActions.getStudentSessions(id))
        dispatch(userActions.getStudentInspectionSessions(id))
        dispatch(userActions.getStudentDeposits(id))
        dispatch(userActions.getStudentRents(id))
        dispatch(userActions.getTeachersList())
    }, [])

    useEffect(() => { // fetches the data
        if (
            fetched_student_done &&
            fetched_student_sessions_done &&
            fetched_student_inspection_sessions_done &&
            fetched_student_deposits_done &&
            fetched_student_rents_done &&
            fetched_teachers_list_done &&
            Object.keys(student).length === 0
        ) {
            setFetchingDataDone(true)
        }
    }, [fetched_student_deposits_done,
        fetched_student_done,
        fetched_student_inspection_sessions_done,
        fetched_student_rents_done,
        fetched_student_sessions_done,
        fetched_teachers_list_done,
    ])

    useEffect(() => {
        if (fetchingDataDone === true) {
            setStudent(state => ({
                ...state,
                info: fetched_student,
                fetched_student_sessions: fetched_student_sessions,
                fetched_student_inspection_sessions: fetched_student_inspection_sessions,
                fetched_student_deposits: fetched_student_deposits,
                fetched_student_rents: fetched_student_rents,
                fetched_teachers_list: fetched_teachers_list,
            }));
            setProcessDataDone(true)
        }
    }, [fetchingDataDone])


    return (
        <Page
            className={classes.root}
            title="Export Student Data"
        >
            <Header/>

            <Card className={classes.card}>
                <CardHeader
                    subheader="Choose the fields you wish to export"
                    title="Export Options"
                />
                <Divider />
                <FieldSelect
                             handleFilerChange={handleFilerChange}
                             exportFilter={exportFilter}
                />
                <Divider />

                <CardActions>
                    <Button color="primary" onClick={handleToggleDialog}
                            disabled={Object.keys(student).length !== 6}
                            variant={!processDataDone ? "contained" : "outlined"}>
                        {!processDataDone ? <CircularProgress size={24} className={classes.button}/> : 'Preview PDF'}
                    </Button>
                    {processDataDone?
                        <PDFDownloadLink
                            document={<InvoicePdf student={student} exportFilter={exportFilter}/>}
                            fileName={`${student.info.first_name}_${student.info.last_name}_export`}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button color="primary"
                                    disabled={Object.keys(student).length !== 6}
                                    variant={"contained"}>
                                {!processDataDone ? <CircularProgress size={24} className={classes.button}/> : 'Download PDF'}
                            </Button>

                        </PDFDownloadLink>
                        : null}
                </CardActions>
            </Card>






            {processDataDone && renderPreiviewToggle &&
            <Dialog open={openPdfPreview} fullScreen>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: 'background.default',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            startIcon={<ArrowLeftIcon fontSize="small"/>}
                            onClick={handleToggleDialog}
                            variant="contained"
                        >
                            Back
                        </Button>
                    </Box>

                    <Box style={{ flexGrow: 1 }}>
                        <PDFViewer
                            height="100%"
                            style={{border: 'none'}}
                            width="100%"
                        >
                            <InvoicePdf student={student} exportFilter={exportFilter}/>
                        </PDFViewer>
                    </Box>
                </Box>
            </Dialog>

            }




        </Page>
    );
};

ExportStudentData.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default ExportStudentData;
