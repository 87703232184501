import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useNavigate} from 'react-router-dom';
import {

    makeStyles,
    colors,
    Grid,

} from '@material-ui/core';
import Page from "../../components/Page";
import {userActions} from "../../Actions";
import {useDispatch, useSelector} from "react-redux";
import {Expenses, Payments} from "./components";

import moment from "moment";
import Header from "./components/Header";
import PaymentsSelect from "./components/PaymentsSelect";
import ExpensesSelect from "./components/ExpensesSelect";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

function returnDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('/');
}

function returnIsoDate() {
    return new Date().toISOString()
}


const Accounting = () => {
    const classes = useStyles();
    const {status} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const deposits = useSelector(state => state.teachers.fetched_deposits)
    const [start, setStart] = React.useState(moment().subtract(30, 'days'))
    const [startIso, setStartIso] = React.useState(moment().subtract(30, 'days').toISOString())
    const [end, setEnd] = React.useState(moment())
    const [endIso, setEndIso] = React.useState(moment().toISOString())
    const [calendarDate, setCalendarDate] = useState(moment());
    const [selectEdge, setSelectEdge] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false)
    const [selectedDepositDetailId, setSelectedDepositDetailId] = React.useState('')
    const userType = useSelector(state => state.authentication.userType)

    function handleOpenDetailsDialog(deposit_id) {
        setSelectedDepositDetailId(deposit_id)
        setOpenDetailsDialog(true)
    }

    function handleCloseDetailsDialog() {
        setSelectedDepositDetailId('')
        setOpenDetailsDialog(false)
    }

    function handleStartChange(input) {
        const date = new Date(input)
        const UTCDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
        setStartIso(UTCDate.toISOString())
        setStart(input)
    }

    function handleEndChange(input) {
        const date = new Date(input)
        const UTCDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
        setEndIso(UTCDate.toISOString())
        setEnd(input)

    }

    useEffect(() => {
        dispatch(userActions.getAllDeposits(startIso, endIso))
    }, [startIso, endIso])

    const truncateDecimals = function (number, digits) {
        let multiplier = Math.pow(10, digits),
            adjustedNum = number * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

        return truncatedNum / multiplier;
    };

    function calculateSum(deposits) {
        let sum = 0
        for (var i = 0; i < deposits.length; i++) {
            sum += parseFloat(deposits[i].deposit)

        }

        return truncateDecimals(sum, 2)

    }

    function handleCalendarOpen(edge) {
        if (selectEdge === 'start') {
            setCalendarDate(moment(start))
        } else {
            setCalendarDate(moment(end))
        }
        setSelectEdge(edge);

    }

    const handleCalendarClose = () => {
        setCalendarDate(moment());
        setSelectEdge(null);
    };

    const handleCalendarAccept = date => {
        setCalendarDate(moment());

        if (selectEdge === 'start') {
            setStart(moment(date));
            setStartIso(moment(date).toISOString())

            if (moment(date).isAfter(end)) {
                setEnd(moment(date));
                setEndIso(moment(date).toISOString())
            }
        } else {
            setEnd(moment(date));
            setEndIso(moment(date).toISOString())

            if (moment(date).isBefore(start)) {
                setStart(moment(date));
                setStartIso(moment(date).toISOString())
            }
        }

        setSelectEdge(null);
    };

    const handleCalendarChange = date => {
        setCalendarDate(date);
    };
    const open = Boolean(selectEdge); // calendar open


    // expenses:

    function handleNavigatePages(page){
        navigate(`/app/accounting/${page}`,)

    }

    return (
        <Page
            className={classes.root}
            title="Accounting"
        >
            <Header />
            <br/>
            <Grid container spacing={3}>

                {userType ==='admin' &&
                <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <PaymentsSelect handleNavigatePages={handleNavigatePages} isselected={status ==='payments'}/>


                </Grid>

                }


                <Grid
                    item
                    lg={userType ==='admin'? 6 : 12}
                    sm={userType ==='admin'?6: 12}
                    xs={12}
                >
                    <ExpensesSelect handleNavigatePages={handleNavigatePages} isselected={status ==='expenses'}/>
                </Grid>


                <Grid item xs={12} md={12} lg={12}>
                    {
                        deposits.length > 0 && status ==='payments' && userType === 'admin'?
                            <Payments
                                handleOpenDetailsDialog={handleOpenDetailsDialog}
                                calculateSum={calculateSum}
                                start={start}
                                end={end}
                                calendarDate={calendarDate}
                                selectEdge={selectEdge}
                                handleCalendarOpen={handleCalendarOpen}
                                handleStartChange={handleStartChange}
                                handleEndChange={handleEndChange}
                                handleCalendarAccept={handleCalendarAccept}
                                handleCalendarChange={handleCalendarChange}
                                handleCalendarClose={handleCalendarClose}
                                open={open}
                                deposits={deposits}
                                openDetailsDialog={openDetailsDialog}
                                handleCloseDetailsDialog={handleCloseDetailsDialog}
                                selectedDepositDetailId={selectedDepositDetailId}

                            />
                            : null
                    }

                    {status ==='expenses' ? <Expenses /> : null}
                </Grid>
            </Grid>
        </Page>
    );
};

Accounting.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default Accounting;
