import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {
    Backdrop,
    Box, Grid, Hidden, Menu, MenuItem, Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Typography,
} from "@material-ui/core";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '30px',
    },
    table: {
        borderRight: '1px solid'
    },
    free: {
        backgroundColor: '#58D68D',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
        "&:hover": {
            backgroundColor: '#48add6',
        },

    },
    ellipsis: {
        textAlign: 'center',
        fontSize: 'small',
        // maxWidth: 10, // percentage also works
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    vacation: {
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,

    },
    overtime: {
        backgroundColor: '#F0B27A',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    noSchedule:
        {
            borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
    noScheduleDate:
        {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            position: 'sticky',
            background: '#FFF',
            left: 1,
            zIndex: 1,
        },

    vacationButton: {
        backgroundColor: '#616A6B',
        // color : '#53a9cb'
    },
    overtimeButton: {
        backgroundColor: '#F0B27A'
    },
    freeButton: {
        backgroundColor: '#58D68D',

    },
    addVacationButton: {
        color: '#384040',
    },
    addOvertimeButton: {
        color: '#F0B27A',
    },

    selectedSession: {
        backgroundColor: '#3494c1',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    tableRoot: {
        height: '100%',
        overflowX: 'auto',
        marginRight: 'auto',
        // marginLeft: 'auto',
        marginTop: '50px',
        // padding: '10px',
        // margin: '10px',


    },
    tableLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    dateCell: {
        // position: '-webkit-sticky',
        position: 'sticky',
        background: '#FFF',
        left: 0,
        zIndex: 1,
    },
    button: {
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    RightButton: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    }


}));


const wtf = [

    {

        inspection: [],
        sessions: [],
        over: [],
        vacation: [],

    }

]


export default function ShowTeacherWeeklyScheduleForSessions({
                                                                 rows,
                                                                 handleGoNextWeek,
                                                                 handleGoPreviousWeek,
                                                                 allFetchingDone,
                                                                 handleAddSessionClick,
                                                                 sessions,
                                                                 all_students,
                                                                 inspection_sessions,
                                                                 no_add,
                                                                 unProcessed_weeks,
                                                                 isReadOnly,
                                                                 //NEW
                                                                 handleEditScheduleToggle,
                                                                 currentWeek,

                                                             }) {
    const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const classes = useStyles();
    let {type} = useParams();

    const [state, setState] = React.useState({
        mouseX: null,
        mouseY: null,
    });

    const handleSessionCellHover = (event) => {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        // console.log(state.mouseX)
    };

    const handleClose = () => {
        setState({
            mouseX: null,
            mouseY: null,
        });
    };


    useEffect(() => {

    }, [rows]);

    function returnStudentName(sessions, date, time, isInspection) {
        if (isInspection) { // Temproary
            return '*Inspection*'
        } else {
            const newDate = new Date(date).toUTCString()
            const timeTest = newDate.split(' ')
            const newNewDate = timeTest[0].split(',')[0] + ' ' + timeTest[2] + ' ' + timeTest[1] + ' ' + timeTest[3]
            let sessionIndex
            sessionIndex = sessions.findIndex(
                session => new Date(session.start).toDateString() === newNewDate
                    && time >= new Date(session.start).getUTCHours() && time < new Date(session.end).getUTCHours()
            )
            return sessionIndex >= 0 ?
                sessions[sessionIndex].student.first_name + ' ' + sessions[sessionIndex].student.last_name
                : null
        }
    }

    function setCellColor(item, hour) {
        return item.selectedSessions.includes(hour) ? classes.selectedSession :
            item.vacations.includes(hour) ? classes.vacation :
                item.overtimes.includes(hour) ? classes.overtime :
                    item.overtimes.includes(hour) ? classes.overtime :
                        item.time.includes(hour) && !item.vacations.includes(hour) ? classes.free :
                            classes.noSchedule
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {/*<Grid container spacing={9}>*/}
                        <Grid item>
                            <Hidden smUp>
                                <Button
                                    disabled={unProcessed_weeks.length !== 0}
                                    onClick={handleGoPreviousWeek}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<NavigateBeforeIcon/>}
                                >
                                    Prev
                                </Button>
                            </Hidden>

                            <Hidden xsDown>
                                <Button
                                    disabled={unProcessed_weeks.length !== 0}
                                    onClick={handleGoPreviousWeek}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<NavigateBeforeIcon/>}
                                    className={classes.button}
                                >
                                    Previous Week
                                </Button>
                            </Hidden>

                        </Grid>

                        <Grid item>
                            <Hidden xsDown>
                                <div>
                                    <Button
                                        variant="contained"
                                        className={classes.freeButton}
                                        startIcon={<AlarmOnIcon/>}
                                    >
                                        Free
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.vacationButton}
                                        startIcon={<TimerOffIcon/>}
                                    >
                                        Off
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.overtimeButton}
                                        startIcon={<AlarmAddIcon/>}
                                    >
                                        Overtime
                                    </Button>
                                </div>
                            </Hidden>
                        </Grid>


                        <Grid item>
                            <Hidden smUp>
                                <Button
                                    disabled={unProcessed_weeks.length !== 0}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<NavigateNextIcon/>}
                                    onClick={handleGoNextWeek}
                                >
                                    Next
                                </Button>
                            </Hidden>

                            <Hidden xsDown>
                                <Button
                                    disabled={unProcessed_weeks.length !== 0}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<NavigateNextIcon/>}
                                    onClick={handleGoNextWeek}
                                    className={classes.RightButton}
                                >
                                    Next Week
                                </Button>
                            </Hidden>

                        </Grid>


                    </div>
                </Grid>

                <Grid item xs={12} md={12} xl={12}>
                    <TableContainer className={allFetchingDone ? classes.tableRoot : classes.tableLoading}>
                        {allFetchingDone ?
                            <Table style={{whiteSpace: 'normal'}}>
                                <TableHead>
                                    <TableRow>
                                        {/*<Hidden xsDown>*/}
                                        {/*    <TableCell className={classes.tableCell}>Day</TableCell>*/}
                                        {/*</Hidden>*/}
                                        <TableCell className={classes.noScheduleDate}>Date</TableCell>
                                        <TableCell className={classes.noSchedule}>07-08</TableCell>
                                        <TableCell className={classes.noSchedule}>08-09</TableCell>
                                        <TableCell className={classes.noSchedule}>09-10</TableCell>
                                        <TableCell className={classes.noSchedule}>10-11</TableCell>
                                        <TableCell className={classes.noSchedule}>11-12</TableCell>
                                        <TableCell className={classes.noSchedule}>12-13</TableCell>
                                        <TableCell className={classes.noSchedule}>13-14</TableCell>
                                        <TableCell className={classes.noSchedule}>14-15</TableCell>
                                        <TableCell className={classes.noSchedule}>15-16</TableCell>
                                        <TableCell className={classes.noSchedule}>16-17</TableCell>
                                        <TableCell className={classes.noSchedule}>17-18</TableCell>
                                        <TableCell className={classes.noSchedule}>18-19</TableCell>
                                        <TableCell className={classes.noSchedule}>19-20</TableCell>
                                        <TableCell className={classes.noSchedule}>20-21</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {
                                        rows && allFetchingDone ?
                                            rows.map((item, index) => (

                                                <TableRow
                                                    hover
                                                    key={index}
                                                >


                                                    {/*<Hidden xsDown>*/}
                                                    {/*    <TableCell key={index}*/}
                                                    {/*               className={classes.tableCell}> {item.Day} </TableCell>*/}

                                                    {/*</Hidden>*/}
                                                    <TableCell className={classes.dateCell} key={index}
                                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                                                    >
                                                        {item.simpleDate}
                                                    </TableCell>

                                                    {hours.map((hour, index) =>

                                                        <TableCell key={index}
                                                                   onMouseEnter={item.sessions.includes(hour)? handleSessionCellHover : null}
                                                                   onClick={() => !isReadOnly ? handleAddSessionClick(hour, item) : null}
                                                                   className={setCellColor(item, hour)}


                                                        >
                                                            <Typography className={classes.ellipsis}>
                                                                {item.inspection_sessions.includes(hour) &&
                                                                allFetchingDone ? returnStudentName(
                                                                    inspection_sessions,
                                                                    item.Date, hour,
                                                                    true
                                                                ) : null}
                                                                {item.sessions.includes(hour) && allFetchingDone ?
                                                                    returnStudentName(sessions,
                                                                        item.Date,
                                                                        hour
                                                                    ) : null}
                                                            </Typography>
                                                        </TableCell>
                                                    )}
                                                    {type === 'full_schedule'?
                                                        <TableCell>
                                                            <Button variant={"outlined"} onClick={()=>handleEditScheduleToggle(item, sessions, inspection_sessions, currentWeek)}>
                                                                Edit
                                                            </Button>
                                                        </TableCell>
                                                        : null}


                                                </TableRow>
                                            ))
                                            : rows && !allFetchingDone ?
                                            <Paper style={{height: '100%'}}>
                                                <CircularProgress style={{alignSelf: 'center'}}/>
                                            </Paper>
                                            : null
                                    }
                                </TableBody>
                            </Table>
                            : <CircularProgress style={{alignSelf: 'center'}}/>}

                    </TableContainer>

                    {/*<Menu*/}
                    {/*    keepMounted*/}
                    {/*    open={state.mouseY !== null}*/}
                    {/*    onClose={handleClose}*/}
                    {/*    anchorReference="anchorPosition"*/}
                    {/*    anchorPosition={*/}
                    {/*        state.mouseY !== null && state.mouseX !== null*/}
                    {/*            ? { top: state.mouseY, left: state.mouseX }*/}
                    {/*            : undefined*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    oddfuiuytjdtjui;ouytfhiuyuyfg*/}
                    {/*</Menu>*/}


                </Grid>


            </Grid>

            {/*<Box>*/}
            {/*    /!*<div style={{display: 'flex', justifyContent: 'space-between'}}>*!/*/}
            {/*    /!*    <div>*!/*/}
            {/*    /!*        <Button*!/*/}
            {/*    /!*            disabled={!allFetchingDone}*!/*/}
            {/*    /!*            onClick={handleGoPreviousWeek}*!/*/}
            {/*    /!*            variant="contained"*!/*/}
            {/*    /!*            color="primary"*!/*/}
            {/*    /!*            startIcon={<NavigateBeforeIcon/>}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            Previous Week*!/*/}
            {/*    /!*        </Button>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*    <div>*!/*/}
            {/*    /!*        <Button*!/*/}
            {/*    /!*            variant="contained"*!/*/}
            {/*    /!*            className={classes.freeButton}*!/*/}
            {/*    /!*            startIcon={<AlarmOnIcon/>}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            Free*!/*/}
            {/*    /!*        </Button>*!/*/}
            {/*    /!*        <Button*!/*/}
            {/*    /!*            variant="contained"*!/*/}
            {/*    /!*            className={classes.vacationButton}*!/*/}
            {/*    /!*            startIcon={<TimerOffIcon/>}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            Off*!/*/}
            {/*    /!*        </Button>*!/*/}
            {/*    /!*        <Button*!/*/}
            {/*    /!*            variant="contained"*!/*/}
            {/*    /!*            className={classes.overtimeButton}*!/*/}
            {/*    /!*            startIcon={<AlarmAddIcon/>}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            Overtime*!/*/}
            {/*    /!*        </Button>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*    <div>*!/*/}
            {/*    /!*        <Button*!/*/}
            {/*    /!*            disabled={!allFetchingDone}*!/*/}
            {/*    /!*            variant="contained"*!/*/}
            {/*    /!*            color="primary"*!/*/}
            {/*    /!*            endIcon={<NavigateNextIcon/>}*!/*/}
            {/*    /!*            onClick={handleGoNextWeek}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            Next Week*!/*/}
            {/*    /!*        </Button>*!/*/}
            {/*    /!*    </div>*!/*/}

            {/*    /!*</div>*!/*/}


            {/*</Box>*/}
        </div>
    );
}
