import React from 'react';
import {Navigate} from 'react-router-dom';
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from './layouts/MainLayout';
import DashboardView from './views/reports/DashboardView';
import NotFoundView from './views/errors/NotFoundView';
import ProductListView from './views/product/ProductListView';
import RegisterView from './views/auth/RegisterView';
import StudentsListContainer from "./Containers/Student/Containers/StudentsListContainer";
import CreateStudent from "./Containers/Student/CreateStudent";
import LoginView from "./Components/LoginView";
import WeekTableContainer from "./Containers/TableContainer/WeekTableContainer";
import Profile from "./views/StudentProfile";
import Settings from "./views/Settings";
import Accounting from "./views/accounting";
import TeachersView from "./views/teachersView";
import Certificate from "./views/Certificate";
import TeacherProfile from "./views/TeacherProfile";
import TeacherManagementView from "./views/TeacherManagementView";
import ExportStudentData from "./views/PrintStudentdata/ExportStudentData";
import RecoverPassword from "./views/auth/RecoverPassword";
import ChangePassword from "./views/auth/ChangePassword";


const routes = (isLoggedIn, userType) => [
    {
        path: 'app',
        element: userType === 'admin' || userType === 'staff' ?
            <DashboardLayout/> :
             userType === 'teacher' ?
                <Navigate to="/teacher/sessions"/> :
                 userType === 'anonymous'?
                <Navigate to="/login"/> : null
        ,
        children: [
            {path: 'account/:id/:tab', element: <Profile/>},
            {path: 'exportStudentData/:id', element: <ExportStudentData/>},

            {path: 'certificate/:id/:first/:last', element: <Certificate/>},

            {path: 'account/:id/:tab/notes', element: <Profile note={true}/>},
            {path: 'account/:id/:tab/notes/:note_id', element: <Profile note={true}/>},
            {path: 'list', element: <StudentsListContainer/>},
            {path: 'teachers_schedule/:type', element: <WeekTableContainer/>},
            {path: 'teacher/:teacherId/:type', element: <TeacherProfile/>},

            {path: 'create_student', element: <CreateStudent/>},
            {path: 'teachers/:action/', element: <TeacherManagementView/>},
            {path: 'account/:id/add_student_session/:type', element: <WeekTableContainer/>},
            {path: 'accounting', element: <Accounting/>},
            {path: 'accounting/:status', element: <Accounting/>},
            {path: 'accounting/:status/:selectedObject/', element: <Accounting/>},
            {path: 'accounting/:status/:selectedObject/expenses/:selectedExpense', element: <Accounting/>},
            {path: 'profile/:id/:tab', element: <Profile note={true}/>},
            {path: 'settings/:tab', element: <Settings/>},
            {path: 'dashboard', element: <DashboardView/>},
            {path: 'products', element: <ProductListView/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'teacher', element: <TeachersView/>},
            {path: 'teacher/:tab/:selectedObject/', element: <TeachersView/>},
            {path: 'teacher/:tab/:selectedObject/expenses/:selectedExpense', element: <TeachersView/>},
            {path: 'teacher/:tab', element: <TeachersView/>}, // action could be : 'confirm', 'view', 'addNote', etc
            {path: 'teacher/:tab/:sessionId/:action', element: <TeachersView/>}, // action could be : 'confirm', 'view', 'addNote', etc
            {path: 'teacher/:tab/view/:id', element: <TeachersView/>},

            {path: 'login', element: <LoginView/>},
            {path: 'recoverPassword', element: <RecoverPassword/>},
            {path: 'password/recovery/:code/:id', element: <ChangePassword/>},

            {path: 'register', element: <RegisterView/>},
            {path: '404', element: <NotFoundView/>},
            {path: '/', element: <Navigate to="/app/list"/>},
            {path: '*', element: <Navigate to="/app/list"/>}
        ]
    }
];

export default routes;
