import React, {useEffect} from 'react';
import {useNavigate, useRoutes} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core';
import './App.css';
import {useDispatch, useSelector} from "react-redux";
import routes from "./routes";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import ErrorAlert from "./Helpers/error-alert";
import {userService} from "./Services";
import {userActions} from "./Actions";

function App() {
    const navigate = useNavigate();
    let BooleanAlert = useSelector(state => state.alert.type)
    let alertType = useSelector(state => state.alert.type)
    let alertMessage = useSelector(state => state.alert.message)
    const [message, setMessage] = React.useState('')
    const [openAlert, setOpenAlert] = React.useState(false)
    const [isLoggedIn, setIsLoggedIn] = React.useState(true)
    const [userType, setUserType] = React.useState(null)
    let user = JSON.parse(localStorage.getItem('user'));
    const routing = useRoutes(routes(isLoggedIn, userType));
    const dispatch = useDispatch();


    useEffect(()=>{
        dispatch(userActions.setUserType(userType));


    }, [userType])
    useEffect(()=>{
        if(user){
            if(user.is_superuser && user.is_staff){
                setIsLoggedIn(true)
                setUserType('admin')
            }

            if(user.is_staff && !user.is_superuser){
                setIsLoggedIn(true)
                setUserType('staff')
            }

            if(user.is_teacher){
                setIsLoggedIn(true)
                setUserType('teacher')
            }

            if(user.is_anonymous){
                setIsLoggedIn(false)
                setUserType('anonymous')
            }


        }
        else{
            localStorage.setItem('user', JSON.stringify({
                email: "",
                first_name: "",
                // id: 1,
                // is_staff: true,
                // is_superuser: true,
                // is_teacher: false,
                is_anonymous: true,
                last_name: "",
                // token: "9c42cf5ca0d12aaa1b2d76acf0230203dcb64fc8",
                username: "anonymous",
            }));
            setUserType('anonymous')
            setIsLoggedIn(false)
            // userService.logout()
            // navigate(`login`)
        }
    }, [user])

    useEffect(()=>{
        if(BooleanAlert){
            setOpenAlert(true)
            if(alertMessage){
                setMessage(alertMessage)
            }
        }
    }, [BooleanAlert,alertMessage])

    useEffect(()=>{
        if(openAlert){
            setTimeout(()=> {
                setOpenAlert(false)
            }, 6000)
        }

    }, [openAlert])


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
            {openAlert ? <ErrorAlert open={openAlert} alertMessage={message} severity={alertType ==='alert-danger'? 'error' : 'success'}/> : null}

        </ThemeProvider>

    );
}

export default App;
