import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, Typography, Avatar,makeStyles } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import gradients from "../../../../utils/gradients";

const useStyles = makeStyles(theme => ({
  root: {
    color: props => props.isselected
        ?theme.palette.white : null ,
    backgroundColor: props => props.isselected
        ? theme.palette.primary.main
        : null,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundImage: gradients.orange,
    color: theme.palette.primary.main,
    height: 48,
    width: 48
  }
}));

const ExpensesSelect = props => {
  const { className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      onClick={()=> {props.handleNavigatePages('expenses')}}
    >
      <div>
        <Typography
          color="inherit"
          component="h3"
          gutterBottom
          variant="overline"
        >
          Expenses
        </Typography>
        <div className={classes.details}>
          <Typography
            color="inherit"
            variant="h3"
          >
            Your Expenses
          </Typography>
        </div>
      </div>
      <Avatar
        className={classes.avatar}
        color="inherit"
      >
        <AttachMoneyIcon />
      </Avatar>
    </Card>
  );
};


ExpensesSelect.propTypes = {
  className: PropTypes.string
};

export default ExpensesSelect;
