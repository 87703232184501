import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Button,makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import {Link as RouterLink} from "react-router-dom";
import {ArrowLeft, Mail, Printer} from "react-feather";

const useStyles = makeStyles(theme => ({
  root: {},
  getAppIcon: {
    marginRight: theme.spacing(1)
  },
  buttons:{
    marginLeft: theme.spacing(1)
  }
}));

const Header = props => {
  const {id,loading,handlePrintCertificate,downloadPDF,UploadAndEmailPDF, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={id? `/app/account/${id}/sessions` : `/app/list`}
              startIcon={<ArrowLeft />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handlePrintCertificate}
          >
            <Printer className={classes.getAppIcon} />
            Print
          </Button>
          <Button
              color="primary"
              variant="contained"
              onClick={downloadPDF}
              className={classes.buttons}
          >
            <GetAppIcon className={classes.getAppIcon} />
            Download
          </Button>
          <Button
              color="primary"
              variant="contained"
              className={classes.buttons}
              onClick={UploadAndEmailPDF}
              disabled={loading}
          >
            <Mail className={classes.getAppIcon}/>
            Send Email
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
