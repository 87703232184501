import React, {useEffect, useState} from 'react';
import {
    makeStyles,
    colors,
    Grid,
    CardHeader,
    Button,
    Typography,
    Divider,
    CardContent,
    List,
    ListItem, ListItemIcon, ListItemText, Tooltip, IconButton, CardActions, Card,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import {Link as RouterLink} from "react-router-dom";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import moment from "moment";
import {Label} from "../../../../../accounting/components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";


const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        // marginTop: theme.spacing(3)
    },
    object: {
        fontWeight: theme.typography.fontWeightMedium
    },

    createCardActions: {
        justifyContent: 'space-around'
    },


    listItem: {
        padding: theme.spacing(2, 0),
        justifyContent: 'space-between'
    },
    listItemDetails: { // Details
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        paddingBottom: 0
    },
    deleteButton: {
        color: '#E30425'
    },
    createSubmitButton: {
        color: colors.green[600]
    },
    selectedListItem: {
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.white
    },

    normalListItem: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selectedListItemText: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightMedium
    },
    selectedListItemDetails: {
        color: theme.palette.white,
        // fontWeight: theme.typography.fontWeightMedium
    },
    addObjectText: {
        minWidth: '100%'
    }
}));


const ExpensesList = ({handleAddExpenseToggle, objects, selectedObject, objects_fetch_status, selectedExpense}) => {
    const classes = useStyles();
    // const {status} = useParams();
    //
    // const navigate = useNavigate();
    // const dispatch = useDispatch();


    return (
        <Card
        >
            <CardHeader
                action={<Button
                    color="primary"
                    size="small"
                    onClick={handleAddExpenseToggle}
                >
                    <AddIcon className={classes.addIcon}/>
                    Add
                </Button>}
                title={(<div>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Typography variant={"h5"} style={{marginRight: 10}}>
                            {
                                objects[selectedObject] ? objects[selectedObject].name + ' expenses:'
                                    : 'Expenses'
                            }
                        </Typography>

                    </div>

                </div>)}
            >
            </CardHeader>
            <Divider/>
            <CardContent className={classes.content}>
                {
                    // objects[selectedObject] && Object.keys(objects[selectedObject]?
                    //     objects[selectedObject].expenses: null).length > 0 ?
                    Object.keys(objects).includes(selectedObject) && objects_fetch_status === 'succeeded'
                        // Object.keys(objects[selectedObject].expenses).includes(selectedExpense)
                        ?

                        <List>
                            {Object.keys(objects[selectedObject].expenses) ?
                                Object.keys(objects[selectedObject].expenses).map((key, index) => (

                                    <ListItem
                                        className={parseInt(key) === parseInt(selectedExpense) ? classes.selectedListItem : classes.normalListItem}
                                        divider={index < Object.keys(objects[selectedObject].expenses) - 1}
                                        key={key}
                                        component={RouterLink}
                                        to={`/teacher/expenses/${selectedObject}/expenses/${key}`}
                                    >
                                        <ListItemIcon> <ReceiptOutlinedIcon/> </ListItemIcon>

                                        <ListItemText>
                                            <Typography
                                                className={parseInt(key) === parseInt(selectedExpense) ? classes.selectedListItemDetails : null}
                                                variant="h6"
                                            >
                                                {objects[selectedObject].expenses[key].title}
                                            </Typography>
                                            <Typography variant="body2"
                                                        className={parseInt(key) === parseInt(selectedExpense) ? classes.selectedListItemDetails : null}>
                                                {moment(objects[selectedObject].expenses[key].updated_at).format('DD/MM/YYYY | hh:mm')}
                                            </Typography>
                                        </ListItemText>

                                        <Label color={colors.green[600]}>
                                            ${objects[selectedObject].expenses[key].amount}
                                        </Label>


                                        <Tooltip title="View Details">
                                            <IconButton
                                                size={"medium"}>
                                                <ArrowForwardIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItem>

                                ))
                                : null}
                        </List>
                        :
                        selectedObject && Object.keys(objects[selectedObject].expenses).length === 0 ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Typography variant={"subtitle2"} color={"textSecondary"}
                                            align={"center"}>
                                    No expenses yet! Click to add an expense
                                </Typography>

                            </div>
                            :

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Typography variant={"subtitle2"} color={"textSecondary"}
                                            align={"center"}>
                                    Select an object to see expenses
                                </Typography>

                            </div>

                }


            </CardContent>


            <CardActions>

            </CardActions>
        </Card>
    );
};

ExpensesList.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default ExpensesList;
