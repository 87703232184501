import React, {useEffect, useState} from 'react';
import {
    makeStyles,
    colors,
    Grid,
    CardHeader,
    Button,
    Typography,
    Divider,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    IconButton,
    CardActions,
    Card,
    FormControl,
    Input,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import {Link as RouterLink} from "react-router-dom";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import moment from "moment";
import {Label} from "../../../../../accounting/components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import {Check, X} from "react-feather";


const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        // marginTop: theme.spacing(3)
    },
    object: {
        fontWeight: theme.typography.fontWeightMedium
    },

    createCardActions: {
        justifyContent: 'space-around'
    },


    listItem: {
        padding: theme.spacing(2, 0),
        justifyContent: 'space-between'
    },
    listItemDetails: { // Details
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        paddingBottom: 0
    },
    deleteButton: {
        color: '#E30425'
    },
    createSubmitButton: {
        color: colors.green[600]
    },
    selectedListItem: {
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.white
    },

    normalListItem: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selectedListItemText: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightMedium
    },
    selectedListItemDetails: {
        color: theme.palette.white,
        // fontWeight: theme.typography.fontWeightMedium
    },
    addObjectText: {
        minWidth: '100%'
    }
}));


const ExpenseDetails = ({
                            handleAddExpenseToggle,objects,selectedObject,selectedExpense,
                            addingExpense,expenseData,handleChange,handleAddExpenseSubmit,

}) => {
    const classes = useStyles();
    // const {status} = useParams();
    //
    // const navigate = useNavigate();
    // const dispatch = useDispatch();


    return (
        <div>
        {!addingExpense ? <Card
            // className={clsx(classes.root, className)}
        >
            <CardHeader
                className={classes.header}
                disableTypography
                title={
                    <Typography
                        display="block"
                        variant="h5"

                    >
                        {
                            objects[selectedObject].expenses[selectedExpense].title + ' ' + 'Details' }

                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                    <List>
                        <ListItem
                            className={classes.listItem}
                            disableGutters
                            divider
                        >
                            <Typography variant="subtitle2">Title</Typography>
                            <Typography variant="h6">
                                {selectedObject && objects[selectedObject].expenses[selectedExpense].title}
                            </Typography>
                        </ListItem>

                        {/*<ListItem*/}
                        {/*    className={classes.listItem}*/}
                        {/*    disableGutters*/}
                        {/*    divider*/}
                        {/*>*/}
                        {/*    <Typography variant="subtitle2">Created</Typography>*/}
                        {/*    <Typography variant="h6">*/}
                        {/*        {moment(expenses[0].created_at).format('DD MMM YYYY | hh:mm:ss')}*/}
                        {/*    </Typography>*/}
                        {/*</ListItem>*/}
                        <ListItem
                            className={classes.listItem}
                            disableGutters
                            divider
                        >
                            <Typography variant="subtitle2">Last Updated</Typography>
                            <Typography variant="h6">
                                {moment
                                (selectedObject && objects[selectedObject].expenses[selectedExpense].updated_at).format('DD MMM YYYY | hh:mm:ss')}
                            </Typography>
                        </ListItem>
                        <ListItem
                            className={classes.listItem}
                            disableGutters
                            divider
                        >
                            <Typography variant="subtitle2">Amount</Typography>
                            <Label
                                color={colors.green[600]}>
                                ${selectedObject && objects[selectedObject].expenses[selectedExpense].amount}
                            </Label>
                        </ListItem>
                        {objects[selectedObject].expenses[selectedExpense].detail?
                            <ListItem
                                className={classes.listItemDetails}
                                disableGutters
                                divider
                            >
                                <Typography variant="subtitle2">
                                    Details:
                                </Typography>
                                <Typography variant="h6">
                                    {selectedObject && objects[selectedObject].expenses[selectedExpense].detail}
                                </Typography>
                            </ListItem>
                            : null }
                    </List>


            </CardContent>
        </Card>

        : addingExpense ? // Adding Expense Card
            <Card
                // className={clsx(classes.root, className)}
            >
                <CardHeader
                    className={classes.header}
                    // disableTypography

                    title={
                        <Typography
                            display="block"
                            variant="h5"

                        >
                            {addingExpense ? 'Create New Expense' : 'Edit Expense'}
                        </Typography>
                    }
                />
                <CardContent className={classes.content}>
                    <List>
                        <ListItem
                            className={classes.listItem}
                            disableGutters
                            divider
                        >
                            <Typography variant="body1">Title</Typography>

                            <FormControl>
                                <Input
                                    value={expenseData.title}
                                    autoFocus
                                    name={'title'}
                                    placeholder={'Expense Title'}
                                    onChange={handleChange}
                                    required

                                />
                            </FormControl>

                        </ListItem>

                        <ListItem
                            className={classes.listItem}
                            disableGutters
                            divider
                        >
                            <Typography variant="body1">Amount</Typography>
                            <FormControl>
                                <Input
                                    value={expenseData.amount}
                                    name={'amount'}
                                    placeholder={'Amount'}
                                    onChange={handleChange}
                                    type="number"
                                    required
                                    startAdornment={<InputAdornment
                                        position="start">CA$</InputAdornment>}
                                />
                            </FormControl>


                        </ListItem>
                        <ListItem
                            className={classes.listItemDetails}
                            disableGutters
                            divider
                        >
                            <TextField value={expenseData.detail}
                                       name={'detail'}
                                       onChange={handleChange}
                                       placeholder={'Details (Optional)'}
                                       multiline
                                       rows={3}
                            />
                        </ListItem>
                    </List>


                </CardContent>
                <CardActions className={classes.createCardActions}>
                    <Button
                        className={classes.createSubmitButton}
                        size="small"
                        startIcon={<Check/>}
                        onClick={handleAddExpenseSubmit}
                    >

                        Submit
                    </Button>

                    <Button
                        className={classes.deleteButton}
                        size="small"
                        startIcon={<X/>}
                        onClick={handleAddExpenseToggle}
                    >

                        Cancel
                    </Button>


                </CardActions>
            </Card>

            : null
}
        </div>
    );
};

ExpenseDetails.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default ExpenseDetails;
