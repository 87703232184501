import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {
    Link as RouterLink, Navigate,
    useNavigate
} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Checkbox, Container, FormHelperText, Link} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../../Actions";
import Typography from "@material-ui/core/Typography";
import {Formik} from "formik";
import * as Yup from "yup";
import Page from "../../../../Components/Page";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },

}));


export default function CreateTeacher({searchTeacherRedirect, handleEnableTeacherProfile}) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        formatted_phone_number: '',
        username: '',
        password: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const createdTeacherId = useSelector(state => state.teachers.created_teacher_id)
    const teacher_creation_done = useSelector(state => state.teachers.teacher_creation_done)
    const [teacherSearchId, setTeacherSearchId] = useState('');
    const [teacherRedirect, setTeacherRedirect] = React.useState(false);
    let get_teacher = useSelector(state => state.teachers.fetched_teacher_done)
    const teacherTableData = useSelector(state => state.teachers.teacherTableData)
    let teacher_vacations = useSelector(state => state.teachers.fetched_teacher_vacations);
    let teacher_schedule = useSelector(state => state.teachers.fetched_teacher_schedule);
    const teacher_overtimes = useSelector(state => state.teachers.fetched_teacher_overtimes)
    const dispatch = useDispatch();


    function handleChange(e) {
        const {name, value} = e.target;
        setUser(user => ({...user, [name]: value}));
    }

    function handleIdChange(e) {

        setTeacherSearchId(e.target.value)
    }

    function handlePhoneNumberChange(value) {
        if (value) {
            setUser(user => ({...user, formatted_phone_number: value}))
            setUser(user => ({
                ...user, phone_number:
                    value.replace(" ", "").replace("-", "").replace
                    ("(", "").replace(")", "").replace(" ", "")
            }))
        }
    }

    function handleSubmit(values) {
        // e.preventDefault();
        setSubmitted(true);
        if (values) {
            dispatch(userActions.createTeacher(values));
            setTeacherRedirect(true)
            // handleEnableTeacherProfile()
        }
    }

    const setRedirect = () => {
        if (teacherTableData || teacher_schedule || teacher_overtimes || teacher_vacations) { // cleanup for state in case there is state from teacher schedule
            dispatch(userActions.clearFetchedTeacherSchedules())
            dispatch(userActions.clearFetchedTeacherVacations())
            dispatch(userActions.clearFetchedTeacherOvertimes())
            dispatch(userActions.clearFetchedTeacherSessions())
            dispatch(userActions.clearTeacherTableData())
        }
        if (teacherSearchId) {
            dispatch(userActions.clearFetchedTeacher())
            dispatch(userActions.getTeacher(teacherSearchId));
            setTeacherRedirect(true)
            // handleEnableTeacherProfile() // set TeacherRedirect in the parent component to false when search is clicked

        }
    }

    const renderRedirect = () => {
        if (teacherRedirect && get_teacher) {
            return <Navigate to={{pathname: `/app/teacher/${teacherSearchId}`}}/>
        }

        if (createdTeacherId && teacher_creation_done) {
            return <Navigate to={{pathname: `/app/teacher/${createdTeacherId}`}}/>
        }
    }

    return <Page
        className={classes.root}
        // title="Register Instructor"
    >
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
        >
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone_number: '',
                        formatted_phone_number: '',
                        username: '',
                        password: '',
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            first_name: Yup.string().max(255).required('First name is required'),
                            last_name: Yup.string().max(255).required('Last name is required'),
                            phone_number: Yup.string().max(25).required('Phone number is required'),
                            password: Yup.string().max(255).required('password is required'),
                        })
                    }
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off"
                        >
                            <Box mb={3}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                >
                                    Create new instructor
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body2"
                                >
                                    Enter instructor's details to create new account
                                </Typography>
                            </Box>
                            <TextField
                                autoFocus
                                error={Boolean(touched.firstName && errors.firstName)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.firstName && errors.firstName}
                                label="First name"
                                margin="normal"
                                required
                                name="first_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.lastName && errors.lastName)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.lastName && errors.lastName}
                                label="Last name"
                                required
                                margin="normal"
                                name="last_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                            />

                            <TextField
                                error={Boolean(touched.phone_number && errors.phone_number)}
                                helperText={touched.phone_number && errors.phone_number}
                                requierd
                                fullWidth
                                autoComplete={"off"}
                                id="phone_number"
                                label="Phone Number"
                                variant="outlined"
                                name="phone_number"
                                // data-cy="phone_number"
                                // defaultCountry={"ca"}
                                value={values.phone_number}
                                onChange={handleChange}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="username"
                                name="username"
                                // autoFocus
                                autoComplete="off"
                                value={values.username}
                                onChange={handleChange}
                            />
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                autoComplete={"off"}
                                helperText={touched.password && errors.password}
                                label="Password"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.password}
                                variant="outlined"
                            />

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Create Instructor
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Box>
    </Page>
}
