import React from 'react';
import {Page, Image, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import National_logo from "../../../Certificate/static/National_Logo.jpg";
import moment from "moment";

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

const styles = StyleSheet.create(Object.assign({}, {
        page: {
            backgroundColor: '#ffffff',
            padding: 24
        },
        h4: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: 1.235
        },
        h6: {

            color : "rgba(0,0,0,0.51)",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.6
        },
        sub1: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.6
    },
        subtitle2: {
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 1.57
        },
        body2: {
            fontSize: 10,
            lineHeight: 1.43
        },
        gutterBottom: {
            marginBottom: 4
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        brand: {
            height: 50,
            width: 100
        },
        company: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 32
        },
        references: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 32
        },
        billing: {
            marginTop: 32
        },
        items: {
            marginTop: 5
        },
        notes: {
            marginTop: 32
        },
        table: {
            display: 'flex',
            width: 'auto'
        },
        tableHeader: {},
        tableBody: {},
        tableRow: {
            borderBottomWidth: 1,
            borderColor: '#EEEEEE',
            borderStyle: 'solid',
            flexDirection: 'row'
        },
        tableCell1: {
            padding: 6,
            width: `${COL1_WIDTH}%`
        },
        tableCellN: {
            padding: 6,
            width: `${COLN_WIDTH}%`
        },
        alignRight: {
            textAlign: 'right'
        },
        tableTitle: {
            color : "rgba(0,0,0,0.45)",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.6,
            marginTop: 32
        }
    })
);


const truncateDecimals = function (number, digits) {
    let multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};

const InvoicePdf = ({ student,exportFilter}) => {

    function returnTotalRentPrice(student) {
        let total = 0
        student.fetched_student_rents.map(item =>
            total += parseFloat(item.price_tax)
        )
        return truncateDecimals(total, 2)
    }

    function returnTotalAmountPaid(student) {
        let total = 0
        student.fetched_student_deposits.map(item => {
                if (item.shown) {
                    total += parseFloat(item.deposit)

                }
            }
        )
        return truncateDecimals(total, 2)
    }

    function returnTotalTrainingHours(student, type) {
        let hour = 0
        if (type === 'total' || type === 'training') {
            student.fetched_student_sessions.map(item => {
                    if (item.type_of_class === 'long') {
                        hour += 2
                    } else {
                        if (item.type_of_class === 'short') {
                            hour += 1
                        }
                    }
                }
            )
        }

        if (type === 'total' || type === 'inspection') {
            student.fetched_student_inspection_sessions.map(item => {
                if (item.inspection.duration === 'L') {
                    hour += 2
                } else {
                    if (item.inspection.duration === 'S') {
                        hour += 1
                    }
                }
            })
        }


        return `${hour} Hours`
    }

    function setPaymentName(type) {
        switch (type) {
            case 'cash':
                return 'Cash'

            case 'e_transfer':
                return 'E-Transfer'

            case 'credit_card':
                return 'Credit Card'

            case 'cheque':
                return 'Cheque'

            case 'paypal':
                return 'Paypal'
            default:
                return ' '
        }
    }

    return (
        // <Dialog open={openPdfPreview} fullScreen>
        //     <Box
        //         style={{
        //             display: 'flex',
        //             flexDirection: 'column',
        //             height: '100%'
        //         }}
        //     >
        //         <Box
        //             style={{
        //                 backgroundColor: 'background.default',
        //                 p: 2
        //             }}
        //         >
        //         <Button
        //             color="primary"
        //             startIcon={<ArrowLeftIcon fontSize="small"/>}
        //             onClick={handleToggleDialog}
        //             variant="contained"
        //         >
        //             Back
        //         </Button>
        //         </Box>
        //
        //         <Box style={{ flexGrow: 1 }}>
        //         <PDFViewer
        //             height="100%"
        //             style={{border: 'none'}}
        //             width="100%"
        //         >
        <Document>


            <Page
                size="A4"
                style={styles.page}
            >
                <View style={styles.header}>
                    <View>
                        <Image
                            source={National_logo}
                            style={styles.brand}
                        />
                        <Text style={styles.h6}>
                            National Truck Driving School
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.h4}>
                            Student Data Export
                        </Text>
                        <Text style={styles.subtitle2}>
                            Export # 3593865
                        </Text>
                    </View>
                </View>
                <View style={styles.company}>
                    <View>
                        <Text style={styles.body2}>
                            Street King William, 123
                        </Text>
                        <Text style={styles.body2}>
                            Level 2, C, 442456
                        </Text>
                        <Text style={styles.body2}>
                            Montréal, QC, Canada
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.body2}>
                            Company No. 4675933
                        </Text>
                        <Text style={styles.body2}>
                            EU VAT No. 949 67545 45
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.body2}>
                            info@nationaldrivingschool.ca
                        </Text>
                        <Text style={styles.body2}>
                            (+1) 514 937 2888
                        </Text>
                    </View>
                </View>
                <View style={styles.references}>
                    <View>
                        <Text style={[styles.subtitle2, styles.gutterBottom]}>
                            Date of issue
                        </Text>
                        <Text style={styles.body2}>
                            {moment().format('DD/mm/yyyy')}
                        </Text>
                    </View>
                    {/*<View>*/}
                    {/*    <Text style={[styles.subtitle2, styles.gutterBottom]}>*/}
                    {/*        Date of issue*/}
                    {/*    </Text>*/}
                    {/*    <Text style={styles.body2}>*/}
                    {/*        {format(invoice.issueDate, 'dd MMM yyyy')}*/}
                    {/*    </Text>*/}
                    {/*</View>*/}
                    <View>
                        <Text style={[styles.subtitle2, styles.gutterBottom]}>
                            Number 3593865
                        </Text>
                        <Text style={styles.body2}>
                            DEV-9483
                        </Text>
                    </View>
                </View>
                {exportFilter.includes('info')?


                    <View style={styles.billing}>
                        <Text style={[styles.subtitle2, styles.gutterBottom]}>
                            Client Info:
                        </Text>
                        <Text style={styles.body2}>
                            {`${student.info.first_name} ${student.info.last_name}`}
                        </Text>
                        <Text style={styles.body2}>
                            {student.info.email}
                        </Text>
                        <Text style={styles.body2}>
                            {student.info.phone_number}
                        </Text>
                        {/*<Text style={styles.body2}>*/}
                        {/*    271 Richmond Rd, Grey Lynn, Auckland 1022, New Zealand*/}
                        {/*</Text>*/}
                    </View>

                    : null}

                {exportFilter.includes('sessions')?
                    <View>
                        <View>
                            <Text style={styles.tableTitle}>
                                Session Records
                            </Text>
                        </View>
                        <View style={styles.items}>
                            <View style={styles.table}>
                                <View style={styles.tableHeader}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.h6}>
                                                Date
                                            </Text>
                                        </View>
                                        {/*<View style={styles.tableCellN}>*/}
                                        {/*    <Text style={[styles.h6,]}>*/}
                                        {/*        Date*/}
                                        {/*    </Text>*/}
                                        {/*</View>*/}
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.h6,]}>
                                                Instructor
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.h6,]}>
                                                Type
                                            </Text>
                                        </View>

                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.h6,]}>
                                                Status
                                            </Text>
                                        </View>

                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.h6,]}>
                                                Duration
                                            </Text>
                                        </View>

                                        {/*<View style={styles.tableCellN}>*/}
                                        {/*    <Text style={[styles.h6, styles.alignRight]}>*/}
                                        {/*        Price*/}
                                        {/*    </Text>*/}
                                        {/*    <Text style={[styles.h6,]}>*/}
                                        {/*        Type*/}
                                        {/*    </Text>*/}
                                        {/*</View>*/}
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {student.fetched_student_sessions.map((item) => (
                                        <View
                                            style={styles.tableRow}
                                            key={item.start}
                                        >
                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {`${moment.utc(item.start).format('DD/MM/YYYY | HH-')}${moment.utc(item.end).format('HH')}`}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {`${student.fetched_teachers_list.find(
                                                        teacher => parseInt(teacher.id) === parseInt(item.teacher_id)
                                                    ).first_name} ${student.fetched_teachers_list.find(
                                                        teacher => parseInt(teacher.id) === parseInt(item.teacher_id)
                                                    ).last_name}`}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {item.extra_hour ? 'Extra' : item.job_session ? 'Job Session' : 'Regular'}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {item.status}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {item.type_of_class === 'long' ? '2 Hours' : '1 Hour'}
                                                </Text>
                                            </View>


                                            {/*<View style={styles.tableCellN}>*/}
                                            {/*    <Text style={[styles.body2, styles.alignRight]}>*/}
                                            {/*        {numeral(item.unitAmount)*/}
                                            {/*            .format(`${item.currency}0,0.00`)}*/}
                                            {/*    </Text>*/}
                                            {/*</View>*/}
                                        </View>
                                    ))}


                                    {student.fetched_student_inspection_sessions.map((item) => (
                                        <View
                                            style={styles.tableRow}
                                            key={item.start}
                                        >


                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {`${moment.utc(item.inspection.start).format('DD/MM/YYYY | HH-')}${moment.utc(item.inspection.end).format('HH')}`}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {`${student.fetched_teachers_list.find(
                                                        teacher => parseInt(teacher.id) === parseInt(item.inspection.teacher)
                                                    ).first_name} ${student.fetched_teachers_list.find(
                                                        teacher => parseInt(teacher.id) === parseInt(item.inspection.teacher)
                                                    ).last_name}`}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    Inspection
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {item.status === 'P' ? 'Pending' : 'Confirmed'}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {item.inspection.duration === 'L' ? '2 Hours' : '1 Hour'}
                                                </Text>
                                            </View>


                                            {/*<View style={styles.tableCellN}>*/}
                                            {/*    <Text style={[styles.body2, styles.alignRight]}>*/}
                                            {/*        {numeral(item.unitAmount)*/}
                                            {/*            .format(`${item.currency}0,0.00`)}*/}
                                            {/*    </Text>*/}
                                            {/*</View>*/}
                                        </View>
                                    ))}

                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCell1}/>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                Regular Hours:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {returnTotalTrainingHours(student, 'training')}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCell1}/>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                Inspection Hours:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {returnTotalTrainingHours(student, 'inspection')}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCell1}/>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                Job Hours:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {returnTotalTrainingHours(student, 'job')}
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCell1}/>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                Total Hours:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {returnTotalTrainingHours(student, 'total')}
                                            </Text>
                                        </View>
                                    </View>
                                    {/*<View style={styles.tableRow}>*/}
                                    {/*    <View style={styles.tableCell1}/>*/}
                                    {/*    <View style={styles.tableCellN}>*/}
                                    {/*        <Text style={styles.body2}>*/}
                                    {/*            Taxes*/}
                                    {/*        </Text>*/}
                                    {/*    </View>*/}
                                    {/*    <View style={styles.tableCellN}>*/}
                                    {/*        <Text style={[styles.body2, styles.alignRight]}>*/}
                                    {/*            {numeral(invoice.taxAmount)*/}
                                    {/*                .format(`${invoice.currency}0,0.00`)}*/}
                                    {/*        </Text>*/}
                                    {/*    </View>*/}
                                    {/*</View>*/}
                                    {/*<View style={styles.tableRow}>*/}
                                    {/*    <View style={styles.tableCell1}/>*/}
                                    {/*    <View style={styles.tableCellN}>*/}
                                    {/*        <Text style={styles.body2}>*/}
                                    {/*            Total*/}
                                    {/*        </Text>*/}
                                    {/*    </View>*/}
                                    {/*    <View style={styles.tableCellN}>*/}
                                    {/*        <Text style={[styles.body2, styles.alignRight]}>*/}
                                    {/*            {numeral(invoice.totalAmount)*/}
                                    {/*                .format(`${invoice.currency}0,0.00`)}*/}
                                    {/*        </Text>*/}
                                    {/*    </View>*/}
                                    {/*</View>*/}
                                </View>
                            </View>
                        </View>

                    </View> : null}

                {exportFilter.includes('payments')?<View>
                    <View>
                        <Text style={styles.tableTitle}>
                            Payment Records
                        </Text>
                    </View>
                    <View style={styles.items}>
                        <View style={styles.table}>
                            <View style={styles.tableHeader}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.h6}>
                                            Date
                                        </Text>
                                    </View>
                                    <View style={styles.tableCell1}>
                                        <Text style={[styles.h6,]}>
                                            Payment Method
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.h6,]}>
                                            Amount
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {student.fetched_student_deposits.map((item) => (
                                    item.shown?
                                    <View
                                        style={styles.tableRow}
                                        key={item.create_date}
                                    >
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                {moment.utc(item.create_date).format('DD/MM/YYYY | HH:MM')}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={styles.body2}>
                                                {setPaymentName(item.type)}
                                            </Text>
                                        </View>

                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {`CA$ ${item.deposit}`}
                                            </Text>
                                        </View>
                                    </View>
                                        : null
                                ))}
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCell1}/>
                                    <View style={styles.tableCellN}>
                                        <Text style={styles.body2}>
                                            Total Amount Paid:
                                        </Text>
                                    </View>
                                    <View style={styles.tableCellN}>
                                        <Text style={[styles.body2,]}>
                                            {`CA$ ${returnTotalAmountPaid(student)}`}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View> : null}



                {exportFilter.includes('rents')?
                    <View>
                        <View>
                            <Text style={styles.tableTitle}>
                                Rental Truck Records
                            </Text>
                        </View>
                        <View style={styles.items}>
                            <View style={styles.table}>
                                <View style={styles.tableHeader}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.h6}>
                                                Date
                                            </Text>
                                        </View>
                                        <View style={styles.tableCell1}>
                                            <Text style={[styles.h6,]}>
                                                Duration
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.h6,]}>
                                                Price
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {student.fetched_student_rents.map((item) => (
                                        <View
                                            style={styles.tableRow}
                                            key={item.created_at}
                                        >
                                            <View style={styles.tableCellN}>
                                                <Text style={styles.body2}>
                                                    {moment.utc(item.date).format('DD/MM/YYYY')}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCell1}>
                                                <Text style={styles.body2}>
                                                    {`${item.hour} Hour(s)`}
                                                </Text>
                                            </View>

                                            <View style={styles.tableCellN}>
                                                <Text style={[styles.body2,]}>
                                                    {`CA$ ${item.price_tax}`}
                                                </Text>
                                            </View>
                                        </View>
                                    ))}
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCell1}/>
                                        <View style={styles.tableCellN}>
                                            <Text style={styles.body2}>
                                                Total Rent Price:
                                            </Text>
                                        </View>
                                        <View style={styles.tableCellN}>
                                            <Text style={[styles.body2,]}>
                                                {`CA$ ${returnTotalRentPrice(student)}`}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    :null}



                <View style={styles.notes}>
                    <Text style={[styles.h6, styles.gutterBottom]}>
                        Notes
                    </Text>
                    <Text style={styles.body2}>
                        Any notes or details that might be necessary can be placed here
                    </Text>
                </View>
            </Page>


        </Document>
        //         </PDFViewer>
        //         </Box>
        //     </Box>
        // </Dialog>
    );
};

InvoicePdf.propTypes = {};

export default InvoicePdf;
