import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {
    Box, Grid, Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Typography,
} from "@material-ui/core";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import PerfectScrollbar from "react-perfect-scrollbar";
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '30px',
    },
    table: {
        borderRight: '1px solid'
    },
    free: {
        backgroundColor: '#58D68D',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,

    },
    ellipsis: {
        textAlign: 'center',
        fontSize: 'small',
        // maxWidth: 10, // percentage also works
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    vacation: {
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,

    },
    overtime: {
        backgroundColor: '#F0B27A',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    noSchedule: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: 10,
        paddingRight: 4,
        paddingLeft: 5,
    },
    vacationButton: {
        backgroundColor: '#616A6B',
        // color : '#53a9cb'
    },
    overtimeButton: {
        backgroundColor: '#F0B27A'
    },
    freeButton: {
        backgroundColor: '#58D68D',

    },
    addVacationButton: {
        color: '#384040',
        // paddingRight: 4,
        // paddingLeft: 5
    },
    addOvertimeButton: {
        color: '#F0B27A'
        ,
        // paddingRight: 4,
        // paddingLeft: 5
    },
    content: {
        // [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${240}px)`,
        //     // marginLeft: drawerWidth,
        // },
        width: '100%',
        flexGrow: 1,
        minHeight: '100%',
        padding: theme.spacing(3),
    },
    tableCell: {
        // paddingRight: 4,
        // paddingLeft: 5
    },
    tableRoot: {
        // height: 700,
    },
    tableLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 600,
    }

}));




export default function ShowTeacherWeeklySchedule({
                                                      rows, handleGoNextWeek, handleGoPreviousWeek, handleAddVacation,
                                                      allFetchingDone, handleAddOvertime,
                                                      sessions,
                                                      all_students
                                                  }) {
    const classes = useStyles();

    useEffect(() => {

    }, [rows]);

    function returnStudentName(sessions, date, time) {
        if (sessions.length === 7) {
            const dateIndex = sessions.findIndex(session => session.date === date)
            const sessionIndex = sessions[dateIndex] ? sessions[dateIndex].sessions.findIndex
            (session => time >= new Date(session.start).getUTCHours() && time < new Date(session.end).getUTCHours()) : null
            console.log(sessionIndex)
            if (sessions[dateIndex].sessions[sessionIndex]) {
                const student_index = all_students.findIndex(student => student.id === parseInt(sessions[dateIndex].sessions[sessionIndex].student))
                return all_students[student_index].first_name + "\n" + all_students[student_index].last_name
            }
        }
    }


    return (
        <div
            className={classes.root}>

            {/*{console.dir(rows)}*/}

            <Box minWidth={800}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <Button
                            disabled={!allFetchingDone}
                            onClick={handleGoPreviousWeek}
                            variant="contained"
                            color="primary"
                            startIcon={<NavigateBeforeIcon/>}
                        >
                            Previous Week
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            className={classes.freeButton}
                            startIcon={<AlarmOnIcon/>}
                        >
                            Free
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.vacationButton}
                            startIcon={<TimerOffIcon/>}
                        >
                            Off
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.overtimeButton}
                            startIcon={<AlarmAddIcon/>}
                        >
                            Overtime
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={!allFetchingDone}
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon/>}
                            onClick={handleGoNextWeek}
                        >
                            Next Week
                        </Button>
                    </div>

                </div>


                {/*<div className={classes.content}>*/}
                {/*    <TableContainer className={classes.content}>*/}
                <Grid item xs={12}>

                    <TableContainer component={Paper}
                        className={allFetchingDone?classes.tableRoot : classes.tableLoading}
                    >

                        {allFetchingDone? <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>Day</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>Date</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>07-08</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>08-09</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>09-10</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>10-11</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>11-12</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>12-13</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>13-14</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>14-15</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>15-16</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>16-17</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>17-18</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>18-19</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>19-20</TableCell>
                                    <TableCell className={classes.tableCell}
                                               style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>20-21</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*{console.dir(rows)}*/}
                                {rows ? rows.map((item, index) => (

                                    <TableRow
                                        hover
                                        key={index}
                                    >
                                        <TableCell className={classes.tableCell}> {item.Day} </TableCell>
                                        <TableCell className={classes.tableCell}
                                                   style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                                        >
                                            {item.Date}
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(7) ? classes.vacation :
                                                item.overtimes.includes(7) ? classes.overtime :
                                                    item.overtimes.includes(7) ? classes.overtime :
                                                        item.time.includes(7) && !item.vacations.includes(7) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(7) && allFetchingDone ? returnStudentName(sessions, item.Date, 7) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(8) ? classes.vacation :
                                                item.overtimes.includes(8) ? classes.overtime :
                                                    item.time.includes(8) && !item.vacations.includes(8) ? classes.free :
                                                        classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(8) && allFetchingDone ? returnStudentName(sessions, item.Date, 8) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(9) ? classes.vacation :
                                                item.overtimes.includes(9) ? classes.overtime :
                                                    item.time.includes(9) && !item.vacations.includes(9) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                                {item.sessions.includes(9) && allFetchingDone ? returnStudentName(sessions, item.Date, 9) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(10) ? classes.vacation :
                                                item.overtimes.includes(10) ? classes.overtime :
                                                    item.time.includes(10) && !item.vacations.includes(10) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(10) && allFetchingDone ? returnStudentName(sessions, item.Date, 10) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(11) ? classes.vacation :
                                                item.overtimes.includes(11) ? classes.overtime :
                                                    item.time.includes(11) && !item.vacations.includes(11) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(11) && allFetchingDone ? returnStudentName(sessions, item.Date, 11) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(12) ? classes.vacation :
                                                item.overtimes.includes(12) ? classes.overtime :
                                                    item.time.includes(12) && !item.vacations.includes(12) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(12) && allFetchingDone ? returnStudentName(sessions, item.Date, 12) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(13) ? classes.vacation :
                                                item.overtimes.includes(13) ? classes.overtime :
                                                    item.time.includes(13) && !item.vacations.includes(13) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(13) && allFetchingDone ? returnStudentName(sessions, item.Date, 13) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(14) ? classes.vacation :
                                                item.overtimes.includes(14) ? classes.overtime :
                                                    item.time.includes(14) && !item.vacations.includes(14) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(14) && allFetchingDone ? returnStudentName(sessions, item.Date, 14) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(15) ? classes.vacation :
                                                item.overtimes.includes(15) ? classes.overtime :
                                                    item.time.includes(15) && !item.vacations.includes(15) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(15) && allFetchingDone ? returnStudentName(sessions, item.Date, 15) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(16) ? classes.vacation :
                                                item.overtimes.includes(16) ? classes.overtime :
                                                    item.time.includes(16) && !item.vacations.includes(16) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(16) && allFetchingDone ? returnStudentName(sessions, item.Date, 16) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(17) ? classes.vacation :
                                                item.overtimes.includes(17) ? classes.overtime :
                                                    item.time.includes(17) && !item.vacations.includes(17) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(17) && allFetchingDone ? returnStudentName(sessions, item.Date, 17) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(18) ? classes.vacation :
                                                item.overtimes.includes(18) ? classes.overtime :
                                                    item.time.includes(18) && !item.vacations.includes(18) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(18) && allFetchingDone ? returnStudentName(sessions, item.Date, 18) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(19) ? classes.vacation :
                                                item.overtimes.includes(19) ? classes.overtime :
                                                    item.time.includes(19) && !item.vacations.includes(19) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(19) && allFetchingDone ? returnStudentName(sessions, item.Date, 19) : null}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            className={item.vacations.includes(20) ? classes.vacation :
                                                item.overtimes.includes(20) ? classes.overtime :
                                                    item.time.includes(20) && !item.vacations.includes(20) ? classes.free : classes.noSchedule}>
                                            <Typography className={classes.ellipsis}>
                                            {item.sessions.includes(20) && allFetchingDone ? returnStudentName(sessions, item.Date, 20) : null}
                                            </Typography>
                                        </TableCell>
                                        {/*{console.dir(item)}*/}

                                        <TableCell className={classes.tableCell}>
                                            <Button
                                                disabled={!allFetchingDone}
                                                onClick={() => handleAddVacation(item)}
                                                startIcon={<AddIcon/>}
                                                size="small"
                                                className={classes.addVacationButton}
                                                variant="outlined">
                                                Off
                                            </Button>

                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Button
                                                disabled={!allFetchingDone}
                                                onClick={() => handleAddOvertime(item)}
                                                size="small"
                                                startIcon={<AddIcon/>}
                                                className={classes.addOvertimeButton}
                                                variant="outlined">
                                                Overtime
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>: <CircularProgress style = {{alignSelf: 'center'}}/>}
                    </TableContainer>

                </Grid>
                {/*</div>*/}
            </Box>
        </div>
    );
}