import React from 'react';
import {
     CardContent, Checkbox, FormControlLabel, Grid,
    makeStyles,
} from '@material-ui/core';

const FieldSelect = ({
                            handleFilerChange,
                         exportFilter
}) => {

    return (
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                        wrap="wrap"
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel
                                control={(
                                    <Checkbox checked={exportFilter.includes('info')} onChange={()=>handleFilerChange('info')} />
                                )}
                                label="Student Info (Full Name, Email, Phone Number)"
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel
                                control={(
                                    <Checkbox checked={exportFilter.includes('sessions')} onChange={()=>handleFilerChange('sessions')} />
                                )}
                                label="Session Records (Date, Instructor, Type, Status, Duration)"
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel
                                control={(
                                    <Checkbox checked={exportFilter.includes('payments')} onChange={()=>handleFilerChange('payments')} />
                                )}
                                label="Payment Records (Date, Payment Method, Amount)"
                            />
                        </Grid>





                        <Grid item xs={12} md={12} lg={12}>
                            <FormControlLabel
                                control={<Checkbox checked={exportFilter.includes('rents')} onChange={()=>handleFilerChange('rents')} />}
                                label="Rental Truck Records (Date, Duration, Price)"
                            />
                        </Grid>


                    </Grid>
                </CardContent>


    );
};

FieldSelect.propTypes = {
    // className: PropTypes.string
};

export default FieldSelect;
