import * as React from "react";
import {useEffect} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormLabel, Radio,
    RadioGroup, Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '30px',
    },
    table: {
        borderRight: '1px solid'
    },
    free: {
        backgroundColor: '#58D68D',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    vacation: {
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)'

    },
    selectedForVacation:{
        backgroundColor: '#616A6B',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    overtime: {
        backgroundColor: '#F0B27A',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    noSchedule: {
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    vacationButton: {
        backgroundColor: '#616A6B',
        // color : '#53a9cb'
    },

    overtimeButton: {
        backgroundColor: '#F0B27A'
    },
    freeButton: {
        backgroundColor: '#58D68D',

    },
    addVacationButton:{
        color: '#384040',
    },
    addOvertimeButton:{
        color: '#F0B27A',
    }


}));



export default function AddVacationView({openDialog, handleCloseDialog,vacationType,handleVacationTypeChange,handleSetVacationSubmit, item,
                                            handleVacationClick,selectedVacationArray
                                        }) {
    const classes = useStyles();

    useEffect(() => {

    }, []);


    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={"xl"}>
            <DialogTitle id="form-dialog-title">Add Vacation </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Date : {item.Date}
                </DialogContentText>
                {/*<FormControl component="fieldset" style={{*/}
                {/*    display: 'flex',*/}
                {/*    flexDirection: 'row',*/}
                {/*    justifyContent: 'space-evenly'*/}
                {/*}}>*/}
                <div>
                    <FormLabel component="legend">Type of Vacation</FormLabel>
                    <RadioGroup aria-label="type_of_package" name="type_of_package"
                                value={vacationType} onChange={handleVacationTypeChange}>
                        <FormControlLabel value="byDay" control={<Radio/>} label="All day"/>
                        <FormControlLabel value="byHour" control={<Radio/>} label="Hourly"/>
                    </RadioGroup>
                </div>

                <div>
                    {vacationType === 'byHour' && item? <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Day</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>Date</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>07-08</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>08-09</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>09-10</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>10-11</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>11-12</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>12-13</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>13-14</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>14-15</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>15-16</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>16-17</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>17-18</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>18-19</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>19-20</TableCell>
                                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>20-21</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                                <TableRow
                                    hover
                                    key={1}
                                >
                                    <TableCell> {item.Day} </TableCell>
                                    <TableCell
                                        style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}
                                    >
                                        {item.Date}
                                    </TableCell>

                                    <TableCell
                                        onClick={() => handleVacationClick(7)}
                                        className={item.vacations.includes(7) || selectedVacationArray.includes(7)? classes.vacation :
                                            item.overtimes.includes(7) ? classes.overtime :
                                                item.overtimes.includes(7) ? classes.overtime :
                                                    item.time.includes(7) && !item.vacations.includes(7) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(8)}
                                        className={item.vacations.includes(8) || selectedVacationArray.includes(8)? classes.vacation :
                                            item.overtimes.includes(8) ? classes.overtime :
                                                item.time.includes(8) && !item.vacations.includes(8) ? classes.free :
                                                    classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(9)}
                                        className={item.vacations.includes(9) || selectedVacationArray.includes(9)? classes.vacation :
                                            item.overtimes.includes(9) ? classes.overtime :
                                                item.time.includes(9) && !item.vacations.includes(9) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(10)}
                                        className={item.vacations.includes(10) || selectedVacationArray.includes(10)? classes.vacation :
                                            item.overtimes.includes(10) ? classes.overtime :
                                                item.time.includes(10) && !item.vacations.includes(10) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(11)}
                                        className={item.vacations.includes(11) || selectedVacationArray.includes(11)? classes.vacation :
                                            item.overtimes.includes(11) ? classes.overtime :
                                                item.time.includes(11) && !item.vacations.includes(11) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(12)}
                                        className={item.vacations.includes(12) || selectedVacationArray.includes(12)? classes.vacation :
                                            item.overtimes.includes(12) ? classes.overtime :
                                                item.time.includes(12) && !item.vacations.includes(12) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(13)}
                                        className={item.vacations.includes(13) || selectedVacationArray.includes(13)? classes.vacation :
                                            item.overtimes.includes(13) ? classes.overtime :
                                                item.time.includes(13) && !item.vacations.includes(13) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(14)}
                                        className={item.vacations.includes(14) || selectedVacationArray.includes(14)? classes.vacation :
                                            item.overtimes.includes(14) ? classes.overtime :
                                                item.time.includes(14) && !item.vacations.includes(14) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(15)}
                                        className={item.vacations.includes(15) || selectedVacationArray.includes(15)? classes.vacation :
                                            item.overtimes.includes(15) ? classes.overtime :
                                                item.time.includes(15) && !item.vacations.includes(15) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(16)}
                                        className={item.vacations.includes(16) || selectedVacationArray.includes(16)? classes.vacation :
                                            item.overtimes.includes(16) ? classes.overtime :
                                                item.time.includes(16) && !item.vacations.includes(16) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(17)}
                                        className={item.vacations.includes(17) || selectedVacationArray.includes(17)? classes.vacation :
                                            item.overtimes.includes(17) ? classes.overtime :
                                                item.time.includes(17) && !item.vacations.includes(17) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(18)}
                                        className={item.vacations.includes(18) || selectedVacationArray.includes(18)? classes.vacation :
                                            item.overtimes.includes(18) ? classes.overtime :
                                                item.time.includes(18) && !item.vacations.includes(18) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(19)}
                                        className={item.vacations.includes(19) || selectedVacationArray.includes(19)? classes.vacation :
                                            item.overtimes.includes(19) ? classes.overtime :
                                                item.time.includes(19) && !item.vacations.includes(19) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                    <TableCell onClick={() => handleVacationClick(20)}
                                        className={item.vacations.includes(20) || selectedVacationArray.includes(20)? classes.vacation :
                                            item.overtimes.includes(20) ? classes.overtime :
                                                item.time.includes(20) && !item.vacations.includes(20) ? classes.free : classes.noSchedule}>
                                    </TableCell>

                                </TableRow>
                        </TableBody>
                    </Table> : null }


                </div>

                {/*</FormControl>*/}
            </DialogContent>

            <DialogActions>
                <Button startIcon={<CheckIcon />}
                    onClick={handleSetVacationSubmit}
                    variant="contained"
                    color="primary" type={"submit"}
                >
                    Submit
                </Button>

            </DialogActions>


        </Dialog>
    );
}