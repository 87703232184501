import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    makeStyles,
    Typography,
    TableContainer,
    TablePagination,
    FormControl,
    Grid,
    Hidden,
    DialogTitle,
    DialogContent, DialogActions, Dialog
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Paginate from "../../../../components/Paginate";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import GenericMoreButton from "../GenericMoreButton";
import Label from "../Label";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        // minWidth: 900
        // maxHeight: '50%'

    },
    author: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    tags: {
        '& > * + *': {
            marginLeft: theme.spacing(1)
        }
    },
    actions: {
        justifyContent: 'center'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    table: {
        maxHeight: '100vh'
    }
}));

const Payments = props => {
    const {
        // payments,
        className,
        deposits,
        calculateSum,
        start,
        end,
        handleEndChange,
        handleStartChange,
        handleOpenDetailsDialog,
        calendarDate,
        selectEdge,
        handleCalendarOpen,
        handleCalendarAccept,
        handleCalendarChange,
        handleCalendarClose,
        openDetailsDialog,
        handleCloseDetailsDialog,
        selectedDepositDetailId,
        open,
        ...rest
    } = props;
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowHeight, setRowHeight] = useState(undefined);
    const [pageHeight, setPageHeight] = useState(undefined);

    let rowHeightRef = useCallback(node => {
        if (node !== null) {
            setRowHeight(node.getBoundingClientRect().height);
        }
    }, []);

    let PageRef = useCallback(node => {
        if (node !== null) {
            setPageHeight(node.getBoundingClientRect().height);
        }
    }, []);


    const handleChangePage = (data) => {

        setPage(data.selected);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };

    function setColor(type) {
        switch (type) {
            case 'cash':
                return '#3DC5E5'

            case 'e_transfer':
                return '#1AAB4E'

            case 'credit_card':
                return '#FB3C81'

            case 'cheque':
                return '#F39C12'

            default:
                return '#00ffff'
        }
    }

    function setPaymentName(type) {
        switch (type) {
            case 'cash':
                return 'Cash'

            case 'e_transfer':
                return 'E-Transfer'

            case 'credit_card':
                return 'Credit Card'

            case 'cheque':
                return 'Cheque'

            default:
                return '#00ffff'
        }
    }


    return (
        <div {...rest} ref={PageRef}
             className={clsx(classes.root, className)}>
            <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
            >
                {deposits.length} Records found. Page {page + 1} of{' '}
                {Math.ceil(deposits.length / rowsPerPage)}
            </Typography>
            <Card
            >
                <Hidden mdUp>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                                <Button onClick={() => handleCalendarOpen('start')}>
                                    <CalendarTodayIcon className={classes.calendarTodayIcon} />
                                    {start.format('DD/MM/YYYY')}
                                </Button>
                            </div>
                        </Grid>

                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center',}}>
                            <Typography variant={"h6"}>
                                to
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center',}}>
                            {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                            {/*    <KeyboardDatePicker*/}
                            {/*        clearable*/}
                            {/*        value={end}*/}
                            {/*        // inputVariant="outlined"*/}
                            {/*        placeholder="01/03/2021"*/}
                            {/*        onChange={date => handleEndChange(date)}*/}
                            {/*        // minDate={new Date()}*/}
                            {/*        format="MM/dd/yyyy"*/}
                            {/*    />*/}
                            {/*</MuiPickersUtilsProvider>*/}
                            <Button onClick={() => handleCalendarOpen('end')}>
                                <CalendarTodayIcon className={classes.calendarTodayIcon} />
                                {end.format('DD/MM/YYYY')}
                            </Button>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden smDown>
                    <div
                        style={{display: 'flex',marginTop: 20, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

                        <div>
                            <FormControl variant={"outlined"} style={{marginRight: 20}}>
                                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                {/*    <KeyboardDatePicker*/}
                                {/*        clearable*/}
                                {/*        value={start}*/}
                                {/*        // inputVariant="outlined"*/}
                                {/*        placeholder="01/03/2021"*/}
                                {/*        onChange={date => handleStartChange(date)}*/}
                                {/*        // minDate={new Date()}*/}
                                {/*        format="MM/dd/yyyy"*/}
                                {/*    />*/}
                                {/*</MuiPickersUtilsProvider>*/}
                                <Button onClick={() => handleCalendarOpen('start')}>
                                    <CalendarTodayIcon className={classes.calendarTodayIcon} />
                                    {start.format('DD/MM/YYYY')}
                                </Button>
                            </FormControl>
                        </div>


                        <Typography variant={"h6"} style={{marginRight: 20}}>
                            to
                        </Typography>

                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                        {/*    <KeyboardDatePicker*/}
                        {/*        clearable*/}
                        {/*        value={end}*/}
                        {/*        // inputVariant="outlined"*/}
                        {/*        placeholder="01/03/2021"*/}
                        {/*        onChange={date => handleEndChange(date)}*/}
                        {/*        // minDate={new Date()}*/}
                        {/*        format="MM/dd/yyyy"*/}
                        {/*    />*/}
                        {/*</MuiPickersUtilsProvider>*/}
                        <Button onClick={() => handleCalendarOpen('end')}>
                            <CalendarTodayIcon className={classes.calendarTodayIcon} />
                            {end.format('DD/MM/YYYY')}
                        </Button>
                    </div>
                </Hidden>


                {/*</div>*/}

                <br/>
                <CardHeader
                    action={<GenericMoreButton disabled={true}/>}
                    title={(<div>

                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography variant={"h5"} style={{marginRight: 10}}>
                                Sum:
                            </Typography>
                            <Typography variant={"subtitle2"}style={{marginRight: 5}}>
                                CA$
                            </Typography>
                            <Typography variant={"h6"}>
                                {calculateSum(deposits)}
                            </Typography>
                        </div>

                    </div>)}
                >
                </CardHeader>
                <Divider/>
                <CardContent className={classes.content}>
                    {/*<PerfectScrollbar options={{suppressScrollY: false}}>*/}
                    <div className={classes.inner}>
                        <TableContainer className={classes.table}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection="desc">
                                            <Tooltip
                                                enterDelay={300}
                                                title="Sort"
                                            >
                                                <TableSortLabel
                                                    active
                                                    direction="desc"
                                                >
                                                    Ref
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>Student Name</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Payment Type</TableCell>
                                        <TableCell align="right">Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deposits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(payment => (
                                        <TableRow
                                            hover
                                            key={payment.id}
                                            ref={rowHeightRef}
                                        >
                                            <TableCell>
                                                #{payment.id}
                                                <Typography variant="body2">
                                                    {moment(payment.create_date).format('DD/MM/YYYY | hh:mm')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {payment.student.first_name + ' ' + payment.student.last_name}
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Typography variant={"subtitle2"} style={{marginRight: 5}}>
                                                        $
                                                    </Typography>

                                                    <Typography variant={"body1"}>
                                                        {payment.deposit}
                                                    </Typography>
                                                </div>


                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    {payment.deposit_tax}*/}
                                            {/*</TableCell>*/}
                                            <TableCell>
                                                <div className={classes.tags}>
                                                    <Label
                                                        color={setColor(payment.type)}
                                                        key={payment.type}
                                                    >
                                                        {setPaymentName(payment.type)}
                                                    </Label>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={()=>handleOpenDetailsDialog(payment.id) }
                                                    variant="outlined"
                                                    disabled={!payment.detail}
                                                >
                                                    Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </div>
                    {/*</PerfectScrollbar>*/}
                </CardContent>
                <CardActions className={classes.actions}>
                    {/*<TablePagination*/}
                    {/*    labelRowsPerPage={'rows'}*/}
                    {/*    component="div"*/}
                    {/*    count={deposits.length}*/}
                    {/*    onChangePage={handleChangePage}*/}
                    {/*    onChangeRowsPerPage={handleChangeRowsPerPage}*/}
                    {/*    page={page}*/}
                    {/*    rowsPerPage={rowsPerPage}*/}
                    {/*    rowsPerPageOptions={[5, 10, 25, rowsPerPage !== 5 || 10 || 25 ? rowsPerPage : null]}*/}
                    {/*/>*/}

                    <Paginate pageCount={Math.ceil(deposits.length / rowsPerPage)}
                              onPageChange={handleChangePage}
                              pageRangeDisplayed={0}

                    />
                </CardActions>
            </Card>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    open={open}
                    maxDate={moment()}
                    clearable
                    value={calendarDate}
                    // placeholder="01/03/2021"
                    onChange={date => handleCalendarChange(date)}
                    onAccept={handleCalendarAccept}
                    onClose={handleCalendarClose}
                    format="dd/MM/yyyy"
                    style={{ display: 'none' }} // Temporal fix to hide the input element
                />
            </MuiPickersUtilsProvider>

            <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog} style={{
                display: 'grid'
            }} fullWidth >
                <DialogTitle id="alert-dialog-title">{"Deposit Details Text"}</DialogTitle>

                <DialogContent>
                    <Typography variant={"body1"}>
                        {selectedDepositDetailId ? deposits.find(item => item.id === selectedDepositDetailId).detail : null}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        startIcon={<ArrowBackIosOutlinedIcon/>}
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={handleCloseDetailsDialog}
                    >
                        Back
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

Payments.propTypes = {
    className: PropTypes.string
};

export default Payments;
