import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import StudentsListComponent from "../Components/StudentsListComponent";
import {userActions} from "../../../Actions";
import {Navigate} from "react-router-dom";
import Page from "../../../Components/Page";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));


export default function StudentsListContainer({}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const students_list = useSelector(state => state.students.fetched_all_students)
    const [studentRedirect, setStudentRedirect] = React.useState(false);
    const [selectedStudentId, setSelectedStudentId] = React.useState("");
    let get_student = useSelector(state => state.students.fetched_student_done)
    const [filterText, setFilterText] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [itemsToDisplay, setItemsToDisplay] = React.useState([]);

    function handleChangeFilter(e){
        const {value} = e.target
        setFilterText(value)
    }

    function handleClearFilter(e) {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setFilterText("")
    }

    useEffect(() => {
        dispatch(userActions.getAllStudents())

    }, [])

    useEffect(() => {
        if (students_list && students_list.length >= 0) {
            setFilteredItems(
                students_list.filter(
                    item =>{
                        const full_name = item.first_name + ' ' + item.last_name
                        return  full_name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
                    }
                )
            )
        }
    }, [students_list, filterText])

    useEffect(() => {
        filterText ? setItemsToDisplay(filteredItems) : setItemsToDisplay(students_list)
    }, [filterText, filteredItems])


    function onStudentClick(id) {
        console.log(id)
        setSelectedStudentId(id)
        dispatch(userActions.clearfetchedStudent())
        dispatch(userActions.getStudent(id));
        setStudentRedirect(true)
    }

    function sortStudentsList(students) {
        const copy = [...students]
        return copy.sort((a, b) => a.id - b.id);
    }

    const renderRedirect = () => {
        if (studentRedirect && get_student) {
            return <Navigate to={{pathname: `/app/account/${selectedStudentId}/sessions`}}/>
        }
    }

    return (

        <Page
            className={classes.root}
            title='Students List'
        >
            {renderRedirect()}

            {itemsToDisplay ?
                <StudentsListComponent students={sortStudentsList(itemsToDisplay)}
                                       onStudentClick={onStudentClick}
                                       handleChangeFilter={handleChangeFilter}
                                       handleClearFilter={handleClearFilter}
                /> : null
            }
        </Page>)
}
