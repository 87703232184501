import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Drawer, Grid, Typography, Button, Hidden, CardHeader, Card, IconButton, Fade} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Notes from "./components/Notes";
import NoteDetails from "./components/NoteDetails/NoteDetails";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {ChevronsDown, ChevronsUp} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../Actions";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),


    },
    drawer: {
        // backgroundColor: 'red',
        // marginTop : '',
        [theme.breakpoints.up('md')]: {
            maxWidth: '30%',
            flexWrap: 'wrap',
            marginLeft: '70%',
            // marginTop: '90%',
            // marginRight: theme.spacing(2),
            // marginLeft: theme.spacing(2),

        },
        minHeight: '100%',
        // borderRadius: '15px'
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > * + *': {
            marginLeft: theme.spacing(2)
        }
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    }
}));

const StudentNotes = props => {
    const {
        selected,
        className,
        onMarkPaid,
        onMarkUnpaid,
        onDelete,
        note_id,
        ...rest
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const {id} = useParams()
    const {tab} = useParams();
    const navigate = useNavigate();
    const notes_list = useSelector(state => state.students.student_notes_list)
    const edit_note_done = useSelector(state => state.students.edit_note_done)
    const editing_note = useSelector(state => state.students.editing_note)
    const create_note_done = useSelector(state => state.students.create_note_done)
    const creating_note = useSelector(state => state.students.creating_note)
    const deleting_note = useSelector(state => state.students.deleting_note)
    const note_deleted = useSelector(state => state.students.note_deleted)
    const [note, setNote] = React.useState({});
    const [fetchingNote, setFetchingNote] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const notes_details = useSelector(state => state.students.student_note_details)
    const [editNoteData, setEditNoteData] = React.useState({title: '', text: ''});
    const [sendingData, setSendingData] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);


    function handleDeleteNote() {
        setSendingData(true)
        dispatch(userActions.deleteNote(id, note_id))
    }


    function handleSubmitCreate() {
        if (!sendingData) {
            if (editNoteData.title) {
                dispatch(userActions.createNote(id, editNoteData))
                setSendingData(true)
            }
        }
    }

    function handleAddNoteToggle() {
        setEditNoteData(state => ({...state, title: ''}))
        setEditNoteData(state => ({...state, text: ''}))
        setIsAdding(true)
    }


    function handleSubmitEdit() {
        if (!sendingData) { // to avoid multiple clicks while already working
            if (editNoteData.title !== notes_details[note_id].title || editNoteData.text !== notes_details[note_id].text) {
                if (editNoteData.title) {
                    dispatch(userActions.editNote(id, note_id, editNoteData))
                    setSendingData(true)
                }
            } else {
                setIsEditing(false)
            }
        }
    }

    function disableIsEditing() {
        setIsEditing(false)
        setIsAdding(false)
    }

    function handleEditNoteToggle() {
        setEditNoteData(state => ({...state, title: notes_details[note_id].title}))
        setEditNoteData(state => ({...state, text: notes_details[note_id].text}))
        setIsEditing(true)

    }

    function handleChange(e) {
        const {
            name,
            value
        } = e.target;
        setEditNoteData(user => ({
            ...user,
            [name]: value
        }));
    }

    useEffect(() => {
        if (note_id > 0) {
            if (!notes_details[note_id]) {
                setFetchingNote(true)
                dispatch(userActions.getStudentNoteDetails(id, note_id))
            }

        }
    }, [note_id])

    useEffect(() => {
        if (notes_details[note_id]) {
            setFetchingNote(false)
        }
    }, [note_id, notes_details])

    useEffect(() => {
        if (edit_note_done && !editing_note) {
            setIsEditing(false)
            setSendingData(false)
            dispatch(userActions.resetEditNote())
            dispatch(userActions.getStudentNotesList(id))

        }
    }, [edit_note_done, editing_note])

    useEffect(() => {
        if (create_note_done && !creating_note) {
            setSendingData(false)
            setIsAdding(false)
            dispatch(userActions.resetCreateNote())
            dispatch(userActions.getStudentNotesList(id))
        }
    }, [create_note_done, creating_note])

    useEffect(() => {
        if (note_deleted && !deleting_note) {
            setSendingData(false)
            dispatch(userActions.resetDeleteNote())
            dispatch(userActions.getStudentNotesList(id))
            navigate(`/app/account/${id}/sessions/notes`,)
        }
    }, [deleting_note, note_deleted])

    return (
        <Fade in={true}>
            <Drawer
                anchor="bottom"
                open={true}
                // eslint-disable-next-line react/jsx-sort-props
                PaperProps={{elevation: 10}}
                variant="persistent"
                classes={{
                    paper: classes.drawer,
                }}
            >


                <div
                    {...rest}
                    className={clsx(classes.root, className)}
                >
                    <Grid
                        alignItems="center"
                        container
                        // spacing={2}
                    >
                        {/*<Hidden smDown>*/}
                        <Grid
                            item
                            md={10}
                            xs={10}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >

                                Notes
                            </Typography>
                        </Grid>
                        <Grid item md={2} xs={2} >
                            <IconButton component={RouterLink} to={`/app/account/${id}/${tab}`}>
                                <ChevronsDown/>
                            </IconButton>
                        </Grid>
                        {/*</Hidden>*/}
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                        >
                            {!note_id && notes_list.results && !isAdding &&
                            <Notes handleAddNoteToggle={handleAddNoteToggle} notes_list={notes_list.results}/>}
                            {note_id && notes_details[note_id] &&
                            <NoteDetails
                                isAdding={isAdding}
                                isEditing={isEditing}
                                note_id={note_id}
                                noteDetails={notes_details}
                                handleEditNoteToggle={handleEditNoteToggle}
                                editNoteData={editNoteData}
                                handleChange={handleChange}
                                handleSubmitEdit={handleSubmitEdit}
                                disableIsEditing={disableIsEditing}
                                sendingData={sendingData}
                                handleDeleteNote={handleDeleteNote}
                            />}

                            {isAdding &&
                            <NoteDetails
                                isAdding={isAdding}
                                isEditing={isEditing}
                                note_id={note_id}
                                noteDetails={notes_details}
                                handleEditNoteToggle={handleEditNoteToggle}
                                editNoteData={editNoteData}
                                handleChange={handleChange}
                                handleSubmitEdit={handleSubmitEdit}
                                disableIsEditing={disableIsEditing}
                                sendingData={sendingData}
                                handleSubmitCreate={handleSubmitCreate}

                            />}

                            {/*<NoteDetails />*/}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        </Fade>
    );
};

StudentNotes.propTypes = {
    // className: PropTypes.string,
    // onDelete: PropTypes.func,
    // onMarkPaid: PropTypes.func,
    // onMarkUnpaid: PropTypes.func,
    // selected: PropTypes.array.isRequired
};

export default StudentNotes;
