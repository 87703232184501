import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Paper, makeStyles, TextField, Checkbox, FormControlLabel, IconButton} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    search: {
        flexGrow: 1,
        height: 42,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.icon
    },
    searchInput: {
        flexGrow: 1
    },
    active: {
        marginLeft: theme.spacing(2)
    }
}));

const Search = ({onKey,handleChangeFilter, filterText,
                    showActive, handleChangeSwitchFilter,handleClearFilter,noFilterNeeded,
                    className, ...rest}) => {

    const classes = useStyles();



    function onKeyDown(e){
        if(e.keyCode === 13){
            e.target.blur();
        }
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Paper
                className={classes.search}
                elevation={1}
            >
                <SearchIcon className={classes.searchIcon}/>
                <TextField
                    onKeyDown={onKeyDown}
                    onChange={handleChangeFilter}
                    value={filterText}
                    className={classes.searchInput}
                    InputProps={{disableUnderline: true}}
                    placeholder="Search by name"
                />
                    <IconButton
                        onClick={handleClearFilter}
                    >
                        <ClearIcon />
                    </IconButton>
            </Paper>
            {
                !noFilterNeeded && <FormControlLabel
                    className={classes.active}
                    control={<Checkbox checked={showActive} onChange={handleChangeSwitchFilter} name="showActive"/>}
                    label="Active Only"
                />
            }

        </div>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func
};

export default Search;
