import {userConstants} from '../Constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {loggedIn: true, user, userType: '',recoverPassword: {
        status: 'idle',
        username: '',
        error: null,
    }
,
    changePassword: {
        status: 'idle',
        username: '',
        error: null,
    }} : {
    recoverPassword: {
        status: 'idle',
        username: '',
        error: null,
    },
    changePassword: {
        status: 'idle',
        username: '',
        error: null,
    }
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user.data.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                loggedIn: false, user, userType: 'anonymous',
            };
        case userConstants.SET_USER_TYPE:
            return {
                ...state,
                userType: action.userType
            }
        case userConstants.RECOVER_PASSWORD_REQUEST:
            return {
                ...state,
                recoverPassword: {
                    ...state.recoverPassword,
                    username: action.username,
                    status: 'loading'
                }
            }
      case userConstants.RECOVER_PASSWORD_SUCCESS:
        return {
          ...state,
          recoverPassword: {
              ...state.recoverPassword,
            username: action.username,
            status: 'succeeded'
          }
        }
      case userConstants.RECOVER_PASSWORD_FAILURE:
        return {
          ...state,
          recoverPassword: {
              ...state.recoverPassword,
            username: action.username,
            status: 'failed',
            error: action.error
          }
        }
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    status: 'loading',
                }
            }

        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    status: 'succeeded',
                }
            }
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    status: 'failed',
                    error: action.error,
                }
            }
        default:
            return state
    }
}
