import * as React from "react";
import {

    Button, Card, CardActions, CardContent, CardHeader, Grid, Hidden, makeStyles,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip, Typography,
} from "@material-ui/core";
import Search from "../../../../../components/Search";
import GenericMoreButton from "../../../../accounting/components/GenericMoreButton";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    button: {
        paddingTop: theme.spacing(1),

        marginLeft: theme.spacing(2)
    },
    search: {
        // marginLeft: theme.spacing(2)
    }
}));

export default function TeachersListComponent({items, handleClearFilter, onClick, filterText, handleChangeFilter}) {
    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                title={(<Search
                    className={classes.search}
                    handleChangeFilter={handleChangeFilter}
                    filterText={filterText}
                    noFilterNeeded={true}
                    handleClearFilter={handleClearFilter}
                />)}
            >
            </CardHeader>
            {/*<CardHeader>*/}

            {/*</CardHeader>*/}
            {/*<CardContent>*/}
                {/*<Grid container spacing={3}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <Grid*/}
                {/*            item*/}
                {/*            md={6}*/}
                {/*            xs={12}*/}
                {/*        >*/}


                        {/*</Grid>*/}
                    {/*</Grid>*/}
                    {/*<Grid item lg={12} xs={12} md={12}>*/}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection='asc'>
                                            <Tooltip
                                                enterDelay={300}
                                                title="Sort"
                                            >
                                                <TableSortLabel
                                                    active
                                                    direction="asc"
                                                >
                                                    ID
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item) => (
                                        <TableRow
                                            onClick={() => onClick(item.id)}
                                            hover
                                            key={item.id}
                                        >
                                            <TableCell>
                                                {item ? item.id : null}
                                            </TableCell>


                                            <TableCell>
                                                {item ? item.first_name + " " + item.last_name : null}
                                            </TableCell>
                                            <TableCell>
                                                <Hidden xsDown>
                                                    <Button variant={"outlined"} color={"primary"}>
                                                        Open Profile
                                                    </Button>
                                                </Hidden>

                                                <Hidden smUp>
                                                    <Button variant={"outlined"} color={"primary"}
                                                    >
                                                        Open
                                                    </Button>
                                                </Hidden>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
            {/*</CardContent>*/}

        </Card>
    )
}
