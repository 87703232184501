import React, { Fragment, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import {
    ListItemIcon,
    ListItemText,
    Tooltip,
    IconButton,
    Menu,
    MenuItem, makeStyles, Button
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AchiveIcon from '@material-ui/icons/ArchiveOutlined';
import {Edit2, Trash} from "react-feather";

const useStyles = makeStyles(() => ({

    deleteButton: {
        color: '#E30425'
    },
}));

const GenericMoreButton = props => {
    const classes = useStyles();
    const moreRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);

    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    return (
        <Fragment>
                <IconButton
                    {...props}
                    onClick={handleMenuOpen}
                    ref={moreRef}
                    size="small"
                    disabled={props.disabled}
                >
                    <MoreIcon />
                </IconButton>
            <Menu
                anchorEl={moreRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                classes={{ paper: classes.menu }}
                onClose={handleMenuClose}
                open={openMenu}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <MenuItem>
                    <Button
                        className={classes.deleteButton}
                        size="small"
                        startIcon={<Trash/>}
                        onClick={props.handleToggleDeleteObject}
                        // variant={"outlined"}
                    >

                        Delete
                    </Button>
                </MenuItem>

                <MenuItem>
                    <Button
                        className={classes.deleteButton}
                        size="small"
                        startIcon={<Edit2/>}
                        // onClick={handleConfirmDeleteExpense}
                        // variant={"outlined"}
                    >

                        Edit
                    </Button>
                </MenuItem>

            </Menu>
        </Fragment>
    );
};

GenericMoreButton.propTypes = {
    className: PropTypes.string
};

export default memo(GenericMoreButton);
