import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import Page from "../../../../Components/Page";
import {userActions} from "../../../../Actions";
import {TeachersListComponent} from "./components";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));


export default function TeachersList() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const teachers_list = useSelector(state => state.teachers.fetched_teachers_list)
    const [selectedId, setSelectedId] = React.useState("");
    let teacher = useSelector(state => state.teachers.fetched_teacher)
    const [filterText, setFilterText] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState([]);
    const [itemsToDisplay, setItemsToDisplay] = React.useState([]);
    const navigate = useNavigate();


    function handleChangeFilter(e) {
        const {value} = e.target
        setFilterText(value)
    }

    function handleClearFilter(e) {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setFilterText("")
    }

    useEffect(() => {
        dispatch(userActions.getTeachersList())
    }, [])

    useEffect(() => {
        if (teachers_list && teachers_list.length >= 0) {
            setFilteredItems(
                teachers_list.filter(
                    item => {
                        const full_name = item.first_name + ' ' + item.last_name
                        return full_name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
                    }
                )
            )
        }
    }, [teachers_list, filterText])

    useEffect(() => {
        filterText ? setItemsToDisplay(filteredItems) : setItemsToDisplay(teachers_list)
    }, [filterText, filteredItems])


    function onClick(id) {
        setSelectedId(id)
        dispatch(userActions.getTeacher(id));
    }

    useEffect(() => {
        if (Object.keys(teacher).length > 0 && selectedId) {
            if (parseInt(teacher.id) === parseInt(selectedId)) {
                navigate(`/app/teacher/${selectedId}/full_schedule`)
            }
        }
    }, [teacher, selectedId])

    function sortList(items) {
        const copy = [...items]
        return copy.sort((a, b) => a.id - b.id);
    }

    return (
        <div>
            {itemsToDisplay && <TeachersListComponent items={sortList(itemsToDisplay)}
                                                      onClick={onClick}
                                                      handleChangeFilter={handleChangeFilter}
                                                      handleClearFilter={handleClearFilter}
            />
            }
        </div>

        // </Page>

)
}
