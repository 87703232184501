import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Divider,
  colors, makeStyles, InputLabel, Select, MenuItem, FormControl, InputAdornment, CircularProgress
} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import {green} from "@material-ui/core/colors";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  formControl:{
    // margin: theme.spacing(1),
    maxHeight: 300,
  },
  buttonProgress: {
    color: green[500],
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const hoursArray =[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]




const General = ({ FieldsAreEmpty,loading,submitSettings,handleChange,settingsData, className, ...rest }) => {

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="General" />
      <Divider />
      <CardContent>
        <form onSubmit={submitSettings}>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Defaults
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
              >
                Change the default package properties
              </Typography>
              <FormControl style={{minWidth: 120}}
                           variant="outlined"
                  // className={classes.formControl}
              >
                <TextField
                    label="Session Hour"
                    fullWidth
                    required
                    id="session_hour"
                    type="number"
                    variant="outlined"
                    name="session_hour"
                    onChange={handleChange}
                    value={settingsData.session_hour}

                />
              </FormControl>
              <br/>
              <FormControl style={{minWidth: 120}}
                           variant="outlined"
              >

                <TextField
                    label="Inspection Hour"
                    fullWidth
                    required
                    id="inspection_hour"
                    type="number"
                    variant="outlined"
                    name="inspection_hour"
                    onChange={handleChange}
                    value={settingsData.inspection_hour}

                />


              </FormControl>
              <br/>
              <FormControl style={{minWidth: 120}}
                           variant="outlined"
              >

                <TextField
                    label="Finance Job Hour"
                    fullWidth
                    required
                    id="f_package_job_hour"
                    type="number"
                    variant="outlined"
                    name="f_package_job_hour"
                    onChange={handleChange}
                    value={settingsData.f_package_job_hour}

                />


              </FormControl>
              <br/>
              <FormControl style={{minWidth: 120}}
                           variant="outlined"
              >

                <TextField
                    label="Finance Training Hour"
                    fullWidth
                    required
                    id="f_package_hour"
                    type="number"
                    variant="outlined"
                    name="f_package_hour"
                    onChange={handleChange}
                    value={settingsData.f_package_hour}

                />


              </FormControl>
              <br/>
              <FormControl style={{minWidth: 120}}
                           variant="outlined"
              >

                <TextField
                    label="Finance Inspection Hour"
                    fullWidth
                    required
                    id="f_package_inspection_hour"
                    type="number"
                    variant="outlined"
                    name="f_package_inspection_hour"
                    onChange={handleChange}
                    value={settingsData.f_package_inspection_hour}

                />


              </FormControl>
              <br/>



              <FormControl style={{minWidth: 120}}
                           variant="outlined"
              >
                <TextField
                    label="session's email time(Hours)"
                    fullWidth
                    required
                    type={"number"}
                    id="session_hour_for_send_email"
                    variant="outlined"
                    name="session_hour_for_send_email"
                    onChange={handleChange}
                    value={settingsData.session_hour_for_send_email}

                />
              </FormControl>
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Rates
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
              >
                Edit the rates
              </Typography>
              <FormControlLabel
                  labelPlacement={"start"}
                control={
                  <TextField
                      InputProps={{
                        endAdornment:  (
                            <InputAdornment position='end'>%</InputAdornment>
                        )
                      }}
                      fullWidth
                      required
                      id="outlined-number"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}                      variant="outlined"
                      name="tax_rate"
                      onChange={handleChange}
                      value={settingsData.tax_rate}

                  />
                }
                  label={
                    <Typography>
                      Tax Rate (Percent)
                    </Typography>
                  }
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="tax_rate"
                        type="number"
                        variant="outlined"
                        name="manual_price"
                        onChange={handleChange}
                        value={settingsData.manual_price}

                    />
                  }
                  label="Manual Full Package Price"
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="tax_rate"
                        type="number"
                        variant="outlined"
                        name="auto_price"
                        onChange={handleChange}
                        value={settingsData.auto_price}

                    />
                  }
                  label="Auto Full Package Price"
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="f_package_price"
                        type="number"
                        variant="outlined"
                        name="f_package_price"
                        onChange={handleChange}
                        value={settingsData.f_package_price}

                    />
                  }
                  label="Finance Package Price"
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="tax_rate"
                        type="number"
                        variant="outlined"
                        name="session_price"
                        onChange={handleChange}
                        value={settingsData.session_price}

                    />
                  }
                  label="Session Price (1 Hour)"
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="tax_rate"
                        type="number"
                        variant="outlined"
                        name="extra_hour_price"
                        onChange={handleChange}
                        value={settingsData.extra_hour_price}

                    />
                  }
                  label="Extra Hour Price (1 Hour)"
              />
              <br/>
              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="tax_rate"
                        type="number"
                        variant="outlined"
                        name="rental_truck_price"
                        onChange={handleChange}
                        value={settingsData.rental_truck_price}

                    />
                  }
                  label="Rental Truck Price"
              />
              <br/>

              <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <TextField
                        fullWidth
                        required
                        id="email_send_in_price"
                        type="number"
                        variant="outlined"
                        name="email_send_in_price"
                        onChange={handleChange}
                        value={settingsData.email_send_in_price}

                    />
                  }
                  label="Email sending price"
              />
              <br/>

            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions className={classes.wrapper}>
        <Button
          className={classes.saveButton}
          variant="contained"
          onClick={submitSettings}
        >
          {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Save Changes'}
        </Button>

        {FieldsAreEmpty? <Alert severity="error">Please fill out all the fields</Alert> : null}

      </CardActions>
    </Card>
  );
};

General.propTypes = {
  className: PropTypes.string
};

export default General;
