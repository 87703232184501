import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {
    Navigate,
    Redirect
    // Redirect,
} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent, CardHeader, CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Radio,
    RadioGroup,
    Select
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../Actions";
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MuiPhoneNumber from "material-ui-phone-number";
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import PersonAddSharpIcon from '@material-ui/icons/PersonAddSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import clsx from "clsx";
import Page from "../../Components/Page";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";


const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    main: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //     display: 'flex',
        //     flexWrap: 'wrap',
        //     flexDirection: 'column'
    },

    pageRoot: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    formControl:{
        // margin: theme.spacing(1),
        maxHeight: 300,
    },

    // grow: {
    //     flexGrow: 1,
    // },
    //
    // color: {
    //     textAlign: 'center',
    //     color: '#482880',
    //     alignItems: 'center',
    // }
    // ,
    // button: {
    //     marginTop: '2%',
    //     justify: "center",
    //     color: '#482880'
    // },
    // style: {
    //     backgroundColor: 'white',
    //     textAlign: 'center',
    // },
    root: {

        // borderRadius: '25px',
        padding: theme.spacing(2



        ),
        textAlign: 'center',
        backgroundColor: "white",
        alignItems: "center",
        justify: "center",
        // marginTop: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    form: {

        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
    },
    search: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(20),
        marginLeft: '12%'
    },
    // margin: {
    //     margin: theme.spacing(1),
    // },
    // withoutLabel: {
    //     marginTop: theme.spacing(3),
    // },
    // textField: {
    //     width: '25ch',
    // },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const truncateDecimals = function (number, digits) {
    let multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};

const payment_types = [
    {value: 'paypal', label: 'Paypal'},
    {value: 'credit_card', label: 'Credit Card'},
    {value: 'debit_card', label: 'Debit Card'},
    {value: 'cheque', label: 'Cheque'},
    {value: 'cash', label: 'Cash'},
    {value: 'e_transfer', label: 'E-transfer'},

]

const transmission_types = [
    {value: 'auto', label: 'Auto(fee for 24 hours: $1499)', f_label:'Auto'},
    {value: 'manual', label: 'Manual(fee for 24 hours: $1699)',f_label:'manual'},


]

export default function CreateStudent({className, ...rest}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        formatted_phone_number: '',
        // identification_number: '',
        home_phone_number: '0',
        formatted_home_phone_number: '',
        type_of_package: "truck",
        type_of_payment: "e_transfer",
        licence_number: "",
        type_of_car: "manual",
        hour: 18,
        agreement: '1699',
        agreement_for_custom_fake: '',
        inspection_hour: 6,
        package: 'full',
    });

    const createdStudentId = useSelector(state => state.students.created_student_id)
    const student_creation_done = useSelector(state => state.students.student_creation_done)
    const [studentSearchId, setStudentSearchId] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openCardDialog, setOpenCardDialog] = React.useState(false)
    let get_student = useSelector(state => state.students.fetched_student_done)
    let get_student_session = useSelector(state => state.students.fetched_student_sessions_done)
    let alert = useSelector(state => state.alert)
    const [alertMessage, setAlertMessage] = React.useState("");
    const [studentRedirect, setStudentRedirect] = React.useState(false);
    const [studentCreateRedirect, setStudentCreateRedirect] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    let constants = useSelector(state => state.students.settings)
    const [tabvalue, setTabValue] = React.useState('full');

    // useEffect(() => {
    //     setStudentCreateRedirect(false)
    //     setStudentRedirect(false)
    //
    //
    //
    // }, []);



    function handleChange(e) {
        const {name, value} = e.target;
        setUser(user => ({...user, [name]: value}));

        // if(name === 'agreement'){
        //     setUser(user => ({...user, agreement_after_tax: (parseFloat(value) * (1+ 0.14975)).toFixed(2)}))
        // }
    }

    function handleAgreementChange(e) {
        setUser(user => ({...user, agreement: e.target.value.toString()}))

    }

    function handlePhoneNumberChange(value) {
        if (value) {
            setUser(user => ({...user, formatted_phone_number: value}))
            setUser(user => ({
                ...user, phone_number:
                    value.replace(" ", "").replace("-", "").replace
                    ("(", "").replace(")", "").replace(" ", "")
            }))
        }
    }

    function handleHomePhoneNumberChange(value) {
        if (value) {
            setUser(user => ({...user, formatted_home_phone_number: value}))
            setUser(user => ({
                ...user, home_phone_number:
                    value.replace(" ", "").replace("-", "").replace
                    ("(", "").replace(")", "").replace(" ", "")
            }))
        }
    }

    function handleIdChange(e) {

        setStudentSearchId(e.target.value)
    }

    function handleSubmit(e) {


        e.preventDefault();
        if (user.first_name && user.last_name && user.phone_number && user.email && user.home_phone_number && user.type_of_payment
            && user.type_of_package && user.licence_number) {

            setLoading(true)
            dispatch(userActions.createStudent(user));
            setStudentCreateRedirect(true)
            // handleEnableStudentProfile()
            // handleCloseDialog()
            // handleStudentCreateRedirect()
        }

    }

    const setRedirect = (e) => {
        e.preventDefault();

        if (studentSearchId) {
            dispatch(userActions.clearfetchedStudent())
            dispatch(userActions.getStudent(studentSearchId));
            setStudentRedirect(true)
            // handleEnableStudentProfile()
        }
    }

    const handleStudentCreateRedirect = () => {
        if (createdStudentId && student_creation_done) {
            setStudentCreateRedirect(true)
        }

    }


    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    const handleOpenDialog = () => {
        if (user.first_name &&
            user.last_name &&
            user.phone_number &&
            user.email) { // check if all fields are entered
            if (validateEmail(user.email)) {
                setOpenDialog(true);
                setAlertMessage("")

            } else {
                setAlertMessage("please enter a valid email address")
            }

        } else {
            setAlertMessage("Please Fill out all the fields")
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setAlertMessage("")

    };

    const handleOpenCardDialog = () => {
        if (user.type_of_payment
            && user.type_of_package) {
            handleCloseDialog()
            setAlertMessage("")
            setOpenCardDialog(true)

        } else {
            setAlertMessage("Please select both fields")

        }

    }

    const handleCloseCardDialog = () => {
        setOpenCardDialog(false);
    };

    const renderRedirect = () => {
        if (studentRedirect && get_student) {
            return <Navigate to={{pathname: `/app/account/${studentSearchId}/sessions`}}/>
        }

        if (createdStudentId && studentCreateRedirect && student_creation_done) {
            return <Navigate to={{pathname: `/app/account/${createdStudentId}/sessions`}}/>
        }

        // if (studentCreateRedirect){
        //     return <Redirect to={{pathname: `/student/profile/${createdStudentId}`}}/>
        // }
    }

    function handleTypeOfCarSelection(e) {
        const {name, value} = e.target;

        setUser(user => ({...user, type_of_car: value}))
        if (value === 'manual') {
            if(user.package==='full'){
                setUser(user => ({...user, agreement: constants.manual_price}))

            }
        }
        if (value === 'auto') {
            if(user.package==='full') {

                setUser(user => ({...user, agreement: constants.auto_price}))
            }

        }
    }

    function handleCustomExtraHourChange(e){
        const {name, value} = e.target;
        setUser(user => ({...user, [name]: value}));
        setUser(user => ({...user, agreement_for_custom_fake:value*parseFloat(constants.extra_hour_price).toString() }))
    }

    function handlePackageChange(e){
        const {name, value} = e.target;
        setUser(user => ({...user, [name]: value}));
        if(value === 'custom'){
            setUser(user => ({...user, hour:1 }))
            setUser(user => ({...user, agreement:1*parseFloat(constants.session_price).toString() }))
        }
        if(value === 'full'){
            setUser(user => ({...user, hour:constants.session_hour }))
            setUser(user => ({...user, inspection_hour:constants.inspection_hour }))

            if(user.type_of_car === 'manual'){
                setUser(user => ({...user, agreement:constants.manual_price }))

            }
            if(user.type_of_car === 'auto'){
                setUser(user => ({...user, agreement:constants.auto_price }))

            }
        }


    }

    const handleTabValueChange = (e, newValue) => {
        setTabValue(newValue);
        // const {name, value} = e.target;
        setUser(user => ({...user, package: newValue}));
        if(newValue === 'custom'){
            setUser(user => ({...user, f_package:false }))
            setUser(user => ({...user, hour:0 }))
            setUser(user => ({...user, inspection_hour:0 }))
            setUser(user => ({...user, job_env_intro:0 }))
            setUser(user => ({...user, agreement:'0'
                    // 1*parseFloat(constants.session_price).toString() CHANGED TO 0 with request from backend dev
            }))
        }
        if(newValue === 'full'){
            setUser(user => ({...user, hour:constants.session_hour }))
            setUser(user => ({...user, inspection_hour:constants.inspection_hour }))
            setUser(user => ({...user, f_package:false }))

            if(user.type_of_car === 'manual'){
                setUser(user => ({...user, agreement:constants.manual_price }))

            }
            if(user.type_of_car === 'auto'){
                setUser(user => ({...user, agreement:constants.auto_price }))

            }
        }

        if(newValue === 'finance'){
            setUser(user => ({...user, f_package:true }))
            setUser(user => ({...user, package:'finance' }))
            setUser(user => ({...user, hour:constants.f_package_hour }))
            setUser(user => ({...user, inspection_hour:constants.f_package_inspection_hour }))
            setUser(user => ({...user, job_env_intro:constants.f_package_job_hour }))

        }
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const hoursArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,19,20,21,22,23,24,25,26,27,28,29,30]

    useEffect(()=>{
        dispatch(userActions.getSettings())
    },[])

    useEffect(()=>{
        if(constants){
            setUser({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                formatted_phone_number: '',
                home_phone_number: '0',
                formatted_home_phone_number: '',
                type_of_package: "truck",
                type_of_payment: "e_transfer",
                licence_number: "",
                type_of_car: "manual",
                hour: user.package === 'full'?
                    constants.session_hour: user.package ==='finance'?
                        constants.f_package_hour: user.package==='custom'?
                            0: 0,
                agreement: user.package === 'full'?
                    constants.manual_price: user.package ==='finance'?
                        constants.f_package_price: user.package==='custom'?
                            0: 0,
                inspection_hour: user.package === 'full'?
                    constants.inspection_hour: user.package ==='finance'?
                        constants.f_package_inspection_hour: user.package==='custom'?
                            0: 0,
                job_env_intro: user.package === 'full'?
                    0: user.package ==='finance'?
                        constants.f_package_job_hour: user.package==='custom'?
                            0: 0,
                package: user.package,
            })
        }
    }, [constants])

    useEffect(()=>{
        if(tabvalue === 'finance'){
            setUser(user => ({...user, agreement:constants.f_package_price?constants.f_package_price : '2999.99'  }))
            setUser(user => ({...user, job_env_intro:constants.f_package_job_hour?constants.f_package_job_hour : '4'  }))
            setUser(user => ({...user, hour:constants.f_package_hour?constants.f_package_hour : '24'  }))
            setUser(user => ({...user, inspection_hour:constants.f_package_inspection_hour?constants.f_package_inspection_hour : '6'  }))

        }
        //
        // if(tabvalue === 'custom'){
        //     setUser(user => ({...user, agreement:constants.f_package_price?constants.f_package_price : '2999.99'  }))
        // }
    }, [tabvalue])


    return (
        <Page title='Create Student' className={classes.pageRoot}>
            <Container maxWidth={"lg"}>
                <Grid container
                      spacing={3}>
                    <Grid
                        item
                        md={8}
                        xs={12}
                    >
                        <div className={classes.main}>
                            <form
                                onSubmit={handleSubmit} autoComplete="off"
                                noValidate
                                // className={clsx(classes.root, className)}
                                {...rest}
                            >
                                <Card>
                                    <CardHeader
                                        subheader="Fill out the form to create new student"
                                        title="Create Student"
                                    />
                                    <Divider/>
                                    <CardContent>
                                            <Tabs value={tabvalue} variant="fullWidth" onChange={handleTabValueChange}
                                                  aria-label="simple tabs example">
                                                <Tab label="Full Package" icon={<ListRoundedIcon/>} value={'full'} {...a11yProps('full')} />
                                                <Tab label="Custom Package" icon={<CreditCardRoundedIcon/>}value={'custom'} {...a11yProps('custom')} />
                                                <Tab label="Finance Package" icon={<CreditCardRoundedIcon/>}value={'finance'} {...a11yProps('finance')} />

                                            </Tabs>
                                        <br/>

                                        {/*<form name="form" >*/}

                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                {/*<Grid*/}
                                                {/*    item*/}
                                                {/*    lg={12}*/}
                                                {/*    md={12}*/}
                                                {/*    xs={12}*/}
                                                {/*>*/}
                                                {/*    <Grid container spacing={1}*/}
                                                {/*          xs={12}*/}
                                                {/*          lg={6}*/}
                                                {/*          md={6}*/}
                                                {/*          style={{alignItems: 'center'}}>*/}
                                                {/*        <Grid item xs={6} md={6}>*/}
                                                {/*            <FormLabel component="legend">Type of Training: </FormLabel>*/}
                                                {/*        </Grid>*/}
                                                {/*        <Grid item xs={6} md={6}>*/}
                                                {/*            <RadioGroup aria-label="Package" name="package"*/}
                                                {/*                        value={user.package} onChange={handlePackageChange}*/}
                                                {/*                        style={{display: 'flex', flexDirection: 'row'}}>*/}
                                                {/*                <FormControlLabel value="full" control={<Radio/>}*/}
                                                {/*                                  label="Full"/>*/}
                                                {/*                <FormControlLabel value="custom" control={<Radio/>}*/}
                                                {/*                                  label="Custom"/>*/}
                                                {/*            </RadioGroup>*/}
                                                {/*        </Grid>*/}

                                                {/*    </Grid>*/}
                                                {/*        /!*<Grid container spacing={1}*!/*/}
                                                {/*        /!*      xs={12}*!/*/}
                                                {/*        /!*      lg={6}*!/*/}
                                                {/*        /!*      md={6}*!/*/}
                                                {/*        /!*      style={{alignItems: 'center'}}>*!/*/}
                                                {/*        /!*    <Grid item xs={6} md={3}>*!/*/}
                                                {/*        /!*        <FormLabel component="legend">Type of*!/*/}
                                                {/*        /!*            Package: </FormLabel>*!/*/}
                                                {/*        /!*    </Grid>*!/*/}
                                                {/*        /!*    <Grid item xs={6} md={9}>*!/*/}
                                                {/*        /!*        <RadioGroup aria-label="type_of_package"*!/*/}
                                                {/*        /!*                    name="type_of_package"*!/*/}
                                                {/*        /!*                    value={user.type_of_package}*!/*/}
                                                {/*        /!*                    onChange={handleChange}*!/*/}
                                                {/*        /!*                    style={{*!/*/}
                                                {/*        /!*                        display: 'flex',*!/*/}
                                                {/*        /!*                        flexDirection: 'row'*!/*/}
                                                {/*        /!*                    }}>*!/*/}
                                                {/*        /!*            <FormControlLabel value="truck" control={<Radio/>}*!/*/}
                                                {/*        /!*                              label="Truck"/>*!/*/}
                                                {/*        /!*            <FormControlLabel value="car" disabled*!/*/}
                                                {/*        /!*                              control={<Radio/>} label="Car"/>*!/*/}
                                                {/*        /!*        </RadioGroup>*!/*/}
                                                {/*        /!*    </Grid>*!/*/}

                                                {/*        /!*</Grid>*!/*/}
                                                {/*</Grid>*/}
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="First name"
                                                        name="first_name"
                                                        onChange={handleChange}
                                                        required
                                                        value={user.first_name}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Last name"
                                                        name="last_name"
                                                        onChange={handleChange}
                                                        required
                                                        value={user.last_name}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Email Address"
                                                        name="email"
                                                        onChange={handleChange}
                                                        required
                                                        value={user.email}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <MuiPhoneNumber
                                                        fullWidth
                                                        disableAreaCodes
                                                        disableDropdown
                                                        requierd
                                                        id="phone_number"
                                                        label="Phone Number"
                                                        variant="outlined"
                                                        name="phone_number"
                                                        data-cy="phone_number"
                                                        defaultCountry={"ca"}
                                                        value={user.formatted_phone_number}
                                                        onChange={handlePhoneNumberChange}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        id="licence_number"
                                                        label="Licence Number"
                                                        variant="outlined"
                                                        name="licence_number"
                                                        onChange={handleChange}
                                                        value={user.licence_number}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    xs={12}

                                                >
                                                    <Grid container spacing={1} style={{alignItems: 'center'}}>
                                                        <TextField
                                                            fullWidth
                                                            label="Type of Payment"
                                                            name="type_of_payment"
                                                            onChange={handleChange}
                                                            required
                                                            select
                                                            SelectProps={{ native: true }}
                                                            value={user.type_of_payment}
                                                            variant="outlined"
                                                        >
                                                            {payment_types.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                        {/*<Grid item xs={6} md={2} lg={2}>*/}
                                                        {/*    <FormLabel component="legend">Type of Payment</FormLabel>*/}
                                                        {/*</Grid>*/}
                                                        {/*<Grid item xs={6} md={10} lg={10}*/}
                                                        {/*      style={{display: 'flex', flexDirection: 'row'}}>*/}
                                                        {/*    <RadioGroup aria-label="type_of_payment"*/}
                                                        {/*                name="type_of_payment"*/}
                                                        {/*                value={user.type_of_payment}*/}
                                                        {/*                onChange={handleChange}>*/}
                                                        {/*        <FormControlLabel value="paypal" control={<Radio/>}*/}
                                                        {/*                          label="Paypal"/>*/}
                                                        {/*        <FormControlLabel value="credit_card" control={<Radio/>}*/}
                                                        {/*                          label="Credit Card"/>*/}
                                                        {/*        <FormControlLabel value="debit_card" control={<Radio/>}*/}
                                                        {/*                          label="Debit card"/>*/}
                                                        {/*        <FormControlLabel value="cheque" control={<Radio/>}*/}
                                                        {/*                          label="Cheque"/>*/}
                                                        {/*        <FormControlLabel value="cash" control={<Radio/>}*/}
                                                        {/*                          label="Cash"/>*/}
                                                        {/*        <FormControlLabel value="e_transfer" control={<Radio/>}*/}
                                                        {/*                          label="e-Transfer"/>*/}

                                                        {/*    </RadioGroup>*/}
                                                        {/*</Grid>*/}

                                                    </Grid>

                                                </Grid>

                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}

                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Type of Car"
                                                        name="type_of_car"
                                                        onChange={handleTypeOfCarSelection}
                                                        required
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={user.type_of_car}
                                                        variant="outlined"
                                                    >
                                                        {transmission_types.map((option) => (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                            >
                                                                {user.package === 'finance'? option.f_label : option.label}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                    {/*<Grid container spacing={1}>*/}
                                                    {/*    <Grid*/}
                                                    {/*        item*/}
                                                    {/*        xs={12}*/}
                                                    {/*        md={12}*/}
                                                    {/*    >*/}
                                                    {/*        <Card className={classes.root}*/}
                                                    {/*              name="manual"*/}
                                                    {/*              onClick={() => handleTypeOfCarSelection('manual')}*/}
                                                    {/*              style={user.type_of_car === 'manual' ? {backgroundColor: '#58D68D'} : null}>*/}
                                                    {/*            <CardActionArea>*/}
                                                    {/*                <CardContent>*/}
                                                    {/*                    <Typography gutterBottom variant="h5"*/}
                                                    {/*                                component="h2">*/}
                                                    {/*                        Manual*/}
                                                    {/*                    </Typography>*/}
                                                    {/*                    {user.package==='full'?*/}
                                                    {/*                    <Typography variant="body1"*/}
                                                    {/*                                color="textSecondary" component="p">*/}
                                                    {/*                        Manual package fee for 24 hours: $1699*/}
                                                    {/*                    </Typography>*/}
                                                    {/*                        :null }*/}
                                                    {/*                </CardContent>*/}
                                                    {/*            </CardActionArea>*/}
                                                    {/*        </Card>*/}
                                                    {/*    </Grid>*/}

                                                    {/*    <Grid*/}
                                                    {/*        item*/}
                                                    {/*        xs={12}*/}
                                                    {/*        md={12}*/}
                                                    {/*    >*/}
                                                    {/*        <Card className={classes.root}*/}
                                                    {/*              onClick={() => handleTypeOfCarSelection('auto')}*/}
                                                    {/*              style={user.type_of_car === 'auto' ? {backgroundColor: '#58D68D'} : null}>*/}
                                                    {/*            <CardActionArea>*/}
                                                    {/*                <CardContent>*/}
                                                    {/*                    <Typography gutterBottom variant="h5"*/}
                                                    {/*                                component="h2">*/}
                                                    {/*                        Auto*/}
                                                    {/*                    </Typography>*/}
                                                    {/*                    {user.package==='full'?*/}
                                                    {/*                        <Typography variant="body1"*/}
                                                    {/*                                    color="textSecondary" component="p">*/}
                                                    {/*                            Auto package fee for 24 hours: $1499*/}
                                                    {/*                        </Typography>*/}
                                                    {/*                        : null}*/}

                                                    {/*                </CardContent>*/}
                                                    {/*            </CardActionArea>*/}
                                                    {/*        </Card>*/}
                                                    {/*    </Grid>*/}
                                                    {/*</Grid>*/}


                                                </Grid>


                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}

                                                >
                                                    {
                                                        user.package === 'full' || user.package === 'finance'?
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: 'space-evenly',
                                                                flexWrap: 'wrap',
                                                            }}>

                                                                <Box minWidth={35}>
                                                                    <FormControl style={{minWidth: 120}}
                                                                                 variant="outlined"
                                                                                // className={classes.formControl}
                                                                    >
                                                                        <InputLabel id="demo-simple-select-label">Training
                                                                            Hours</InputLabel>
                                                                        <Select
                                                                            MenuProps={{ classes: { paper: classes.formControl } }}
                                                                            id="hour"
                                                                            label="Training Hours"
                                                                            // variant="outlined"
                                                                            name="hour"
                                                                            value={user.hour}
                                                                            onChange={handleChange}
                                                                        >
                                                                            {hoursArray.map(hour => (
                                                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>

                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>


                                                                </Box>


                                                                <Box minWidth={35}>
                                                                    <FormControl style={{minWidth: 120}}
                                                                                 variant="outlined">
                                                                        <InputLabel
                                                                            id="demo-simple-select-label">Inspection</InputLabel>
                                                                        <Select
                                                                            MenuProps={{ classes: { paper: classes.formControl } }}
                                                                            id="hour"
                                                                            label="Inspection Hours"
                                                                            // variant="outlined"
                                                                            name="inspection_hour"
                                                                            value={user.inspection_hour}
                                                                            onChange={handleChange}
                                                                        >
                                                                            {hoursArray.map(hour => (
                                                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>

                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>


                                                                </Box>
                                                                {user.package === 'finance'?
                                                                    <Box minWidth={35}>
                                                                        <FormControl style={{minWidth: 120}}
                                                                                     variant="outlined">
                                                                            <InputLabel
                                                                                id="demo-simple-select-label">Job</InputLabel>
                                                                            <Select
                                                                                MenuProps={{ classes: { paper: classes.formControl } }}
                                                                                id="job_env_intro"
                                                                                label="Job hours"
                                                                                // variant="outlined"
                                                                                name="job_env_intro"
                                                                                value={user.job_env_intro}
                                                                                onChange={handleChange}
                                                                            >
                                                                                {hoursArray.map(hour => (
                                                                                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>

                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>


                                                                    </Box>
                                                                    :null}

                                                            </div> : null

                                                            // : <div style={{
                                                            //     display: "flex",
                                                            //     justifyContent: 'space-evenly',
                                                            //     flexWrap: 'wrap',
                                                            // }}>
                                                            //     <Box minWidth={35}>
                                                            //         <FormControl style={{minWidth: 120}} variant="outlined">
                                                            //             <InputLabel id="demo-simple-select-label">Extra
                                                            //                 Hours</InputLabel>
                                                            //             <Select
                                                            //                 MenuProps={{ classes: { paper: classes.formControl } }}
                                                            //                 id="hour"
                                                            //                 label="Training Hours"
                                                            //                 name="hour"
                                                            //                 value={user.hour}
                                                            //                 onChange={handleCustomExtraHourChange}
                                                            //             >
                                                            //                 {hoursArray.map(hour => (
                                                            //                     <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                            //
                                                            //                 ))}
                                                            //             </Select>
                                                            //         </FormControl>
                                                            //
                                                            //
                                                            //     </Box>
                                                            // </div>
                                                    }

                                                </Grid>


                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}

                                                >
                                                    {user.package !== 'custom'?
                                                        <div>
                                                            <Box style={{
                                                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                                justifyContent: 'space-evenly', flexWrap: 'wrap'
                                                            }}>


                                                                <FormControl className={classes.margin} variant="outlined">
                                                                    <InputLabel
                                                                        htmlFor="outlined-adornment-amount">Fee</InputLabel>
                                                                    <OutlinedInput
                                                                        id="agreement"
                                                                        label="Fee"
                                                                        variant="outlined"
                                                                        name="agreement"
                                                                        value={tabvalue === 'full'|| tabvalue === 'finance'?
                                                                            user.agreement : tabvalue==='custom'?
                                                                                user.agreement_for_custom_fake : 0}
                                                                        onChange={handleAgreementChange}
                                                                        startAdornment={<InputAdornment
                                                                            position="start">C$</InputAdornment>}
                                                                        labelWidth={60}
                                                                    />
                                                                </FormControl>

                                                                <br/>

                                                                <Typography variant="body1" color="textPrimary"
                                                                            component="p">
                                                                    After
                                                                    Tax: {user.agreement !== '' ?
                                                                    truncateDecimals((parseFloat(tabvalue === 'full'?
                                                                        user.agreement : tabvalue==='custom'?
                                                                            user.agreement_for_custom_fake:  tabvalue === 'finance'? constants.f_package_price : 0

                                                                        ))
                                                                        * (1 + (constants.tax_rate/100)), 2) : '00.00'}
                                                                </Typography>

                                                            </Box>

                                                        </div>

                                                    : null}


                                                </Grid>


                                            </Grid>
                                        {/*</form>*/}

                                    </CardContent>
                                    <Divider/>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        p={2}
                                    >
                                        <Button
                                            onClick={handleSubmit} color="primary" type={"submit"}
                                            variant={!loading? "contained": "outlined"}
                                        >
                                            {loading ? <CircularProgress size={24} className={classes.buttonProgress}/> : 'Create'}
                                        </Button>
                                    </Box>
                                </Card>
                            </form>
                        </div>


                    </Grid>


                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        {/*<Page className={classes.pageRoot}>*/}
                            <form
                                onSubmit={setRedirect} autoComplete="off"
                                noValidate
                                // className={clsx(classes.root, className)}
                                {...rest}
                            >
                            <Card>

                                <CardHeader
                                    subheader="Search students by name"
                                    title="Search Students"

                                />
                                <Divider/>

                                <CardContent style={{display: 'flex', flexDirection:'row', justifyContent: 'center'}}>
                                    {/*<form >*/}
                                        <TextField style={{}} id="outlined-basic" label="Search Student"
                                                   variant="outlined"
                                                   color={"secondary"}
                                                   onChange={handleIdChange}
                                                   value={studentSearchId}
                                                   required/>
                                    {/*</form>*/}


                                    <IconButton size={"medium"} onClick={setRedirect} type="submit">
                                        <SearchSharpIcon color={"secondary"}/>
                                    </IconButton>

                                </CardContent>
                            </Card>
                            </form>
                        {/*</Page>*/}

                            {/*<Typography style={{marginTop: 0}} color={"primary"}*/}
                            {/*            variant="h5" component="h2" gutterBottom className={classes.color}>*/}
                            {/*    Search Student<SearchIcon color={"primary"}/>*/}
                            {/*</Typography>*/}

                            {/*<div className={classes.search}>*/}
                            {/*    <div className={classes.row}>*/}
                            {/*        <form onSubmit={setRedirect} autoComplete="off"*/}
                            {/*        >*/}
                            {/*            <TextField style={{}} id="outlined-basic" label="Search Student"*/}
                            {/*                       variant="outlined"*/}
                            {/*                       color={"secondary"}*/}
                            {/*                       onChange={handleIdChange}*/}
                            {/*                       value={studentSearchId}*/}
                            {/*                       required/>*/}
                            {/*        </form>*/}


                            {/*        <IconButton size={"medium"} onClick={setRedirect} type="submit">*/}
                            {/*            <SearchSharpIcon color={"secondary"}/>*/}
                            {/*        </IconButton>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                    </Grid>


                </Grid>
            </Container>
            {renderRedirect()}

        </Page>
    )

}
