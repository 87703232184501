import {userConstants} from '../Constants';
// import update from 'react-addons-update';
import produce from 'immer';
import {act} from "@testing-library/react";

const initialState = {
    created_teacher_id: null, teacher_creation_done: false, fetched_teacher: {}, fetched_teacher_schedule: [],
    fetched_teacher_vacations: [], teacherTableData: [], fetched_teacher_overtimes: [], fetched_teacher_sessions: [],
    fetched_teacher_inspection_sessions: [],
    fetched_teacher_sessions_for_a_week: [], fetched_teacher_overtimes_for_a_week: [], fetched_teacher_vacations_for_a_week: [],
    fetched_teacher_inspection_sessions_for_a_week: [], fetched_deposits:[], secretary:{status: 'idle'}, deleteTeacher: 'idle'

}

export function teachers(state = initialState, action) {
    switch (action.type) {
        case userConstants.CREATE_TEACHER_SCHEDULE_REQUEST:
            return {
                ...state,
                creating_teacher_schedule: true,
                created_teacher_schedule: false
            }

        case userConstants.CREATE_TEACHER_SCHEDULE_SUCCESS:
            return {
                ...state,
                creating_teacher_schedule: false,
                created_teacher_schedule: true
            }

        case userConstants.CREATE_TEACHER_REQUEST:
            return {...state, registering: true};
        case userConstants.CREATE_TEACHER_SUCCESS:
            return {
                ...state,
                created_teacher: action.user.data,
                teacher_creation_done: true,
                created_teacher_id: action.user.data.id
            };
        case userConstants.CREATE_TEACHER_FAILURE:
            return {...state};

        case userConstants.GET_TEACHER_REQUEST:
            return {
                ...state,
                getting_teachers: true,
                fetched_teacher_done: false
            }
        case userConstants.GET_TEACHER_SUCCESS:
            return {
                ...state,
                fetched_teacher: action.teacher.data,
                getting_teachers: false,
                fetched_teacher_done: true
            }
        case userConstants.GET_TEACHER_FAILURE:
            return {...state};

        case userConstants.GET_TEACHER_SCHEDULE_REQUEST:
            return {
                ...state,
                getting_teacher_schedule: true
            }
        case userConstants.GET_TEACHER_SCHEDULE_SUCCESS:
            return {
                ...state,
                fetched_teacher_schedule: action.schedule.data,
                getting_teacher_schedule: false
            }
        case userConstants.GET_TEACHER_SCHEDULE_FAILURE:
            return {...state};

        case userConstants.GET_TEACHERS_LIST_REQUEST:
            return {
                ...state,
                getting_teachers_list: true
            }
        case userConstants.GET_TEACHERS_LIST_SUCCESS:
            return {
                ...state,
                fetched_teachers_list: action.teachers_list.data,
                getting_teachers_list: false,
                fetched_teachers_list_done: true
            }
        case userConstants.GET_TEACHERS_LIST_FAILURE:
            return {...state};


        case userConstants.RESET_LOCAL_TEACHER_SCHEDULE:
            return {...state, fetched_teacher_schedule: []}

        case userConstants.GET_TEACHER_VACATION_REQUEST:
            return {
                ...state, fetching_teacher_vacations: true,
                fetched_teacher_vacations_done: false
            }

        case userConstants.GET_TEACHER_VACATION_SUCCESS:
            return {
                ...state,
                fetching_teacher_vacations: false,
                fetched_teacher_vacations: action.vacations ? [...state.fetched_teacher_vacations, action.vacations] : [...state.fetched_teacher_vacations], //TODO: probably will override existing vacations
                fetched_teacher_vacations_done: true

            }

        case userConstants.GET_TEACHER_VACATION_FAILURE:
            return {
                ...state,
            }

        case userConstants.GET_TEACHER_OVERTIME_REQUEST:
            return {
                ...state,
                fetching_teacher_overtimes: true,
                fetched_teacher_overtimes_done: false
            }

        case userConstants.GET_TEACHER_OVERTIME_SUCCESS:
            return {
                ...state,
                fetching_teacher_overtimes: false,
                fetched_teacher_overtimes: action.overtimes ? [...state.fetched_teacher_overtimes, action.overtimes] : [...state.fetched_teacher_overtimes], //TODO: probably will override existing vacations
                fetched_teacher_overtimes_done: true

            }

        case userConstants.GET_TEACHER_OVERTIME_FAILURE:
            return {
                ...state,
            }


        case userConstants.CLEAR_FETCHED_TEACHER_VACATIONS:
            return {
                ...state,
                fetched_teacher_vacations_done: false,
                fetched_teacher_vacations: []
            }

        case userConstants.CLEAR_FETCHED_TEACHER_OVERTIMES:
            return {
                ...state,
                fetched_teacher_overtimes_done: false,
                fetched_teacher_overtimes: []
            }

        case userConstants.CLEAR_FETCHED_TEACHER_SESSIONS:
            return {
                ...state,
                fetched_teacher_sessions_done: false,
                fetched_teacher_sessions: []
            }

        // case userConstants.CREATE_TEACHER_VACATION_REQUEST:
        //     return {
        //         ...state,
        //         creating_teacher_vacation: true,
        //         created_teacher_vacation: false,
        //     }
        // case userConstants.CREATE_TEACHER_VACATION_SUCCESS:
        //     return {
        //         ...state,
        //         creating_teacher_vacation: false,
        //         created_teacher_vacation: true,
        //     }
        //
        // case userConstants.CREATE_TEACHER_VACATION_FAILURE:
        //     return {
        //         ...state,
        //         creating_teacher_vacation: false,
        //         created_teacher_vacation: false,
        //     }

        case userConstants.INITIALIZE_TEACHER_TABLE_DATA:
            return {
                ...state,
                teacherTableData: action.data
            }
        case userConstants.SET_TEACHER_SCHEDULE_TIME:
            return produce(state, draft => {
                draft.teacherTableData[action.index].time = action.time;

            });
        case userConstants.TEACHER_SCHEDULE_GO_NEXT_WEEK:
            return produce(state, draft => {
                draft.teacherTableData[action.index].Day = action.Day
                draft.teacherTableData[action.index].Date = action.Date
            });
        case userConstants.TEACHER_SCHEDULE_GO_PREVIOUS_WEEK:
            return produce(state, draft => {
                draft.teacherTableData[action.index].Day = action.Day
                draft.teacherTableData[action.index].Date = action.Date
            });

        case userConstants.SET_TEACHER_VACATIONS_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].vacations = action.vacation
            })


        // case userConstants.CREATE_TEACHER_OVERTIME_SUCCESS:
        //     return {
        //         ...state,
        //         creating_teacher_overtime: false,
        //         created_teacher_overtime: true,
        //     }

        case userConstants.CLEAR_CREATED_TEACHER_OVERTIME_DONE:
            return {
                ...state,
                created_teacher_overtime: false
            }

        case userConstants.CLEAR_CREATED_TEACHER_VACATION_DONE:
            return {
                ...state,
                created_teacher_vacation: false
            }

        case userConstants.DELETE_TEACHER_SCHEDULE_REQUEST:
            return {
                ...state,
                deleting_teacher_schedule: true,
                deleted_teacher_schedule: false,
                deleted_teacher_schedule_day: ""

            }
        case userConstants.DELETE_TEACHER_SCHEDULE_SUCCESS:
            return {
                ...state,
                deleting_teacher_schedule: false,
                deleted_teacher_schedule: true,
                deleted_teacher_schedule_day: action.week_day
            }

        case userConstants.DELETE_SCHEDULE_AFTER_SUCCESSFUL_RESPONSE:
            return produce(state, draft => {
                draft.teacherTableData[action.deletedScheduleIndex].time = []
            })

        case userConstants.SET_TEACHER_OVERTIMES_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].overtimes = action.overtime
            })

        case userConstants.GET_TEACHER_SESSIONS_REQUEST:
            return {
                ...state,
                fetching_teacher_sessions: true,
                fetched_teacher_sessions_done: false
            }

        case userConstants.GET_TEACHER_SESSIONS_SUCCESS:
            return {
                ...state,
                fetching_teacher_sessions: false,
                fetched_teacher_sessions: action.sessions ? [...state.fetched_teacher_sessions, action.sessions] : [...state.fetched_teacher_sessions], //TODO: probably will override existing vacations
                fetched_teacher_sessions_done: true

            }

        case userConstants.GET_TEACHER_SESSIONS_FAILURE:
            return {
                ...state
            }

        case userConstants.SET_TEACHER_SESSIONS_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].sessions = action.session
            })


        case userConstants.CLEAR_TEACHER_TABLE_DATA:
            return {
                ...state,
                teacherTableData: []
            }

        case userConstants.CLEAR_FETCHED_TEACHER:
            return {
                ...state,
                fetched_teacher: {}
            }

        case userConstants.CLEAR_CREATED_TEACHER:
            return {
                ...state,
                teacher_creation_done: false,
                created_teacher_id: undefined
            }

        case userConstants.SET_SELECTED_TEMP_TIME_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].selectedTempTime = action.time
            })

        case userConstants.CLEAR_SELECTED_TEMP_TIME_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].selectedTempTime = []
            })
        case userConstants.CLEAR_TEACHER_SCHEDULE_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].time = []
            })

        // case userConstants.SET_SELECTED_SESSION_IN_DATA:
        //     return produce(state, draft => {
        //         draft.teacherTableData[action.index].selectedSessions = action.time
        //     })

        // case userConstants.CLEAR_SELECTED_SESSION_IN_DATA:
        //     return produce(state, draft => {
        //         draft.teacherTableData[action.index].selectedSessions = []
        //     })

        case userConstants.CLEAR_FETCHED_TEACHER_SCHEDULES:
            return produce(state, draft => {
                draft.fetched_teacher_schedule = []
            })


        case userConstants.CLEAR_ALL_FETCHED_DATA:
            return {
                ...state,
                fetched_teacher_overtimes_done: false,
                fetched_teacher_vacations_done: false,
                fetched_teacher_sessions_done: false,
                    fetched_teacher_overtimes: [],
                fetched_teacher_vacations: [],
                fetched_teacher_sessions: []
            }

        case userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_REQUEST:
            return {
                ...state,
                fetching_teacher_sessions: true,
                fetched_teacher_sessions_done: false
            }

        case userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_SUCCESS:
            return {
                ...state,
                fetching_teacher_sessions: false,
                fetched_teacher_sessions_done: true,
                fetched_teacher_sessions_for_a_week: action.sessions

            }

        case userConstants.GET_TEACHER_SESSIONS_FOR_A_WEEK_FAILURE:
            return {
                ...state,

            }

        case userConstants.PROCESS_TEACHER_SESSIONS_FOR_A_WEEK:
            return {
                ...state,
                fetched_teacher_sessions: action.sessions,
            }

        case userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_REQUEST:
            return {
                ...state,
                fetching_teacher_overtimes: true,
                fetched_teacher_overtimes_done: false
            }

        case userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_SUCCESS:
            return {
                ...state,
                fetching_teacher_overtimes: false,
                fetched_teacher_overtimes_for_a_week : action.overtimes,
                fetched_teacher_overtimes_done: true
            }

        case userConstants.GET_TEACHER_OVERTIME_FOR_A_WEEK_FAILURE:
            return {
                ...state,
            }

        case userConstants.PROCESS_TEACHER_OVERTIMES_FOR_A_WEEK:
            return {
                ...state,
                fetched_teacher_overtimes: action.overtimes,
            }


        case userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_REQUEST:
            return {
                ...state,
                fetching_teacher_vacations: true,
                fetched_teacher_vacations_done: false
            }

        case userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_SUCCESS:
            return {
                ...state,
                fetching_teacher_vacations: false,
                fetched_teacher_vacations_for_a_week : action.vacations,
                fetched_teacher_vacations_done: true
            }

        case userConstants.GET_TEACHER_VACATIONS_FOR_A_WEEK_FAILURE:
            return {
                ...state,

            }

        case userConstants.PROCESS_TEACHER_VACATIONS_FOR_A_WEEK:
            return {
                ...state,
                fetched_teacher_vacations: action.vacations,
            }



        case userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_REQUEST:
            return {
                ...state,
                fetching_teacher_inspection_sessions: true,
                fetched_teacher_inspection_sessions_done: false
            }

        case userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_SUCCESS:
            return {
                ...state,
                fetching_teacher_inspection_sessions: false,
                fetched_teacher_inspection_sessions_done: true,
                fetched_teacher_inspection_sessions_for_a_week: action.sessions

            }

        case userConstants.GET_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK_FAILURE:
            return {
                ...state,

            }

        case userConstants.PROCESS_TEACHER_INSPECTION_SESSIONS_FOR_A_WEEK:
            return {
                ...state,
                fetched_teacher_inspection_sessions: action.sessions,
            }

        case userConstants.SET_TEACHER_INSPECTION_SESSIONS_IN_DATA:
            return produce(state, draft => {
                draft.teacherTableData[action.index].inspection_sessions = action.session
            })

        case userConstants.CLEAR_FETCHED_TEACHER_INSPECTION_SESSIONS:
            return {
                ...state,
                fetched_teacher_inspection_sessions_done: false,
                fetched_teacher_inspection_sessions: []
            }

        case userConstants.GET_ALL_DEPOSITS_REQUEST:
            return {
                ...state,
                fetching_all_deposits: true,
                fetched_all_deposits_done: false,

            }

        case userConstants.GET_ALL_DEPOSITS_SUCCESS:
            return {
                ...state,
                fetching_all_deposits: false,
                fetched_all_deposits_done: true,
                fetched_deposits:action.deposits.data
            }

            //TODO: add Failure


        case userConstants.CREATE_SECRETARY_REQUEST:
            return {
                ...state,
                secretary: {
                    ...state.secretary,
                    status: 'loading'
                }

            }

        case userConstants.CREATE_SECRETARY_SUCCESS:
            return {
                ...state,
                secretary: {
                    ...state.secretary,
                    status: 'succeeded'
                }

            }

        case userConstants.CREATE_SECRETARY_FAILURE:
            return {
                ...state,
                secretary: {
                    ...state.secretary,
                    status: 'failed'
                }

            }

        case userConstants.DELETE_TEACHER_REQUEST:
            return {
                ...state,
                deleteTeacher: 'loading'
            }

        case userConstants.DELETE_TEACHER_SUCCESS:
            return {
                ...state,
                deleteTeacher: 'succeeded'
            }

        case userConstants.DELETE_TEACHER_FAILURE:
            return {
                ...state,
                deleteTeacher: 'failed'
            }





        default:
            return state
    }
}
