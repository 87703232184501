export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('user'));

    if (token) {
        return { 'Authorization': 'Token ' + token.token };
    } else {
        return {};
    }

}
