import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Tabs, Tab, Divider, colors, makeStyles, Grid
} from '@material-ui/core';
import {history} from '../../Helpers'


import Page from "../../Components/Page";
import Header from "./components/Header";
import CreateTeacherSelect from "../TeacherManagementView/componenets/CreateTeacherSelect";
import TeachersListSelect from "../TeacherManagementView/componenets/TeachersListSelect";
import {CreateTeacher} from "../TeacherManagementView/componenets";
import TeachersList from "../TeacherManagementView/componenets/TeachersList";
import RentalSelect from "./components/RentalSelect";
import FinanceSelect from "./components/FinanceSelect";
import SessionsSelect from "./components/SessionsSelect";
import {userActions} from "../../Actions";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import {DollarSign as FinanceIcon, List as SessionIcon, Truck as RentalIcon} from "react-feather";
import ShowStudentSessions from "../../Containers/Student/ShowStudentSessions";
import FinanceContainer from "../../Containers/Student/Containers/FinanceContainer";
import RentalTruckContainer from "../../Containers/Student/Containers/RentalTruckContainer";
import StudentNotes from "../StudentNotes/StudentNotes";

const useStyles = makeStyles(theme => ({
    root: {

        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    inner: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    },
    select: {
        marginTop: theme.spacing(2)
    }
}));

const Profile = ({note}) => {
    const { id, tab, note_id } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    function handleNavigatePages(tab) {
        navigate(`/app/account/${id}/${tab}`,)
    }






    return (
        <Page
            className={classes.root}
            title="Student Profile"
        >
            <Header />
            <Divider />
            <Grid container spacing={2} className={classes.select}>

                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <SessionsSelect handleNavigatePages={handleNavigatePages} isselected={tab === 'sessions'}/>
                </Grid>

                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <FinanceSelect handleNavigatePages={handleNavigatePages} isselected={tab === 'finance'}/>
                </Grid>

                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <RentalSelect handleNavigatePages={handleNavigatePages} isselected={tab === 'rental_truck'}/>
                </Grid>


                <Grid item xs={12} md={12} lg={12}>
                    {
                        tab === 'sessions' && <ShowStudentSessions />
                    }
                    {
                        tab === 'finance' && <FinanceContainer/>
                    }
                    {
                        tab === 'rental_truck' && <RentalTruckContainer/>
                    }

                    {
                        note && <StudentNotes note_id={note_id}/>
                    }
                </Grid>
            </Grid>

        </Page>
    );
};

Profile.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default Profile;
