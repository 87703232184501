import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant={"standard"} {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function ErrorAlert({ alertMessage, severity} ) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (

            <Snackbar open={open} bodyStyle={{ width: '100%', height: '20vh',flexGrow: 0 }} autoHideDuration={6000} onClose={handleClose} className={classes.root}>
                <Alert onClose={handleClose} severity={severity}>
                    <Typography variant="overline" display="block" gutterBottom>
                        {alertMessage}
                    </Typography>
                </Alert>
            </Snackbar>

    );
}
