import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles, Icon, SvgIcon
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from "../../components/Logo";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { ReactComponent as MySvg } from '../../icons/truck (1).svg';
import {Users} from "react-feather";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  handleLogout,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);



  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/app/list">
          {/*<LocalShippingOutlinedIcon />*/}
          <SvgIcon >
            <MySvg/>
          </SvgIcon>
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton color="inherit"
                    component={RouterLink}
                    to={`/app/teachers/create`}
        >
          <Badge
              // badgeContent={notifications.length}
              color="primary"
              variant="dot"
          >
            <Users />
          </Badge>
        </IconButton>
          <IconButton color="inherit" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
