import React, {useState, useEffect} from 'react';
import {Link as RouterLink, Navigate, useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Typography,
    Button,
    Hidden,
    IconButton,
    Snackbar,
    Tooltip,
    colors,
    makeStyles,
    Menu,
    MenuItem,
    DialogTitle,
    DialogContent,
    Switch,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Card,
    CardActionArea,
    CardContent,
    Box,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    DialogActions, Dialog, List, ListItem, ListItemText
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import {useDispatch, useSelector} from "react-redux";
import {FileText, Upload} from "react-feather";
import {userActions} from "../../../../Actions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import Divider from "@material-ui/core/Divider";
import NavigateNextSharpIcon from "@material-ui/icons/NavigateNextSharp";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import Label from "../../../StudentNotes/components/Notes/components/Label";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import {lightGreen} from "@material-ui/core/colors";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px'
    },
    cover: {
        position: 'relative',
        height: 120,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        '&:before': {
            position: 'absolute',
            content: '" "',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundImage:
                'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
        },
        '&:hover': {
            '& $changeButton': {
                visibility: 'visible'
            }
        }
    },
    changeButton: {
        visibility: 'hidden',
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        backgroundColor: colors.blueGrey[900],
        color: theme.palette.white,
        [theme.breakpoints.down('md')]: {
            top: theme.spacing(3),
            bottom: 'auto'
        },
        '&:hover': {
            backgroundColor: colors.blueGrey[900]
        }
    },
    addPhotoIcon: {
        marginRight: theme.spacing(1)
    },
    container: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        padding: theme.spacing(2, 3),
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    avatar: {
        border: `2px solid ${theme.palette.white}`,
        height: 120,
        width: 120,
        top: -60,
        left: theme.spacing(3),
        position: 'absolute'
    },
    details: {
        marginLeft: theme.spacing(3)
    },
    actions: {
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1)
        },
        '& > * + *': {
            marginLeft: theme.spacing(1)
        }
    },
    pendingButton: {
        color: theme.palette.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    personAddIcon: {
        marginRight: theme.spacing(1)
    },
    mailIcon: {
        marginRight: theme.spacing(1)
    },
    active:{
        backgroundColor: colors.red[900]
    },

    deactive:{
        backgroundColor: colors.green[600]
    }
}));

const truncateDecimals = function (number, digits) {
    let multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};


const Header = props => {
    const {className, ...rest} = props;
    const {id, tab} = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userType = useSelector(state => state.authentication.userType)



    //IMPORTED FROM STUDENT Profile
    const student = useSelector(state => state.students.fetched_student);
    const [openEditStudentDialog, setOpenEditStudentDialog] = React.useState(false);
    const [openDeleteStudentDialog, setOpenDeleteStudentDialog] = React.useState(false);
    const deleted_student_done = useSelector(state => state.students.deleted_student_done)
    const editing_student = useSelector(state => state.students.editing_student)
    const student_edit_done = useSelector(state => state.students.student_edit_done)
    const deleting_sutdent = useSelector(state => state.students.deleting_student)
    const [studentListRedirect, setStudentListRedirect] = React.useState(false);
    const deleted_student_id = useSelector(state => state.students.deleted_student_id)
    const [loadingActiveStatusChange, setLoadingActiveStatusChange] = React.useState(false);
    const [activeStatusChangeSuccess, setActiveStatusChangeSuccess] = React.useState(false);
    const notes_list = useSelector(state => state.students.student_notes_list)

    const buttonClassname = clsx({
        [classes.buttonSuccess]: activeStatusChangeSuccess,
    });
    const [editStudentData, setEditStudentData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        formatted_phone_number: '',
        identification_number: '',
        home_phone_number: '0',
        formatted_home_phone_number: '',
        type_of_package: "",
        type_of_payment: "",
        type_of_car: "manual",
        licence_number: "",
        hour: 24,
        agreement: "",
        active: ""

    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleAnchorClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorClose = () => {
        setAnchorEl(null);
    };
    //
    //
    // const handleTabValueChange = (event, newValue) => {
    //     setTabValue(newValue);
    // };
    //
    //
    // const refreshStudentSessions = () => { // REFRESHES THE STUDENT SESSIONS AFTER It's been created. TODO: VERY BAD-- FIX
    //     dispatch(userActions.clearStudentSessionCreationDone())
    //     dispatch(userActions.getStudentSessions(id))
    //     dispatch(userActions.getStudentInspectionSessions(id))
    //
    //
    // }

    const handleEditStudent = () => {
        handleAnchorClose()
        setOpenEditStudentDialog(true)
        // we set the values we want to edit to the previous value that we got from server
        setEditStudentData(user => ({...user, first_name: student.first_name}));
        setEditStudentData(user => ({...user, last_name: student.last_name}));
        setEditStudentData(user => ({...user, email: student.email}));
        setEditStudentData(user => ({...user, phone_number: student.phone_number}));
        setEditStudentData(user => ({...user, formatted_phone_number: student.phone_number}));
        setEditStudentData(user => ({...user, identification_number: student.identification_number}));
        setEditStudentData(user => ({...user, licence_number: student.licence_number}));
        setEditStudentData(user => ({...user, agreement: student.agreement ? student.agreement : '1699.00'}));
        setEditStudentData(user => ({...user, active: student.active}));
        setEditStudentData(user => ({...user, inspection_hour: student.inspection_hour}));


        // we don't wanna edit these but they have to be in the edit request to server anyway
        setEditStudentData(user => ({...user, type_of_package: student.type_of_package}));
        setEditStudentData(user => ({...user, type_of_payment: student.type_of_payment}));
        setEditStudentData(user => ({...user, type_of_car: student.type_of_car}));
        setEditStudentData(user => ({...user, hour: student.hour}));


    }

    const handleCloseDeleteStudentDialog = () => {
        setOpenDeleteStudentDialog(false)

    }

    const handleDeleteStudent = () => {
        handleAnchorClose()
        setOpenDeleteStudentDialog(true)
    }

    const handleDeleteStudentCancel = () => {
        setOpenDeleteStudentDialog(false)

    }

    const handleDeleteStudentConfirm = () => {
        dispatch(userActions.deleteStudent(id))
        setOpenDeleteStudentDialog(false)
    }


    const handleEditStudentDialogClose = () => {
        dispatch(userActions.getStudent(id));
        setOpenEditStudentDialog(false)

    }

    function handleChange(e) {
        const {name, value} = e.target;
        setEditStudentData(user => ({...user, [name]: value}));
    }

    function handleEditStudentActiveChange() {
        setEditStudentData(user => ({...user, active: !user.active}));
    }

    function handlePhoneNumberChange(value) {
        if (value) {
            setEditStudentData(user => ({...user, formatted_phone_number: value}))
            setEditStudentData(user => ({
                ...user, phone_number:
                    value.replace(" ", "").replace("-", "").replace
                    ("(", "").replace(")", "").replace(" ", "")
            }))
        }
    }


    function handleTypeOfCarSelection(type) {

        setEditStudentData(user => ({...user, type_of_car: type}))

        if (type === 'manual') {
            setEditStudentData(user => ({...user, agreement: '1699'}))
        }
        if (type === 'auto') {
            setEditStudentData(user => ({...user, agreement: '1499'}))

        }
    }

    function handleEditAgreement(e) {
        setEditStudentData(user => ({...user, agreement: e.target.value.toString()}))
    }


    const handleEditSubmit = (e) => {

        e.preventDefault();
        if (editStudentData.first_name && editStudentData.last_name && editStudentData.phone_number && editStudentData.email &&
            editStudentData.identification_number && editStudentData.home_phone_number && editStudentData.type_of_payment
            && editStudentData.type_of_package) {

            dispatch(userActions.editStudent(editStudentData, id));
            setTimeout(function () {
                dispatch(userActions.getStudent(id));
            }, 800); // refresh afrer 800 ms
            handleEditStudentDialogClose()
        }

    }


    const renderStudentListRedirect = () => { // for redirecting to student list after student is deleted
        if (studentListRedirect) {
            return <Navigate to='/app/list'/>
        }
    }

    function handleChangeActiveStatus(e) {
        e.preventDefault()
        setLoadingActiveStatusChange(true)
        if (editStudentData.first_name && editStudentData.last_name && editStudentData.phone_number && editStudentData.email &&
            editStudentData.identification_number && editStudentData.home_phone_number && editStudentData.type_of_payment
            && editStudentData.type_of_package) {
            let temp = {...editStudentData}
            temp.active = !temp.active
            dispatch(userActions.editStudent(temp, id));
        }
    }

    useEffect(() => { // redirect to student list when deleted
        if (deleted_student_done && !deleting_sutdent && deleted_student_id === id) {
            setStudentListRedirect(true)
        }
    }, [deleted_student_done, deleting_sutdent, deleted_student_id])


    useEffect(() => {
        dispatch(userActions.getStudent(id));
        dispatch(userActions.getStudentNotesList(id))
        // dispatch(userActions.getTeachersList())
        dispatch(userActions.getStudentSessions(id))
        dispatch(userActions.getStudentInspectionSessions(id))
        dispatch(userActions.getStudentDeposits(id))
        dispatch(userActions.getStudentRents(id))
        // dispatch(userActions.getSettings())
        dispatch(userActions.clearStudentSessionCreationDone())
        dispatch(userActions.clearWeekTableData())

    }, []);

    useEffect(() => { // fill the edit object
        setEditStudentData(user => ({...user, first_name: student.first_name}));
        setEditStudentData(user => ({...user, last_name: student.last_name}));
        setEditStudentData(user => ({...user, email: student.email}));
        setEditStudentData(user => ({...user, phone_number: student.phone_number}));
        setEditStudentData(user => ({...user, formatted_phone_number: student.phone_number}));
        setEditStudentData(user => ({...user, identification_number: student.identification_number}));
        setEditStudentData(user => ({...user, licence_number: student.licence_number}));
        setEditStudentData(user => ({...user, agreement: student.agreement ? student.agreement : '1699.00'}));
        setEditStudentData(user => ({...user, active: student.active}));
        setEditStudentData(user => ({...user, inspection_hour: student.inspection_hour}));
        // we don't wanna edit these but they have to be in the edit request to server anyway
        setEditStudentData(user => ({...user, type_of_package: student.type_of_package}));
        setEditStudentData(user => ({...user, type_of_payment: student.type_of_payment}));
        setEditStudentData(user => ({...user, type_of_car: student.type_of_car}));
        setEditStudentData(user => ({...user, hour: student.hour}));
    }, [student])


    useEffect(() => {
        if (student_edit_done && !editing_student) {
            dispatch(userActions.resetEditStudent())
            setLoadingActiveStatusChange(false)
        }
    }, [editing_student, student_edit_done])


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Hidden mdUp>
                <div className={classes.actions}>
                    <Tooltip title="More options">
                        <IconButton onClick={handleAnchorClick}
                        >
                            <MoreIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            </Hidden>
            <div className={classes.container}>
                <div className={classes.details}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                    <AccountCircleOutlinedIcon style={{marginRight: '5px'}}/>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        {student.first_name + ' ' + student.last_name}
                    </Typography>
                    </div>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <PersonOutlineOutlinedIcon style={{marginRight: '5px'}}/>
                        <Typography
                            component="h2"
                            color="textSecondary"
                            gutterBottom
                            variant="h5"
                        >
                            {student.id}
                        </Typography>
                    </div>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <MailOutlineIcon style={{marginRight: '5px'}}/>
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="h5"
                        >
                            {student.email}
                        </Typography>
                    </div>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <PhoneIphoneOutlinedIcon style={{marginRight: '5px'}}/>
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="h5"
                        >
                            {student.phone_number}
                        </Typography>


                    </div>

                    <List className={classes.note}>
                        <ListItem
                            // divider={i < note.length - 1}
                            key={1}
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                            onClick={()=> navigate(`/app/account/${id}/${tab}/notes`,)}
                        >
                            <ListItemText>
                                <Typography
                                    // className={clsx({
                                    //     [classes.done]: note.done
                                    // })}
                                    variant="h6"

                                >
                                    Notes
                                </Typography>
                            </ListItemText>
                            <Label color={colors.green[600]}>
                                {notes_list? `${notes_list.count} notes` : 'loading notes'}
                            </Label>
                            <Tooltip title="View Notes">
                                <IconButton component={RouterLink} to={`/app/account/${id}/${tab}/notes`} size={"medium"}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItem>

                    </List>



                </div>
                <Hidden smDown>
                    <div className={classes.actions}>
                        <Button
                            color="secondary"
                            // component={RouterLink}
                            // to="/chat"
                            disabled={loadingActiveStatusChange}
                            onClick={handleChangeActiveStatus}
                            variant="contained"
                            className={student.active? classes.active : classes.deactive}
                        >
                            <PriorityHighOutlinedIcon className={classes.mailIcon}/>
                            {student.active? 'Deactivate' : 'Activate'}
                        </Button>

                        <Button
                            color="primary"
                            onClick={handleEditStudent}
                            variant="contained"
                        >
                            <EditIcon className={classes.personAddIcon}/>
                            Edit Profile
                        </Button>
                        <Tooltip title="More options">
                            <IconButton onClick={handleAnchorClick}
                            >
                                <MoreIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </Hidden>
                {/*<Button startIcon={<Book />}*/}
                {/*        color={"primary"}*/}
                {/*        variant={"contained"}*/}
                {/*        component={RouterLink}*/}
                {/*        size={"small"}*/}
                {/*        to={`/app/account/${id}/sessions/notes`}*/}
                {/*>*/}
                {/*    Student Notes*/}
                {/*</Button>*/}

            </div>





            <Hidden smDown>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
                >
                    <Menu
                        // id='long-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleAnchorClose}
                        // PaperProps={{
                        //     style: {
                        //         // maxHeight: ITEM_HEIGHT * 4.5,
                        //         width: '20ch'
                        //     }
                        // }}
                    >
                        {/*<MenuItem key={0} onClick={handleEditStudent}>*/}
                        {/*    <EditIcon/>*/}
                        {/*    Edit Student*/}
                        {/*</MenuItem>*/}
                        {/*<br/>*/}
                        <MenuItem
                            key={1}
                            onClick={handleDeleteStudent}
                            // style={{backgroundColor: ''}}
                        >
                            <DeleteForeverRoundedIcon/>
                            Delete Student
                        </MenuItem>
                        <MenuItem
                            key={2}
                            // style={{backgroundColor: ''}}
                            component={RouterLink}
                            to={`/app/certificate/${student.id}/${student.first_name}/${student.last_name}`}
                        >
                            <FileText />
                            Get Certificate
                        </MenuItem>

                        <MenuItem
                            key={2}
                            // style={{backgroundColor: ''}}
                            component={RouterLink}
                            to={`/app/exportStudentData/${student.id}/`}
                        >
                            <Upload />
                            Export Student Data
                        </MenuItem>
                    </Menu>
                </div>
            </Hidden>

            <Hidden mdUp>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
                >
                    <Menu
                        id='long-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleAnchorClose}
                        PaperProps={{
                            style: {
                                // maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch'
                            }
                        }}
                    >
                        <MenuItem key={0} onClick={handleEditStudent}>
                            <EditIcon/>
                            Edit Student
                        </MenuItem>
                        <MenuItem
                            key={1}
                            onClick={handleDeleteStudent}
                            style={{backgroundColor: ''}}
                        >
                            <DeleteForeverRoundedIcon/>
                            Delete Student
                        </MenuItem>

                        <MenuItem
                            key={3}
                            style={{backgroundColor: ''}}
                            component={RouterLink}
                            to={`/app/certificate/${student.id}/${student.first_name}/${student.last_name}`}
                        >
                            <FileText />
                            Get Certificate
                        </MenuItem>

                        <MenuItem
                            key={2}
                            style={{backgroundColor: ''}}
                            component={RouterLink}
                            to={`/app/exportStudentData/${student.id}/`}
                        >
                            <Upload />
                            Export Student Data
                        </MenuItem>

                        <MenuItem
                            key={4}
                            style={{backgroundColor: ''}}
                            disabled={loadingActiveStatusChange}
                            onClick={handleChangeActiveStatus}
                            variant="contained"
                        >
                            <PriorityHighOutlinedIcon className={classes.mailIcon}/>
                            {student.active? 'Deactivate' : 'Activate'}
                        </MenuItem>
                    </Menu>
                </div>
            </Hidden>











            <Dialog open={openEditStudentDialog} onClose={handleEditStudentDialogClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Student</DialogTitle>
                <DialogContent style={{}}>


                    <form className={classes.form} name="form" onSubmit={handleEditSubmit}
                          style={
                              {
                                  display: "flex",
                                  justifyContent: 'center',
                                  flexDirection: 'column'
                              }
                          }
                    >


                        <TextField
                            required
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            variant="outlined"
                            onChange={handleChange}
                            value={editStudentData.first_name}
                            className={classes.style}

                        />
                        <br/>
                        <TextField
                            required
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            // autoComplete="Last Name"
                            variant="outlined"
                            onChange={handleChange}
                            value={editStudentData.last_name}
                        />
                        <br/>

                        <TextField
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                            value={editStudentData.email}

                        />
                        <br/>

                        <MuiPhoneNumber
                            disableAreaCodes
                            disableDropdown
                            requierd
                            id="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            name="phone_number"
                            data-cy="phone_number"
                            defaultCountry={"ca"}
                            value={editStudentData.formatted_phone_number}
                            onChange={handlePhoneNumberChange}
                        />
                        <br/>

                        {userType === 'admin' && <TextField
                            required
                            id="identification_number"
                            label="Identification Number"
                            variant="outlined"
                            name="identification_number"
                            onChange={handleChange}
                            value={editStudentData.identification_number}

                        />
                        }
                        <br/>

                        <TextField
                            required
                            id="licence_number"
                            label="Licence Number"
                            variant="outlined"
                            name="licence_number"
                            onChange={handleChange}
                            value={editStudentData.licence_number}

                        />

                    </form>

                    <br/>
                    <Divider/>
                    <br/>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="body1" color="textSecondary" component="p">
                            Active Status:
                        </Typography>

                        <Switch
                            checked={editStudentData.active}
                            onChange={handleEditStudentActiveChange}
                            color="primary"
                            name="active"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </div>


                    <br/>
                    <Divider/>
                    <br/>


                    <FormControl component="fieldset" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly'
                    }}>
                        <div>
                            <FormLabel component="legend">Type of Package</FormLabel>
                            <RadioGroup aria-label="type_of_package" name="type_of_package"
                                        value={editStudentData.type_of_package} onChange={handleChange}>
                                <FormControlLabel value="car" disabled control={<Radio/>} label="Car"/>
                                <FormControlLabel value="truck" control={<Radio/>} label="Truck"/>
                            </RadioGroup>
                        </div>
                        <div>
                            <FormLabel component="legend">Type of Payment</FormLabel>
                            <RadioGroup aria-label="type_of_payment" name="type_of_payment"
                                        value={editStudentData.type_of_payment} onChange={handleChange}>
                                <FormControlLabel value="paypal" control={<Radio/>} label="Paypal"/>
                                <FormControlLabel value="credit_card" control={<Radio/>} label="Credit Card"/>
                                <FormControlLabel value="debit_card" control={<Radio/>} label="Debit card"/>
                                <FormControlLabel value="cheque" control={<Radio/>} label="Cheque"/>
                                <FormControlLabel value="cash" control={<Radio/>} label="Cash"/>
                                <FormControlLabel value="e_transfer" control={<Radio/>} label="e-Transfer"/>

                            </RadioGroup>
                        </div>
                    </FormControl>
                    <br/>
                    <Divider/>
                    <br/>
                    <div style={{display: "flex", flexDirection: 'row'}}>
                        <Card className={classes.root}
                              name="manual"
                              onClick={() => handleTypeOfCarSelection('manual')}
                              style={editStudentData.type_of_car === 'manual' ? {backgroundColor: '#58D68D'} : null}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Manual
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" component="p">
                                        Manual package fee for 24 hours: $1699
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card className={classes.root}
                              onClick={() => handleTypeOfCarSelection('auto')}
                              style={editStudentData.type_of_car === 'auto' ? {backgroundColor: '#58D68D'} : null}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Auto
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" component="p">
                                        Auto package fee for 24 hours: $1499
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    <br/>
                    <div>
                        <Box style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            justifyContent: 'space-evenly', flexWrap: 'wrap'
                        }}>
                            <FormControl className={classes.margin} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">Fee</InputLabel>
                                <OutlinedInput
                                    id="agreement"
                                    label="Fee"
                                    variant="outlined"
                                    name="agreement"
                                    value={editStudentData.agreement}
                                    onChange={handleEditAgreement}
                                    startadornment={<InputAdornment position="start">C$</InputAdornment>}
                                    labelWidth={60}
                                />
                            </FormControl>
                            <br/>
                            <Typography variant="body1" color="textPrimary" component="p">
                                After
                                Tax: {editStudentData.agreement !== '' ? truncateDecimals((parseFloat(editStudentData.agreement)) * (1 + 0.14975), 2) : '00.00'}
                            </Typography>
                        </Box>
                    </div>
                    <br/>


                </DialogContent>
                <DialogActions>
                    <Button startIcon={<NavigateNextSharpIcon/>} variant="contained"
                            size={"large"} color={"primary"}
                            onClick={handleEditSubmit}
                            className={classes.button}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>






            <Dialog open={openDeleteStudentDialog} onClose={handleCloseDeleteStudentDialog}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Are you Sure you want to delete this Student?</DialogTitle>
                <DialogActions>
                    <Button startIcon={<DeleteForeverRoundedIcon/>}
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteStudentConfirm}
                    >
                        Yes
                    </Button>
                    <Button startIcon={<ArrowBackIosOutlinedIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleDeleteStudentCancel}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            {renderStudentListRedirect()}

        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
