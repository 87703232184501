import React, {useEffect, useState} from 'react';
import {Navigate, useParams, useNavigate, Link as RouterLink} from 'react-router-dom';
import {

    Divider,
    makeStyles,
    colors,
    Grid,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Typography,
    DialogActions,
    Dialog,
    FormControl,
    CardHeader,
    Tooltip,
    CardActions,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton, ListItemSecondaryAction, Button, TextField, Input, InputAdornment
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import GenericMoreButton from "../GenericMoreButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import {ArrowRight, Check, Edit, Folder, Trash, X} from "react-feather";
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import moment from "moment";
import Label from "../Label";
import {userActions} from "../../../../Actions";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3)
    },
    tabs: {
        marginTop: theme.spacing(3)
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        // marginTop: theme.spacing(3)
    },
    object: {
        fontWeight: theme.typography.fontWeightMedium
    },

    createCardActions: {
        justifyContent: 'space-around'
    },


    listItem: {
        padding: theme.spacing(2, 0),
        justifyContent: 'space-between'
    },
    listItemDetails: { // Details
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        paddingBottom: 0
    },
    deleteButton: {
        color: '#E30425'
    },
    createSubmitButton:{
        color: colors.green[600]
    },
    selectedListItem: {
        borderRadius: '5px',
        backgroundColor: theme.palette.primary.main,
        // color: theme.palette.white
    },

    normalListItem:{
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selectedListItemText: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightMedium
    },
    selectedListItemDetails: {
        color: theme.palette.white,
        // fontWeight: theme.typography.fontWeightMedium
    },
    addObjectText: {
        minWidth: '100%'
    }
}));

const Expenses = () => {
    const {selectedObject} = useParams();
    const {selectedExpense} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const objects = useSelector(state => state.accounting.objects)
    const [currentObjectIndex, setCurrentObjectIndex] = React.useState(null)
    const [currentExpenseIndex, setCurrentExpenseIndex] = React.useState(null)
    const [addingObject, setAddingObject] = React.useState(false)
    const [addingExpense, setAddingExpense] = React.useState(false)
    const [editingExpense, setEditingExpense] = React.useState(false)

    const [objectNameData, setObjectNameData] = React.useState({name: ""})
    const creating_object = useSelector(state => state.accounting.creating_object)
    const [addingObjectRequest, setAddingObjectRequest] = React.useState(false)
    const [expenseData, setExpenseData] = React.useState({title: '', amount: '', detail:''})
    const creating_expense = useSelector(state => state.accounting.creating_expense)

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
    const deleting_expense= useSelector(state => state.accounting.deleting_expense)
    const [deletingItem, setDeletingItem] = React.useState(false)
    const deleting_object= useSelector(state => state.accounting.deleting_object)
    const [deletingObjectItem, setDeletingObjectItem] = React.useState(false)
    const fetching_objects=useSelector(state => state.accounting.fetching_objects)
    const [pendingEditExpenseRequest, setPendingEditExpenseRequest] = React.useState(false)
    const editing_expense = useSelector(state => state.accounting.editing_expense)



    useEffect(() => {
        if (objects.length < 1) {
            dispatch(userActions.getAccountingObjects())

        }
    }, [objects])

    useEffect(() => { // fetch expenses when object is clicked
        setCurrentObjectIndex(objects.findIndex(object => object.id === parseInt(selectedObject)))
        if (selectedObject && objects.length > 0 && objects.find(object => object.id === parseInt(selectedObject)).expenses === undefined) {
            const index = objects.findIndex(object => object.id === parseInt(selectedObject))
            dispatch(userActions.getObjectExpenses(parseInt(selectedObject), index))
        }

    }, [selectedObject, objects.length])

    useEffect(() => { // fetch expenses when object is clicked
        // if(selectedExpense && currentExpenseIndex === null){
        //     setCurrentExpenseIndex()
        // }
        if (selectedExpense &&
            objects.length > 0 &&
            objects[currentObjectIndex].expenses.find(expense => expense.id === parseInt(selectedExpense))) {
            setCurrentExpenseIndex(objects[currentObjectIndex].expenses.findIndex( // so we know the index of the current expense
                expense => expense.id === parseInt(selectedExpense)
            ))
        }

    }, [selectedExpense])

    useEffect(()=>{
        if(!creating_object && addingObjectRequest){ // means request is done
            setAddingObjectRequest(false)
            setAddingObject(false)
        }
    }, [creating_object, addingObjectRequest])

    function handleAddObjectToggle() {
        setAddingObject(true)
    }

    function handleAddObjectSubmit(){
        if(objectNameData){
            dispatch(userActions.AddAccountingObject(objectNameData))
            setAddingObjectRequest(true)

        }
    }

    function handleAddObjectClose(){
        setAddingObject(false)
        setObjectNameData({name: ''})

    }

    function handleChangeObjectNameData(e){
        const {name, value} = e.target
        setObjectNameData({name: value})
    }

    function handleAddExpenseToggle(){
        if(addingExpense === true){
            setExpenseData({title: '', amount: '', detail:''})
        }
        setAddingExpense(state=> !state)
    }

    function handleChange(e) {
        const {
            name,
            value
        } = e.target;
        setExpenseData(data => ({
            ...data,
            [name]: value
        }));
    }

    function handleAddExpenseSubmit(){
        if(expenseData.title && expenseData.amount){
            dispatch(userActions.AddObjectExpense(expenseData, selectedObject, currentObjectIndex))
            setAddingObjectRequest(true) // enable loading
        }
    }

    useEffect(()=>{
        if(!creating_expense && addingExpense){ // means request is done
            setAddingObjectRequest(false)
            setAddingExpense(false)
            setExpenseData({title: '', amount: '', detail:''})

        }
    }, [creating_expense, addingObjectRequest])

    function ToggleDeleteExpenseDialog(){
        setOpenDeleteDialog(true)
    }

    function handleCloseDeleteDialog(){
        setOpenDeleteDialog(false)
    }

    function handleConfirmDeleteExpense(){
        if(!deletingItem){ //deletingItem is only for loading purposes. deletingExpense comes from store
            dispatch(userActions.deleteExpense(selectedObject, currentObjectIndex,selectedExpense, currentExpenseIndex))
            setDeletingItem(true)

        }
    }



    useEffect(()=>{
        if(!deleting_expense && deletingItem){
            setDeletingItem(false)
            setOpenDeleteDialog(false)
            setCurrentExpenseIndex(null)

        }
    }, [deleting_expense, deletingItem])


    function handleToggleDeleteObject(object_id){
        navigate(`/app/accounting/expenses/${object_id}`,)
        setOpenDeleteDialog(true)
    }

    function handleConfirmDeleteObject(){
        if(!deletingObjectItem){ //deletingItem is only for loading purposes. deletingObject comes from store
            navigate(`/app/accounting/expenses/`,)
            dispatch(userActions.deleteObject(selectedObject, currentObjectIndex))
            setDeletingObjectItem(true)

        }
    }

    useEffect(()=>{
        if(!deleting_object && deletingObjectItem) {
            setDeletingObjectItem(false)
            navigate(`/app/accounting/expenses/`,)
            setOpenDeleteDialog(false)
            setCurrentExpenseIndex(null)
            setCurrentObjectIndex(null)
        }
    }, [deleting_object, deletingObjectItem])


    function handleEditExpenseToggle(){

        if(editingExpense){
            setExpenseData({title: '', amount: '', detail:''})

        } else{
            setExpenseData(objects[currentObjectIndex].expenses[currentExpenseIndex])

        }
            setEditingExpense(state=> !state)




    }

    function handleEditExpenseSubmit(){
        if(expenseData.title && expenseData.amount){
            dispatch(userActions.editExpense(expenseData, selectedObject, currentObjectIndex, selectedExpense, currentExpenseIndex))
            setPendingEditExpenseRequest(true) // enable loading
        }
    }

    useEffect(()=>{
        if(pendingEditExpenseRequest && !editing_expense){ // means request is done
            setPendingEditExpenseRequest(false)
            setEditingExpense(false)
            setExpenseData({title: '', amount: '', detail:''})
        }
    }, [editing_expense, pendingEditExpenseRequest])



    return (
        <div>
            <Grid container spacing={1}>
                <Grid item md={3} xs={12}>
                    {objects.length > 0 ?
                        <Card
                        >
                            <CardHeader
                                action={<Button
                                    color="primary"
                                    size="small"
                                    onClick={handleAddObjectToggle}
                                >
                                    <AddIcon className={classes.addIcon}/>
                                    Add
                                </Button>}
                                title={(<div>

                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Typography variant={"h5"} style={{marginRight: 10}}>
                                            Objects:
                                        </Typography>

                                    </div>

                                </div>)}
                            >
                            </CardHeader>
                            <Divider/>
                            <CardContent className={classes.content}>
                                <List>

                                    {addingObject ?
                                        <ListItem>
                                            <TextField placeholder={"Object Title"}
                                                       autoFocus inputProps={{className: classes.addObjectText}}
                                                value={objectNameData.name}
                                                       name={"objectNameData"}
                                                       fullWidth
                                                onChange={handleChangeObjectNameData}
                                            />


                                            <Tooltip title="Submit">
                                            <IconButton onClick={handleAddObjectSubmit} className={classes.createSubmitButton}>
                                                <Check />
                                            </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Cancel">
                                            <IconButton onClick={handleAddObjectClose} className={classes.deleteButton}>
                                                <X />
                                            </IconButton>
                                            </Tooltip>

                                        </ListItem>
                                        : null}

                                    {objects.map((object, index) => (
                                        <ListItem
                                            className={object.id === parseInt(selectedObject) ? classes.selectedListItem : classes.normalListItem}
                                            divider={index < objects.length - 1}
                                            key={object.id}
                                            component={RouterLink}
                                            to={`/app/accounting/expenses/${object.id}/`}
                                            onClick={()=>{setCurrentExpenseIndex(null)}}
                                        >
                                            <ListItemIcon> <Folder/> </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1"
                                                            className={object.id === parseInt(selectedObject) ? classes.selectedListItemText : null}>
                                                    {object.name}
                                                </Typography>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title="More Options">
                                                    <GenericMoreButton handleToggleDeleteObject={()=>handleToggleDeleteObject(object.id)}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>


                                    ))}
                                </List>


                            </CardContent>


                            <CardActions>

                            </CardActions>
                        </Card>

                        : null}

                </Grid>

                <Grid item md={4} xs={12}>
                    <Card
                    >
                        <CardHeader
                            action={<Button
                                color="primary"
                                size="small"
                                onClick={handleAddExpenseToggle}
                                disabled={!selectedObject}

                            >
                                <AddIcon className={classes.addIcon}/>
                                Add
                            </Button>}
                            title={(<div>

                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography variant={"h5"} style={{marginRight: 10}}>
                                        {
                                            objects[currentObjectIndex] ? objects[currentObjectIndex].name + '\'s Expenses:'
                                                : 'Expenses'
                                        }
                                    </Typography>

                                </div>

                            </div>)}
                        >
                        </CardHeader>
                        <Divider/>
                        <CardContent className={classes.content}>
                            {
                                !fetching_objects && selectedObject && objects.length > 0 &&
                                objects.find(object => object.id === parseInt(selectedObject)).expenses &&
                                objects.find(object => object.id === parseInt(selectedObject)).expenses.length > 0 ?
                                    <List>
                                        {objects.find(object => object.id === parseInt(selectedObject)).expenses.map((expense, index) => (

                                            <ListItem
                                                className={expense.id === parseInt(selectedExpense) ? classes.selectedListItem : classes.normalListItem}
                                                divider={index < objects.find(object => object.id === parseInt(selectedObject)).expenses.length - 1}
                                                key={expense.id}
                                                component={RouterLink}
                                                to={`/app/accounting/expenses/${expense._object}/expenses/${expense.id}`}
                                            >
                                                <ListItemIcon> <ReceiptOutlinedIcon/> </ListItemIcon>

                                                <ListItemText>
                                                    <Typography
                                                        className={expense.id === parseInt(selectedExpense) ? classes.selectedListItemDetails : null}
                                                        variant="h6"
                                                    >
                                                        {expense.title}
                                                    </Typography>
                                                    <Typography variant="body2"
                                                                className={expense.id === parseInt(selectedExpense) ? classes.selectedListItemDetails : null}>
                                                        {moment(expense.updated_at).format('DD/MM/YYYY | hh:mm')}
                                                    </Typography>
                                                </ListItemText>

                                                <Label color={colors.green[600]}>
                                                    ${expense.amount}
                                                </Label>


                                                <Tooltip title="View Details">
                                                    <IconButton component={RouterLink}
                                                                to={`/app/accounting/expenses/${expense._object}/expenses/${expense.id}`}
                                                                size={"medium"}>
                                                        <ArrowForwardIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>

                                        ))}

                                    </List>
                                    :
                                    selectedObject && objects.length > 0 &&
                                    objects.find(object => object.id === parseInt(selectedObject)).expenses &&
                                    objects.find(object => object.id === parseInt(selectedObject)).expenses.length === 0 ?

                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Typography variant={"subtitle2"} color={"textSecondary"} align={"center"}>
                                                No expenses yet! Click to add an expense
                                            </Typography>

                                        </div>
                                        :

                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Typography variant={"subtitle2"} color={"textSecondary"} align={"center"}>
                                                Select an object to see expenses
                                            </Typography>

                                        </div>

                            }


                        </CardContent>


                        <CardActions>

                        </CardActions>
                    </Card>
                </Grid>

                <Grid item md={5} xs={12}>
                    {!editingExpense && !addingExpense?<Card
                    >
                        <CardHeader
                            className={classes.header}
                            disableTypography
                            action={<Button
                                color="primary"
                                size="small"
                                onClick={handleEditExpenseToggle}
                                disabled={!selectedObject}

                            >
                                <Edit/>
                                Edit
                            </Button>}
                            title={
                                <Typography
                                    display="block"
                                    variant="h5"

                                >
                                    {/*{objects[currentObjectIndex].expenses[currentExpenseIndex]}'s Details*/}
                                    Details
                                </Typography>
                            }
                        />
                        <CardContent className={classes.content}>
                            {selectedExpense && currentExpenseIndex !== null && currentObjectIndex !== null && !deletingItem ?
                                <List>
                                    <ListItem
                                        className={classes.listItem}
                                        disableGutters
                                        divider
                                    >
                                        <Typography variant="subtitle2">Title</Typography>
                                        <Typography variant="h6">
                                            {objects[currentObjectIndex].expenses[currentExpenseIndex].title}
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        className={classes.listItem}
                                        disableGutters
                                        divider
                                    >
                                        <Typography variant="subtitle2">Last Updated</Typography>
                                        <Typography variant="h6">
                                            {moment
                                            (objects[currentObjectIndex].expenses
                                                [currentExpenseIndex].updated_at).format('DD MMM YYYY | hh:mm:ss')}
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        className={classes.listItem}
                                        disableGutters
                                        divider
                                    >
                                        <Typography variant="subtitle2">Amount</Typography>
                                        <Label
                                            color={colors.green[600]}>${objects[currentObjectIndex].expenses[currentExpenseIndex].amount}</Label>
                                    </ListItem>
                                    <ListItem
                                        className={classes.listItemDetails}
                                        disableGutters
                                        divider
                                    >
                                        <Typography variant="subtitle2">
                                            Details:
                                        </Typography>
                                        <Typography variant="h6">
                                            {objects[currentObjectIndex].expenses[currentExpenseIndex].detail}
                                        </Typography>
                                    </ListItem>
                                </List>
                                :

                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Typography variant={"subtitle2"} color={"textSecondary"} align={"center"}>
                                        Select an expense to see the details
                                    </Typography>

                                </div>
                            }

                        </CardContent>
                        <CardActions>
                            {selectedExpense && currentExpenseIndex !== null && currentObjectIndex !== null ?
                                <Button
                                    className={classes.deleteButton}
                                    size="small"
                                    startIcon={<Trash/>}
                                    onClick={ToggleDeleteExpenseDialog}
                                >

                                    Delete
                                </Button>

                                : null}

                        </CardActions>
                    </Card>

                        : addingExpense || editingExpense? // Adding Expense Card
                        <Card
                            // className={clsx(classes.root, className)}
                        >
                            <CardHeader
                                className={classes.header}

                                title={
                                    <Typography
                                        display="block"
                                        variant="h5"

                                    >
                                        {addingExpense? 'Create New Expense': 'Edit Expense'}
                                    </Typography>
                                }
                            />
                            <CardContent className={classes.content}>
                                    <List>
                                        <ListItem
                                            className={classes.listItem}
                                            disableGutters
                                            divider
                                        >
                                            <Typography variant="body1">Title</Typography>

                                            <FormControl >
                                                <Input
                                                    value={expenseData.title}
                                                    autoFocus
                                                    name={'title'}
                                                    placeholder={'Expense Title'}
                                                    onChange={handleChange}
                                                    required

                                                />
                                            </FormControl>

                                        </ListItem>

                                        <ListItem
                                            className={classes.listItem}
                                            disableGutters
                                            divider
                                        >
                                            <Typography variant="body1">Amount</Typography>
                                            <FormControl >
                                                <Input
                                                    value={expenseData.amount}
                                                    name={'amount'}
                                                    placeholder={'Amount'}
                                                    onChange={handleChange}
                                                    type="number"
                                                    required
                                                    startAdornment={<InputAdornment position="start">CA$</InputAdornment>}
                                                />
                                            </FormControl>


                                        </ListItem>
                                        <ListItem
                                            className={classes.listItemDetails}
                                            disableGutters
                                            divider
                                        >
                                            <TextField value={expenseData.detail}
                                                       name={'detail'}
                                                       onChange={handleChange}
                                                       placeholder={'Details (Optional)'}
                                                       multiline
                                                       rows={3}
                                            />
                                        </ListItem>
                                    </List>


                            </CardContent>
                            <CardActions className={classes.createCardActions}>
                                    <Button
                                        className={classes.createSubmitButton}
                                        size="small"
                                        startIcon={<Check/>}
                                        onClick={addingExpense? handleAddExpenseSubmit : handleEditExpenseSubmit}
                                    >

                                        Submit
                                    </Button>

                                <Button
                                    className={classes.deleteButton}
                                    size="small"
                                    startIcon={<X/>}
                                    onClick={
                                        addingExpense
                                        ?
                                            handleAddExpenseToggle
                                            :
                                        handleEditExpenseToggle
                                    }
                                >

                                    Cancel
                                </Button>


                            </CardActions>
                        </Card>

                        : null
                    }

                </Grid>

                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>
                        <Typography variant={"h5"}>
                            Are you sure you want to delete this item?
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Typography variant={"h6"}>
                            {!selectedExpense? 'This object and ALL OF ITS EXPENSES WILL BE DELETED. PROCEED WITH CAUTION' : null}
                        </Typography>
                    </DialogContent>

                    <DialogActions className={classes.createCardActions}>
                        <Button
                            className={classes.deleteButton}
                            size="small"
                            startIcon={<Trash/>}
                            onClick={selectedExpense?handleConfirmDeleteExpense : handleConfirmDeleteObject}
                            variant={"outlined"}
                        >

                            Delete
                        </Button>

                        <Button
                            className={classes.createSubmitButton}
                            size="small"
                            startIcon={<ArrowRight/>}
                            variant={"outlined"}
                            onClick={handleCloseDeleteDialog}
                        >

                            Don't Delete
                        </Button>


                    </DialogActions>
                </Dialog>


            </Grid>

        </div>


    );
};

Expenses.propTypes = {
    // history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired
};

export default Expenses;
