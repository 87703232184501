import * as React from "react";
import {useEffect} from "react";
import {

    Button, Grid, Hidden, makeStyles,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@material-ui/core";
import Search from "../../../components/Search";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    button: {
        paddingTop: theme.spacing(1),

        marginLeft: theme.spacing(2)
    },
    search: {
        marginLeft: theme.spacing(2)
    }
}));

export default function StudentsListComponent({students,handleClearFilter, onStudentClick, filterText, handleChangeFilter}) {
    const classes = useStyles();

    const [showActive, setShowActive] = React.useState(true)
    const [tempStudents, setTempStudents] = React.useState([])

    function filterStudents(students, filter) {
        if (filter) {
            return students.filter(student => student.active)

        } else {
            if (!filter) {
                return students.filter(student => !student.active)

            }
        }
    }

    function handleChangeSwitchFilter() {
        setShowActive(!showActive)
    }


    useEffect(() => {
        setTempStudents(filterStudents(students, showActive))
    }, [showActive, students])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            {/*<TextField*/}
                            {/*    // fullWidth*/}
                            {/*    label="Search By name"*/}
                            {/*    name="filterTex"*/}
                            {/*    onChange={handleChangeFilter}*/}
                            {/*    // required*/}
                            {/*    value={filterText}*/}
                            {/*    variant="standard"*/}
                            {/*/>*/}

                            <Search
                                className={classes.search}
                                handleChangeFilter={handleChangeFilter}
                                filterText={filterText}
                                showActive={showActive}
                                handleChangeSwitchFilter={handleChangeSwitchFilter}
                                handleClearFilter={handleClearFilter}
                                // onKey={onKey}
                            />


                            {/*<Grid item lg={2} xs={12} md={2}>*/}


                            {/*<div className={classes.button}>*/}
                            {/*    <Typography*/}
                            {/*        color={'textPrimary'}*/}
                            {/*        variant='body1'*/}
                            {/*    >*/}
                            {/*        {showActive? 'Showing Active Students' : !showActive? 'Showing Inactive Students': null}*/}

                            {/*    </Typography>*/}
                            {/*    <Switch*/}
                            {/*        checked={showActive}*/}
                            {/*        onChange={handleChangeFilter}*/}
                            {/*        color="primary"*/}
                            {/*        name="checkedB"*/}
                            {/*        inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*</Grid>*/}


                        </Grid>

                </Grid>


                <Grid item lg={12} xs={12} md={12}>
                    <TableContainer>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection='asc'>
                                        <Tooltip
                                            enterDelay={300}
                                            title="Sort"
                                        >
                                            <TableSortLabel
                                                active
                                                direction="asc"
                                            >
                                                ID
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    Identification Number*/}
                                    {/*</TableCell>*/}

                                    <TableCell>
                                        Action
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tempStudents.map((student) => (

                                    <TableRow
                                        onClick={() => onStudentClick(student.id)}
                                        hover
                                        key={student.id}

                                    >
                                        <TableCell>
                                            {student ? student.id : null}
                                        </TableCell>


                                        <TableCell>
                                            {student ? student.first_name + " " + student.last_name : null}
                                        </TableCell>


                                        {/*<TableCell>*/}
                                        {/*    {student ? student.identification_number : null}*/}
                                        {/*</TableCell>*/}

                                        <TableCell>
                                            <Hidden xsDown>
                                                <Button variant={"outlined"} color={"primary"}
                                                        onClick={() => onStudentClick(student.id)}
                                                >
                                                    Click to Open
                                                </Button>
                                            </Hidden>

                                            <Hidden smUp>
                                                <Button variant={"outlined"} color={"primary"}
                                                        onClick={() => onStudentClick(student.id)}
                                                >
                                                    Open
                                                </Button>
                                            </Hidden>

                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>


        </div>
    )


}
